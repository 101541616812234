import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrgaoService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('orgao', http, notification, authService);
  }

}
