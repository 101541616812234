export enum TIPOS_MODALIDADE_LICITACO {
  '99-Não Utilizado' = 0,
  '1-Concorrência' = 1,
  '2-Tomada de Preços' = 2,
  '3-Convite' = 3,
  '4-Concurso' = 4,
  '5-Leilão' = 5,
  '6-Dispensa por Valor' = 6,
  '7-Dispensa por outros motivos' = 7,
  '8-Inexigível' = 8,
  '9-Sem Licitação' = 9,
  '10-Pregão Eletrônico' = 10,
  '11-Pregão Presencial' = 11,
  '12-Adesão a Registro de Preço' = 12,
  '13-Chamada Pública' = 13,
  '14-RDC - Regime Diferenciado de Contratações Públicas' = 14,
  '15-Lei Nº 13.303/2016' = 15,
  '16-Lei Nº 13.303/2016 (Art. 29 ou 30)' = 16,
  '17-Internacional (GN 2349-9)' = 17,
  '18-Internacional (GN 2350-9)' = 18,
  '19-Contratação Emergencial de Organização (Art. 12, II da Lei Nº 9.454/2011)' = 19,
  '20-Dispensa COVID-19 (Art. 4º da Lei 13.979/2020)' = 20,
  '21-Dispensa (Lei 14.133/21)' = 21,
  '22-Inexigibilidade (Lei 14.133/21)' = 22,
  '23-Concorrência (Lei 14.133/21)' = 23,
  '24-Pregão (Lei 14.133/21)' = 24,
  '25-Concurso (Lei 14.133/21)' = 25,
  '26-Pregão (Medida Provisória 1.047/21)' = 26,
  '27-Dispensa (Medida Provisória 1.047/21)' = 27,
  '28-Internacional Não Competitiva' = 28,
  '29-Licitação Internacional Competitiva' = 29,
  '30-Credenciamento (Lei Nº 14.133/2021)' = 30,
  '31-Concorrência - Publicidade (Lei Nº 12.232/2010)' = 31,
  '32-Diálogo Competitivo (Lei Nº 14.133/2021)' = 32,
  '33-Alienação de bens, dispensada a licitação (Lei Nº 14.133/2021)' = 33,
  '34-Leilão (Lei Nº 14.133/2021)' = 34,
  '35-Adesão a Ata de Registro de Preços (Lei Nº 14.133/2021)' = 35,
  '36-Licitação Credenciamento - (Lei Nº 8.666/93)' = 36,
}
