import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractResponse } from './../../../../../../libs/shared/src/lib/interfaces/shared.interface';
import { AuthService } from './../../shared/services/auth.service';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root',
})
export class CidadeService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('cidade', http, notification, authService);
  }

  async update(cidade, idMp = null) {
    const urlAwsBrasao = await this.getUrlAvatar(
      cidade.brasao,
      cidade.nomCidade
    );
    cidade = { ...cidade, brasao: urlAwsBrasao };

    return await this.insertOrUpdate(cidade, idMp, 'cidade')
      .then((result) => {
        this.notification.success('Cidade', result.message);
        return result;
      })
      .catch((err) => {
        this.notification.warning('Cidade', err.message);
        return err;
      });
  }

  async insertOrUpdate(
    cidade: any,
    idMp: any,
    entidade: any
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      if (cidade.id) {
        this.http
          .put(
            this.getServer(entidade),
            cidade,
            this.headerAuthenticateOptions()
          )
          .toPromise()
          .then((response) => {
            resolve(response as AbstractResponse);
          })
          .catch((err) => {
            reject({
              message: err.error.message,
              result: undefined,
            });
          });
        return;
      }
      this.http
        .post(
          this.getServer(entidade),
          cidade,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response) => {
          resolve(response as AbstractResponse);
        })
        .catch((err) => {
          reject({
            message: err.error.message,
            result: undefined,
          });
        });
    });
  }

  async getAllCidadesInInterval(
    params: any,
    entidade: string = this.entidade,
    titulo: string = this.transformarEntidadeEmTitulo(entidade)
  ): Promise<any> {
    return this.http
      .post(
        `${this.getServer(entidade)}/params`,
        params,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { data: any; message: string }) => {
        const returned =
          result.message && result.data.length > 0
            ? result.message
            : 'Nenhum registro encontrado!';
        if (result.data.length == 0) {
          this.notification.warning(`${titulo}`, `${returned}`);
        }
        if (result.data.length > 0) {
          this.notification.success(`${titulo}`, result.message);
        }
        return result;
      })
      .catch((err: any) => {
        return this.notification.error(titulo, err);
      });
  }

  setCompareToTableColumns(displayData, tableColumns) {
    if (displayData.length === 0) {
      return;
    }

    tableColumns.map((column) => {
      column[`compare`] =
        typeof displayData[0][column.value] === 'string'
          ? (a, b) => a[column.value].localeCompare(b[column.value])
          : (a, b) => a[column.value] - b[column.value];
    });
  }
}
