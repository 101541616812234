import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Module } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http: HttpClient;
  public authServiceUrl = this.generateUrlByEnvironment();
  private token: string;
  private tokenDinamico: string;
  protected usuarioNome: string;
  protected usuarioEmail: string;
  protected usuarioCpf: string;
  protected unidadeGestoras: any;
  protected unidadeGestoraSelected: any;
  protected submodulos = [];
  protected formularios = [];
  private helper = new JwtHelperService();
  public subject: Subject<{
    autenticado: boolean;
    token?: string;
    subject?: any;
  }>;

  constructor(
    http: HttpClient,
    private router: Router,
    private cookierService: CookieService
  ) {
    this.http = http;
    this.subject = new Subject();
  }

  generateUrlByEnvironment() {
    // return environment.serviceUrl('auth-api');
    return `https://ps-auth-api.dev.publicservice.com.br`;
  }

  getUrlAppAcesso(): string {
    return `https://ps-acesso-app.dev.publicservice.com.br`;
  }

  public autenticarMod(
    ug = null
  ): Observable<{
    autenticado: boolean;
    token?: string;
    subject?: any;
  }> {
    if (localStorage) {
      localStorage.clear();
    }
    const data = {
      codModulo: 1,
      idUnidadeGestora: ug ? ug : 1,
    };
    this.http
      .post(this.authServiceUrl + '/api/loginModulo', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        }),
      })
      .subscribe(
        (response: any) => {
          if (response) {
            if (localStorage) {
              localStorage.clear();
              let formularios = [];
              const idSubmodulos = [];
              const submodulos = response.submodulos.filter((sub) => {
                if (!idSubmodulos.includes(sub.id)) {
                  idSubmodulos.push(sub.id);
                  return true;
                }
              });
              formularios = response.formularios.filter((item) => {
                return !formularios.includes(item);
              });
              localStorage.setItem('token', response.accessToken.accessToken);
              localStorage.setItem(
                'usuarioIdModulo',
                JSON.stringify(data.codModulo)
              );
              localStorage.setItem('submodulos', JSON.stringify(submodulos));
              localStorage.setItem('formularios', JSON.stringify(formularios));
              localStorage.setItem(
                'usuarioEmail',
                this.cookierService.get('user-email')
              );
              localStorage.setItem(
                'usuarioNome',
                this.cookierService.get('user-nome')
              );
              localStorage.setItem(
                'usuarioCpf',
                this.cookierService.get('user-cpf')
              );
              localStorage.setItem(
                'unidadeGestoraSelected',
                this.cookierService.get('user-ug')
              );
              localStorage.setItem(
                'unidadesGestoras',
                this.cookierService.get('user-ugs')
              );
              this.unidadeGestoras = this.cookierService.get('user-ugs');
              this.unidadeGestoraSelected = this.cookierService.get('user-ug');
              this.submodulos = submodulos;
              this.formularios = formularios;
            }
            this.token = response.accessToken.accessToken;
            this.sendNext();
          }
        },
        (err) => {
          this.sendNext(err);
          this.logout();
        }
      );

    return this.subject;
  }

  public async logout() {
    await this.http
      .post(this.authServiceUrl + '/api/logout', null, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getToken()}`,
        }),
      })
      .toPromise();

    this.token = null;
    localStorage.clear();
    this.cookierService.deleteAll('/', environment.domain);
    window.location.href = this.getUrlAppAcesso();
  }

  private sendNext(error?) {
    const nextValue = {
      autenticado: this.isAuthenticated(),
      token: this.token,
      subject: this.getIdUser(),
      err: error,
    };
    this.subject.next(nextValue);
    this.subject.complete();
  }

  getDateSelected() {
    // this.dateSelected.setHours(new Date().getHours());
    // this.dateSelected.setMinutes(new Date().getMinutes());
    // this.dateSelected.setSeconds(new Date().getSeconds());
    const dateSelected = moment(new Date()).format();
    // dateSelected = dateSelected.substr(0, dateSelected.lastIndexOf('-'));
    return dateSelected;
  }

  public isAuthenticated(): boolean {
    const t = localStorage.getItem('token');
    if (!t || !this.helper.decodeToken(t)) {
      return false;
    }
    return !this.helper.isTokenExpired(t);
  }

  public getAvatar() {
    if (localStorage && this.isAuthenticated()) {
      return this.cookierService.get('user-avatar');
    }
  }

  public getToken() {
    if (localStorage) {
      this.token = localStorage.getItem('token');
    }
    if (this.isAuthenticated()) {
      return this.token;
    }
    return null;
  }

  public getInfoToken() {
    if (localStorage && this.isAuthenticated()) {
      return this.helper.decodeToken(this.getToken());
    }
  }

  public getTokenDinamico() {
    if (localStorage) {
      this.tokenDinamico = localStorage.getItem('tokenDinamico');
    }

    if (this.isAuthenticated()) return this.tokenDinamico;
  }

  public getIdUser() {
    if (localStorage && this.isAuthenticated()) {
      const tkn = this.getToken();
      const userId = this.helper.decodeToken(tkn).userInfo.idUsuario;
      return userId;
    }
    return -1;
  }

  public getUserInfo(tokenDinamico = false) {
    if (localStorage && this.isAuthenticated()) {
      return this.helper.decodeToken(
        tokenDinamico ? this.getTokenDinamico() : this.getToken()
      ).userInfo;
    }
  }

  public getNomUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioNome = localStorage.getItem('usuarioNome');
      return this.usuarioNome;
    }
  }

  public getEmailUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioEmail = localStorage.getItem('usuarioEmail');
      return this.usuarioEmail;
    }
  }

  public getCpfUser() {
    if (localStorage && this.isAuthenticated()) {
      this.usuarioCpf = localStorage.getItem('usuarioCpf');
      return this.usuarioCpf;
    }
  }

  public getUnidadesGestoras() {
    if (localStorage && this.isAuthenticated()) {
      this.unidadeGestoras = JSON.parse(
        localStorage.getItem('unidadesGestoras')
      );
      return this.unidadeGestoras;
    }
  }

  clearAllCookies() {
    const allCookies: {} = this.cookierService.getAll();
    for (const cookieName in allCookies) {
      if (allCookies.hasOwnProperty(cookieName)) {
        this.cookierService.delete(cookieName);
      }
    }
  }

  public getOrgaoSelected() {
    if (localStorage && this.isAuthenticated()) {
      this.unidadeGestoraSelected = JSON.parse(
        localStorage.getItem('unidadeGestoraSelected')
      );
      return this.unidadeGestoraSelected;
    }
  }

  public async setOrgaoSelected(ug) {
    this.autenticarMod(ug.id).subscribe(
      (s: any) => {
        this.router.navigate(['/pages/dashboard']);
        if (localStorage && this.isAuthenticated()) {
          localStorage.setItem('unidadeGestoraSelected', JSON.stringify(ug));
        }
      },
      (err) => {}
    );
  }

  public getFormularios() {
    if (localStorage) {
      this.formularios = JSON.parse(localStorage.getItem('formularios'));
      return this.formularios;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.queryParams.cookie) {
      const cookies = JSON.parse(
        atob(decodeURIComponent(route.queryParams.cookie))
      );

      this.clearAllCookies();
      this.cookierService.set(
        'ps-token',
        cookies['ps-token'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-avatar',
        cookies['user-avatar'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-cpf',
        cookies['user-cpf'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-email',
        cookies['user-email'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-id',
        cookies['user-id'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-nome',
        cookies['user-nome'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-ug',
        cookies['user-ug'],
        1,
        '/',
        environment.domain
      );
      this.cookierService.set(
        'user-ugs',
        cookies['user-ugs'],
        1,
        '/',
        environment.domain
      );
      localStorage.clear();
    }
    if (!this.getToken() || !this.cookierService.get('ps-token')) {
      if (this.cookierService.get('ps-token')) {
        this.token = this.cookierService.get('ps-token');
        localStorage.setItem('token', this.token);
        localStorage.setItem(
          'modulo-icone',
          this.cookierService.get('modulo-icone')
        );
        localStorage.setItem(
          'modulo-nome',
          this.cookierService.get('modulo-nome')
        );
        const ugSelected = JSON.parse(this.cookierService.get('user-ug'));
        this.autenticarMod(ugSelected.id).subscribe((result: any) => {
          if (result?.err) {
            this.logout();
            return;
          }
          this.router.navigate(['']);
        });
        return true;
      }

      this.logout();
      window.location.href = `${this.getUrlAppAcesso()}?mod=1?`;
      return false;
    }

    return true;
  }

  public getmoduloNomeIcone(): Partial<Module> {
    return {
      nomModulo: localStorage.getItem('modulo-nome'),
      icone: localStorage.getItem('modulo-icone'),
    };
  }
}
