import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-nz-input',
  template: `
    <nz-input-group
      nzSearch
      *ngIf="type === 'lupa'"
      [nzAddOnAfter]="suffixIconButton"
    >
      <input
        type="text"
        nz-input
        [(ngModel)]="modalValue"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [mask]="to.mask?.pattern"
        [dropSpecialCharacters]="to.mask?.dropSpecialCharacters"
      />
    </nz-input-group>
    <ng-template #suffixIconButton>
      <button
        (click)="modalValue = null"
        nz-button
        type="button"
        nzType="text"
        nzSearch
      >
        <i nz-icon nzType="close-circle"></i>
      </button>

      <button
        (click)="showModal()"
        nz-button
        type="button"
        nzType="primary"
        nzSearch
      >
        <i nz-icon nzType="search"></i>
      </button>
    </ng-template>

    <nz-input-group
      *ngIf="type === 'text' || type === 'password'"
      [nzSuffix]="inputClearTpl"
    >
      <input
        nz-input
        [(ngModel)]="inputValue"
        [formControl]="formControl"
        [type]="to.type || 'text'"
        [formlyAttributes]="field"
        [mask]="to.mask?.pattern"
        [dropSpecialCharacters]="to.mask?.dropSpecialCharacters"
      />
      <ng-template #inputClearTpl>
        <i
          nz-icon
          class="ant-input-clear-icon"
          nzTheme="fill"
          nzType="close-circle"
          *ngIf="inputValue && !to.disabled"
          (click)="inputValue = null"
        ></i>
      </ng-template>
    </nz-input-group>

    <input
      *ngIf="type === 'mask'"
      nz-input
      [mask]="to.mask?.pattern"
      [dropSpecialCharacters]="to.mask?.dropSpecialCharacters"
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
    />

    <nz-input-number
      *ngIf="type === 'number'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [nzFormatter]="formatter"
      [nzParser]="parser"
    ></nz-input-number>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      nz-input-number {
        width: 100%;
      }
    `,
  ],
})
export class PsFormInputComponent extends FieldType {
  modal: any;
  inputValue;
  modalValue;

  constructor(private modalService: NzModalService) {
    super();
  }

  showModal(): void {
    this.modal = this.modalService.create({
      nzTitle: this.to.modalOptions.modalTitle,
      nzContent: this.to.modalOptions.modalComponent,
      nzFooter: null,
      nzWidth: 1000,
    });

    this.modal.afterClose.subscribe((result) => {
      this.to.modalEvent.selectItem(result.data);
      this.formControl.setValue(result.data.idPublico);
    });
  }

  get type() {
    return this.to.type || 'text';
  }

  get parser() {
    return this.to?.parser || Function(...['value'], `return value`);
  }

  get formatter() {
    return this.to?.formatter || Function(...['value'], `return value`);
  }
}
