import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '@ps-erp-angular/shared';
import { ApiComponent } from './api/api.component';
import { CidadeComponent } from './cidade/cidade.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditarPerfilComponent } from './editar-perfil/editar-perfil.component';
import { InstituicaoSiconfiComponent } from './instituicao-siconfi/instituicao-siconfi.component';
import { LayoutComponent } from './layout/layout.component';
import { LicencaComponent } from './licenca/licenca.component';
import { ModuloComponent } from './modulo/modulo.component';
import { OrgaoComponent } from './orgao/orgao.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PessoaFisicaComponent } from './pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './pessoa-juridica/pessoa-juridica.component';
import { ServidorCloudComponent } from './servidor-cloud/servidor-cloud.component';
import { SubmoduloComponent } from './submodulo/submodulo.component';
import { UnidadeGestoraComponent } from './unidade-gestora/unidade-gestora.component';
import { UnidadeOrcamentariaComponent } from './unidade-orcamentaria/unidade-orcamentaria.component';
import { UsuarioComponent } from './usuario/usuario.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'session',
    pathMatch: 'full',
  },
  {
    path: 'session',
    component: LayoutComponent,
    canActivate: [AuthService],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'editar-perfil', component: EditarPerfilComponent },
      {
        path: 'cadastros-basicos',
        children: [{ path: 'cidade', component: CidadeComponent }],
      },
      {
        path: 'pessoa',
        children: [
          { path: 'pessoa-fisica', component: PessoaFisicaComponent },
          { path: 'pessoa-juridica', component: PessoaJuridicaComponent },
        ],
      },
      {
        path: 'orgaos',
        children: [
          { path: 'unidade-gestora', component: UnidadeGestoraComponent },
          { path: 'orgao', component: OrgaoComponent },
          {
            path: 'unidade-orcamentaria',
            component: UnidadeOrcamentariaComponent,
          },
          {
            path: 'instituicao-siconfi',
            component: InstituicaoSiconfiComponent,
          },
        ],
      },
      {
        path: 'permissoes',
        children: [
          { path: 'licenca', component: LicencaComponent },
          { path: 'perfil', component: PerfilComponent },
          { path: 'usuario', component: UsuarioComponent },
          // { path: 'cliente', component: ClienteComponent },
          // { path: 'parceiro', component: ParceiroComponent },
        ],
      },
      {
        path: 'sistemas',
        children: [
          { path: 'servidor-cloud', component: ServidorCloudComponent },
          { path: 'modulo', component: ModuloComponent },
          { path: 'submodulo', component: SubmoduloComponent },
        ],
      },
      {
        path: 'controle',
        children: [
          { path: 'apis', component: ApiComponent },
          // { path: 'provedores', component: ProvedoresComponent },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
