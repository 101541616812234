import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable()
export class TableService {
  deepCopy(object: any | any[]) {
    return JSON.parse(JSON.stringify(object));
  }

  sort(sortAttribute: { key: string; value: string }, inputData: any[]) {
    const dataArr = this.deepCopy(inputData);
    if (sortAttribute.key === '' || sortAttribute.value === null) {
      return dataArr;
    }

    const outputDataList = dataArr.sort((a, b) => {
      const isAsc = sortAttribute.value === 'ascend';
      switch (sortAttribute.key) {
        case sortAttribute.key:
          return this.compare(
            typeof a[sortAttribute.key] !== 'string'
              ? a[sortAttribute.key]
              : a[sortAttribute.key].toUpperCase(),
            typeof b[sortAttribute.key] !== 'string'
              ? b[sortAttribute.key]
              : b[sortAttribute.key].toUpperCase(),
            isAsc,
          );
        default:
          return 0;
      }
    });
    return outputDataList;
  }

  search(input: any, inputData: any[]) {
    const searchText = (item) => {
      for (const key in item) {
        if (item[key] == null) {
          continue;
        }
        if (typeof item[key] === 'number' && item[key] !== 0) {
          const date = formatDate(item[key], 'yyyy-MM-dd HH:mm:ss', 'en');
          if (date.indexOf(input.toString()) !== -1) {
            return true;
          }
        }

        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }

        if (
          typeof item[key] === 'object' &&
          !item[key].hasOwnProperty('Length')
        ) {
          for (const keyChild in item[key]) {
            if (item[key][keyChild] == null) {
              continue;
            }

            if (
              typeof item[key][keyChild] === 'number' &&
              item[key][keyChild] !== 0
            ) {
              const date = formatDate(
                item[key][keyChild],
                'yyyy-MM-dd HH:mm:ss',
                'en',
              );
              if (date.indexOf(input.toString()) !== -1) {
                return true;
              }
            }

            if (
              item[key][keyChild]
                .toString()
                .toUpperCase()
                .indexOf(input.toString().toUpperCase()) !== -1
            ) {
              return true;
            }

            if (typeof item[key][keyChild] === 'object') {
              for (const keyNextChild of Object.keys(item[key][keyChild])) {
                if (item[key][keyChild][keyNextChild]) {
                  if (
                    typeof item[key][keyChild][keyNextChild] === 'number' &&
                    item[key][keyChild][keyNextChild] !== 0
                  ) {
                    const date = formatDate(
                      item[key][keyChild][keyNextChild],
                      'yyyy-MM-dd HH:mm:ss',
                      'en',
                    );
                    if (date.indexOf(input.toString()) !== -1) {
                      return true;
                    }
                  }

                  if (
                    item[key][keyChild][keyNextChild]
                      .toString()
                      .toUpperCase()
                      .indexOf(input.toString().toUpperCase()) !== -1
                  ) {
                    return true;
                  }
                }

                if (!item[key][keyNextChild]) {
                  continue;
                }

                if (
                  typeof item[key][keyNextChild] === 'number' &&
                  item[key][keyNextChild] !== 0
                ) {
                  const date = formatDate(
                    item[key][keyNextChild],
                    'yyyy-MM-dd HH:mm:ss',
                    'en',
                  );
                  if (date.indexOf(input.toString()) !== -1) {
                    return true;
                  }
                }

                if (
                  item[key][keyNextChild]
                    .toString()
                    .toUpperCase()
                    .indexOf(input.toString().toUpperCase()) !== -1
                ) {
                  return true;
                }
              }
            }
          }
        }
      }
    };
    inputData = inputData.filter((value) => searchText(value));
    return inputData;
  }

  private compare(a, b, isAsc: boolean) {
    if (!a) {
      return (a = '-');
    }
    if (!b) {
      return (b = '-');
    }

    if (a === b) {
      return 0;
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
