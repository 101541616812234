export class FinishedDocuments {
  static getAll() {
    return [
      {
        numero: 34,
        unidadeGestora: 'Câmara',
        unidadeOrcamentaria: 'Gabinete do Presidente',
        favorecido: 'Gabinete do Presidente',
        tipo: 'Contrato',
        preco: 15000,
        dataDocumento: '11/08/2020',
        dataLimiteAssinatura: '05/01/2021',
        status: 'Confirmado',
        checked: false,
        numTotalAss: 6,
        assEfetivadas: 2,
      },
      {
        numero: 37,
        unidadeGestora: 'Câmara',
        unidadeOrcamentaria: 'Gabinete do Presidente',
        favorecido: 'Gabinete do Presidente',
        tipo: 'Prestação de contas',
        preco: 1200,
        dataDocumento: '01/12/2020',
        dataLimiteAssinatura: '04/04/2021',
        status: 'Desacordo',
        checked: false,
        numTotalAss: 5,
        assEfetivadas: 3,
      },
    ];
  }
}
