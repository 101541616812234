import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { CodigoModulos, ModulosPublicSoft } from './consts/modulos';

export interface PasswordValidation {
  erro: boolean;
  mensagem: string;
}

export type TypeMask = 'cpf' | 'cnpj' | 'price' | 'date' | 'full-date';

export abstract class Helper {
  public static encryptData(data, encryptSecretKey: string): string {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        encryptSecretKey
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  public static decryptData(data: string, encryptSecretKey: string): Object {
    try {
      const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  public static getSigla(value: string): string {
    const sigla: string[] = [];
    value.split(' ').forEach((txtSplited, i, arr) => {
      if (txtSplited.length > 3) {
        if (arr.length > 1) {
          sigla.push(txtSplited.substring(0, 1));
          return;
        }
        sigla.push(txtSplited.substring(0, 3));
        return;
      }
      sigla.push(txtSplited.substring(0, 1));
    });
    return sigla.join('').toUpperCase();
  }
  public static ajustarParaURI(value: string): string {
    return value
      .substring(0, 35)
      .replace(/[^\w\sÁáÂâãÃõÕÉéÊêÍíÓóÔôÚúÇç]/gi, '-')
      .replace(/\s+/g, '-') // Substitui espaços por "-"
      .replace(/-+/g, '-') // Remove múltiplos "-" consecutivos
      .replace(/-$/, '') // Remove "-" no final da string
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, ''); // Converte para minúsculas
  }

  public static addMask(valor, tipo: TypeMask) {
    if (tipo === 'cpf') {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }

    if (tipo === 'cnpj') {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    if (tipo === 'price') {
      const changeType = Number(valor);
      return changeType.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    if (tipo === 'date') {
      valor = valor.slice(0, valor.lastIndexOf('.'));
      return moment(new Date(valor)).format('DD/MM/YYYY');
    }

    if (tipo === 'full-date') {
      valor = valor.slice(0, valor.lastIndexOf('.'));
      return valor ? moment(valor).format('DD/MM/YYYY - HH:mm:ss') : '';
    }

    if (tipo === 'aplicacao-origem') {
      if (ModulosPublicSoft['Assinatura Digital'] === valor) {
        return 'Avulso';
      }

      return CodigoModulos[valor];
    }
  }

  public static mascaraCpfCnpj(valor: string): string {
    if (valor && valor.length > 11) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    return valor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  public static cpfCnpjMascarado(valor: string) {
    if (valor && valor.length > 11) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    return valor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '***.$2.$3-**');
  }

  public static validarCampo(campo: string, tipoValidacao: string): boolean {
    if (!tipoValidacao) {
      return;
    }

    if (tipoValidacao === 'cpf') {
      return this.validaCPF(campo);
    }

    if (tipoValidacao === 'cnpj') {
      return this.validaCNPJ(campo);
    }

    if (tipoValidacao === 'email') {
      return this.validaEmail(campo);
    }
  }

  public static validaCNPJ(value) {
    if (!value) {
      return false;
    }

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes =
      typeof value === 'string' ||
      Number.isInteger(value) ||
      Array.isArray(value);

    // Elimina valor em formato inválido
    if (!validTypes) {
      return false;
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g);
    const numbers = Array.isArray(match) ? match.map(Number) : [];

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) {
      return false;
    }

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1) {
      return false;
    }

    // Cálculo validador
    const calc = (x) => {
      const slice = numbers.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) {
          factor = 9;
        }
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = calc(12);
    if (digit0 !== digits[0]) {
      return false;
    }

    // Valida 2o. dígito verificador
    const digit1 = calc(13);
    return digit1 === digits[1];
  }

  public static validaCPF(inputCPF) {
    if (!inputCPF) {
      return;
    }

    if (inputCPF.length !== 11) {
      return false;
    }

    const cpfNaoValidos = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];

    let soma = 0;
    let resto;

    if (cpfNaoValidos.includes(inputCPF)) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      soma = soma + Number(inputCPF.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== Number(inputCPF.substring(9, 10))) {
      return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma = soma + Number(inputCPF.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== Number(inputCPF.substring(10, 11))) {
      return false;
    }

    return true;
  }

  public static convertSelectedFieldsToURLParams(fields: any[]) {
    let obj: any = {};

    for (const field of fields) {
      obj[field.props] = field.value;
    }

    return obj;
  }

  public static validaEmail(email: string): boolean {
    return /[\w\_\-\.]+@[\w\.]+\.\w+/.test(email);
  }

  public static validaSenha(senha: string): PasswordValidation {
    if (senha.length < 8) {
      return {
        erro: true,
        mensagem: 'Senha não pode ser menor que 8 caracteres',
      };
    }

    if (
      /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/.test(
        senha
      )
    ) {
      return {
        erro: true,
        mensagem: 'Evite senhas que sejam sequências',
      };
    }

    if (/[?=.*[A-Z]]/.test(senha)) {
      return {
        erro: false,
        mensagem: 'Senha deve ter pelo menos uma letra maiúscula',
      };
    }

    if (/[?=.*[0-9]]/.test(senha)) {
      return { erro: false, mensagem: 'Senha deve ter pelo menos um número' };
    }

    if (/[?=.*[!@#$%^&*]]/.test(senha)) {
      return {
        erro: false,
        mensagem: 'Senha deve ter pelo menos um caracter especial',
      };
    }

    if (/[\b(\d+)\1+\b]/.test(senha)) {
      return {
        erro: true,
        mensagem: 'Evita repetir números em sequência',
      };
    }

    return { erro: false, mensagem: 'Senha válida' };
  }
}
