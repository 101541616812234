import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ps-painel-administrativo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // // windowHeight = `${(window.innerHeight * 70) / 100}px`;
  // relativeHeight: string;
  constructor() {}

  ngOnInit() {
    // this.relativeHeight = `${window.innerHeight * 0.3}px`;
  }
}
