<button
  [nzShape]="'circle'"
  [nzType]="'primary'"
  class="m-l-10 btn-actions"
  nz-button
  type="button"
  nz-dropdown
  [nzDropdownMenu]="dropdown"
  [nzTrigger]="'click'"
>
  <i nz-icon nzType="plus"></i>
</button>
<nz-dropdown-menu #dropdown>
  <ul nz-menu>
    <li
      class="d-flex justify-content-between align-itens-center"
      nz-menu-item
      nz-tooltip
      [nzTooltipPlacement]="'topLeft'"
      [nzTooltipTitle]="'Cadastrar um novo recurso'"
    >
      Implantar <i nz-icon nzType="plus" nzTheme="outline"></i>
    </li>
    <li
      class="d-flex justify-content-between align-itens-center"
      nz-menu-item
      nz-tooltip
      [nzTooltipPlacement]="'topLeft'"
      [nzTooltipTitle]="'Alterar um recurso'"
    >
      Alterar <i nz-icon nzType="edit" nzTheme="outline"></i>
    </li>
    <li
      class="d-flex justify-content-between align-itens-center"
      nz-menu-item
      nz-tooltip
      [nzTooltipPlacement]="'topLeft'"
      [nzTooltipTitle]="'Excluir um recurso'"
    >
      Excluir <i nz-icon nzType="delete" nzTheme="outline"></i>
    </li>
    <li
      class="d-flex justify-content-between align-itens-center"
      nz-menu-item
      nz-tooltip
      [nzTooltipPlacement]="'topLeft'"
      [nzTooltipTitle]="'Gravar um recurso'"
    >
      Gravar <i nz-icon nzType="check" nzTheme="outline"></i>
    </li>
    <li
      class="d-flex justify-content-between align-itens-center"
      nz-menu-item
      nz-tooltip
      [nzTooltipPlacement]="'topLeft'"
      [nzTooltipTitle]="'Cancelar um recurso'"
    >
      Cancelar <i nz-icon nzType="stop" nzTheme="outline"></i>
    </li>

    <li nz-menu-item nzDisabled>disabled menu item</li>
    <li nz-submenu nzTitle="Outros">
      <ul>
        <li nz-menu-item>Opção 1</li>
        <li nz-menu-item>Opção 2</li>
      </ul>
    </li>
    <li class="d-flex justify-content-between align-itens-center" nz-menu-item>
      Imprimir <i nz-icon nzType="printer" nzTheme="outline"></i>
    </li>
  </ul>
</nz-dropdown-menu>
