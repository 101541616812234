<!-- Floating Action Button like Google Material -->
<div *ngIf="isActive()" class="menu pmd-floating-action" role="navigation">

  <ng-container *ngFor="let btn of options">
    <a style="background-color: {{btn.color}};" *ngIf="options.length >= 1" (click)="btn.onClick ? btn.onClick() : null"
      nz-tooltip [nzTooltipPlacement]="'left'" [nzTooltipTitle]="btn.tooltip.toUpperCase()"
      class=" pmd-btn-flat pmd-floating-action-btn btn btn-sm pmd-btn-fab pmd-btn-raised pmd-ripple-effect btn-default"
      [attr.data-title]="btn.tooltip" href="javascript:void(0);">
      <span class="pmd-floating-hidden">{{ btn.tooltip }}</span>
      <i nz-icon style="color:{{btn.fontColor}}" [nzType]="btn.icon" nzTheme="outline"></i>
    </a>
  </ng-container>

  <a class="pmd-floating-action-btn btn pmd-btn-fab pmd-btn-raised pmd-ripple-effect {{actions.length === 1 && actions[0].color === 'secondary'?'btn-secondary':'btn-primary '}}"
    data-title="Ações" href="javascript:void(0);" nz-tooltip [nzTooltipPlacement]="'left'"
    [nzTooltipTitle]="actions.length === 1 && this.innerWidth >= 748 ? actions[0].tooltip.toUpperCase() : null"
    (click)="actions.length === 1 ? actions[0].onClick() : pushOptions()">
    <span class="pmd-floating-hidden">Primary</span>
    <i nz-icon [nzType]="actions.length === 1 ? actions[0].icon === 'null'?'':actions[0].icon : 'more'"
      [style]="actions[0].percent?'font-size:14px':'fonte-size:16px'">{{
      parseFloat(actions[0].percent)}}</i>
  </a>
</div>