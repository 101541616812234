import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

export interface Licenca {
  id?: number;
  numero?: number;
  validade?: number;
  situacao?: number;
  usuarioCriacao?: number;
  aplicacaoOrigem?: number;
  idUnidadeGestora?: number;
  licencaModulos?: {
    idModulo?: number;
    licencaSubmodulos?: {
      idSubmodulo?: number;
      licencaFormularios?: {
        idFormulario?: number;
      }[];
    }[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class LicencaService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('licenca', http, notification, authService);
  }

  async consultaUg(valor, field) {
    return valor
      ? this.consultarPorParametro(
          { field: field, value: valor },
          'unidade-gestora'
        )
      : null;
  }
}
