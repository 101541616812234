import { Component, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ApiComponent } from '../api/api.component';
import { AuthService } from '../shared/services/auth.service';
import { SubmoduloService } from '../shared/services/submodulo.service';

@Component({
  selector: 'ps-painel-administrativo-app-submodulo',
  templateUrl: './submodulo.component.html',
  styleUrls: ['./submodulo.component.scss'],
})
export class SubmoduloComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  openModal: boolean;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageIndex;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    nomSubmodulo: true,
  };

  checkboxAdm: boolean;

  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableSubmoduloColumns = [];
  apiEntity = [];
  titulo: string = 'Submodulo';
  entidade: string = 'submodulo-sistema';
  fieldSubmodulosSelected;
  arrColumnsSubmodulosSelect: any[];
  showComboTagsSubmodulos: any[];
  selectedSubmodulosValues;
  showComboTagsSubmodulo;
  searchInput: any;
  fieldsSubmodulosSelected = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<SubmoduloComponent>,
    private service: SubmoduloService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigExampleTable();
      await this.setConfigExampleFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }
  ngOnInit(): void {
    if (this.openModal) this.getSubmoduloPerPeriod();
  }

  async setConfigExampleFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableSubmoduloColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigExampleTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsExamples = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        });
        this.tableSubmoduloColumns = tableColumnsExamples;

        this.arrColumnsSubmodulosSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    this.form = this.formBuilder.group({
      id: [null],
      nomSubmodulo: [null, Validators.required],
      adm: [null],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      formularios: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          nomFormulario: [null, Validators.required],
          api: [null],
          desApi: [null],
          codApi: [null],
          // apiNaoEncontrada: [null],
          // messageApiNaoEncontrada: [null],
          // intervaloDigitando: [null],
          // entidade: [null],
          tipo: [1],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
        }),
      ]),
    });
  }

  showModalApi(index) {
    const apiModal = this.modalService.create({
      nzTitle: 'Selecione uma Api',
      nzContent: ApiComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    apiModal.afterClose.subscribe((api) => {
      this.apiEntity[index] = api;
      this.form.get(`formularios.${index}`).patchValue({
        api: api,
        desApi: api.nome,
        codApi: api.codigo,
      });
    });
  }

  limparApi(index) {
    this.form.get(`formularios.${index}`).patchValue({
      api: null,
      desApi: null,
      codApi: null,
    });
    this.apiEntity[index] = null;
  }

  prepareToUpdateForm(submodulo) {
    this.form.get('id').setValue(submodulo.id);
    this.form.get('nomSubmodulo').setValue(submodulo.nomSubmodulo);
    this.form.get('adm').setValue(!!submodulo.adm);
    this.form.get('usuarioCriacao').setValue(submodulo.usuarioCriacao);
    this.form.get('aplicacaoOrigem').setValue(submodulo.aplicacaoOrigem);
    this.form.get('dtCriacao').setValue(submodulo.dtCriacao);

    for (const index in submodulo.formularios) {
      if (submodulo.formularios[index]) {
        if (!this.form.get(`formularios.${index}`)) {
          this.addFormulario();
        }
        this.form
          .get(`formularios.${index}.id`)
          .setValue(submodulo.formularios[index].id);
        this.form
          .get(`formularios.${index}.nomFormulario`)
          .setValue(submodulo.formularios[index].nomFormulario);
        this.form
          .get(`formularios.${index}.tipo`)
          .setValue(submodulo.formularios[index].tipo);
        this.form
          .get(`formularios.${index}.usuarioCriacao`)
          .setValue(submodulo.formularios[index].usuarioCriacao);
        this.form
          .get(`formularios.${index}.aplicacaoOrigem`)
          .setValue(submodulo.formularios[index].aplicacaoOrigem);
      }
    }
    this.editarSubmoduloForm(submodulo).then(() => {
      this.changeTabIndex(0);
    });
  }
  async editarSubmoduloForm(submodulo) {
    for (const formulario of submodulo.formularios) {
      const index = submodulo.formularios.indexOf(formulario);
      this.apiEntity[index] = formulario;
      await this.service
        .consultarPorParametro(
          { field: 'codigo', value: formulario.codApi },
          'api'
        )
        .then(async (result: any) => {
          this.form.get(`formularios.${index}`).patchValue({
            api: result.data,
            desApi: result.data.nome,
            codApi: result.data.codigo,
          });
        });
    }
  }

  removeFormulario(index: number) {
    if (this.form.get(`formularios.${index}.id`).value) {
      this.modalService.confirm({
        nzTitle: 'Deseja realmente remover o registro?',
        nzOnOk: () => {
          this.service
            .excluirFilho(
              this.form.value.id,
              this.form.get(`formularios.${index}.id`).value,
              'formulario',
              this.entidade,
              this.titulo
            )
            .then(() => {
              const form: FormArray = this.form.get('formularios') as FormArray;
              form.removeAt(index);
              this.apiEntity[index] = null;
            });
        },
      });

      return;
    }
    const formulario: FormArray = this.form.get('formularios') as FormArray;
    formulario.removeAt(index);
  }
  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterSubmoduloOptionsSelect(item) {}

  novoCadastro = () => {
    this.apiEntity = [];
    this.resetForm();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    if (this.form.invalid) {
      this.service.notification.warning(
        this.titulo,
        'Preencha todos os campos!'
      );
      
      return;
    }

    if (this.form.value.id && this.form.value.id > 0) {
      await this.update(this.form).then((result: any) =>
        result.data ? this.resetForm() : null
      );
      this.filterSubmodulos();
      return;
    }

    await this.inserirSubmodulo(this.form).then((result: any) => {
      result.data ? this.resetForm() : null;
      this.filterSubmodulos();
    });
  }

  async inserirSubmodulo(submodulo) {
    submodulo = this.prepareToRequest(submodulo.value);
    return await this.execute(submodulo);
  }

  async update(submodulo) {
    submodulo = this.prepareToRequest(submodulo.value);
    return await this.execute(submodulo);
  }

  async execute(registro, cadastroBasico = null, entidade = this.entidade) {
    return await this.modalService.confirm({
      nzOkText: `${
        registro.id || registro.idPrivado ? 'Alterar' : 'Cadastrar'
      } registro`,
      nzTitle: `${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }`,
      nzOnOk: async () => {
        return await this.service
          .insertOrUpdate(registro, cadastroBasico, entidade)
          .then((result) => {
            this.resetForm();
            return result;
          });
      },
    });
  }

  prepareToRequest(submodulo) {
    submodulo.adm = submodulo.adm ? 1 : 0;
    submodulo.formularios.map((formulario) => {
      !formulario.id ? delete formulario.id : null;
      formulario.entidade = this.returnNomEntidade(formulario.nomFormulario);
    });
    return submodulo;
  }

  returnNomEntidade(nomFormulario) {
    nomFormulario = nomFormulario.toLowerCase();
    nomFormulario = nomFormulario.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    nomFormulario = nomFormulario.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    nomFormulario = nomFormulario.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    nomFormulario = nomFormulario.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    nomFormulario = nomFormulario.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    nomFormulario = nomFormulario.replace(new RegExp('[Ç]', 'gi'), 'c');
    nomFormulario = nomFormulario.replace(
      new RegExp('[ -(),;:|!"#$%&/=?~^><ªº]', 'gi'),
      '-'
    );
    return nomFormulario;
  }

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  editarExample = (item) => {};

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
    this.filterSubmodulos();
  };

  atualizar = () => {
    this.getSubmoduloPerPeriod(this.fieldsSubmodulosSelected);
  };

  getWidthContent() {
    return window.innerWidth;
  }

  dblclickSubmodulo(item) {
    this.openModal ? this.selectItem(item) : this.prepareToUpdateForm(item);
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  addFormulario() {
    const formularioForm: FormArray = this.form.get('formularios') as FormArray;
    formularioForm.push(
      this.formBuilder.group({
        id: [null],
        nomFormulario: [null, [Validators.required]],
        api: [null],
        desApi: [null],
        codApi: [null],
        apiNaoEncontrada: [null],
        messageApiNaoEncontrada: [null],
        intervaloDigitando: [null],
        entidade: [null],
        tipo: [1],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  verificaFormularioVazio(formularios): boolean {
    const formVazio =
      formularios.filter(
        (formulario) =>
          !formulario.nomFormulario || formulario.nomFormulario === ''
      ).length > 0;
    return formVazio;
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterSubmodulos() {
    return this.getSubmoduloPerPeriod(this.fieldsSubmodulosSelected);
  }

  removeFiltroSubmoduloPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableSubmoduloColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsSubmodulosSelect.splice(
      this.tableSubmoduloColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsSubmodulosSelected.splice(
      this.fieldsSubmodulosSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsSubmodulosSelected.length === 0) {
      this.showComboTagsSubmodulo = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async pageChange(valor) {
    this.pageIndex = Number(valor);
    await this.getSubmoduloPerPeriod(
      this.fieldsSubmodulosSelected,
      Number(valor),
      10
    );
  }

  async getSubmoduloPerPeriod(
    fieldsSubmodulosSelected = [],
    page: number = this.pageIndex,
    size: number = 10
  ) {
    this.loadingTable = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsSubmodulosSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (response: any) => {
        if (response.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = response.data.paramsPaginator[0];
        this.displayData = response.data.resultado;

        this.loadingTable = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingTable = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldSubmodulosSelected && this.searchInput) {
      if (this.fieldSubmodulosSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSubmodulosSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsSubmodulosSelected.length !== 0) {
        this.removeFiltroSubmoduloPorField(this.fieldsSubmodulosSelected[0]);
      }
      this.fieldsSubmodulosSelected.push({
        props: this.fieldSubmodulosSelected.value,
        field: this.fieldSubmodulosSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSubmodulosSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsSubmodulosSelect.findIndex(
        (a) => a.value === this.fieldSubmodulosSelected.value
      );

      this.arrColumnsSubmodulosSelect.splice(index, 1);

      this.showComboTagsSubmodulo = true;
      this.fieldSubmodulosSelected = '';
      this.searchInput = '';
      this.selectedSubmodulosValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {
    this.modalRef.destroy(item);
  }
}
