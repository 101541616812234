import { Component, Input, OnInit } from '@angular/core';
import { FooterConfig } from './footer.interface';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-footer',
  templateUrl: './ps-footer.component.html',
  styleUrls: ['./ps-footer.component.scss'],
})
export class PsFooterComponent implements OnInit {
  public date: Date = new Date();
  @Input() config: FooterConfig;
  @Input() version: string;

  constructor() {}

  ngOnInit(): void {}
}
