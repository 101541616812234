import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'formly-array-section',
    template: `
    <ng-container *ngIf="!to.hideLabel">
        <hr>
        <div style="display: flex; justify-content: space-between;">
            <h5>{{ to.label }}</h5>
            <button nz-button [nzSize]="'small'" [nzType]="'primary'" [nzShape]="'circle'" type="button" (click)="add()">
                <span *ngIf="to.addText">{{ to.addText }}</span>
                <i nz-icon nzType="plus" nzTheme="outline"></i>
            </button>
        </div>

        <hr>
    </ng-container>
    <div *ngFor="let field of field.fieldGroup; let i = index;" class="row container__array">
      <formly-field class="col" [field]="field"></formly-field>
      <div class="btn-remove">
        <button nz-button [nzSize]="'small'" [nzShape]="'circle'" nzDanger *ngIf="!to.hideRemoveButtons" type="button" (click)="remove(i)">
            <span *ngIf="to.removeText">{{ to.removeText }}</span>
            <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
      </div>
    </div>
  `,
})
export class PsFormArrayComponent extends FieldArrayType implements OnInit {

    ngOnInit() { }
}
