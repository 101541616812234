<ul
  nz-menu
  [nzMode]="mode"
  class="ant-menu ant-menu-root ant-menu-inline {{ level }}"
  [ngClass]="{
    'ant-menu-inline-collapsed': isFolded && !level,
    'ant-menu-dark': isSideNavDark
  }"
>
  <ng-container
    *ngFor="let item of menuItems | searchRecursive: search:'title':'submenu'"
  >
    <li
      nz-menu-item
      [ngClass]="{ 'menu-item': item.level >= 3 }"
      [nzPaddingLeft]="item.level * 24"
      *ngIf="!item.submenu || item.submenu.length === 0"
      [routerLinkActive]="'ant-menu-item-selected'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a
        [routerLink]="item.path"
        [queryParams]="item.queryParams"
        [title]="item.title"
        (click)="addTab(item)"
      >
        <i
          *ngIf="item.iconType == 'nzIcon'"
          nz-icon
          [nzType]="item.icon"
          [theme]="item.iconTheme"
        ></i>
        <i
          *ngIf="item.iconType == 'fontawesome'"
          class="m-r-10"
          [ngClass]="[item.iconTheme, item.icon]"
        ></i>
        <span>{{ item.title }}</span>
      </a>
    </li>

    <li
      nz-submenu
      [nzPaddingLeft]="item.level * 24"
      *ngIf="item.submenu && item.submenu.length > 0"
      [nzTitle]="item.title"
      [nzIcon]="item.icon"
      [nzOpen]="item.open"
      [routerLinkActive]="'ant-menu-submenu-open'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <ps-recursive-sidebar
        [menuItems]="item.submenu"
        [level]="item.level"
      ></ps-recursive-sidebar>
    </li>
  </ng-container>
</ul>
