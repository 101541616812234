import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-table',
  template: `
    <nz-table
      #table
      nzBordered
      [nzData]="rowData"
      [nzPageSize]="10"
      [nzLoading]="loading"
    >
      <thead>
        <tr>
          <th
            style="background: #edf1fd;"
            [nzChecked]="allChecked"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <ng-container *ngFor="let h of headers">
            <th
              style="background: #edf1fd;"
              *ngIf="checkList[h.key]"
              [nzSortFn]="h.compare"
              [nzSortPriority]="h.priority"
            >
              {{ h.title }}
            </th>
          </ng-container>
          <th style="background: #edf1fd; width: 5px">
            <button
              nz-button
              nzType="text"
              nzSize="small"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="checkListTemplate"
            >
              <i nz-icon nzType="setting"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of table.data">
          <td
            [nzChecked]="setChecked.has(data.id)"
            (nzCheckedChange)="selectedRow($event, data)"
          ></td>
          <ng-container *ngFor="let index of columns">
            <td *ngIf="checkList[index]">
              {{ data[index] }}
            </td>
          </ng-container>
          <ng-container *ngIf="hasTagCol">
            <td *ngIf="checkList[tagColKey]">
              <nz-tag
                [nzColor]="data[tagColKey] === condition ? tagSuccess : tagFail"
                >{{ data[tagColKey] }}</nz-tag
              >
            </td>
          </ng-container>
          <ng-container *ngIf="hasActionsCol">
            <td *ngIf="checkList[actionsColKey]">
              <ng-content select="[actions]"></ng-content>
            </td>
          </ng-container>
          <td></td>
        </tr>
      </tbody>
    </nz-table>
  `,
})
export class PsTableComponent implements OnInit {
  @Input() headers: any[];
  @Input() columns: any[];
  @Input() rowData: any[];

  @Input() checkList: any;
  @Input() checkListTemplate: TemplateRef<any>;

  @Input() loading: boolean;

  @Input() hasTagCol = false;

  @Input() tagColKey: string;
  @Input() tagColTitle: string;

  @Input() condition: any;
  @Input() tagFail: string;
  @Input() tagSuccess: string;

  @Input() hasActionsCol: boolean;

  @Input() actionsColKey: string;
  @Input() actionsColTitle: string;

  @Output() selectRow = new EventEmitter();
  @Output() selectAll = new EventEmitter();

  allChecked = false;
  setChecked = new Set<any>();

  constructor() {}

  ngOnInit(): void {}

  selectedRow(checked, row) {
    if (!checked) {
      this.setChecked.delete(row.id);
      this.selectRow.emit(false);
      return;
    }

    this.setChecked.add(row.id);
    this.selectRow.emit(row);
  }

  onAllChecked(checked) {
    this.rowData.forEach((row) => {
      if (!checked) {
        this.setChecked.delete(row.id);
        this.selectAll.emit(false);
        return;
      }

      this.setChecked.add(row.id);
    });

    const data = this.rowData.filter((d) => this.setChecked.has(d.id));
    this.selectAll.emit(data);
  }
}
