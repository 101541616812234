import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../shared/services/auth.service';
import { ParceiroService } from '../shared/services/parceiro.service';

@Component({
  selector: 'ps-painel-administrativo-app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss'],
})
export class ParceiroComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean };

  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableExampleColumns = [];
  titulo: string = 'Example';
  entidade: string = 'example';
  fieldExamplesSelected;
  arrColumnsExamplesSelect: any[];
  showComboTagsExemplos: any[];
  selectedExamplesValues;
  showComboTagsExamples;
  searchInput: any;
  fieldsExamplesSelected = [];

  constructor(
    private service: ParceiroService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigExampleTable();
      await this.setConfigExampleFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigExampleFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableExampleColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigExampleTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsExamples = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        });
        this.tableExampleColumns = tableColumnsExamples;

        this.arrColumnsExamplesSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form;
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterExampleOptionsSelect(item) {}

  novoCadastro = () => {
    this.changeTabIndex(0);
  };

  salvar = () => {};

  editar = () => {};

  editarExample = (item) => {};

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {};

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterExample() {
    return this.getExamplePerPeriod(this.fieldsExamplesSelected);
  }

  removeFiltroExamplePorField(fieldsValuesTag) {
    const tableExportColumn = this.tableExampleColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsExamplesSelect.splice(
      this.tableExampleColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsExamplesSelected.splice(
      this.fieldsExamplesSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsExamplesSelected.length === 0) {
      this.showComboTagsExamples = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getExamplePerPeriod(fieldsExamplesSelected) {
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsExamplesSelected,
        entidade: this.entidade,
        titulo: this.titulo,
      })
      .then(async (example: any) => {
        if (example.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = example.data.paramsPaginator[0];
        this.displayData = example.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldExamplesSelected && this.searchInput) {
      if (this.fieldExamplesSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldExamplesSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsExamplesSelected.length !== 0) {
        this.removeFiltroExamplePorField(this.fieldsExamplesSelected[0]);
      }
      this.fieldExamplesSelected.push({
        props: this.fieldExamplesSelected.value,
        field: this.fieldExamplesSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldExamplesSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsExamplesSelect.findIndex(
        (a) => a.value === this.fieldExamplesSelected.value
      );

      this.arrColumnsExamplesSelect.splice(index, 1);

      this.showComboTagsExamples = true;
      this.fieldExamplesSelected = '';
      this.searchInput = '';
      this.selectedExamplesValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
