<!-- <nz-layout>
  <nz-layout>
    <nz-content>
      <section style="padding: 0 20px">
        <ps-fab
          [actions]="{ 'selecionar': selecionar, 'implantar': implantar }"
          [form]="form"
          [selecionar]="selecionar"
          [implantar]="implantar"
        ></ps-fab>
        <nz-tabset [nzSelectedIndex]="tabIndex" class="" [nzAnimated]="false">
          <nz-tab [nzTitle]="'Pesquisa'">
            <br>
            <ps-grid
              [defaultColDef]="defaultColDefs"
              [rowData]="rowData"
              [gridOptions]="gridOptions"
              [columnDefs]="columnDefs"
            ></ps-grid>
          </nz-tab>
          <nz-tab [nzTitle]="'Cadastro'">
            <br>
            <form
              nz-form
              accessible-form
              [form]="form"
              [nzLayout]="'vertical'"
              autocomplete="off"
            >
              <ps-form
                [form]="form"
                [fields]="fields"
                [model]="model"
              ></ps-form>
            </form>
          </nz-tab>
        </nz-tabset>
      </section>
    </nz-content>
  </nz-layout>
</nz-layout> -->

<nz-card
  style="width: 100%"
  [nzTitle]="!tabIndex ? 'Cadastro' : 'Pesquisa'"
  [nzExtra]="extra"
>
  <nz-tabset
    [nzSelectedIndex]="tabIndex"
    [nzAnimated]="false"
    class="nz-tabset__container"
  >
    <nz-tab>
      <br />
      <form
        nz-form
        accessible-form
        [form]="form"
        [nzLayout]="'vertical'"
        autocomplete="off"
      >
        <ps-form [form]="form" [fields]="fields" [model]="model"></ps-form>
      </form>
    </nz-tab>
    <nz-tab>
      <br />
      <ps-grid
        [defaultColDef]="defaultColDefs"
        [rowData]="rowData"
        [gridOptions]="gridOptions"
        [columnDefs]="columnDefs"
      ></ps-grid>
    </nz-tab>
  </nz-tabset>
</nz-card>

<ng-template #extra>
  <div class="d-flex justify-content-between">
    <nz-radio-group [(ngModel)]="tabIndex" nzButtonStyle="solid">
      <label nz-radio-button [nzValue]="0">Cadastrar</label>
      <label nz-radio-button [nzValue]="1">Pesquisar</label>
    </nz-radio-group>
  </div>
  <ps-fab
    [actions]="{ selecionar: selecionar, implantar: implantar }"

  ></ps-fab>
</ng-template>
