import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Helper } from '../shared/Helper';
import { AuthService } from '../shared/services/auth.service';
import { ModuloService } from '../shared/services/modulo.service';
import { SubmoduloComponent } from '../submodulo/submodulo.component';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'ps-painel-administrativo-app-modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss'],
})
export class ModuloComponent implements OnInit {
  @ViewChild('hiddenfileinput', { static: false })
  hiddenFileInput: ElementRef;
  @ViewChild('hiddenfileinputSplash', { static: false })
  hiddenfileInputSplash: ElementRef;
  @ViewChild('bgColor', { static: false })
  bgColor: ElementRef;
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  subModuloEntity = [];
  checkList: { [typekey: string]: boolean } = {
    codigo: true,
    icone: true,
    splash: true,
    nomModulo: true,
    sistemaLegado: true,
    nomeAplicacaoServidorWindows: true,
    idServidorCloud: true,
    link: true,
  };
  sistemaLegado: boolean = false;
  openModal: boolean = false;

  servidoresCloudArr: any[];

  previewImage;
  previewVisible;
  pageIndex;

  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableModuloColumns = [];
  titulo: string = 'Módulo';
  entidade: string = 'modulo-sistema';
  entidadeSubmodulo: string = 'submodulo-sistema';

  fieldModulosSelected;
  arrColumnsModulosSelect: any[];
  showComboTagsModulos: any[];
  selectedModulosValues;
  showComboTagsModulo;
  searchInput: any;
  fieldsModulosSelected = [];
  idServidorCloudSelectValue;
  private dataModuleIcone: { base64: string; name: string };
  private dataModuleSplash: { base64: string; name: string };

  constructor(
    private service: ModuloService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigModuloTable();
      await this.setConfigModulosFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigModulosFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableModuloColumns
      );
    });
    this.loadingPage = false;
  }

  addSubmodulo() {
    const submoduloForm: FormArray = this.form.get(
      'moduloSubmodulos'
    ) as FormArray;
    submoduloForm.push(
      this.formBuilder.group({
        idSubmodulo: [null, [Validators.required]],
        desSubmodulo: [null],
        submoduloNaoEncontrado: [false],
        messageSubmoduloNaoEncontrado: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  verificaSubmoduloVazio(moduloSubmodulos): boolean {
    return (
      moduloSubmodulos.filter(
        (moduloSubmodulo) =>
          !moduloSubmodulo.idSubmodulo || moduloSubmodulo.idSubmodulo === ''
      ).length > 0
    );
  }

  async verificarCodigoInformado() {
    const id = this.form.value.codigo;
    this.service
      .getAllRegistrosInInterval({
        params: [
          {
            field: 'ID',
            props: 'id',
            text: `ID - ${id}`,
            value: `${id}`,
          },
        ],
        entidade: this.entidade,
        titulo: this.titulo,
      })
      .then((modulo) => {
        if (!modulo) return;
        if (modulo?.data?.length !== 0) {
          this.service.notification.warning(this.titulo, 'Código já em uso', {
            nzDuration: 7000,
          });
        }
      });
  }

  async setConfigModuloTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsModulos = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare: ['id_uf', 'id', 'codigo'].includes(column.value)
              ? (a, b) => a[column.value] - b[column.value]
              : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        });
        this.tableModuloColumns = tableColumnsModulos;

        this.arrColumnsModulosSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      id: [null],
      codigo: [null, Validators.required],
      icone: [null, Validators.required],
      splash: [null],
      bgColor: [null],
      nomModulo: [null, Validators.required],
      sistemaLegado: [this.sistemaLegado],
      nomeAplicacaoServidorWindows: [null],
      idServidorCloud: [null],
      link: [null],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      aplicacaoOrigem: 1,
      moduloSubmodulos: this.formBuilder.array([
        this.formBuilder.group({
          idSubmodulo: [null, Validators.required],
          desSubmodulo: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
        }),
      ]),
    });
  }

  showModalSubmodulo(index) {
    const submoduloModal = this.modalService.create({
      nzTitle: 'Selecione um Submodulo',
      nzContent: SubmoduloComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    submoduloModal.afterClose.subscribe((submodulo) => {
      this.subModuloEntity[index] = submodulo;
      this.form.get(`moduloSubmodulos.${index}`).patchValue({
        idSubmodulo: submodulo.id,
        desSubmodulo: submodulo.nomSubmodulo,
        aplicacaoOrigem: 1,
        usuarioCriacao: this.authService.getIdUser(),
      });
    });
  }

  async getServidoresCloud() {
    await this.service
      .getAllRegistrosInInterval({
        entidade: 'servidor-cloud',
        titulo: 'Servidor Cloud',
      })
      .then(async (response: any) => {
        if (response.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }

        this.servidoresCloudArr = response.data.resultado;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
      });
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterModuloOptionsSelect(item) {}

  novoCadastro = () => {
    this.sistemaLegado = false;
    this.reset();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    if (!this.form.valid) {
      this.service.notification.warning(
        this.titulo,
        'Preencha todos os campos!'
      );
      return;
    }

    this.getOptionsSelect();

    this.form.controls.idServidorCloud.setValue(
      this.idServidorCloudSelectValue
    );
    const { sistemaLegado, idServidorCloud } = this.form.value;
    if (sistemaLegado) {
      if (!idServidorCloud) {
        this.service.notification.warning(
          this.titulo,
          'Selecione um Servidor Cloud!'
        );
        return;
      }

      if (!this.form.value.bgColor) {
        this.service.notification.warning(
          this.titulo,
          'Selecione uma cor de Background!'
        );
        return;
      }
    }

    const servidorCloud = this.moduloFormToCadastro(this.form);
    return await this.execute(servidorCloud);
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        const { base64, name } = this.dataModuleIcone || {};
        const { base64: base64Splash, name: nameSplash } =
          this.dataModuleSplash || {};
        // const urlIcone = this.form.value.id
        //   ? this.form.value.icone
        //   : await this.service.getUrlAvatar(base64, name);
        if (base64Splash) {
          const base64SplashEncoded = base64Splash.replace(
            /^data:image\/\w+;base64,/,
            ''
          );
          const splashObj = { base64: base64SplashEncoded, nome: nameSplash };
          registro.splash = splashObj;
        }

        if (base64) {
          const base64Encoded = base64.replace(/^data:image\/\w+;base64,/, '');
          const iconeObj = { base64: base64Encoded, nome: name };
          registro.icone = iconeObj;
        }

        await this.service
          .insertOrUpdate({ ...registro }, idMp, entidade)
          .then(() => {
            this.novoCadastro();
          })
          .catch(() => {
            this.novoCadastro();
            this.loadingPage = false;
          });
        this.loadingPage = false;
        return this.reset();
      },
    });
  }

  onColorChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.form.controls.bgColor.setValue(input.value);

    input.blur();
  }

  reset() {
    this.subModuloEntity = [];
    this.resetForm();
    this.dataModuleSplash = null;
    this.dataModuleIcone = null;
    this.resetSubmodulo();
    this.hiddenFileInput.nativeElement.value = '';
    if (this.sistemaLegado) {
      this.hiddenfileInputSplash.nativeElement.value = '';
      this.bgColor.nativeElement.value = '';
    }
  }

  getOptionsSelect() {
    this.form.controls.idServidorCloud.setValue(
      this.idServidorCloudSelectValue
    );
  }

  moduloFormToCadastro(form) {
    const modulo = form.value;

    return {
      ...modulo,
      sistemaLegado: modulo.sistemaLegado ? 'S' : 'N',
    };
  }
  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  dblclick(item) {
    this.openModal ? this.selectItem(item) : this.prepareToUpdateForm(item);
  }

  addSubmoduloPadrao(sistemaLegado: boolean) {
    if (!sistemaLegado) {
      this.form.get(`moduloSubmodulos.0`).reset();
      this.form.get('splash').reset();
      return;
    }
    this.loadingPage = true;
    this.service
      .getAllRegistrosInInterval({
        entidade: this.entidadeSubmodulo,
        params: [
          {
            field: 'Nome do Submodulo',
            props: 'nomSubmodulo',
            text: 'Nome do Submodulo - Submodulo Padrão',
            value: 'Submodulo Padrão',
          },
        ],
        titulo: 'Submodulo',
        page: 1,
        size: 10,
      })
      .then(async (response: any) => {
        if (response.data.length === 0) {
          this.service.notification.warning(
            'Submódulo',
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }

        this.form.get(`moduloSubmodulos.0`).patchValue({
          desSubmodulo: response.data.resultado[0].nomSubmodulo,
          idSubmodulo: response.data.resultado[0].id,
          usuarioCriacao: response.data.resultado[0].usuarioCriacao,
          aplicacaoOrigem: response.data.resultado[0].aplicacaoOrigem,
        });

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error('Submódulo', err);
        this.loadingPage = false;
      });
  }

  prepareToUpdateForm(modulo) {
    this.form.patchValue({
      ...modulo,
      idServidorCloud: modulo.idServidorCloud,
      sistemaLegado: modulo.sistemaLegado === 'S',
    });
    for (const index in modulo.moduloSubmodulos) {
      if (modulo.moduloSubmodulos[index]) {
        if (!this.form.get(`moduloSubmodulos.${index}`)) {
          this.addSubmodulo();
        }
        this.form.get(`moduloSubmodulos.${index}`).patchValue({
          desSubmodulo: modulo.moduloSubmodulos[index].submodulo.nomSubmodulo,
          idSubmodulo: modulo.moduloSubmodulos[index].idSubmodulo,
          usuarioCriacao: modulo.moduloSubmodulos[index].usuarioCriacao,
          aplicacaoOrigem: modulo.moduloSubmodulos[index].aplicacaoOrigem,
        });
      }
    }
    this.changeTabIndex(0);
    if (this.sistemaLegado && modulo.bgColor && this.bgColor?.nativeElement)
      this.bgColor.nativeElement.value = modulo.bgColor;
  }

  resetSubmodulo() {
    const submoduloForm: FormArray = this.form.get(
      'moduloSubmodulos'
    ) as FormArray;
    submoduloForm.controls.splice(0, submoduloForm.controls.length);
    this.addSubmodulo();
  }

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.getModuloPerPeriod();
  };

  ngOnInit(): void {
    this.getServidoresCloud();
  }

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      throw this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterModulo() {
    return this.getModuloPerPeriod(this.fieldsModulosSelected);
  }

  removeFiltroModuloPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableModuloColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsModulosSelect.splice(
      this.tableModuloColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsModulosSelected.splice(
      this.fieldsModulosSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsModulosSelected.length === 0) {
      this.showComboTagsModulo = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }

    if (
      column.value === 'idServidorCloud' &&
      item.idServidorCloud &&
      this.servidoresCloudArr?.length
    )
      return this.servidoresCloudArr.find(
        (e) => e.idPrivado === item.idServidorCloud
      ).nomeServidor;

    return value;
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };

  uploadAvatarModuloIcone(event) {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['png', 'jpg', 'jpeg'];

      if (!allowedExtensions.includes(fileExtension)) {
        return this.service.notification.warning(
          'Formato de arquivo inválido',
          'Por favor, selecione um arquivo PNG ou JPG.'
        );
      }
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      this.form.controls.icone.setValue(e.target.result);
      this.dataModuleIcone = {
        base64: e.target.result as string,
        name: event.target.files[0].name,
      };
    };
  }
  uploadAvatarModuloSplash(event) {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ['png', 'jpg', 'jpeg'];

      if (!allowedExtensions.includes(fileExtension)) {
        return this.service.notification.warning(
          'Formato de arquivo inválido',
          'Por favor, selecione um arquivo PNG ou JPG.'
        );
      }
    }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      this.form.controls.splash.setValue(e.target.result);
      this.dataModuleSplash = {
        base64: e.target.result as string,
        name: event.target.files[0].name,
      };
    };
  }

  async pageChange(valor) {
    this.pageIndex = Number(valor);
    await this.getModuloPerPeriod(
      this.fieldsModulosSelected,
      Number(valor),
      10
    );
  }

  async getModuloPerPeriod(
    fieldsModulosSelected = [],
    page: number = this.pageIndex,
    size: number = 10
  ) {
    this.loadingTable = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsModulosSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (modulo: any) => {
        if (modulo?.data?.length === 0 && modulo) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = modulo.data.paramsPaginator[0];
        this.displayData = modulo.data.resultado.sort(
          (a, b) => a['codigo'] - b['codigo']
        );

        this.loadingTable = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingTable = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldModulosSelected && this.searchInput) {
      if (this.fieldModulosSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldModulosSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsModulosSelected.length !== 0) {
        this.removeFiltroModuloPorField(this.fieldsModulosSelected[0]);
      }
      this.fieldsModulosSelected.push({
        field: this.fieldModulosSelected.text,
        props: this.fieldModulosSelected.value,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldModulosSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsModulosSelect.findIndex(
        (a) => a.value === this.fieldModulosSelected.value
      );

      this.arrColumnsModulosSelect.splice(index, 1);

      this.showComboTagsModulo = true;
      this.fieldModulosSelected = '';
      this.searchInput = '';
      this.selectedModulosValues = [];
    }
  }

  limpaSubmodulo(index) {
    this.form.get(`moduloSubmodulos.${index}`).patchValue({
      desSubmodulo: null,
      idSubmodulo: null,
    });
    this.subModuloEntity[index] = null;
  }

  removeSubmodulo(index: number) {
    const submoduloForm: FormArray = this.form.get(
      'moduloSubmodulos'
    ) as FormArray;
    submoduloForm.removeAt(index);
    this.subModuloEntity[index] = null;
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
