import { Injectable } from '@angular/core';

export interface Script {
  name: string;
  src: string;
}

@Injectable()
export class ScriptService {
  private scripts: any = {};
  private scriptStore: Script[] = [
    { name: 'vlibras', src: 'https://vlibras.gov.br/app/vlibras-plugin.js' },
  ];

  constructor() {
    this.scriptStore.forEach((script: Script) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));

    return Promise.all(scripts);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;

        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.body.appendChild(script);
      }
    });
  }
}
