import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-input-currency',
  template: `
    <input
      nz-input
      prefix="R$ "
      mask="separator.2"
      thousandSeparator="."
      [decimalMarker]="','"
      [formControl]="formControl"
      [formlyAttributes]="field"
    />
  `,
})
export class PsInputCurrencyComponent extends FieldType implements OnInit {
  ngOnInit() {}
}
