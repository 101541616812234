import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('perfil', http, notification, authService);
  }

  async consultaUgPorId(evento) {
    return evento
      ? this.consultarPorParametro(
          { field: 'id', value: evento },
          'unidade-gestora'
        )
      : null;
  }

  async consultaPermissaoUg(
    unidadeGestora,
    entidade: string = this.entidade,
    titulo: string = this.transformarEntidadeEmTitulo(entidade)
  ): Promise<{ permissoes: any; error: boolean }> {
    return await this.http
      .get(
        `${this.generateUrlByEnvironment(
          '3032',
          `api/perfil/${unidadeGestora?.id}`,
          'autorizacao'
        )}/permissao`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: any) => {
        if (response.data) {
          return { error: false, permissoes: response.data };
        }
      })
      .catch((error) => {
        this.notificarErro(error, titulo);
        return { error: true, permissoes: null };
      });
  }
}
