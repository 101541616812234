import { Injectable } from '@angular/core';
import _ from 'lodash';

// @TODO Code below not working when building library because the relative path is not understanding for building
// import { version as VERSION } from '../../../package.json';

interface AppState {
    userInfo: {
        usuarioNome: string,
        usuarioEmail: string,
        usuarioCpf: string,
        unidadesGestoras: Array<any>,
        token: string,
        primeiroAcesso: any,
        idUsuario: number
    };
    siderFold?: boolean;
    isNavbar?: boolean;
}

interface StorageState {
    app: AppState;
}

const persistConfig = {
    name: 'publicsoft',
    version: btoa('1.0.0'),
    whiteList: ['app'],
};

@Injectable()
export class StorageService {
    constructor() { }

    save = (state: StorageState) => {
        try {
            const { name, version, whiteList } = persistConfig;
            const filteredState = Object.keys(state)
                .filter(key => whiteList.includes(key))
                .reduce((obj, key) => {
                    obj[key] = state[key];
                    return obj;
                }, {});
            const serializedState = JSON.stringify(filteredState);
            localStorage.setItem(`${name}-${version}`, serializedState);
            return state;
        } catch (err) {
            throw err;
        }
    }

    load = () => {
        try {
            const { name, version } = persistConfig;
            const serializedState = localStorage.getItem(`${name}-${version}`);
            if (serializedState === null) {
                return {};
            }
            return JSON.parse(serializedState);
        } catch (err) {
            throw err;
        }
    }

    get = (key, defaultValue = null) => _.get(this.load(), key, defaultValue);

    clear = () => localStorage.clear();
}
