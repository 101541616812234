import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IModulo } from './../interfaces/document-interfaces';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ModuloService extends AbstractService {
  private title: string = 'Modulo';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public notification: NzNotificationService
  ) {
    super('modulo', http, authService, notification);
  }
  async listarModule(modulo?: { descricao: string }): Promise<unknown> {
    return await this.getManyModulo(modulo)
      .then((resp: { msg: string; data: IModulo[] }) => {
        this.listarModuleNav();
        return resp;
      })
      .catch((err) => {
        this.notification.error(this.title, err.msg);
      });
  }

  async getOne(
    params?: Partial<IModulo>
  ): Promise<{ msg: string; data: IModulo } | void> {
    return await this.getOneModulo(params).catch((err) => {
      this.notification.error(this.title, err.message);
    });
  }

  async listarModuleNav(modulo?: { descricao: string }): Promise<unknown> {
    return await this.getManyModuloNav(modulo)
      .then((resp: { msg: string; data: IModulo[] }) => resp)
      .catch((err) => {
        this.notification.error(this.title, err.msg);
      });
  }

  async updateModulo(modulo: Partial<IModulo>) {
    const module = this.prepareToUpdateForm(modulo);
    return await this.putUpdateModulo(module)
      .then((resp: { msg: string; data: any[] }) => {
        this.listarModuleNav();
        this.notification.success(this.title, resp.msg);
        return resp.data as IModulo[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.msg}. err: ${err}`);
      });
  }

  prepareToUpdateForm(module: Partial<IModulo>) {
    const tiposDoc = module.tiposDocumento.map((value) => {
      return {
        idPrivado: value.idPrivado,
        idPublico: value.idPublico,
        descricao: value.descricao,
        atestadores: value.atestadores.map((key) => {
          if (key.deleted) {
            return {
              idPessoa: key.idPessoa,
              dataInicioAtesto: key.dataInicioAtesto,
              deleted: key.deleted,
            };
          }
          return {
            idPessoa: key.idPessoa,
            dataInicioAtesto: key.dataInicioAtesto,
          };
        }),
      };
    });
    return {
      ...module,
      idModuloPai: module.idModuloPai,
      tiposDocumento: tiposDoc,
    };
  }

  async createModulo(modulo) {
    modulo = this.formatDocumentToInsertOrUpdate(modulo);
    return await this.postCreateModule(modulo)
      .then((resp: { msg: string; data: IModulo[] }) => {
        this.listarModuleNav();
        this.notification.success(this.title, resp.msg);
        return resp;
      })
      .catch((err) => {
        this.notification.error(this.title, err.msg || err.message || err);
      });
  }

  formatDocumentToInsertOrUpdate(form) {
    const tiposDoc = form.tiposDocumento.map((value) => {
      return {
        idPrivado: value.idPrivado,
        idPublico: value.idPublico,
        descricao: value.descricao,
        atestadores: value.atestadores.map((key, index) => {
          if (key.deleted) {
            return {
              idPessoa: key.idPessoa,
              dataInicioAtesto: key.dataInicioAtesto,
              deleted: key.deleted,
            };
          }
          return {
            idPessoa: key.idPessoa,
            dataInicioAtesto: key.dataInicioAtesto,
          };
        }),
      };
    });
    const newArr = {
      ...form,
      idModuloPai: form.idModuloPai,
      tiposDocumento: tiposDoc,
    };

    newArr.tiposDocumento = newArr.tiposDocumento.map((tipoDoc) => {
      tipoDoc.atestadores = tipoDoc.atestadores.filter(
        (atestador) => !atestador.deleted
      );
      return tipoDoc;
    });

    return newArr;
  }

  deletarVinculacaoTipoDocModulo({ idPrivado }: { idPrivado: number }) {
    return this.deleteVinculacaoTipoDocModulo(idPrivado)
      .then((resp: { msg: string; data: IModulo[] }) => {
        this.notification.success(this.title, resp.msg);
        return resp;
      })
      .catch((err) => {
        this.notification.error(this.title, err.msg || err.message || err);
      });
  }

  deleteVinculacaoTipoDocModulo(idPrivado) {
    return new Promise((resolve, reject) => {
      const postRequest: Subscription = this.http
        .delete(
          `${this.getServer('modulo')}/delete-vinculacao/${idPrivado}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            postRequest.unsubscribe();
          },
        });
    });
  }

  async createVinculacaoTipoDocModulo(body: {
    idPrivadoModulo: number;
    idPrivadoTipoDocumento: number;
    atestadores: Array<{ idPessoaAtestador: number; dataInicioAtesto: Date }>;
  }) {
    return await this.postNovaVinculacaoTipoDocModulo(body)
      .then((resp: { msg: string; data: IModulo[] }) => {
        this.notification.success('Vinculação', resp.msg);
        return resp;
      })
      .catch((err) => {
        this.notification.error('Vinculação', err.msg || err.message || err);
      });
  }

  postNovaVinculacaoTipoDocModulo(body) {
    return new Promise((resolve, reject) => {
      const postRequest: Subscription = this.http
        .post(
          `${this.getServer('modulo')}/create-vinculacao`,
          body,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            postRequest.unsubscribe();
          },
        });
    });
  }

  async getOneModulo(
    modulo: Partial<IModulo>
  ): Promise<{ msg: string; data: IModulo }> {
    return new Promise<{ msg: string; data: IModulo }>((resolve, reject) => {
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('modulo')}/get-one?${this.buildUrlQuery(
            modulo as { [key: string]: string | number }
          )}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: IModulo }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async deleteModulo(idPrivado: number) {
    return await this.deleteSoftDeleteSetor(idPrivado)
      .then((resp: { msg: string; data?: any[] }) => {
        this.notification.success(this.title, resp.msg);
        return resp.data as IModulo[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.msg}. err: ${err}`);
      });
  }

  private deleteSoftDeleteSetor(idPrivado: number) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const putRequest: Subscription = this.http
        .delete(
          `${this.getServer('modulo')}/delete/${idPrivado}`,
          this.headerAuthenticateOptions()
        )
        .pipe(
          tap(() => {
            this.listarModuleNav();
          })
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            putRequest.unsubscribe();
          },
        });
    });
  }

  async getManyModulo(params?: {
    descricao: string;
  }): Promise<{ msg: string; data: unknown[] }> {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const getRequest: Subscription = this.http
        .get(
          `${this.getServer('modulo')}/get-many?${this.buildUrlQuery(
            params as {
              [key: string]: string | number | boolean;
            }
          )}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: IModulo[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            getRequest.unsubscribe();
          },
        });
    });
  }

  async getManyModuloGrid(params?: {
    descricao: string;
  }): Promise<{ msg: string; data: unknown[] }> {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const getRequest: Subscription = this.http
        .get(
          `${this.getServer('modulo')}/get-many-grid?${this.buildUrlQuery(
            params as {
              [key: string]: string | number | boolean;
            }
          )}`,
          this.headerAuthenticateOptions()
        )
        .pipe(
          map((resp: { msg: string; data: IModulo[] }) => {
            return {
              ...resp,
              data: resp.data.map((mo) => {
                return {
                  ...mo,
                  descricaoModuloPai: mo?.moduloPai?.descricao,
                  idPublicoModuloPai: mo?.moduloPai?.idPublico,
                };
              }),
            };
          })
        )
        .subscribe({
          next: (response: { msg: string; data: IModulo[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            getRequest.unsubscribe();
          },
        });
    });
  }

  async getManyModuloNav(params?: {
    descricao: string;
  }): Promise<{ msg: string; data: unknown[] }> {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const getRequest: Subscription = this.http
        .get(
          `${this.getServer('modulo')}/listar?${this.buildUrlQuery(
            params as {
              [key: string]: string | number | boolean;
            }
          )}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: IModulo[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            getRequest.unsubscribe();
          },
        });
    });
  }

  public getManyModuloNav$(params?: {
    descricao: string;
  }): Observable<{ msg: string; data: IModulo[] }> {
    return this.http
      .get<{ msg: string; data: IModulo[] }>(
        `${this.getServer('modulo')}/listar?${this.buildUrlQuery(
          params as {
            [key: string]: string | number | boolean;
          }
        )}`,
        this.headerAuthenticateOptions()
      )
      .pipe(
        map((resp: { msg: string; data: IModulo[] }) => {
          const sortModulos = (modulos: IModulo[]): IModulo[] => {
            modulos.sort((a, b) => a.descricao.localeCompare(b.descricao));

            for (const modulo of modulos) {
              if (modulo.tiposDocumento && modulo.tiposDocumento.length > 0) {
                modulo.tiposDocumento.sort((a, b) =>
                  a.descricao.localeCompare(b.descricao)
                );
              }

              if (modulo.modulosFilho && modulo.modulosFilho.length > 0) {
                modulo.modulosFilho = sortModulos(modulo.modulosFilho);
              }
            }

            return modulos;
          };

          return {
            msg: resp.msg,
            data: sortModulos(resp.data),
          } as { msg: string; data: IModulo[] };
        })
      );
  }
  async postCreateModule(body): Promise<{ msg: string; data: unknown[] }> {
    return new Promise((resolve, reject) => {
      const postRequest: Subscription = this.http
        .post(
          `${this.getServer('modulo')}/create`,
          body,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            postRequest.unsubscribe();
          },
        });
    });
  }
  private putUpdateModulo(body) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const putRequest: Subscription = this.http
        .put(
          `${this.getServer('modulo')}/update`,
          body,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            putRequest.unsubscribe();
          },
        });
    });
  }

  private buildUrlQuery(params: {
    [key: string]: string | number | boolean;
  }): string {
    const arrQuery: string[] = new Array();
    for (const key in params) {
      if (params[key]) {
        arrQuery.push(`${key}=${params[key]}`);
      }
    }

    return arrQuery.join('&');
  }
}
