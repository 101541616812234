import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-field-nz-input',
  template: `
    <nz-select
      nzShowSearch
      nzAllowClear
      nzPlaceHolder="{{ to.placeholder }}"
      [formControl]="formControl"
      [formlyAttributes]="field"
    >
      <nz-option
        *ngFor="let item of to.options"
        [nzLabel]="item.nome"
        [nzValue]="item.idPublico"
      ></nz-option>
    </nz-select>
  `,
  styles: ['nz-select { width: 100% }'],
})
export class PsFormSelectComponent extends FieldType implements OnInit {
  ngOnInit() {}
}
