import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Helper } from '../Helper';
import { UrlUploadApi, UrlUploadApiPublic } from '../consts/upload';
import { AuthService } from './auth.service';
// import { UrlUploadApi } from './../../../../../../libs/shared/src/lib/consts/urls-consts';

export interface AbstractResponse {
  message?: string;
  type?: string;
  data?: any;
}

export type AbstractParams = {
  params?: any;
  entidade: string;
  titulo?: string;
  page?: number;
  size?: number;
  all?: boolean;
  selectUser?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AbstractService {
  public urlServidorEndereco: string;
  public urlServidorUnidadeGestora: string;
  public urlServidorUnidadeGestoraNacional: string;
  public urlServidorPessoa: string;
  public urlServidorAutorizacao: string;
  public urlServidorHorario: string;
  public urlNaturezaJuridica: string;
  public urlServidorCnae: string;
  public urlServidorUnidadeOrcamentaria: string;
  public urlServidorCliente: string;
  public urlServidorConfig: string;
  public urlServer: any;
  public serverApiName: any;
  public portalServidorConfig: string;
  public entidade: string;
  public titulo: string;
  public tokenDicamico = false;
  public spinnerSchema = false;
  private helper = new JwtHelperService();
  AbstractResponse: {
    message?: string;
    type?: string;
    data?: any;
  };

  constructor(
    entidade: string,
    public http: HttpClient,
    public notification: NzNotificationService, // public helper = new JwtHelperService()
    public authService: AuthService,
    tokenDinamico = false,
    public entidadesEmNacional: string[] = [
      'endereco-api',
      'natureza-juridica-api',
      'cnae-api',
    ],
    public entidadesEmMunicipal: string[] = [
      'unidade-gestora-api',
      'pessoa-api',
      'unidade-orcamentaria-api',
    ]
  ) {
    this.entidade = entidade;
  }

  transformarEntidadeEmTitulo(entidade: string): string {
    return `${
      entidade.substring(0, 1).toUpperCase() +
      entidade.split('-').join(' ').substring(1)
    }`;
  }

  async consultaPermissao(
    tipo,
    entidade,
    unidadeGestora = null
  ): Promise<{ permissoes: any; error: boolean }> {
    return await this.http
      .get(
        `${this.getServer(entidade)}/${unidadeGestora.id}/${tipo}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: any) => {
        if (response.data) {
          return { error: false, permissoes: response.data };
        }
      })
      .catch((_) => {
        return { error: true, permissoes: null };
      });
  }

  //caso o tÃ­tulo nÃ£o possua acentuaÃ§Ã£o ortografica ou algum sinal
  private getTitulo(): string {
    return `${
      this.entidade.substring(0, 1).toUpperCase() +
      this.entidade.split('-').join(' ').substring(1)
    }`;
  }

  async delete(
    body: number,
    entidade: string = this.entidade,
    titulo: string = this.transformarEntidadeEmTitulo(entidade)
  ) {
    return await this.excluir(body, entidade, titulo);
  }

  excluir(
    object,
    entidade: string = this.entidade,
    titulo: string = this.transformarEntidadeEmTitulo(entidade)
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${this.getServer(entidade)}/${
            object.id ?? object.idPrivado ?? object.idPessoa
          }`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response: AbstractResponse) => {
          this.notification.success(titulo, response.message);
          resolve(response as AbstractResponse);
        })
        .catch((err) => {
          this.notification.error(titulo, err.error.message);
          reject({
            message: err.error.message,
            result: undefined,
          } as AbstractResponse);
        });
    });
  }

  getUrlByEntidade(entidade: string = this.entidade): string {
    // recupera token do local storage
    const token = localStorage.getItem('token');
    // decodifica token
    const tokenDecode = this.helper.decodeToken(token);

    const { idUg, idMunicipio } = tokenDecode.userInfo;

    const urlServer = {
      [`logradouro`]: this.generateUrlByEnvironment(
        '3011',
        'endereco-api/api/logradouro',
        'nacional'
      ),
      [`cidade`]: this.generateUrlByEnvironment(
        '3011',
        'endereco-api/api/cidade',
        'nacional'
      ),
      [`auth`]: this.generateUrlByEnvironment('3014', 'api', 'auth'),
      [`cnae-subclasse`]: this.generateUrlByEnvironment(
        '3011',
        `pessoa-api/api/cnae-subclasse`,
        'nacional'
      ),
      [`natureza-juridica`]: this.generateUrlByEnvironment(
        '3011',
        `natureza-juridica-api/api/natureza-juridica`,
        'nacional'
      ),
      [`pessoa-fisica`]: this.generateUrlByEnvironment(
        '3010',
        `pessoa-api/api/pessoa-fisica/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`pessoa-juridica`]: this.generateUrlByEnvironment(
        '3010',
        `pessoa-api/api/pessoa-juridica/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`pessoa-contato`]: this.generateUrlByEnvironment(
        '3010',
        `pessoa-api/api/pessoa-contato/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`pessoa-endereco`]: this.generateUrlByEnvironment(
        '3010',
        `pessoa-api/api/pessoa-endereco/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`pessoa-responsaveis`]: this.generateUrlByEnvironment(
        '3011',
        `pessoa-api/api/pessoa-responsaveis/${idUg}/${idMunicipio}`,
        'nacional'
      ),
      [`instituicao-siconfi`]: this.generateUrlByEnvironment(
        '3011',
        `unidade-gestora-api/api/instituicao-siconfi`,
        'nacional'
      ),
      [`unidade-federativa`]: this.generateUrlByEnvironment(
        '3011',
        'endereco-api/api/unidade-federativa',
        'nacional'
      ),
      [`orgao`]: this.generateUrlByEnvironment(
        '3010',
        `unidade-orcamentaria-api/api/orgao/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`unidade-orcamentaria`]: this.generateUrlByEnvironment(
        '3010',
        `unidade-orcamentaria-api/api/unidade-orcamentaria/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`unidade-gestora`]: this.generateUrlByEnvironment(
        '3010',
        `unidade-gestora-api/api/unidade-gestora/${idUg}/${idMunicipio}`,
        'municipal'
      ),
      [`servidor-cloud`]: this.generateUrlByEnvironment(
        '3010',
        `api/servidor-cloud`,
        'municipal'
      ),
      [`licenca`]: this.generateUrlByEnvironment(
        '3032',
        `api/licenca/${idUg}`,
        'autorizacao'
      ),
      [`perfil`]: this.generateUrlByEnvironment(
        '3032',
        `api/perfil/${idUg}`,
        'autorizacao'
      ),
      [`licenca-ug`]: this.generateUrlByEnvironment(
        '3032',
        `api/licenca`,
        'autorizacao'
      ),
      [`perfil-ug`]: this.generateUrlByEnvironment(
        '3032',
        `api/perfil`,
        'autorizacao'
      ),
      [`usuario`]: this.generateUrlByEnvironment(
        '3032',
        `api/usuario/${idUg}`,
        'autorizacao'
      ),
      [`modulo`]: this.generateUrlByEnvironment(
        '3032',
        `api/modulo/${idUg}`,
        'autorizacao'
      ),
      [`api`]: this.generateUrlByEnvironment(
        '3003',
        `api/api/${idUg}`,
        'dependencias'
      ),
      [`entidade`]: this.generateUrlByEnvironment(
        '3003',
        `api/entidade`,
        'dependencias'
      ),
      [`modulo-sistema`]: this.generateUrlByEnvironment(
        '3032',
        `api/modulo/${idUg}`,
        'autorizacao'
      ),
      [`submodulo-sistema`]: this.generateUrlByEnvironment(
        '3032',
        `api/submodulo/${idUg}`,
        'autorizacao'
      ),
      [`submodulo`]: this.generateUrlByEnvironment(
        '3032',
        `api/submodulo/${idUg}`,
        'autorizacao'
      ),
    };

    return urlServer[entidade];
  }
  validarCampo(campo, tipoValidacao) {
    return Helper.validarCampo(campo, tipoValidacao);
  }

  async pesquisarTodos(entidade = this.entidade): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      return this.http
        .get(
          `${this.getServer(entidade)}/get-all`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response) => {
          resolve(response as AbstractResponse);
        })
        .catch((err) => {
          reject({
            message: err.error.message,
            result: undefined,
          } as AbstractResponse);
        });
    });
  }

  excluirFilho(
    idEntidade,
    idFilho,
    entidadeFilho,
    entidade = this.entidade,
    titulo
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${this.getServer(
            entidade
          )}/${idEntidade}/${entidadeFilho}/${idFilho}`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response: any) => {
          this.notification.success(titulo, response.message);
          resolve(response as AbstractResponse);
        })
        .catch((err) => {
          this.notification.error(
            titulo,
            typeof err === typeof 'string' ? err : err.message
          );
          reject({
            message: err.error.message,
            result: undefined,
          } as AbstractResponse);
        });
    });
  }

  async getTableProps(
    entidade: string = this.entidade,
    titulo: string = this.transformarEntidadeEmTitulo(entidade)
  ) {
    const camposPesquisa: Array<{
      text: string;
      value: any;
      type: string;
    }> = [];
    const colunasTabela: Array<{
      text: string;
      value: string;
      mask: string;
      compare?: (a: any, b: any) => any;
      objectChildren: {};
      type?: string;
    }> = [];
    return this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
              type: response.data[p].type,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsProps: colunasTabela,
        };
      });
  }

  async getAllRegistrosInInterval(parametros: AbstractParams): Promise<any> {
    const {
      entidade = this.entidade,
      params = [],
      titulo = this.transformarEntidadeEmTitulo(this.entidade),
      page = 1,
      size = 10,
      all = params.length === 0,
    } = parametros;
    const paramsPaginator = `page=${page}&size=${size}${
      all ? `&todos=${all}` : ``
    }`;
    const filter =
      params.length !== 0 ? `&${params[0].props}=${params[0].value}` : '';

    const selectUser = parametros.selectUser === true ? '-select' : '';

    return this.http
      .get(
        `${this.getServer(
          entidade
        )}/pesquisar-avancado${selectUser}?${paramsPaginator}${filter}`,

        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { data: any; message: string }) => {
        const returned =
          result.message && result.data.resultado.length > 0
            ? result.message
            : 'Nenhum registro encontrado!';
        if (result.data.resultado.length == 0) {
          this.notification.warning(`${titulo}`, `${returned}`);
        }
        if (result.data.resultado.length > 0) {
          // this.notification.success(`${titulo}`, result.message);
        }
        return result;
      })
      .catch((err: any) => {
        // this.notificarWarning(err, titulo);
        return { data: [], message: err };
      });
  }

  notificarErro(
    err: any | { message: string; data: any } | string,
    titulo: string = this.transformarEntidadeEmTitulo(this.entidade)
  ) {
    return this.notification.error(
      titulo,
      typeof err === typeof 'string' ? err : err.message
    );
  }
  notificarWarning(
    err: any | { message: string; data: any } | string,
    titulo: string = this.transformarEntidadeEmTitulo(this.entidade)
  ) {
    return this.notification.warning(
      titulo,
      typeof err === typeof 'string' ? err : err.message
    );
  }

  pesquisarAvancado(
    parametros,
    entidade: string = this.entidade
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      const selectUser = parametros.selectUser === true ? '-select' : '';

      this.http
        .get(
          `${this.getServer(entidade)}/pesquisar-avancado${selectUser}?page=${
            parametros.page
          }&size=${parametros.size}&${parametros.field.valor}=${
            parametros.value
          }`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response) => {
          resolve(response as AbstractResponse);
        })
        .catch((err) => {
          reject({
            message: err.error.message,
            result: undefined,
          } as AbstractResponse);
        });
    });
  }

  insertOrUpdate(
    form,
    idMp?,
    entidade: string = this.entidade
  ): Promise<AbstractResponse> {
    const titulo = this.transformarEntidadeEmTitulo(entidade);
    if (form.id) {
      return this.http
        .put(
          `${this.getServer(entidade)}/${form.id}`,
          form,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((result: { message: string; data: any }) => {
          this.notification.success(titulo, result.message);
          return result;
        })
        .catch((err) => {
          this.notificarErro(err, titulo);
          return err;
        });
    }
    return this.http
      .post(
        `${this.getServer(entidade)}`,
        form,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: any }) => {
        this.notification.success(titulo, result.message);
        return result;
      })
      .catch((err) => {
        this.notification.error(
          titulo,
          typeof err === 'string' ? err : err.message
        );
        return err;
      });
  }

  getServer(entidade: string = this.entidade): string {
    const entity = entidade ? entidade : this.entidade;
    return `${this.getUrlByEntidade(entity)}`;
  }

  getUrl(options?: { entidade?: string; path?: string }): string {
    const { entidade = this.entidade, path = null } = options;
    return path
      ? `${this.getServer(entidade)}/${path}`
      : this.getServer(entidade);
  }

  public headerAuthenticateOptions(tokenDinamico = false) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${
          (tokenDinamico || this.tokenDicamico) &&
          this.authService.getTokenDinamico()
            ? this.authService.getTokenDinamico()
            : this.authService.getToken()
        }`,
      }),
    };
  }

  generateUrlByEnvironment(port: string, path?: string, server = ''): string {
    // return `http://localhost:${port}/${path}`;

    // if (['autorizacao', 'municipal'].includes(server)) {
    //   return `http://localhost:${port}/${path}`;
    // }

    // if (['auth'].includes(server)) {
    //   return `http://localhost:${port}/${path}`;
    // }
    // if (['nacional'].includes(server)) {
    //   return `${environment.apiUrl(server)}/${path}`;
    // }

    // if (server === 'autorizacao') {
    //   return `http://localhost:3005/${path}`;
    // }
    // if (server === 'exportador') {
    //   return `${environment.apiTools(server)}/${path}`;
    // }

    // return `${environment.apiAssinatura(path)}`;
    return `${environment.apiUrl(server)}/${path}`;
  }

  async getUrlAvatar(icone, nome): Promise<string> {
    const base64Encoded = icone.replace(/^data:image\/\w+;base64,/, '');

    return await this.http
      .post(
        `${UrlUploadApi}/api/arquivo/base64`,
        {
          ambiente: environment.ambiente === 'PRODUCTION' ? 'prod' : 'homo',
          path: `PainelAdiministrativo/${
            this.authService.getOrgaoSelected().id
          }/${new Date().getFullYear()}/${new Date().getDay()}`,
          file: base64Encoded,
          nome: `${nome}-avatar.png`,
          extensao: 'png',
        },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: any) => {
        const uuidUpload = result.dados.uuid;

        return await this.http
          .get(
            `${UrlUploadApiPublic}/${uuidUpload}`,
            this.headerAuthenticateOptions()
          )
          .toPromise()
          .then(async (resultUpload: any) => {
            return resultUpload.url;
          });
      });
  }

  setCompareToTableColumns(displayData, tableColumns) {
    if (displayData?.length === 0) {
      return;
    }

    tableColumns.map((column) => {
      column[`compare`] =
        typeof displayData[0][column.value] === 'string'
          ? (a, b) => a[column.value].localeCompare(b[column.value])
          : (a, b) => a[column.value] - b[column.value];
    });
  }

  consultarPorParametro(
    parametros,
    entidade: string = this.entidade
  ): Promise<AbstractResponse> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.getServer(entidade)}/consultar-por-parametro?field=${
            parametros.field
          }&value=${parametros.value}`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject({
            message: typeof err === typeof 'string' ? err : err.message,
            result: undefined,
          } as AbstractResponse);
        });
    });
  }
}
