import {
  Directive,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { GridConfig, GRID_CONFIG } from './models/grid.config';
import { Setting } from './setting.interface';
import { PsColDef } from './types/ps-col-def';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class PsAggridBaseComponent implements OnChanges {
  gridActivated = false;

  locale;

  // tslint:disable-next-line: no-input-rename
  @Input('masterDetail') masterDetail = false;

  // tslint:disable-next-line: no-input-rename
  @Input('settings') settings: Partial<Setting>;

  // tslint:disable-next-line: no-input-rename
  @Input('columnDefs') columnDefs: PsColDef[];

  // tslint:disable-next-line: no-input-rename
  @Input('rowData') rowData: any[];

  public overlayLoadingTemplate;
  public overlayNoRowsTemplate;

  public loadingOverlayComponent: string;

  public frameworksComponent;
  protected gridApi: GridApi;
  // tslint:disable-next-line: no-input-rename
  @Input('defaultColDef') defaultColDef: ColDef;

  // tslint:disable-next-line: no-input-rename
  @Input('detailCellRendererParams') detailCellRendererParams: any;
  autoGroupColumnDef: ColDef = {
    headerName: 'Name',
    field: 'name',
    minWidth: 250,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { footerValueGetter: '"Total (" + x + ")"' },
  };

  kGridOptions: GridOptions;

  @Input('gridOptions') set gridOptions(gridOptions: GridOptions) {
    this.kGridOptions = _.merge(gridOptions, {
      autoGroupColumnDef: this.autoGroupColumnDef,
      groupSelectsChildren: true,
      animateRows: true,
      rowSelection: 'multiple',
      pagination: true,
      paginationPageSize: 10,
      domLayout: 'autoHeight',
    });
  }

  get gridOptions(): GridOptions {
    return this.kGridOptions;
  }

  constructor(@Inject(GRID_CONFIG) public gridConfig: GridConfig) {}

  public get isAntd(): boolean {
    return this.gridConfig.gridType === 'antd';
  }

  public get isAgGrid(): boolean {
    return this.gridConfig.gridType === 'ag-grid';
  }

  abstract onGridReady(params: any): void;

  ngOnChanges(simpleChanges: SimpleChanges) {}
}
