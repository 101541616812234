import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  AbstractError,
  IColumnsFieldsProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from '../interfaces/shared.interface';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class DetailDocumentService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService
  ) {
    super(
      'finishedDocument',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  async getProps(entidade: string = null): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    // return await this.http.get(`${this.getServer(entidade)}/props`, this.headerAuthenticateOptions()).toPromise()
    return this.getPropsEventTableDocMock()
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }
}
