import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';
import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsPendingService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService
  ) {
    super(
      'pendingDocument',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }
}
