import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MenuItem,
  PsTabSessionService,
  ThemeService,
} from '@ps-erp-angular/shared';
import { PsOnRouterChange } from '../../utils/util';

@Component({
  selector: 'ps-recursive-sidebar',
  templateUrl: './ps-recursive-sidebar.component.html',
  styleUrls: ['./ps-recursive-sidebar.component.scss'],
})
export class PsRecursiveSidebarComponent implements OnInit {
  @Input() menuItems: MenuItem[];
  @Input() version: string;

  @Input() level: number;

  search: any;
  isFolded: boolean;
  isExpand: boolean;
  isSideNavDark: boolean;

  @Output() toggleFolder: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private themeService: ThemeService,
    private tabSessionService: PsTabSessionService
  ) {}

  get mode() {
    return this.isFolded ? 'vertical' : 'inline';
  }

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );

    const initialPage = this.menuItems.find((page) => page.initialPage);

    if (initialPage) {
      this.tabSessionService.addTab({
        name: initialPage.title,
        url: initialPage.path,
        queryParams: initialPage.queryParams,
      });
    }
  }

  addTab(item: MenuItem): void {
    PsOnRouterChange.emitRouterChange = {
      name: item.tabTitle ?? item.title,
      path: item.path,
      queryParams: item.queryParams,
    };
    this.tabSessionService.addTab({
      name: item.tabTitle ?? item.title,
      url: item.path,
      queryParams: item.queryParams,
    });
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.toggleFolder.emit();
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }
}
