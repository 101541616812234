import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { isUndefined } from 'util';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PessoaService extends AbstractService {
  constructor(
    private abstractHttp: HttpClient,
    protected abstractAuthService: AuthService,
    public notification: NzNotificationService
  ) {
    super(null, abstractHttp, notification, abstractAuthService);
  }

  verificaEnderecoVazio(enderecos): boolean {
    return (
      enderecos.filter((endereco) => endereco.cep && endereco.cep !== '')
        .length > 0
    );
  }

  enderecoRepitido(form): boolean {
    const enderecos = form.get(`enderecos`).value;
    enderecos.map((endereco) =>
      form.get(`enderecos.${enderecos.indexOf(endereco)}.error`).setValue(false)
    );
    const indexEnderecoRepetido = [];
    for (const index in enderecos) {
      if (enderecos.length > 0) {
        const endereco = enderecos[index];
        enderecos.map((end) => {
          if (
            enderecos.indexOf(end) !== Number(index) &&
            end.cep === endereco.cep &&
            end.numero === endereco.numero &&
            end.nomCidade === endereco.nomCidade &&
            end.nomBairro === endereco.nomBairro &&
            end.nomLogradouro === end.nomLogradouro &&
            end.estado === endereco.estado
          ) {
            indexEnderecoRepetido.push(index);
          }
        });
      }
    }
    if (indexEnderecoRepetido.length > 0) {
      indexEnderecoRepetido.map((index) =>
        form.get(`enderecos.${index}.error`).setValue(true)
      );
      this.notification.warning(
        'Pessoa física',
        'Registro possui endereços iguais.'
      );
    }
    return indexEnderecoRepetido.length > 0;
  }

  verificaPessoaRespVazio(pessoaResponsaveis): boolean {
    return (
      pessoaResponsaveis.filter(
        (pessoaResponsavel) =>
          pessoaResponsavel.cpf && pessoaResponsavel.cpf !== ''
      ).length > 0
    );
  }

  verificaPassaporte(form): boolean {
    if (
      form.value.nacionalidade &&
      form.value.nacionalidade !== 0 &&
      !form.value.passaporte.numero
    ) {
      this.notification.warning(
        'Pessoa física',
        'A pessoa extrangeira/naturalizada deve preecher o passaporte.'
      );
    }
    return (
      form.value.nacionalidade &&
      form.value.nacionalidade !== 0 &&
      !form.value.passaporte.numero
    );
  }

  async consultaEndereco(cep, form, index) {
    if (
      cep === '' ||
      cep.length < 8 ||
      (form.get('enderecos').controls[index].hasOwnProperty('nomLogradouro') &&
        !isUndefined(form.get('enderecos').controls[index].value.nomLogradouro))
    ) {
      return null;
    }

    return this.abstractHttp
      .get(
        `${this.getServer('logradouro')}/listar/${cep}`,
        this.headerAuthenticateOptions()
      )
      .toPromise();
  }

  verificaContatoVazio(contatos): boolean {
    return (
      contatos.filter((contato) => contato.email && contato.email !== '')
        .length > 0
    );
  }
}
