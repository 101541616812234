import { AbstractControl } from '@angular/forms';

export function fieldMatchValidator(control: AbstractControl) {
    const { password, passwordConfirm } = control.value;

    // avoid displaying the message error when values are empty
    if (!passwordConfirm || !password) {
        return null;
    }

    if (passwordConfirm === password) {
        return null;
    }

    return { fieldMatch: { message: 'Senhas não conferem.' } };
}

export function emailValidator(control: AbstractControl) {
    if (control.value) {
        const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        return matches ? { email: true } : null;
    } else {
        return null;
    }
}
