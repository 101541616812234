<nz-tabset 
    [nzType]="'card'" 
    [nzTabBarExtraContent]="extraTemplate" 
    [nzSelectedIndex]="tabSelectedIndex"
>
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="titleTemplate" (nzClick)="handlerTabClick(tab)">
        <ng-template #titleTemplate>
            <div>
                {{ tab.name }}
                <i nz-icon nzType="close" (click)="closeTab(tab); $event.stopPropagation();" class="ant-tabs-close-x"></i>
            </div>
        </ng-template>
    </nz-tab>
</nz-tabset>

<nz-card *ngIf="tabs.length">
    <router-outlet></router-outlet>
</nz-card>

<ng-template #extraTemplate>
</ng-template>