import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable()
export class SolicitacaoITBI extends AbstractService {
  private titulo: string = 'Solicitação de ITBI';
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super(
      'solicitacao-ITBI',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  public async getOneImovel(formParams?) {
    return await this.getGetOneImovel(formParams)
      .then((resp: any) => {
        if(resp.data === null){
          throw new Error('Imóvel não encontrado.') 
        }
        return resp.data;
      })
  }

  private getGetOneImovel(formParams) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(formParams).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const subscription: Subscription = this.http
        .get(`${this.getServer('itbi')}/get-one${formParams ? params : ''}`, {
          headers: new HttpHeaders({
            //'x-authorization': `${localInfo.cnpjInst}`,
            'x-authorization': `09073628000191`
          }),
        })
        .subscribe({
          next: (resp) => {
            resolve(resp);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async postSolicitarITBI(formParams, formData) {
    return await this.postPostSolicitarITBI(formParams, formData)
      .then((resp: any) => {
        return resp.data;
      })
  }

  private postPostSolicitarITBI(formParams, formData) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(formParams).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const subscription: Subscription = this.http
        .post(`${this.getServer('itbi')}/solicitar-itbi${formParams ? params : ''}`, formData, {
          headers: new HttpHeaders({
           //'x-authorization': `${localInfo.cnpjInst}`,
           'x-authorization': `09073628000191`
          })
        })
        .subscribe({
          next: (resp) => {
            resolve(resp);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    }); 
  }

  viewComprovante(processoId: number): Promise<void> {
    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);
    return new Promise<void>((resolve, reject) => {
      this.http.get(`${this.getServer('itbi')}/get-comprovante?idProcesso=${processoId}&action=${'view'}`, 
      { 
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`,
        }), 
        responseType: 'arraybuffer',
      }).subscribe(
        (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = window.URL.createObjectURL(blob);
          const test = window.open(blobUrl);
  
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            resolve();
          }, 1000);
        },
        (error) => {
          console.error('Corpo da resposta:', error?.error); // Loga o corpo da resposta no console
          reject(error);// Rejeite a Promise em caso de erro
        },
      );
    });
  }

  baixarComprovante(processoId: number): Promise<void> {
    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);
    return new Promise<void>((resolve, reject) => {
      this.http.get(`${this.getServer('itbi')}/get-comprovante?idProcesso=${processoId}&action=${'download'}`, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`,
        }), 
        responseType: 'arraybuffer',
      }).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.download = `comprovante_processo_ITBI_${processoId}.pdf`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)

        window.URL.revokeObjectURL(link.href)

        resolve()
      },
      (error) => {
        console.error('Corpo da resposta: ', error?.error)
        reject(error)
      })
    })

  }


}
