import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-actions',
  templateUrl: './ps-actions.component.html',
  styles: [
    `
      .btn-actions {
        position: fixed;
        right: 20px;
        bottom: 50px;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }
    `,
  ],
})
export class PsActionsComponent {}
