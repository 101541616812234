import { FormlyFieldConfig } from '@ngx-formly/core';

export const getField = (
  key: string,
  fields: FormlyFieldConfig[]
): FormlyFieldConfig => {
  for (let i = 0, len = fields.length; i < len; i++) {
    const f = fields[i];
    if (f.key === key) {
      return f;
    }

    if (f.fieldGroup && !f.key) {
      const cf = getField(key, f.fieldGroup);
      if (cf) {
        return cf;
      }
    }
  }
};

export const changeField = (
  key: string,
  fields: FormlyFieldConfig[],
  fieldChanged: FormlyFieldConfig
): FormlyFieldConfig[] => {
  fields.some((field) => {
    if (field.fieldGroup) {
      for (const i in field.fieldGroup) {
        if (field.fieldGroup[i].key === key) {
          field.fieldGroup[i] = fieldChanged;
        }
      }
    }

    // else if(field.fieldArray) {
    //     for(const i in field.fieldArray) {
    //         return getKey(formGroup, key, field.fieldArray[i]);
    //     }
    // }
  });

  return fields;
};
