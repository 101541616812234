<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="'Cidades'" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="manutencaoLoading">
        <form nz-form [formGroup]="formB" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div class="m-l-20 p-l-10" nz-col nzSpan="9" nzSm="9" nzXs="24">
              <nz-form-item>
                <nz-form-label>Nome da cidade</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomCidade" placeholder="Nome da cidade">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="m-l-20" nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>Código IBGE</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="codigoIbge" placeholder="Código IBGE">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="m-l-15" nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-label>Brasão</nz-form-label>
              <nz-form-control>
                <div class="d-inline-flex">
                  <input style="display: none" accept='image/*' type="file" (change)="uploadAvatarCity($event)"
                    #hiddenfileinput>
                  <!-- <nb-user [name]="formB.value.nomCidade" [picture]="formB.value.brasao || null"> -->
                  <nz-avatar (click)="hiddenfileinput.click(); $event.preventDefault()" [nzShape]="'square'"
                    [nzSize]="40" [nzIcon]="'picture'" [nzSrc]="formB.value.brasao || null">
                  </nz-avatar>
                  <!-- </nb-user> -->
                  <button class="m-l-10 p-l-10 p-r-10" nz-button nzType="primary"
                    (click)="hiddenfileinput.click(); $event.preventDefault()"
                    style="display:flex; align-items:center; justify-content: center; ">
                    <span nz-icon style="font-size: 1.1rem;" nzType="import" nzTheme="outline"></span>
                  </button>
                </div>
              </nz-form-control>
            </div>
            <!-- <div class="clearfix m-l-10" nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-upload style="transform:scale(0.6);position: relative;bottom:0.5em"
                nzAction="https://run.mocky.io/v3/61302951-086b-44b2-acb1-b585b79fad86" nzListType="picture-card"
                [(nzFileList)]="fileList" formControlName='brasao' [nzShowButton]="fileList.length < 1"
                [nzPreview]="handlePreview">
                <div>
                  <span nz-icon style="font-size: 2em;" nzType="plus"></span>
                  <div style="margin-top: 8px ;font-size: 2em;">Upload</div>
                </div>
              </nz-upload>
              <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal>
            </div> -->
          </div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row>
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldCidadesSelected"
                              (ngModelChange)="getFilterCidadeOptionsSelect(fieldCidadesSelected)">
                              <nz-option *ngFor="let interval of arrColumnsCidadesSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>

                          </div>
                          <nz-input-group *ngIf="fieldCidadesSelected && fieldCidadesSelected.type === 'input'"
                            class="m-l-10 " nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="''">
                            <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                              [(ngModel)]="searchInput" />
                          </nz-input-group>
                          <div
                            *ngIf="fieldCidadesSelected && tabIndex == 1 && fieldCidadesSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldCidadesSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsCidades" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroCidadePorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsCidadesSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'" [nzOffset]="showComboTagsCidades?8:20" nzSpan="3"
                            nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterCidades()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="true" #CidadeListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableCidadeColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="CidadeListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="editarCidade(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>


              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableCidadeColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>