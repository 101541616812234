<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row nzGutter="16">
            <div nz-col nzSpan="21" nzSm="21" nzXs="20">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomSubmodulo" placeholder="Nome do submodulo">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="3" nzSm="3" nzXs="4">
              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-form-control>
                  <label nz-checkbox formControlName="adm" [(ngModel)]="checkboxAdm">adm?</label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5 m-b-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="p-b-10 p-l-20">Formulário</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <!-- <button [disabled]="verificaEntidadeVazia(form.value.entidades)" type="button" nbButton outline
                status="primary" (click)="addEntidade()"><nb-icon icon="plus"></nb-icon></button> -->
              <button nz-button [disabled]="verificaFormularioVazio(form.value.formularios)" (click)="addFormulario()"
                nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>


            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

          <div formArrayName="formularios">
            <div nz-row nzGutter="12" *ngFor="let formulario of form.get('formularios')['controls']; index as i"
              [formGroupName]="i">
              <div nz-col nzSpan="15" nzSm="15" nzXs="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Nome</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nomFormulario" maxlength="100" placeholder="Nome do formulario">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col [nzSpan]="form.value.formularios.length > 1?8:9"
                [nzSm]="form.value.formularios.length > 1?8:9" nzXs="24">
                <nz-form-item>
                  <nz-form-label nzRequired>Api</nz-form-label>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input formControlName="desApi" maxlength="100" (keydown)="showModalApi(i)" nz-input
                      placeholder="Nome da api" (click)="showModalApi(i)" />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button *ngIf="apiEntity[i]" (click)="limparApi(i)" nz-button type="button" nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="showModalApi(i)" nzSearch><span nz-icon
                        nzType="search"></span></button>
                  </ng-template>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="1" nzSm="1" nzXs="24">
                <nz-form-item>
                  <nz-form-label>&nbsp;</nz-form-label>
                  <nz-form-control>
                    <button nzDanger nzType="primary" *ngIf="form.value.formularios.length > 1" nz-button outline
                      (click)="removeFormulario(i)"> <i nz-icon nzType="delete"></i></button>
                  </nz-form-control></nz-form-item>
              </div>
            </div>
          </div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldSubmodulosSelected"
                              (ngModelChange)="getFilterSubmoduloOptionsSelect(fieldSubmodulosSelected)">
                              <nz-option *ngFor="let interval of arrColumnsSubmodulosSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <nz-input-group *ngIf="fieldSubmodulosSelected && fieldSubmodulosSelected.type === 'input'"
                            class="m-l-10 " nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="''">
                            <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                              [(ngModel)]="searchInput" />
                          </nz-input-group>
                          <div
                            *ngIf="fieldSubmodulosSelected && tabIndex == 1 && fieldSubmodulosSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldSubmodulosSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsSubmodulo" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroSubmoduloPorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsSubmodulosSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="showComboTagsSubmodulos?openModal?7:8:openModal?19:20" nzSpan="3" nzXl="3"
                            nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                              (click)="filterSubmodulos()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #SubmoduloListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableSubmoduloColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="SubmoduloListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="dblclickSubmodulo(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableSubmoduloColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>