import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AcordoDividaImobiliariaService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super('', abstractHttp, abstractAuthService, abstractNotification);
  }

  public async getQntdMaxParcelas(params?) {
    return await this.getGetQntdMaxParcelas(params).then((resp: any) => {
      if (resp.data == null) {
        throw new Error('valor não encontrado');
      }
      return resp.data;
    });
  }

  private getGetQntdMaxParcelas(param?) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('acordo-divida')}/imobiliaria/qntd-max-parcelas${
            param ? params : ''
          }`,
          {
            headers: new HttpHeaders({
              // "x-authorization": `${localInfo.cnpjInst}`
              'x-authorization': `09073628000191`,
            }),
          }
        )
        .subscribe({
          next: (resp) => {
            resolve(resp);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async getImoveis(formParams?) {
    return await this.getGetImoveis(formParams).then((resp: any) => {
      if (resp.data === null) {
        throw new Error('Imóvel não encontrado.');
      }
      return resp.data;
    });
  }

  private getGetImoveis(formParams) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(formParams).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('imovel')}/dividas/get-many${
            formParams ? params : ''
          }`,
          {
            headers: new HttpHeaders({
              //'x-authorization': `${localInfo.cnpjInst}`,
              'x-authorization': `09073628000191`,
            }),
          }
        )
        .subscribe({
          next: (resp) => {
            resolve(resp);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async getDividas(params) {
    return await this.getGetDividas(params).then((response: any) => {
      if (response.data === null) {
        throw new Error('Dívidas não encontrada.');
      }
      return response.data;
    });
  }

  private getGetDividas(param) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/imobiliaria/lancamentos/get-many${param ? params : ''}`;

      const subscription: Subscription = this.abstractHttp
        .get(url, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': `09073628000191`,
          }),
        })
        .subscribe({
          next: (respone) => {
            resolve(respone);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  async postCalcDividas(param, data) {
    return await this.postPostCalcDividas(param, data).then((response: any) => {
      if (response.data === null) {
        throw new Error('Dívidas não encontrada.');
      }
      return response.data;
    });
  }

  private postPostCalcDividas(param, data) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/imobiliaria/lancamentos/calc-mjct${param ? params : ''}`;

      const subscription: Subscription = this.abstractHttp
        .post(url, data, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': `09073628000191`,
          }),
        })
        .subscribe({
          next: (respone) => {
            resolve(respone);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async postDivida(formData) {
    return this.postPostDivida(formData).then((response: any) => {
      return response.data;
    });
  }

  private postPostDivida(formData) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams().toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/imobiliaria/solicitar-acordo`;

      const subscription: Subscription = this.abstractHttp
        .post(url, formData, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': '09073628000191',
          }),
        })
        .subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }
}
