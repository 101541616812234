import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProvedoresService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('example', http, notification, authService);
  }

  async getCidadesTableProps(): Promise<any> {
    return {};
  }

  async getExamplesTableProps() {
    const camposPesquisa: Array<{
      text: string;
      value: any;
      type: string;
    }> = [];
    const colunasTabela: Array<{
      text: string;
      value: string;
      mask: string;
      compare?: (a: any, b: any) => any;
      objectChildren: {};
      type?: string;
    }> = [];
    return this.http
      .get(
        `${this.getServer('example')}/any/props`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
              type: response.data[p].type,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsExample: colunasTabela,
        };
      });
  }

  async getAllExampleInInterval(fieldsExamplesSelected): Promise<any> {
    return;
  }
}
