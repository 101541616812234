import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-content',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./ps-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
})
export class PsContentComponent implements OnInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.elementRef.nativeElement, 'ant-layout-content');
  }

  ngOnInit(): void {}
}
