import { Component, OnInit } from '@angular/core';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  Helper,
  IColumnsFieldsProps,
  ISearchFieldsProps,
  ProcessoService,
} from '@ps-erp-angular/shared';
import { ProcessoInterface } from 'libs/shared/src/lib/interfaces/processo-interface';
import * as moment from 'moment';

@Component({
  selector: 'ps-processo',
  templateUrl: './ps-processo.component.html',
  styleUrls: ['./ps-processo.component.scss'],
})
export class PsProcessoComponent implements OnInit {
  getDateNow: any = new Date().toLocaleString();
  selectedValue;

  listOfColumn = [
    {
      text: 'Usuario',
      value: 'usuario',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.usuario.nome.localeCompare(b.usuario.nome),
      priority: false,
    },
    {
      text: 'Unidade Gestora',
      value: 'unidadeGestora',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.cnpjUnidadeGestora.localeCompare(b.cnpjUnidadeGestora),
      priority: 4,
    },
    {
      text: 'Criado',
      value: 'dtCriacao',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.createdAt.localeCompare(b.createdAt),
      priority: 3,
    },
    {
      text: 'Atualizado',
      value: 'dtAlteracao',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.updatedAt.localeCompare(b.updatedAt),
      priority: 2,
    },
    {
      text: 'ID',
      value: 'id',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.id.localeCompare(b.id),
      priority: 1,
    },
  ];

  fieldSelected: any;
  fieldsSelected = [];
  searchInput;
  selectedValues = [];
  showComboTags = false;

  dataPicker = false;
  campoCodigo = false;

  opcoesFiltro;

  fabButtons: IMenuButton[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  checkList = {
    id: true,
    usuario: true,
    unidadeGestora: true,
    dtCriacao: true,
    dtAlteracao: true,
  };

  loadingPage = false;
  loadingTable = false;

  checked = false;
  indeterminate = false;

  listProcessos: any;
  fieldEnvioSelected;
  fieldsMensagensEnvioSelected = [];
  setOfCheckedId = new Set<number>();

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  constructor(private processoService: ProcessoService) {}

  async ngOnInit() {
    this.opcoesFiltro = await this.processoService.getSearchFields();
    this.resetFabButtons();
  }

  getWindow() {
    return window.innerWidth;
  }

  changeValue(value) {
    this.dataPicker = value === 'dtCriacao' || value === 'dtAlteracao';
    this.campoCodigo = value === 'id';
  }

  async getProcessos() {
    try {
      const params = Helper.convertSelectedFieldsToURLParams(
        this.fieldsSelected
      );

      this.loadingPage = true;
      this.listProcessos = await this.processoService.getProcessos(params);
    } finally {
      this.loadingPage = false;
    }
  }

  attDashboard = () => {
    this.reset();
    this.getDate();
  };

  async reset() {
    await this.getProcessos();
  }

  resetarListColumns() {
    this.checkList = {
      id: true,
      usuario: true,
      unidadeGestora: true,
      dtCriacao: true,
      dtAlteracao: true,
    };
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: this.attDashboard,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getOptionsSelect() {}

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const filtro = this.opcoesFiltro.find(
        (item) => item.value === this.fieldSelected.value
      );

      filtro.enabled = false;
      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  get filtros() {
    return this.opcoesFiltro.filter((item) => item?.enabled);
  }

  removeFiltroPorField(index) {
    const filtro = this.opcoesFiltro.find(
      (item) => item.value === this.fieldsSelected[index].props
    );

    filtro.enabled = true;

    this.fieldsSelected.splice(index, 1);
    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }
}
