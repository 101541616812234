import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnidadeOrcamentariaService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('unidade-orcamentaria', http, notification, authService);
  }


  async consultaUnidadeGestora(codigo, isIdPrivado = false) {
    return codigo
      ? this.consultarPorParametro(
        { field: isIdPrivado ? 'id' : 'idPublico', value: codigo },
        'unidade-gestora',
      )
      : null;
  }



  async consultaOrgao(codigo) {
    return codigo
      ? this.consultarPorParametro(
        { field: 'idPublico', value: codigo },
        'orgao',
      )
      : null;
  }

  async consultaPessoaFisica(cpf) {
    return cpf
      ? await this.consultarPorParametro(
        { field: 'cpf', value: cpf },
        'pessoa-fisica',
      )
      : null;
  }

  


}
