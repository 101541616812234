import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessibleForm } from './acessible-form.directive';
import { SideNavDirective } from './side-nav.directive';

@NgModule({
  declarations: [SideNavDirective, AccessibleForm],
  imports: [CommonModule],
  exports: [SideNavDirective, AccessibleForm],
})
export class PsDirectiveModule {}
