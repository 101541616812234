import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnChanges,
  OnInit,
} from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CustomLoadingOverlay } from './ag-grid/components/custom-loading-overlay.component';
import { GridConfig, GRID_CONFIG } from './models/grid.config';
import { PsAggridBaseComponent } from './ps-grid-base.component';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-grid',
  templateUrl: './ps-grid.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsGridComponent
  extends PsAggridBaseComponent
  implements OnChanges, OnInit {
  autoGroupColumnDef: ColDef = {
    headerName: 'Name',
    field: 'name',
    minWidth: 250,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { footerValueGetter: '"Total (" + x + ")"' },
  };

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(GRID_CONFIG) public gridConfig: GridConfig
  ) {
    super(gridConfig);
    this.frameworksComponent = {
      customLoadingOverlay: CustomLoadingOverlay,
    };
    this.loadingOverlayComponent = 'customLoadingOverlay';
  }

  ngOnInit(): void {}

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    setTimeout(() => {
      this.gridApi.hideOverlay();
    }, 3000);
  }

  ngOnChanges(changes) {}
}
