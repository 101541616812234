import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PsDirectiveModule } from '../../directives/ps-directives.module';
import { PsDynamicFormModule } from '../ps-dynamic-form';
import { CustomLoadingOverlay } from './ag-grid/components/custom-loading-overlay.component';
import { GridConfig, GRID_CONFIG } from './models/grid.config';
import { PsGridComponent } from './ps-grid.component';
import { GridFormlyCellComponent } from './types/grid-formly-cell.component';

@NgModule({
  declarations: [
    PsGridComponent,
    GridFormlyCellComponent,
    CustomLoadingOverlay,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PsDynamicFormModule,
    PsDirectiveModule,
    NzTableModule,
    NzCardModule,
    NzButtonModule,
    NzFormModule,
    NzTagModule,
    NzSwitchModule,
    NzDividerModule,
    NzToolTipModule,
    NzAlertModule,
    NzRadioModule,
    NzSpinModule,
    AgGridModule.withComponents([GridFormlyCellComponent]),
  ],
  exports: [PsGridComponent],
})
export class PsGridModule {
  static forRoot(config: GridConfig): ModuleWithProviders<PsGridModule> {
    return {
      ngModule: PsGridModule,
      providers: [{ provide: GRID_CONFIG, useValue: config }],
    };
  }
}
