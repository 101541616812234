import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ConfigOption,
  FormlyConfig,
  FormlyModule,
  FORMLY_CONFIG,
} from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NgxMaskModule } from 'ngx-mask';
import { GridTypeComponent } from './../ps-grid/types/grid.type';
import { PsFormInputComponent, PsFormTemplateComponent } from './custom';
import {
  minlengthValidationMessages,
  minValidatorMessage,
} from './custom/messages/custom-messages';
import { PsFormArrayComponent } from './custom/ps-form-array.component';
import { PsFormGroupComponent } from './custom/ps-form-group.component';
import { PsFormInputDateComponent } from './custom/ps-form-input-date.component';
import { PsFormSelectComponent } from './custom/ps-form-select.component';
import { PsFormTabsComponent } from './custom/ps-form-tabs.component';
import { PsFormWrapperPasswordComponent } from './custom/ps-form-wrapper-password';
import { PsInputCurrencyComponent } from './custom/ps-input-currency.component';
import {
  emailValidator,
  fieldMatchValidator,
} from './custom/validators/custom-validators';
import { PsDynamicFormComponent } from './ps-dynamic-form.component';

@NgModule({
  declarations: [
    PsFormGroupComponent,
    PsFormArrayComponent,
    PsFormInputDateComponent,
    PsFormSelectComponent,
    PsInputCurrencyComponent,
    PsFormInputComponent,
    PsFormTabsComponent,
    PsDynamicFormComponent,
    PsFormTemplateComponent,
    PsFormWrapperPasswordComponent,
    GridTypeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NzModalModule,
    NzInputModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzFormModule,
    NzButtonModule,
    NzIconModule,
    NzDatePickerModule,
    NzTabsModule,
    NzSelectModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(),
    FormlyNgZorroAntdModule,
    FormlyModule.forRoot({
      types: [
        { name: 'formly-group', component: PsFormGroupComponent },
        { name: 'array', component: PsFormArrayComponent },
        { name: 'date', component: PsFormInputDateComponent, extends: 'input' },
        { name: 'input', component: PsFormInputComponent },
        {
          name: 'currency',
          component: PsInputCurrencyComponent,
          extends: 'input',
        },
        { name: 'tabs', component: PsFormTabsComponent },
        { name: 'grid', component: GridTypeComponent },
        // { name: 'password', component: PsInputPasswordComponent, extends: 'input' },
        { name: 'ps-template', component: PsFormTemplateComponent },
        {
          name: 'ps-select',
          component: PsFormSelectComponent,
          extends: 'select',
        },
      ],
      validationMessages: [
        { name: 'required', message: 'Campo obrigatório' },
        { name: 'email', message: 'Email inválido' },
        { name: 'min', message: minValidatorMessage },
        { name: 'minlength', message: minlengthValidationMessages },
      ],
      validators: [
        { name: 'email', validation: emailValidator },
        { name: 'fieldMatch', validation: fieldMatchValidator },
      ],
      wrappers: [
        {
          name: 'wrapper-password',
          component: PsFormWrapperPasswordComponent,
        },
      ],
    }),
  ],
  exports: [PsDynamicFormComponent, PsInputCurrencyComponent, FormlyModule],
})
export class PsDynamicFormModule {
  static forRoot(
    config: ConfigOption = {}
  ): ModuleWithProviders<PsDynamicFormModule> {
    return {
      ngModule: PsDynamicFormModule,
      providers: [
        // { provide: FORMLY_CONFIG, multi: true, useFactory: defaultFormlyConfig, deps: [FormlyConfig] },
        {
          provide: FORMLY_CONFIG,
          useValue: config,
          multi: true,
          deps: [FormlyConfig],
        },
      ],
    };
  }

  constructor(
    configService: FormlyConfig,
    @Inject(FORMLY_CONFIG) configs: ConfigOption[] = []
  ) {
    if (!configs) {
      return;
    }

    configs.forEach((config) => configService.addConfig(config));
  }
}
