import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { IAtestadores } from '../interfaces/tipo-documento';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AtestadorService extends AbstractService {
  private title: string = 'Atestador';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public notification: NzNotificationService
  ) {
    super('atestador', http, authService, notification);
  }

  async createNovosAtestadoresEmLote(novosAtestadores) {
    const formatAtestadores = [];

    for (const nAtestadores of novosAtestadores) {
      formatAtestadores.push({
        idTipoDocumento: nAtestadores.idTipoDocumento,
        idModulo: nAtestadores.idPrivadoModulo,
        idPessoa: nAtestadores.idPessoaAtestador,
        dataInicioAtesto: nAtestadores.dataInicioAtesto,
      });
    }

    const atestadoresToCreate = { atestadores: formatAtestadores };
    return this.postAtestadorEmLote(atestadoresToCreate);
  }

  async editarAtestadoresEmLote(atestadoresEditados) {
    const formatAtestadores = [];

    for (const nAtestadores of atestadoresEditados) {
      formatAtestadores.push({
        idPessoa: nAtestadores.idPessoaAtestador,
        dataInicioAtesto: nAtestadores.dataInicioAtesto,
        ...nAtestadores.vinculacaoModuloTipoDoc,
        idVinculacaoTipoDocumentoModulo:
          nAtestadores.vinculacaoModuloTipoDoc.idPrivado,
        // idPrivadoAtestador: nAtestadores.idPrivado,
      });
    }

    const atestadoresToCreate = { atestadores: formatAtestadores };
    return this.putAtestadoresEmlote(atestadoresToCreate);
  }

  async consultarAtestadorByIdPrivadoTipoDocModulo(idPrivado: number) {
    return this.getConsultarAtestadorByIdPrivadoTipoDocModulo(idPrivado);
  }

  async consultarAtestadorByTipoDocModulo(idModulo: number, idTipoDoc: number) {
    return this.getAtestadorByTipoDocModulo(idModulo, idTipoDoc) as Promise<{
      msg: string;
      data: IAtestadores[];
    }>;
  }

  async validarRemoverAtestador(queryParams: {
    cpf: number;
    idModulo: number;
    idTipoDocumento: number;
  }) {
    return this.putVerificarAtestadorPodeSerRemovido(queryParams);
  }

  async putVerificarAtestadorPodeSerRemovido(queryParams: {
    cpf: number;
    idModulo: number;
    idTipoDocumento: number;
  }) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const req: Subscription = this.http
        .put(
          `${this.getServer('atestador')}/remover`,
          queryParams,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            req.unsubscribe();
          },
        });
    });
  }

  async getConsultarAtestadorByIdPrivadoTipoDocModulo(idPrivado: number) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const req: Subscription = this.http
        .get(
          `${this.getServer(
            'atestador'
          )}/get-one?idVinculacaoTipoDocumentoModulo=${idPrivado}&withPessoa=true`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            req.unsubscribe();
          },
        });
    });
  }

  async getAtestadorByTipoDocModulo(idModulo: number, idTipoDoc: number) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const req: Subscription = this.http
        .get(
          `${this.getServer(
            'atestador'
          )}/get-atestadores-by-tipo-doc-mod?idModulo=${idModulo}&idTipoDocumento=${idTipoDoc}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            req.unsubscribe();
          },
        });
    });
  }

  async putAtestadoresEmlote(atestadoresEditados) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const req: Subscription = this.http
        .put(
          `${this.getServer('atestador')}/edit-in-lote`,
          atestadoresEditados,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            req.unsubscribe();
          },
        });
    });
  }

  async postAtestadorEmLote(novosAtestadores: {
    atestadores: Array<{
      idTipoDocumento: number;
      idModulo: number;
      idPessoa: number;
      dataInicioAtesto: Date;
    }>;
  }) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const req: Subscription = this.http
        .post(
          `${this.getServer('atestador')}/create-in-lote`,
          novosAtestadores,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            req.unsubscribe();
          },
        });
    });
  }
}
