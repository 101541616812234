<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="m-t-15 p-l-20 p-r-10" nzGutter="16">
            <div nz-col nzSpan="2" nzSm="2" nzXs="24">
              <nz-form-item>
                <nz-form-label>Código</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="codigoOrgao" placeholder="Cód. orgão">

                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="dot"><strong>.</strong></div>
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">

              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('idPublico')"
                  nzErrorTip="Código da unidade é obrigatório.">
                  <input nz-input formControlName="idPublico" placeholder="Código da unidade" [mask]="'9{3}'">

                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="13" nzSm="13" nzXs="24">

              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('nomeUnidade')"
                  nzErrorTip="Nome da unidade é obrigatório.">
                  <input nz-input formControlName="nomeUnidade" placeholder="Nome da unidade orçamentária">

                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="3" nzSm="3" nzXs="24">

              <nz-form-item>
                <nz-form-label nzRequired>Sigla</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('siglaUnidade')"
                  nzErrorTip="sigla é obrigatório.">
                  <input nz-input type="text" class="text-uppercase" [maxLength]="3" formControlName="siglaUnidade"
                    placeholder="Sigla da unidade orçamentária">

                </nz-form-control>
              </nz-form-item>
            </div>
            <!-- <div class="m-l-20 p-l-10" nz-col nzSpan="9" nzSm="9" nzXs="24">
              <nz-form-item>
                <nz-form-label>UnidadeOrcamentaria</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="example" placeholder="UnidadeOrcamentaria">
                </nz-form-control>
              </nz-form-item>
            </div> -->
          </div>
          <nz-card>
            <h4 class="m-t-15 m-l-20">Norma de criação</h4>
            <div nz-row class="p-l-20 p-r-20" [nzGutter]="22">
              <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Norma</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="normaCriacao" placeholder="Norma de criação">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>
                    Data
                  </nz-form-label>
                  <nz-date-picker fullWidth formControlName="dataNormaCriacao" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="10" nzSm="10" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Instrumento</nz-form-label>
                  <nz-form-control>
                    <nz-radio-group formControlName="instrumentoCriacao" [(ngModel)]="radioValue">
                      <label nz-radio [nzValue]="1">Lei</label>
                      <label nz-radio [nzValue]="2">Decreto</label>
                      <label nz-radio [nzValue]="3">Portaria</label>
                      <label nz-radio [nzValue]="4">Outros</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row class="p-l-20 p-r-20" [nzGutter]="22">
              <div nz-col nzSpan="24" nzSm="24" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Finalidade</nz-form-label>
                  <nz-form-control>
                    <textarea rows="4" nz-input formControlName="finalidadeOrgao"
                      placeholder="Finalidade do orgão"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-card>
          <h4 class="m-t-15 m-l-20">Orgão</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row nzGutter="22" class="p-l-20 p-r-20">

            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Código</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('idPublicoOrgao')"
                  nzErrorTip="Código do Orgão é obrigatório.">


                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input formControlName="idPublicoOrgao" (keydown)="showModalOrgao()" nz-input
                      placeholder="Código do Orgão" (click)="showModalOrgao()" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #suffixIconButton>
                  <button *ngIf="orgao" (click)="limparOrgao()" nz-button type="button" nzType="text" nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>
                  <button nz-button nzType="primary" (click)="showModalOrgao()" nzSearch><span nz-icon
                      nzType="search"></span></button>
                </ng-template>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="17" nzSm="17" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('nomeOrgao')"
                  nzErrorTip="Nome do orgão é obrigatório.">
                  <input nz-input formControlName="nomeOrgao" placeholder="Nome do orgão">

                </nz-form-control>
              </nz-form-item>
            </div>

          </div>

          <!-- to-do: verificar necessidade dos ordenadores nessa tela -->
          <!-- <div *ngIf="false"> -->
          <h4 class="m-t-15 m-l-20">Unidade gestora</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row nzGutter="22" class="p-l-20 p-r-20">

            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Código</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('idPublicoUnidadeGestora')"
                  nzErrorTip="Código da unidade gestora é obrigatório.">
                  <nz-input-group nzSearch [nzAddOnAfter]="showModalUgReference">
                    <input formControlName="idPublicoUnidadeGestora" (keydown)="showModalUg()" nz-input
                      placeholder="Código da Unidade gestora" (click)="showModalUg()" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #showModalUgReference>
                  <button *ngIf="unidadeGestora" (click)="limparUg()" nz-button type="button" nzType="text" nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>
                  <button nz-button nzType="primary" (click)="showModalUg()" nzSearch><span nz-icon
                      nzType="search"></span></button>
                </ng-template>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="17" nzSm="17" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomUnidadeGestora" placeholder="Nome da Unidade Gestora">

                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5" nzSpan="8" nzSm="8" nzXs="24">
              <h4 class="m-b-0 p-l-20">Ordenadores de despesa</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="15" nzSpan="1" nzSm="1" nzXs="24">
              <button nz-button [disabled]="!verificaOrdenadorVazio(form.value.ordenadoresDespesa)" nzType="primary"
                (click)="addOrdenador()">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div formArrayName="ordenadoresDespesa">
            <div nz-row *ngFor="let ordenador of form.get('ordenadoresDespesa')['controls']; index as i"
              [formGroupName]="i">
              <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                <div>
                  <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
                    <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>CPF</nz-form-label>
                        <nz-form-control>
                          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                            <input formControlName="cpf" [mask]="'000.000.000-00'" (keydown)="showModalCpfOrdenador(i)"
                              nz-input placeholder="CPF" (click)="showModalCpfOrdenador(i)" />
                          </nz-input-group>
                        </nz-form-control>
                        <ng-template #suffixIconButton>
                          <button *ngIf="ordenador.value.pessoaFisica" (click)="limparCpfOrdenador(i)" nz-button
                            type="button" nzType="text" nzSearch>
                            <i nz-icon nzType="close-circle"></i>
                          </button>
                          <button nz-button nzType="primary" (click)="showModalCpfOrdenador(i)" nzSearch><span nz-icon
                              nzType="search"></span></button>
                        </ng-template>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="15" nzSm="15" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>Nome</nz-form-label>
                        <nz-form-control>
                          <input nz-input placeholder="Nome do ordenador" formControlName="nome">
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
                    <!-- 6-8 -->
                    <div nz-col nzSpan="10" nzSm="10" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>Cargo</nz-form-label>
                        <nz-form-control>
                          <input nz-input placeholder="Cargo do ordenador" formControlName="cargo">
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>Data da nomeação</nz-form-label>
                        <nz-form-control>
                          <nz-date-picker fullWidth formControlName="dataNomeacao" nzFormat="dd/MM/yyyy">
                          </nz-date-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                      <nz-form-label nzRequired>
                        Situação
                      </nz-form-label>
                      <nz-form-control>
                        <nz-select fullWidth nzPlaceHolder="Situação" formControlName="situacao">
                          <nz-option nzLabel="Empossado" [nzValue]=0></nz-option>
                          <nz-option nzLabel="Reempossado" [nzValue]=1></nz-option>
                          <nz-option nzLabel="Afastado" [nzValue]=3></nz-option>
                        </nz-select>
                      </nz-form-control>
                    </div>

                  </div>
                </div>
              </fieldset>
              <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                <button *ngIf="form.value.ordenadoresDespesa.length > 1 && !ordenador.id" class="m-t-20" nz-button
                  nzDanger [disabled]="false" nzType="primary" (click)="removeOrdenador(i)">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- </div> e -->
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldUnidadesOrcamentariasSelected"
                              (ngModelChange)="getFilterUnidadeOrcamentariaOptionsSelect(fieldUnidadesOrcamentariasSelected)">
                              <nz-option *ngFor="let interval of arrColumnsUnidadesOrcamentariasSelect"
                                [nzValue]="interval" [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group
                              *ngIf="fieldUnidadesOrcamentariasSelected && fieldUnidadesOrcamentariasSelected.type === 'input'">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>
                          <div
                            *ngIf="fieldUnidadesOrcamentariasSelected && tabIndex == 1 && fieldUnidadesOrcamentariasSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldUnidadesOrcamentariasSelected" nzSpan="2" nzXl="2" nzXs="12"
                            class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsUnidadesOrcamentarias" class="p-0" nz-col nzSpan="12" nzXl="12"
                            nzLg="12" nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroUnidadeOrcamentariaPorField(fieldsValuesTag)
                            " class="filters"
                                  *ngFor="let fieldsValuesTag of fieldsUnidadesOrcamentariasSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'" [nzOffset]="showComboTagsUnidadesOrcamentarias?8:20"
                            nzSpan="3" nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                              (click)="filterUnidadeOrcamentaria()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #UnidadeOrcamentariaListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableUnidadeOrcamentariaColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="UnidadeOrcamentariaListTable.data" let-index="index">
            <tr (dblclick)="doubleClick(item)" style="cursor: pointer" nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableUnidadeOrcamentariaColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>