import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, Subscription } from 'rxjs';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('usuario', http, notification, authService);
  }

  async consultaPessoaFisica(cpf) {
    return cpf
      ? await this.consultarPorParametro(
          { field: 'cpf', value: cpf },
          'pessoa-fisica'
        )
      : null;
  }

  async getCidadesTableProps(): Promise<any> {
    return {};
  }

  async consultaUg(nomUg) {
    return nomUg
      ? this.consultarPorParametro(
          { field: 'razaoSocialPessoaJuridica', value: nomUg },
          'unidade-gestora'
        )
      : null;
  }

  async getVinculacoes(vinc, entidade, titulo): Promise<any> {
    return await this.http
      .get(
        `${this.getServer(
          entidade
        )}/consultar-por-parametro?field=id&value=${vinc}`,

        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result) => {
        return result;
      })
      .catch((err) => {
        this.notificarErro(err, titulo);
      });
  }

  async consultaPerfil(idUg, page, pageSize) {
    if (!idUg) {
      return { error: false, unidadeGestora: null };
    }
    return this.pesquisarAvancado(
      {
        page: page,
        size: pageSize,
        field: { valor: 'idUnidadeGestora' },
        value: idUg,
        selectUser: true,
      },
      'perfil'
    ).then((result) => {
      return result.data.resultado;
    });
  }

  encryptPassword(password: string): Promise<string> {
    return this.encryptData(password);
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }

  decryptPassword(encryptedPassword): Promise<string> {
    return this.decryptData(encryptedPassword) as Promise<string>;
  }

  public async encryptData(data: any): Promise<string> {
    return await this.postEncryptData(data)
      .then((resp) => resp)
      .catch((e) => {
        this.notification.error('Criptografia', 'Erro ao criptografar dado!');
        return 'Erro ao criptografar senha!';
      });
  }

  private postEncryptData(data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const sub: Subscription = this.postEncryptData$(data).subscribe({
        next: ({ data }: { data: string }) => {
          resolve(data);
        },
        error: (e) => {
          reject(e);
        },
        complete() {
          sub.unsubscribe();
        },
      });
    });
  }
  private postEncryptData$(data: any): Observable<{ data: string }> {
    return this.http.post<{ data: string }>(
      `${this.getServer('auth')}/encrypt`,
      { data }
    );
  }

  public async decryptData(data: any): Promise<unknown> {
    return await this.postDecryptData(data)
      .then((resp) => resp)
      .catch((e) => {
        this.notification.error(
          'Criptografia',
          'Erro ao tentar descriptografar dado!'
        );
      });
  }

  private postDecryptData(data: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const sub: Subscription = this.postDecryptData$(data).subscribe({
        next: ({ data }: { data: string }) => {
          resolve(data);
        },
        error: (e) => {
          reject(e);
        },
        complete() {
          sub.unsubscribe();
        },
      });
    });
  }
  private postDecryptData$(data: any): Observable<{ data: string }> {
    return this.http.post<{ data: string }>(
      `${this.getServer('auth')}/decrypt`,
      { data }
    );
  }
}
