import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { PsDirectiveModule } from '../../directives';
import { PsDynamicFormModule } from '../ps-dynamic-form';
import { PsGridModule } from '../ps-grid';
import { PsFilterComponent } from './ps-filter.component';

@NgModule({
  imports: [
    CommonModule,
    NzCardModule,
    NzFormModule,
    NzTagModule,
    NzIconModule,
    NzButtonModule,
    NzCollapseModule,
    NzGridModule,
    PsDynamicFormModule,
    FormsModule,
    ReactiveFormsModule,
    PsDirectiveModule,
    PsGridModule.forRoot({ gridType: 'ag-grid' }),
  ],
  declarations: [PsFilterComponent],
  exports: [PsFilterComponent],
  providers: [],
})
export class PsFilterModule {}
