export interface PasswordValidation {
  erro: boolean;
  mensagem: string;
}
import * as CryptoJS from 'crypto-js';
export abstract class Helper {
  public static encryptData(data, encryptSecretKey: string): string {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        encryptSecretKey
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  public static decryptData(data: string, encryptSecretKey: string): Object {
    try {
      const bytes = CryptoJS.AES.decrypt(data, encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  public static addMask(valor, tipo: string) {
    if (tipo === 'cpf') {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }

    if (tipo === 'cnpj') {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    if (tipo === 'date') {
      valor = new Date(valor);
      const pad = (num) => num.toString().padStart(2, '0');
      const dia = pad(valor.getDate());
      const mes = pad(valor.getMonth() + 1); // Janeiro é 0!
      const ano = valor.getFullYear();
      const horas = pad(valor.getHours());
      const minutos = pad(valor.getMinutes());
      return `${dia}-${mes}-${ano} ${horas}:${minutos}`;
      //TODO: implementar typeof string e instanceof Date
      // return valor.replace(
      //   /(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})/g,
      //   '$1-$2-$3 $4:$5'
      // );
    }
  }

  public static mascaraCpfCnpj(valor: string): string {
    if (valor && valor.length > 11) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  public static cpfCnpjMascarado(valor: string) {
    if (valor && valor.length > 11) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    }

    return valor?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '***.$2.$3-**');
  }

  public static validarCampo(campo: string, tipoValidacao: string): boolean {
    if (!tipoValidacao) {
      return;
    }

    if (tipoValidacao === 'cpf') {
      return this.validaCPF(campo);
    }

    if (tipoValidacao === 'cnpj') {
      return this.validaCNPJ(campo);
    }

    if (tipoValidacao === 'email') {
      return this.validaEmail(campo);
    }
  }

  public static validaCNPJ(cnpj) {
    const cnpjNaoValidos = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ];

    if (cnpj.length === 14) {
      if (cnpjNaoValidos.includes(cnpj)) {
        return false;
      }

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== digitos.charAt(0)) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      return !(resultado !== digitos.charAt(1));
    }
  }

  public static validaCPF(inputCPF) {
    const cpfNaoValidos = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];

    let soma = 0;
    let resto;

    if (inputCPF.length === 11) {
      if (cpfNaoValidos.includes(inputCPF)) {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        soma = soma + Number(inputCPF.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== Number(inputCPF.substring(9, 10))) {
        return false;
      }

      soma = 0;
      for (let i = 1; i <= 10; i++) {
        soma = soma + Number(inputCPF.substring(i - 1, i)) * (12 - i);
      }

      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== Number(inputCPF.substring(10, 11))) {
        return false;
      }
    }

    return true;
  }

  public static validaEmail(email: string): boolean {
    return /[\w\_\-\.]+@[\w\.]+\.\w+/.test(email);
  }

  public static validaSenha(senha: string): PasswordValidation {
    if (senha.length < 8) {
      return {
        erro: true,
        mensagem: 'Senha não pode ser menor que 8 caracteres',
      };
    }

    if (
      /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/.test(
        senha
      )
    ) {
      return {
        erro: true,
        mensagem: 'Evite senhas que sejam sequências',
      };
    }

    if (/[?=.*[A-Z]]/.test(senha)) {
      return {
        erro: false,
        mensagem: 'Senha deve ter pelo menos uma letra maiúscula',
      };
    }

    if (/[?=.*[0-9]]/.test(senha)) {
      return { erro: false, mensagem: 'Senha deve ter pelo menos um número' };
    }

    if (/[?=.*[!@#$%^&*]]/.test(senha)) {
      return {
        erro: false,
        mensagem: 'Senha deve ter pelo menos um caracter especial',
      };
    }

    if (/[\b(\d+)\1+\b]/.test(senha)) {
      return {
        erro: true,
        mensagem: 'Evita repetir números em sequência',
      };
    }

    return { erro: false, mensagem: 'Senha válida' };
  }
}
