import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { StatusDocumento } from '../enum/const.enum';
import {
  AbstractError,
  AbstractResponse,
  IColumnsFieldsProps,
  IResponseFieldsAnexoProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from '../interfaces/shared.interface';
import { IDocumento } from './../interfaces/document-interfaces';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsUploadService extends AbstractService {
  @Output() assinarAnexo = new EventEmitter<boolean>();
  @Output() ratificacao = new EventEmitter<boolean>();
  @Output() ratificacaoAnexo = new EventEmitter<boolean>();
  @Output() formLoading = new EventEmitter<boolean>();
  @Output() labelTip = new EventEmitter<string>();

  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super('document', abstractHttp, abstractAuthService, abstractNotification);
  }

  async insertDocumento(
    form,
    assinar: boolean,
    assinanteLogado,
    assinarAnexo,
    adicionarAtestadores: boolean = false
  ) {
    form = this.formatDocumentToInsertOrUpdate(
      form,
      assinanteLogado,
      assinar,
      assinarAnexo
    );

    return await this.insertDocument(
      form,
      assinar,
      assinarAnexo,
      adicionarAtestadores
    );
  }

  async updateDocument(
    form,
    assinar,
    assinanteLogado,
    assinarAnexo,
    adicionarAtestadores = false
  ) {
    form = this.formatDocumentToInsertOrUpdate(
      form,
      assinanteLogado,
      assinar,
      assinarAnexo
    );

    const formData = new FormData();
    form.documentoAnexo.filter((file) => {
      file.files?.forEach((key) => {
        formData.append('docsAnexados', key);
      });
    });

    const newAnexo = form.documentoAnexo.map((key) => {
      return { ...key, anexoSignatario: form.signatariosAnexo };
    });

    formData.append(
      'documentos',
      JSON.stringify({
        documentoAnexo: newAnexo,
        tipoDocumento: {
          idPrivado: form.tipoDocumento.idPrivado,
          idPublico: form.tipoDocumento.idPublico,
          tipo: form.tipoDocumento.tipo,
          descricao: form.tipoDocumento.descricao,
          idUnidadeGestora: form.tipoDocumento.idUnidadeGestora,
        },
        idSetor: form.idSetor,
        idDocVinculado: form.idDocVinculado,
        idTipoDocumento: form.tipoDocumento.idPrivado,
        numeroDoc: form.numeroDoc,
        idUnidadeGestora: form.idUnidadeGestora,
        dataLimiteAssinatura: form.dataLimiteAssinatura,
        historicoDoc: form.historicoDoc,
        signatarios: form.signatarios,
        statusDocumento: form.statusDocumento,
        chancela: form.chancela,
        psHash: form.psHash,
        idPublico: form.idPublico,
        idPrivado: form.idPrivado,
        numeroLicitacao: form.numeroLicitacao,
        cpfPessoaFavorecido: form.cpfPessoaFavorecido,
        idPessoaFavorecido: form.idPessoaFavorecido,
        modalidadeLicitacao: form.modalidadeLicitacao,
        numeroObra: form.numeroObra,
        numeroContrato: form.numeroContrato,
        numeroConvenio: form.numeroConvenio,
        numeroProgramaInstitucional: form.numeroProgramaInstitucional,
        valorDoc: form.valorDoc,
        informacoesAdicionais: form.informacoesAdicionais,
        nomeFavorecido: form.nomeFavorecido,
        numeroEvento: form.numeroEvento,
      })
    );

    return await this.update(
      formData,
      form.idPrivado,
      'anyDocument',
      `?adicionarAtestadores=${adicionarAtestadores}`
    );
  }

  ratificarEvent(msg: any) {
    this.ratificacao.emit(msg);
  }

  ratificarAnexoEvent(msg: any) {
    this.ratificacaoAnexo.emit(msg);
  }

  anexoEvent(msg: any) {
    this.assinarAnexo.emit(msg);
  }

  loadingEvent(load: any) {
    this.formLoading.emit(load);
  }

  sendLabelTip(msg: string) {
    this.labelTip.emit(msg);
  }

  formatDocumentToInsertOrUpdate(
    form,
    assinanteLogado,
    assinar,
    assinarAnexo?
  ) {
    const signedDate = this.authService.getDateSelected();
    let signatarios;
    let documentoAnexo;
    let signatariosAnexo;

    if (!form.idPrivado) {
      signatarios = form.signatarios.map((ass) => {
        return {
          idPrivado: form.idPrivado,
          idDocumento: form.idDocumento,
          idPessoa: form.idPessoa,
          idAssinante: form.idAssinante,
          idSetor: form.idSetor,
          dataAssinatura:
            assinanteLogado &&
            assinanteLogado.cpf === ass.cpf &&
            assinar === true
              ? signedDate
              : null,
          status: StatusDocumento.Pendente,
          signatario:
            ass.cnpj && !ass.cpf
              ? ass.signatario.replace(/[^0-9]+/g, '')
              : null,
          cargo: ass.cargo,
          cnpj: ass.cnpj,
          cpf: ass.cpf,
          email: ass.email,
          idPessoaFisica: ass.idPessoaFisica,
          idPessoaJuridica: ass.idPessoaJuridica,
          idResponsavel: ass.idResponsavel,
          matricula: ass.matricula,
          nomCidade: ass.nomCidade,
          nome: ass.nome,
          razaoSocial: ass.razaoSocial,
        };
      });

      signatariosAnexo = form.signatariosAnexo.map((ass) => {
        return {
          idPrivado: form.idPrivado,
          idDocumentoAnexo: form.idDocumentoAnexo,
          idPessoa: form.idPessoa,
          idAssinante: form.idAssinante,
          dataAssinatura:
            assinanteLogado &&
            assinanteLogado.cpf === ass.cpfAnexo &&
            assinar === true
              ? signedDate
              : null,
          status: StatusDocumento.Pendente,
          signatario:
            ass.cnpjAnexo && !ass.cpfAnexo
              ? ass.signatario.replace(/[^0-9]+/g, '')
              : null,
          cargo: ass.cargoAnexo,
          cnpj: ass.cnpjAnexo,
          cpf: ass.cpfAnexo,
          email: ass.emailAnexo,
          idPessoaFisica: ass.idPessoaFisica,
          idPessoaJuridica: ass.idPessoaJuridica,
          idResponsavel: ass.idResponsavel,
          matricula: ass.matriculaAnexo,
          nomCidade: ass.nomCidadeAnexo,
          nome: ass.nomeAnexo,
          razaoSocial: ass.razaoSocialAnexo,
        };
      });

      documentoAnexo = form.anexos.map((anx) => {
        return {
          idPrivado: form.idPrivado,
          idDocumento: form.idDocumento,
          dataAssinatura:
            assinanteLogado &&
            assinanteLogado.cpf === anx.cpf &&
            assinarAnexo === true
              ? signedDate
              : null,
          status: StatusDocumento.Pendente,
          idSignatario: anx.idSignatario,
          idTipoDocumentoAnexo: anx.idTipoDocumentoAnexo,
          nome: anx.nome,
          tipoDocumentoAnexo: anx.tipoDocumentoAnexo,
          tituloManifesto: anx.tituloManifesto,
          vinculacao: anx.vinculacao,
          descricao: anx.descricao,
          files: anx.files,
        };
      });
    }
    return Object.assign(
      {
        documentoAnexo: form.idPrivado ? form.anexos : documentoAnexo,
        documentB64: form.documentB64,
        actionType: 'Assinar',
        numeroDoc: form.numeroDoc,
        tipoDocumento: {
          idPrivado: form.entidadeTipoDoc?.idPrivado,
          idPublico: form.entidadeTipoDoc.idPublico,
          tipo: form.entidadeTipoDoc.tipo,
          descricao: form.entidadeTipoDoc.descricao,
          idUnidadeGestora: form.entidadeTipoDoc.idUnidadeGestora,
          tituloManifesto: form?.entidadeTipoDoc?.tituloManifesto,
        },
        idModulo: form.idModulo,
        idDocVinculado: form.idDocVinculado,
        idTipoDocumento: form.entidadeTipoDoc.idPrivado,
        idUnidadeGestora: form.idUnidadeGestora,
        usCriacao: form.usCriacao,
        dataDocumento: new Date(this.authService.getDateSelected()),
        dataLimiteAssinatura: form.dataLimiteAssinatura,
        aplicacaoOrigem: 3,
        statusDocumento: StatusDocumento.Pendente,
        historicoDoc: form.historicoDoc,
        numProcesso: form.numProcesso,
        chancela: form.chancela,
        avulso: true,
        signatarios: form.idPrivado ? form.signatarios : signatarios,
        signatariosAnexo: form.idPrivado
          ? form.signatariosAnexo
          : signatariosAnexo,
        dtCriacao: form.dtCriacao,
        psHash: form.psHash,
        idPublico: form.idPublico,
        idPrivado: form.idPrivado,
        idSetor: form.idSetor,
        numeroLicitacao: form.numeroLicitacao,
        cpfPessoaFavorecido: form.cpfPessoaFavorecido,
        idPessoaFavorecido: form.idPessoaFavorecido,
        modalidadeLicitacao: form.modalidadeLicitacao,
        numeroObra: form.numeroObra,
        numeroContrato: form.numeroContrato,
        numeroConvenio: form.numeroConvenio,
        numeroProgramaInstitucional: form.numeroProgramaInstitucional,
        valorDoc: form.valorDoc,
        informacoesAdicionais: form.informacoesAdicionais,
        nomeFavorecido: form.nomeFavorecido,
        numeroEvento: form.numeroEvento,
      } as IDocumento,
      { certByDocumentForSign: form.certByDocumentForSign }
    );
  }

  // formatDocumentToUpdate(form, assinanteLogado, assinar) {
  //   const responsavel = form.pessoaResponsaveis?.map((resp) => {
  //     return {
  //       id: resp.id,
  //       cpfResponsavel: resp.cpf,
  //       nomeResponsavel: resp.nome,
  //       emailResponsavel: resp.email,
  //     };
  //   });
  //   const signedDate = this.authService.getDateSelected();

  //   const assinantes = form.signatarios.map((ass) => {
  //     return {
  //       dadosPessoaFisica: { cpf: ass.cpf },
  //       dadosPessoaJuridica: {
  //         cnpj: ass.cnpj,
  //         razaoSocial: ass.razaoSocial,
  //         signatario:
  //           ass.cnpj && !ass.cpf
  //             ? ass.signatario.replace(/[^0-9]+/g, '')
  //             : null,
  //         responsaveis: responsavel,
  //       },
  //       dataAssinatura:
  //         assinanteLogado.cpf === ass.cpf && assinar === true
  //           ? signedDate
  //           : null,
  //       status: StatusDocumento.Pendente,
  //       cidade: ass.nomCidade,
  //       ...ass,
  //     } as IAssinante;
  //   });
  //   return {

  //     anexos: form.anexos,

  //   } as Partial<IDocumento>;
  // }

  async insertDocument(
    form,
    assinar,
    assinarAnexo,
    adicionarAtestadores: boolean = false
  ): Promise<AbstractResponse> {
    form.signatarios.push();
    form.idModulo;
    form.idTipoDocumento;

    const formData = new FormData();
    formData.append('documentB64', form.documentB64);
    form.documentoAnexo.filter((file) => {
      file.files.forEach((key) => {
        formData.append('docsAnexados', key);
      });
    });

    formData.append(
      'documentos',
      JSON.stringify({
        documentoAnexo: form.documentoAnexo,
        tipoDocumentoAnexo: form.idTipoDocumentoAnexo,
        statusDocumento: form.statusDocumento,
        idDocVinculado: form.idDocVinculado,
        idSetor: form.idSetor,
        avulso: form.avulso,
        dtCriacao: form.dtCriacao,
        actionType: form.actionType,
        aplicacaoOrigem: form.aplicacaoOrigem,
        certByDocumentForSign: form.certByDocumentForSign,
        dataDocumento: form.dataDocumento,
        dataLimiteAssinatura: form.dataLimiteAssinatura,
        historicoDoc: form.historicoDoc,
        numeroLicitacao: form.numeroLicitacao,
        cpfPessoaFavorecido: form.cpfPessoaFavorecido,
        idPessoaFavorecido: form.idPessoaFavorecido,
        modalidadeLicitacao: form.modalidadeLicitacao,
        numeroObra: form.numeroObra,
        numeroContrato: form.numeroContrato,
        numeroConvenio: form.numeroConvenio,
        numeroProgramaInstitucional: form.numeroProgramaInstitucional,
        valorDoc: form.valorDoc,
        informacoesAdicionais: form.informacoesAdicionais,
        nomeFavorecido: form.nomeFavorecido,
        numeroEvento: form.numeroEvento,
        idUnidadeGestora: form.idUnidadeGestora,
        numProcesso: form.numProcesso,
        chancela: form.chancela,
        numeroDoc: form.numeroDoc,
        signatarios: form.signatarios,
        anexoSignatario: form.signatariosAnexo,
        tipoDocumento: form.tipoDocumento,
        idTipoDocumento: form.idTipoDocumento,
        usCriacao: form.usCriacao,
        idModulo: form.idModulo,
      })
    );

    return new Promise(async (resolve, reject) => {
      await this.http
        .post(
          `${this.getServer(
            'document'
          )}?assinar=${assinar}&assinarAnexo=${assinarAnexo}&adicionarAtestadores=${adicionarAtestadores}`,
          formData,
          this.headerAuthenticateOptions()
        )

        .toPromise()
        .then((response: any) => {
          this.notification.success('Cadastrar', response.message);

          if (assinar) {
            this.notification.success(
              'Assinado',
              'Documento assinado com sucesso!'
            );
          }

          resolve(response as any);
        })
        .catch((err) => {
          this.notification.error('Cadastrar', err.message);
          // tslint:disable-next-line: no-bitwise
          reject({
            message: err,
            data: undefined,
          } as AbstractResponse);
        });
    });
  }

  async getPessoasFisicaByCpf(cpf: string) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaByCpf')}${cpf}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getPessoasFisicasById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getPessoaFisicaByPessoaId(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getPessoaFisicaByPessoaIdNoAsync(id: number) {
    return this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .subscribe(
        async (result: { message: string; data: any }) => {
          return result.data;
        },
        (err) => {
          return this.notification.error('Pessoa Fisica', err.message);
        }
      );
  }

  async getPessoaFisicaByCpf(cpf) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaByCpf')}${cpf}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async deleteById(idPrivado, server: string) {
    return await this.http
      .delete(
        `${this.getServer(server)}/${idPrivado}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return result.message;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getDocumentTableProps(
    entidade: string = null
  ): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }

  async getDocumentTableAnexoProps(
    entidade: string = null
  ): Promise<IResponseFieldsAnexoProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      });
  }
}
