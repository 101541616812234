<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="p-l-20 p-r-20" [nzGutter]="18">
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Código</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('idPublico')" nzErrorTip="Código é obrigatório.">
                  <input nz-input formControlName="idPublico" placeholder="Código" [mask]="'9{2}'">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('nomeOrgao')" nzErrorTip="Nome é obrigatório.">
                  <input nz-input formControlName="nomeOrgao" placeholder="Nome do Orgão">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>UF</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidateStatus('poder')" nzErrorTip="Poder é obrigatória.">
                  <nz-select fullWidth nzShowSearch formControlName="poder" nzPlaceHolder="Poder">
                    <nz-option [nzValue]="0" [nzLabel]="'Executivo'">
                    </nz-option>
                    <nz-option [nzValue]="1" [nzLabel]="'Legislativo'">
                    </nz-option>
                    <nz-option [nzValue]="2" [nzLabel]="'Judiciário'">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <nz-card>
            <h4 class="m-t-15 m-l-20">Norma de criação</h4>
            <div nz-row class="p-l-20 p-r-20" [nzGutter]="22">
              <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Norma</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="normaCriacao" placeholder="Norma de criação">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>
                    Data
                  </nz-form-label>
                  <nz-date-picker fullWidth formControlName="dataNormaCriacao" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="10" nzSm="10" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Instrumento</nz-form-label>
                  <nz-form-control>
                    <nz-radio-group formControlName="instrumentoCriacao" [(ngModel)]="radioValue">
                      <label nz-radio [nzValue]="1">Lei</label>
                      <label nz-radio [nzValue]="2">Decreto</label>
                      <label nz-radio [nzValue]="3">Portaria</label>
                      <label nz-radio [nzValue]="4">Outros</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row class="p-l-20 p-r-20" [nzGutter]="22">
              <div nz-col nzSpan="24" nzSm="24" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Finalidade</nz-form-label>
                  <nz-form-control>
                    <textarea rows="4" nz-input formControlName="finalidadeOrgao"
                      placeholder="Finalidade do orgão"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-card>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldOrgaosSelected"
                              (ngModelChange)="getFilterOrgaoOptionsSelect(fieldOrgaosSelected)">
                              <nz-option *ngFor="let interval of arrColumnsOrgaosSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group *ngIf="fieldOrgaosSelected && fieldOrgaosSelected.type === 'input'">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>
                          <div
                            *ngIf="fieldOrgaosSelected && tabIndex == 1 && fieldOrgaosSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldOrgaosSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsOrgaos" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroOrgaoPorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsOrgaosSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="fieldsOrgaosSelected.length !=0?openModal?7:8:openModal?19:20" nzSpan="3"
                            nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterOrgao()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #OrgaoListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableOrgaoColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="OrgaoListTable.data" let-index="index">
            <tr (click)="clickItem(item)" (dblclick)="dbClickOrgao(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableOrgaoColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>