import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class PsValidationFormService {
  constructor() {}

  validateFormArray(control) {
    for (const i of control.controls) {
      this.validateAllFormFields(control.controls[i] as FormGroup);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormArray) {
        return this.validateFormArray(control);
      }
      if (control instanceof FormGroup) {
        return this.validateAllFormFields(control);
      }

      control.markAsTouched({ onlySelf: true });
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }
}
