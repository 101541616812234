export * from './lib/services/atestador.service';
export * from './lib/types/document.types';

export * from './lib/consts/document';
export * from './lib/consts/mensagem';
export * from './lib/consts/modulos';
export * from './lib/consts/tipos-licitacao.enum';
export * from './lib/consts/urls-consts';
export * from './lib/enum/const.enum';
export * from './lib/enum/events.enum';
export * from './lib/helper';
export * from './lib/interfaces/assinantes-interface';
export * from './lib/interfaces/auth-interfaces';
export * from './lib/interfaces/concentrador';
export * from './lib/interfaces/dashboard';
export * from './lib/interfaces/divida-lancamento-interface';
export * from './lib/interfaces/document-interfaces';
export * from './lib/interfaces/exportacao-interface';
export * from './lib/interfaces/mensagem-interface';
export * from './lib/interfaces/menu-items.interface';
export * from './lib/interfaces/module.interface';
export * from './lib/interfaces/parcelamento-divida-interface';
export * from './lib/interfaces/pessoa-fisica';
export * from './lib/interfaces/shared.interface';
export * from './lib/interfaces/tipo-documento';
export * from './lib/mocks/dashboard';
export * from './lib/mocks/document-finished';
// export * from './lib/mocks/documents';
export * from './lib/services/acordo-divida-imobiliaria.service';
export * from './lib/services/acordo-divida-mercantil.service';
export * from './lib/services/api/auth.service';
export * from './lib/services/api/core/sistema.service';
export * from './lib/services/api/http-error.interceptor';
export * from './lib/services/api/jwt.interceptor';
export * from './lib/services/assinantes.service';
export * from './lib/services/dashboard.service';
export * from './lib/services/detail-document.service';
export * from './lib/services/document.service';
export * from './lib/services/documents-pending.service';
export * from './lib/services/documents-upload.service';
export * from './lib/services/exportacao-arquivos.service';
export * from './lib/services/mensagens-envio.service';
export * from './lib/services/mensagens.service';
export * from './lib/services/menu.service';
export * from './lib/services/modal-termo-aceite.service';
export * from './lib/services/modulo.service';
export * from './lib/services/pessoa-fisica.service';
export * from './lib/services/pessoa-juridica.service';
export * from './lib/services/processo.service';
export * from './lib/services/ps-tab-session.service';
export * from './lib/services/script.service';
export * from './lib/services/setor.service';
export * from './lib/services/solicitacao-alvara.service';
export * from './lib/services/solicitacao-itbi.service';
export * from './lib/services/storage.service';
export * from './lib/services/submodulo.service';
export * from './lib/services/table.service';
export * from './lib/services/theme.service';
export * from './lib/services/tipo-documento.service';
export * from './lib/shared.module';
