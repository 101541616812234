import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ILancamentosContabeisNovoInterface } from '../interfaces/lancamentos-contabeis-interface';
import { ServiceResponse } from '../utils';
import { AuthService } from './api/auth.service';

@Injectable({ providedIn: 'root' })
export class LancamentosContabeisService {
  private helper = new JwtHelperService();
  unidadeGestoraToken: any;
  constructor(private http: HttpClient, private authService: AuthService) {}

  async setUnidadeGestoraToken(): Promise<void> {
    this.unidadeGestoraToken = await this.helper.decodeToken(
      this.authService.getToken()
    );

    return this.unidadeGestoraToken;
  }

  getTokenUserInfo(): any {
    this.setUnidadeGestoraToken();
    return this.unidadeGestoraToken;
  }

  dateCalenderAtual() {
    return this.authService.externalizeDate();
  }

  async getLancamentosDetalhe(
    params?
  ): Promise<ILancamentosContabeisNovoInterface[]> {
    params = new URLSearchParams(params).toString();
    const response = (await this.http
      .get(
        `http://localhost:3333/siafic/v1/lancamento-contabil/modal-detalhe?${params}`,
        {
          headers: new HttpHeaders({
            'x-authorization': '29d658cd-6be1-4439-b8f5-0733b788de6e',
          }),
        }
      )
      .toPromise()) as ServiceResponse;
    return response.data as ILancamentosContabeisNovoInterface[];
  }

  async getLancamentos(params?): Promise<ILancamentosContabeisNovoInterface[]> {
    params = new URLSearchParams(params).toString();
    const response = (await this.http
      .get(
        `http://localhost:3333/siafic/v1/lancamento-contabil/listar?${params}`,
        {
          headers: new HttpHeaders({
            'x-authorization': 'ea69ef51-b1e6-4df2-a181-3d5300a90548',
          }),
        }
      )
      .toPromise()) as ServiceResponse;
    return response.data as ILancamentosContabeisNovoInterface[];
  }

  async getLancamentosDashboard(params?): Promise<any> {
    params = new URLSearchParams(params).toString();
  }

  async getLancamentosCards(params?): Promise<any> {
    params = new URLSearchParams(params).toString();
    const response = (await this.http
      .get(
        `http://localhost:3333/siafic/v1/lancamento-contabil/cards?${params}`,
        {
          headers: new HttpHeaders({
            'x-authorization': 'ea69ef51-b1e6-4df2-a181-3d5300a90548',
          }),
        }
      )
      .toPromise()) as ServiceResponse;
    return response.data;
  }

  async getLancamentoChart(params?): Promise<any> {
    params = new URLSearchParams(params).toString();

    const response = (await this.http
      .get(
        `http://localhost:3333/siafic/v1/lancamento-contabil/chart?${params}`,
        {
          headers: new HttpHeaders({
            'x-authorization': 'ea69ef51-b1e6-4df2-a181-3d5300a90548',
          }),
        }
      )
      .toPromise()) as ServiceResponse;

    return response.data;
  }
}
