import { Component } from '@angular/core';
import { ProcessoService } from "@ps-erp-angular/shared";


@Component({
  selector: 'ps-siafic-processo',
  templateUrl: './processo.component.html',
  styleUrls: ['./processo.component.scss'],
})
export class ProcessoComponent {

  getDateNow: any = new Date().toLocaleString();

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  constructor(private processoService: ProcessoService) {

  }

  checked = false
  indeterminate = false;

  listProcessos: any
  fieldEnvioSelected;
  fieldsMensagensEnvioSelected = [];
  setOfCheckedId = new Set<number>();


  getWindow() {
    return window.innerWidth;
  }

  async getProcessos() {
    // (await this.processoService.getProcessos()).subscribe((processoApi) => {
    //   this.listProcessos = processoApi['data']
    // })
  }

}
