const enum ModulosPublicSoft {
  'Painel Adiministrativo' = 1,
  'Doacoes Covid' = 2,
  'Assinatura Digital' = 3,
  'Folha Pagamento' = 4,
  'Arrecadação Tributária' = 5,
  'Contabilidade' = 6,
  'Planejamento' = 7,
  'Recadastramento Imobiliario' = 8,
}

const CodigoModulos = {
  1: 'Painel Adiministrativo',
  2: 'Doacoes Covid',
  3: 'Assinatura Digital',
  4: 'Folha Pagamento',
  5: 'Arrecadação Tributária',
  6: 'Contabilidade',
  7: 'Planejamento',
  8: 'Recadastramento Imobiliario',
};

export { ModulosPublicSoft, CodigoModulos };
