<!-- <pre>{{ model | json }}</pre> -->
<!-- <pre>{{ fields[0] | json }}</pre> -->
<!-- <pre>{{ (filtersAppliedObs | async) | json }}</pre> -->
<!-- {{ (filtersAppliedObs | async).length }} - {{ isBoolean((filtersAppliedObs | async).length) }} -->

<nz-card [nzTitle]="'Filtros'">
  <form
    nz-form
    accessible-form
    [form]="form"
    [nzLayout]="'vertical'"
    (submit)="submit()"
    autocomplete="off"
  >
    <ps-form
      [form]="form"
      [fields]="fields"
      [options]="options"
      [model]="model"
    ></ps-form>

    <nz-collapse nzGhost>
      <nz-collapse-panel nzHeader="Filtro Avançado">
        <div nz-row  >
          <div nz-col nzSpan="11">
            <form
              nz-form
              accessible-form
              [form]="form"
              [nzLayout]="'vertical'"
              (submit)="submit()"
              autocomplete="off"
            >
              <ps-form
                [form]="formAvancado"
                [fields]="fieldsAvancado"
                [options]="options"
                [model]="model"
              ></ps-form>

              <!-- <nz-button-group> -->
                <button class="m-r-5" nz-button [nzType]="operador === 'E' ? 'primary' : 'default'" nzShape="round" nzSize="small" (click)="setOperator('E')">E</button>
                <button class="m-r-5" nz-button [nzType]="operador === 'Ou' ? 'primary' : 'default'" nzShape="round" nzSize="small" (click)="setOperator('Ou')">Ou</button>
                <button class="m-r-5" nz-button [nzType]="operador === 'Entre' ? 'primary' : 'default'" nzShape="round" nzSize="small" (click)="setOperator('Entre')">Entre</button>
              <!-- </nz-button-group> -->
            </form>
          </div>
          <div nz-col nzSpan="2" class="align-center">
            <button nz-button nzType="primary" (click)="addFilterToRow()">
              <i nz-icon nzType="right"></i>
            </button>
          </div>
          <div nz-col nzSpan="11">
            <div nz-col nzSpan="24">
              <nz-form-label>Filtros adicionados</nz-form-label>
              <div class="p-l-10 p-r-10 p-t-10 m-b-20 filters filters__applied">
                <nz-tag
                  [nzColor]="'blue'"
                  *ngFor="let filter of filtersAppliedObs | async; let i = index"
                  [nzMode]="'closeable'"
                  (nzOnClose)="handleClose(filter)"
                  class="cursor: move;"
                >
                  <span *ngIf="filter.filterLabel; else operator">
                    {{ filter?.filterLabel }} -
                    {{ filter?.valueFormatted?.label || filter?.valueFormatted }}
                  </span>
                  <ng-template #operator>
                    <span>
                      {{ filter?.operador }}
                    </span>
                  </ng-template>
                </nz-tag>
              </div>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>

    <!-- <div class="d-flex justify-content-between">
      <button nz-button [nzType]="'primary'" [disabled]="!form.get('filter').value" type="button"(click)="addFilter()">Adicionar Filtro</button>
    </div> -->
  </form>
</nz-card>
