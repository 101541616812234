import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SistemaService {
  constructor(private http: HttpClient) {}

  getSistemas(ugId: number): Observable<any> {
    return this.http.get(
      `${environment.apiUrl('autorizacao')}/api/licenca/${ugId}/sistemas`
    );
  }
}
