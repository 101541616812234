import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzContextMenuService } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  NzFormatEmitEvent,
  NzTreeNode,
  NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { AuthService } from '../shared/services/auth.service';
import { LicencaService } from '../shared/services/licenca.service';
import { Licenca } from './../shared/services/licenca.service';
import { UnidadeGestoraComponent } from './../unidade-gestora/unidade-gestora.component';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  id: any;
  idChildren?: any;
  name: string;
  checked: boolean;
  type: string;
}

@Component({
  selector: 'ps-painel-administrativo-app-licenca',
  templateUrl: './licenca.component.html',
  styleUrls: ['./licenca.component.scss'],
})
export class LicencaComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons!: IMenuButton[];
  displayData;
  activatedNode;
  intervaloDigitandoUg;
  ugNaoEncontrada;
  messageUgNaoEncontrada;
  // public VALIDADE= VALIDADE
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    numero: true,
    unidadeGestora: true,
    cnpjUnidadeGestora: true,
  };
  public openModal: boolean = false;
  unidadeGestora;
  data: TreeNode<FSEntry>[] = [];
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};
  errorTreeGrid;
  checkboxAtivo: boolean = true;
  ngModelValidade: 1 | 0 = 0;

  //licenca, trocar para o nome da entidade arrColumnsLicencasSelect
  tableLicencaColumns = [];
  titulo: string = 'Licença';
  entidade: string = 'licenca';
  fieldLicencasSelected;
  arrColumnsLicencasSelect: any[];
  showComboTagsExemplos: any[];
  selectedLicencasValues;
  showComboTagsLicencas;
  searchInput: any;
  fieldsLicencasSelected = [];

  modulos: Array<TreeNode<FSEntry>>;

  constructor(
    public nzContextMenuService: NzContextMenuService,
    private service: LicencaService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigLicencaTable();
      await this.setConfigLicencaFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async openFolder(data) {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  async setConfigLicencaFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableLicencaColumns
      );
    });
    this.loadingPage = false;
  }

  checkAllLicenca(permissao: any) {
    console.log(permissao);
    permissao.forEach((child) => (child.data.checked = true));
  }

  checkLicense(license: TreeNode<FSEntry>): void {
    license.data.checked
      ? license.children.forEach((child) => (child.data.checked = false))
      : null;
  }

  hasModulesChecked(
    modulo: TreeNode<FSEntry>,
    isBoolean: boolean = true
  ): boolean | 'lock' | 'unlock' {
    if (isBoolean) {
      return !!modulo.children.find((child) => child.data.checked);
    }
    return !!modulo.children.find((child) => child.data.checked)
      ? 'unlock'
      : 'lock';
  }

  hasSubmoduleChecked(modulo): boolean {
    let submodulos = modulo.children;
    return submodulos.some((submodulo) => submodulo.data.checked);
    //usando o if porque se retirar o for assim que ver o return encerra suas interações
  }

  async setConfigLicencaTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsLicencas = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        });
        this.tableLicencaColumns = tableColumnsLicencas;
        this.arrColumnsLicencasSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      id: [null],
      numero: [{ value: null, disabled: true }, Validators.required],
      validade: [0, Validators.required],
      situacao: [null],
      idUnidadeGestora: [null, Validators.required],
      desUnidadeGestora: [null],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      licMod: this.formBuilder.array([
        this.formBuilder.group({
          idModulo: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
          licSub: this.formBuilder.array([
            this.formBuilder.group({
              aplicacaoOrigem: [1],
              usuarioCriacao: [this.authService.getIdUser()],
              idSubmodulo: [null],
              licFor: this.formBuilder.array([
                this.formBuilder.group({
                  aplicacaoOrigem: [1],
                  usuarioCriacao: [this.authService.getIdUser()],
                  idFormulario: [null],
                }),
              ]),
            }),
          ]),
        }),
      ]),
    });

    this.setValidateStatus(['numero', 'validade']);
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.inserOrUpdateLicence(this.form, this.modulos).then((result: any) =>
      result.data ? this.reset() : null
    );
    return;
  }

  async inserOrUpdateLicence(licenca, permissoes) {
    licenca = await this.prepareToRequest(licenca, permissoes);

    return await this.execute(licenca);
  }

  async prepareToRequest(licenca, permissoes) {
    //repetição para que o código atinja a profundidade necessária de níveis do treeview
    permissoes = await this.editarPermicoesCheckedToDataRecursive(permissoes);
    permissoes = await this.editarPermicoesCheckedToDataRecursive(permissoes);
    licenca = this.licencaFormToLicenca(licenca, permissoes);
    if (!licenca && licenca.length === 0) {
      return this.service.notification.warning(
        'Nenhum submodulo ou formulário selecionado!',
        this.titulo
      );
    }
    return licenca;
  }

  editarPermicoesCheckedToDataRecursive(
    permissoes: Array<TreeNode<FSEntry>>
  ): Array<TreeNode<FSEntry>> {
    for (const permissao of permissoes) {
      if (permissao['children'] && permissao['children'].length !== 0) {
        permissao.data = {
          ...permissao.data,
          checked: permissao.children.some((child) => child.data.checked),
        };
      }
      for (const key in permissao) {
        if (key === 'children' && permissao.children.length !== 0) {
          this.editarPermicoesCheckedToDataRecursive(permissao.children);
        }
      }
    }

    return permissoes as Array<TreeNode<FSEntry>>;
  }

  editarPermicoesDataToCheckedRecursive(
    dataArr: NzTreeNodeOptions[]
  ): NzTreeNodeOptions[] {
    for (const data of dataArr) {
      if (data['children']) {
        data['checked'] = data.children.every((e) => e.data.checked)
          ? true
          : data.data.checked;
        data['isChecked'] = data.children.every((e) => e.data.checked)
          ? true
          : data.data.checked;
      } else {
        data['checked'] = data.data.checked;
        data['isChecked'] = data.data.checked;
      }

      for (const key in data) {
        if (data[key]) {
          if (key === 'children' && data.children.length !== 0) {
            this.editarPermicoesDataToCheckedRecursive(data.children);
          }
        }
      }
    }
    return dataArr as NzTreeNodeOptions[];
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.insertOrUpdate(registro, idMp, entidade);
        this.loadingPage = false;
        return this.reset();
      },
    });
  }

  selecionar = () => {
    this.selecionarItem(this.getRegistrySelected());
  };

  licencaFormToLicenca(form, permissoes): Licenca | any {
    permissoes = permissoes.filter(
      (licencaModulo) => licencaModulo.data.checked
    );
    permissoes.map(
      (licencaModulo) =>
        (licencaModulo.children = licencaModulo.children.filter(
          (licencaSubmodulo) => licencaSubmodulo.data.checked
        ))
    );
    permissoes.map((licencaModulo) =>
      licencaModulo.children.map(
        (licencaSubmodulo) =>
          (licencaSubmodulo.children = licencaSubmodulo.children.filter(
            (licencaFormulario) => licencaFormulario.data.checked
          ))
      )
    );
    form.get('numero').enable();
    return {
      id: form.get('id').value,
      numero: form.get('numero').value,
      validade:
        form.get('validade').value === 0
          ? -1
          : Number.parseInt(form.get('validade').value, 10),
      situacao: form.get('situacao').value === true ? 1 : 0,
      usuarioCriacao: form.get('usuarioCriacao').value,
      aplicacaoOrigem: form.get('aplicacaoOrigem').value,
      dtCriacao: form.get('dtCriacao').value,
      idUnidadeGestora: form.get('idUnidadeGestora').value,

      licMod: permissoes.map((licencaModulo) => {
        return {
          idModulo: licencaModulo.data.id,
          usuarioCriacao: form.get('usuarioCriacao').value,
          aplicacaoOrigem: form.get('aplicacaoOrigem').value,
          licSub: licencaModulo.children.map((licencaSubmodulo) => {
            return {
              usuarioCriacao: form.get('usuarioCriacao').value,
              aplicacaoOrigem: form.get('aplicacaoOrigem').value,
              idSubmodulo: licencaSubmodulo.data.id,
              licFor: licencaSubmodulo.children.map((licencaFormulario) => {
                return {
                  usuarioCriacao: form.get('usuarioCriacao').value,
                  aplicacaoOrigem: form.get('aplicacaoOrigem').value,
                  idFormulario: licencaFormulario.data.id,
                };
              }),
            };
          }),
        };
      }),
    };
  }

  reset() {
    this.resetForm();
    this.limparUg();
    this.modulos = [];
    // this.submitted = false;
    // this.showForm = !this.showForm;
  }

  getFilterLicencaOptionsSelect(item) {}

  novoCadastro = () => {
    this.reset();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  dbclick = (item) => {
    this.openModal ? this.selecionarItem(item) : this.prepareToUpdateForm(item);
  };
  selecionarItem(item) {
    // this.modalRef.destroy(item);
  }

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterLicenca();
  };

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(async () => {
          await this.atualizar();
        });
        this.loadingPage = false;
      },
    });
  }

  async consultaUg(valor, field) {
    if (!valor || valor === this.unidadeGestora?.razaoSocialPessoaJuridica) {
      return;
    }
    clearTimeout(this.intervaloDigitandoUg);
    this.ugNaoEncontrada = false;
    this.intervaloDigitandoUg = setTimeout(async () => {
      await this.service
        .consultaUg(valor, field)
        .then(async (result) => {
          this.unidadeGestora = result.data;
          this.form.patchValue({
            idUnidadeGestora: this.unidadeGestora.id,
            desUnidadeGestora: this.unidadeGestora.razaoSocialPessoaJuridica,
          });
        })
        .catch((err) => {
          this.unidadeGestora = null;
          this.ugNaoEncontrada = true;
          this.messageUgNaoEncontrada = err.message;
          this.form.patchValue({
            idUnidadeGestora: null,
          });
        });
      if (!this.form.value.id) {
        this.montarTreeGrid();
        this.formatNumero();
      }
    }, 500);
  }

  async prepareToUpdateForm(licenca) {
    this.form.get('id').setValue(licenca.id);
    this.form.get('numero').disable();
    this.form.get('numero').setValue(licenca.numero);
    this.form
      .get('validade')
      .setValue(licenca.validade === -1 ? 0 : licenca.validade);
    this.form.get('situacao').setValue(licenca.situacao === 1 ? true : false);
    this.form.get('usuarioCriacao').setValue(licenca.usuarioCriacao);
    this.form
      .get('aplicacaoOrigem')
      .setValue(licenca.aplicacaoOrigem ? licenca.aplicacaoOrigem : 1);
    this.form.get('dtCriacao').setValue(licenca.dtCriacao);
    this.form.get('idUnidadeGestora').setValue(licenca.idUnidadeGestora);
    this.unidadeGestora = { id: licenca.idUnidadeGestora };
    this.form
      .get('desUnidadeGestora')
      .setValue(`${licenca.idUnidadeGestora} - `);
    await this.consultaUg(licenca.idUnidadeGestora, 'id');
    const permissoes = [];
    licenca.licMod.map((licencaModulo) => {
      const submodulos = [];
      licencaModulo.licSub.map((licencaSubmodulo) => {
        const formularios = [];
        licencaSubmodulo.licFor.map((licencaFormulario) => {
          formularios.push({
            id: licencaFormulario.formulario.id,
            nomFormulario: licencaFormulario.formulario.nomFormulario,
            usuarioCriacao: licencaFormulario.usuarioCriacao,
            aplicacaoOrigem: licencaFormulario.aplicacaoOrigem
              ? licencaFormulario.aplicacaoOrigem
              : 1,
          });
        });
        submodulos.push({
          id: licencaSubmodulo.submodulo.id,
          nomSubmodulo: licencaSubmodulo.submodulo.nomSubmodulo,
          usuarioCriacao: licencaSubmodulo.usuarioCriacao,
          aplicacaoOrigem: licencaSubmodulo.aplicacaoOrigem
            ? licencaSubmodulo.aplicacaoOrigem
            : 1,
          formularios: formularios,
        });
      });
      permissoes.push({
        id: licencaModulo.modulo.id,
        nomModulo: licencaModulo.modulo.nomModulo,
        usuarioCriacao: licencaModulo.usuarioCriacao,
        aplicacaoOrigem: licencaModulo.aplicacaoOrigem
          ? licencaModulo.aplicacaoOrigem
          : 1,
        submodulos: submodulos,
      });
    });
    this.montarTreeGrid(permissoes);
    this.changeTabIndex(0);
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    //considerar usar slice
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  showModalUg() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Orgão`,
      nzContent: UnidadeGestoraComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((unidadeGestora) => {
      this.unidadeGestora = unidadeGestora;
      this.form.patchValue({
        idUnidadeGestora: unidadeGestora.id,
        desUnidadeGestora: unidadeGestora.razaoSocialPessoaJuridica,
      });
      if (!this.form.value.id) {
        this.montarTreeGrid();
        this.formatNumero();
      }
    });
  }
  formatNumero() {
    const data = new Date();
    const dia = data.getDate() > 9 ? data.getDate() : `0${data.getDate()}`;
    const mes =
      data.getMonth() > 8 ? data.getMonth() + 1 : `0${data.getMonth() + 1}`;
    this.form
      .get('numero')
      .setValue(
        `${dia}${mes}${data.getFullYear()}${Math.floor(
          1000000 + Math.random() * 9000000
        )}`
      );
  }

  // async loadWithModal() {
  //   if (this.modalAtivo) {
  //     this.windowWidth = `${window.innerWidth * 82 / 100}px`;
  //     this.windowHeight = `${window.innerHeight * 70 / 100}px`;
  //     if (this.licenca) {
  //       this.showForm = true;
  //       const modulos = await this.prepareToUpdateForm(this.licenca);
  //       this.montarTreeGrid(modulos);
  //     }
  //   }
  // }

  limparUg() {
    this.form.patchValue({
      idUnidadeGestora: null,
      desUnidadeGestora: null,
      numero: null,
    });
    this.unidadeGestora = null;
    !this.form.value.id ? this.montarTreeGrid() : null;
  }

  async montarTreeGrid(permissoes = null) {
    this.data = [];
    if (!this.unidadeGestora) {
      return;
    }
    const result = (
      await this.service.consultaPermissao(
        'sistemas',
        'licenca-ug',
        this.unidadeGestora
      )
    ).permissoes;
    const resultUg = (
      await this.service.consultaPermissao(
        'permissao',
        'perfil-ug',
        this.unidadeGestora
      )
    ).permissoes;
    const modulos = [];

    this.form.value.id
      ? result.map((mod) =>
          resultUg.filter((modUg) => modUg.id === mod.id).length > 0
            ? modulos.push(mod)
            : null
        )
      : result.map((mod) =>
          !resultUg ||
          resultUg.filter((modUg) => modUg.id === mod.id).length > 0
            ? modulos.push(mod)
            : null
        );

    if (permissoes && permissoes.length > 0) {
      modulos.map((moduloPermissao) => {
        permissoes.map((moduloPerfil) => {
          if (moduloPermissao.id === moduloPerfil.id) {
            moduloPermissao.checked = true;
            moduloPermissao.submodulos.map((submoduloPermissao) => {
              moduloPerfil.submodulos.map((submoduloPerfil) => {
                if (submoduloPermissao.id === submoduloPerfil.id) {
                  submoduloPermissao.checked = true;
                  submoduloPermissao.formularios.map((formularioPermissao) => {
                    submoduloPerfil.formularios.map((formularioPerfil) => {
                      if (formularioPermissao.id === formularioPerfil.id) {
                        formularioPermissao.checked = true;
                      }
                    });
                  });
                }
              });
            });
          }
        });
      });
    }

    this.modulos = [];

    modulos.map((modulo) => {
      const submodulos = [];
      modulo.submodulos.map((submodulo) => {
        const formularios = [];
        submodulo.formularios.map((formulario) => {
          formularios.push({
            data: {
              id: formulario.id,
              idChildren: submodulo.id,
              name: formulario.nomFormulario,
              type: 'formulario',
              checked: !!formulario.checked,
            },
            // checkedAll: formulario.checked
            //   ? submodulo.formularios.every((form) => form.checked === true)
            //   : false,
            children: [],
          });
        });
        submodulos.push({
          data: {
            id: submodulo.id,
            idChildren: modulo.id,
            name: submodulo.nomSubmodulo,
            checked: !!submodulo.checked,
            type: 'submodulo',
          },
          // checkedAll: submodulo.checked
          //   ? modulo.submodulos.every((mod) => mod.checked === true)
          //   : false,
          children: formularios,
        });
      });
      this.data.push({
        data: {
          id: modulo.id,
          idChildren: null,
          name: modulo.nomModulo,
          checked: !!modulo.checked,
          type: 'modulo',
        },
        children: submodulos,
      });

      this.modulos.push({
        data: {
          id: modulo.id,
          idChildren: null,
          name: modulo.nomModulo,
          checked: !!modulo.checked,
          type: 'modulo',
        },
        children: submodulos,
      });
    });

    this.errorTreeGrid =
      modulos.length === 0
        ? 'Unidade gestora já possui licença com todos os módulos'
        : '';
  }

  activeNode(data: NzFormatEmitEvent): void {
    this.activatedNode = data.node!;
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterLicenca() {
    return this.getLicencaPerPeriod(this.fieldsLicencasSelected);
  }

  removeFiltroLicencaPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableLicencaColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsLicencasSelect.splice(
      this.tableLicencaColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsLicencasSelected.splice(
      this.fieldsLicencasSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsLicencasSelected.length === 0) {
      this.showComboTagsLicencas = false;
    }
  }

  async pageChange(valor) {
    await this.getLicencaPerPeriod(
      this.fieldsLicencasSelected,
      Number(valor),
      10
    );
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getLicencaPerPeriod(
    fieldsLicencasSelected,
    page?: number,
    size?: number
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsLicencasSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (licenca: any) => {
        if (licenca.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = licenca.data.paramsPaginator[0];
        this.displayData = licenca.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        // this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldLicencasSelected && this.searchInput) {
      if (this.fieldLicencasSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldLicencasSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsLicencasSelected.length !== 0) {
        this.removeFiltroLicencaPorField(this.fieldsLicencasSelected[0]);
      }
      this.fieldsLicencasSelected.push({
        props: this.fieldLicencasSelected.value,
        field: this.fieldLicencasSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldLicencasSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsLicencasSelect.findIndex(
        (a) => a.value === this.fieldLicencasSelected.value
      );

      this.arrColumnsLicencasSelect.splice(index, 1);

      this.showComboTagsLicencas = true;
      this.fieldLicencasSelected = '';
      this.searchInput = '';
      this.selectedLicencasValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  checkTipoValidade() {
    this.form.get('validade').value === '' ||
    this.form.get('validade').value === null
      ? this.form.patchValue({ validade: 0 })
      : null;
  }

  selectItem(item) {}
}
