import {
  Component,
  DoCheck,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormlyForm } from '@ngx-formly/core';

// tslint:disable-next-line: no-conflicting-lifecycle
@Component({
  template: `<formly-field
    *ngFor="let f of fields"
    [field]="f"
  ></formly-field>`,
  // tslint:disable-next-line: component-selector
  selector: 'ps-form',
})
export class PsDynamicFormComponent
  extends FormlyForm
  implements DoCheck, OnChanges, OnDestroy {
  ngDoCheck() {
    super.ngDoCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
