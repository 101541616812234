import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import { Optional } from 'ag-grid-community';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { OrgaoService } from '../shared/services/orgao.service';
import { AuthService } from './../shared/services/auth.service';
@Component({
  selector: 'ps-painel-administrativo-app-orgao',
  templateUrl: './orgao.component.html',
  styleUrls: ['./orgao.component.scss'],
})
export class OrgaoComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    nomeOrgao: true,
  };
  pageTotal: number;
  openModal: boolean;
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};

  //orgao, trocar para o nome da entidade arrColumnsOrgaosSelect
  tableOrgaoColumns = [];
  titulo: string = 'Orgão';
  entidade: string = 'orgao';
  fieldOrgaosSelected;
  arrColumnsOrgaosSelect: any[];
  showComboTagsExemplos: any[];
  selectedOrgaosValues;
  showComboTagsOrgaos;
  searchInput: any;
  fieldsOrgaosSelected = [];
  radioValue: number;

  constructor(
    @Optional()
    private modalRef: NzModalRef<OrgaoComponent>,
    private service: OrgaoService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigOrgaoTable();
      await this.setConfigOrgaoFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigOrgaoFilter() {
    this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableOrgaoColumns
      );
    });
    this.loadingPage = false;
  }

  getTitulo(): string {
    return this.titulo;
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async setConfigOrgaoTable() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      const tableColumnsCidades = result.tableColumnsProps.map((column) => {
        return {
          ...column,
          compare:
            typeof column.value === 'string'
              ? (a, b) => a[column.value].localeCompare(b[column.value])
              : (a, b) => a[column.value] - b[column.value],

          // width:
          //   column.value === 'idPublico'
          //     ? '8rem'
          //     : column.value === 'titulo'
          //     ? '15rem'
          //     : column.value === 'dtCriacao'
          //     ? '20rem'
          //     : null,
        };
      });
      this.tableOrgaoColumns = tableColumnsCidades;

      this.arrColumnsOrgaosSelect = result.searchColumns;
    });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      id: [null],
      idPublico: [null, Validators.required],
      nomeOrgao: [null, Validators.required],
      poder: [null, Validators.required],
      normaCriacao: [null],
      dataNormaCriacao: [null],
      finalidadeOrgao: [null],
      instrumentoCriacao: [null],
      usuarioCriacao: [this.authService.getIdUser()],
      aplicacaoOrigem: [1],
      dtCriacao: [new Date()],
    });
    this.setValidateStatus(['idPublico', 'poder', 'nomeOrgao']);
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterOrgaoOptionsSelect(item) {}

  selecionar = () => {
    this.selectItem(this.getRegistrySelected());
  };

  novoCadastro = () => {
    this.resetForm();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const orgao = this.orgaoFormToCadastroOrgao(this.form);
    return await this.execute(orgao);
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.insertOrUpdate(registro, idMp, entidade);
        this.loadingPage = false;
        return this.reset();
      },
    });
  }

  orgaoFormToCadastroOrgao(form) {
    const orgao = form.value;
    return orgao;
  }

  reset() {
    this.resetForm();
  }

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  dbClickOrgao = (item) => {
    this.openModal ? this.selectItem(item) : this.prepareToUpdateForm(item);
  };

  selectItem(item) {
    this.modalRef.destroy(item);
  }

  async prepareToUpdateForm(orgao) {
    this.form.patchValue({ ...orgao });
    // this.radioValue = orgao.instrumentoCriacao;
    this.form.controls.instrumentoCriacao.setValue(orgao.instrumentoCriacao);
    this.changeTabIndex(0);
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  atualizar = () => {
    this.filterOrgao();
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.resetFabButtons();
      this.atualizar();
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterOrgao() {
    return this.getOrgaoPerPeriod(this.fieldsOrgaosSelected);
  }

  async pageChange(valor) {
    await this.getOrgaoPerPeriod(this.fieldsOrgaosSelected, Number(valor), 10);
  }

  async getOrgaoPerPeriod(fieldsOrgaosSelected, page?: number, size?: number) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsOrgaosSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (orgao: any) => {
        this.displayData = orgao.data.resultado;
        this.pageTotal = orgao.data.paramsPaginator[0];
        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldOrgaosSelected && this.searchInput) {
      if (this.fieldOrgaosSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldOrgaosSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsOrgaosSelected.length !== 0) {
        this.removeFiltroOrgaoPorField(this.fieldsOrgaosSelected[0]);
      }
      this.fieldsOrgaosSelected.push({
        props: this.fieldOrgaosSelected.value,
        field: this.fieldOrgaosSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldOrgaosSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsOrgaosSelect.findIndex(
        (a) => a.value === this.fieldOrgaosSelected.value
      );

      this.arrColumnsOrgaosSelect.splice(index, 1);

      this.showComboTagsOrgaos = true;
      this.fieldOrgaosSelected = '';
      this.searchInput = '';
      this.selectedOrgaosValues = [];
    }
  }

  removeFiltroOrgaoPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableOrgaoColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsOrgaosSelect.splice(
      this.tableOrgaoColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsOrgaosSelected.splice(
      this.fieldsOrgaosSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsOrgaosSelected.length === 0) {
      this.showComboTagsOrgaos = false;
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  clickItem(item) {}
}
