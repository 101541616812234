import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AcordoDividaMercantilService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super('', abstractHttp, abstractAuthService, abstractNotification);
  }

  public async getQntdMaxParcelas(params?) {
    return await this.getGetQntdMaxParcelas(params).then((resp: any) => {
      if (resp.data == null) {
        throw new Error('valor não encontrado');
      }
      return resp.data;
    });
  }

  private getGetQntdMaxParcelas(param?) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('acordo-divida')}/mercantil/qntd-max-parcelas${
            param ? params : ''
          }`,
          {
            headers: new HttpHeaders({
              // "x-authorization": `${localInfo.cnpjInst}`
              'x-authorization': `09073628000191`,
            }),
          }
        )
        .subscribe({
          next: (resp) => {
            resolve(resp);
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async getDadosMercantil(params) {
    return await this.getGetDadosMercantil(params).then((response: any) => {
      if (response.data == null) {
        throw new Error('Dados mercantil não encontrado');
      }

      return response.data;
    });
  }

  private getGetDadosMercantil(param) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/mercantil/get-dados-mercantil${param ? params : ''}`;

      const subscription: Subscription = this.abstractHttp
        .get(url, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': `09073628000191`,
          }),
        })
        .subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe;
          },
        });
    });
  }

  public async getLancamentos(params) {
    return await this.getGetLancamentos(params).then((response: any) => {
      if (response.data === null) {
        throw new Error('Dívida não encontrada.');
      }
      return response.data;
    });
  }

  private getGetLancamentos(param) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/mercantil/get-lancamentos${param ? params : ''}`;

      const subscription: Subscription = this.abstractHttp
        .get(url, {
          headers: new HttpHeaders({
            'x-authorization': `09073628000191`,
          }),
        })
        .subscribe({
          next: (respone) => {
            resolve(respone);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  async postCalcDividas(data) {
    return await this.postPostCalcDividas(data).then((response: any) => {
      if (response.data === null) {
        throw new Error('Dívidas não encontrada.');
      }
      return response.data;
    });
  }

  private postPostCalcDividas(data) {
    return new Promise((resolve, reject) => {
      // const params = `?${new URLSearchParams(param).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/mercantil/get-lancamentos-calculados`;

      const subscription: Subscription = this.abstractHttp
        .post(url, data, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': `09073628000191`,
          }),
        })
        .subscribe({
          next: (respone) => {
            resolve(respone);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }

  public async postDivida(formData) {
    return this.postPostDivida(formData).then((response: any) => {
      return response.data;
    });
  }

  private postPostDivida(formData) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams().toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer(
        'acordo-divida'
      )}/mercantil/solicitar-acordo`;

      const subscription: Subscription = this.abstractHttp
        .post(url, formData, {
          headers: new HttpHeaders({
            // 'x-authorization' : `${localInfo.cnpjInst}`
            'x-authorization': '09073628000191',
          }),
        })
        .subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        });
    });
  }
}
