<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="p-l-20 p-r-20" [nzGutter]="18">
            <div nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Código Siconfi</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidatorStatus('idPublico')"
                  nzErrorTip="Código Siconfi é obrigatório.">
                  <input nz-input type="text" fullWidth formControlName="idPublico"
                    placeholder="Digite um código válido">

                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome instituição Siconfi</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidatorStatus('nome')" nzErrorTip="Nome Instituição Siconfi é
                  obrigatório.">
                  <input nz-input type="text" fullWidth formControlName="nome" placeholder="Digite um nome válido">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="7" nzSm="7" nzXs="24">
              <nz-form-item>
                <nz-form-label>UF</nz-form-label>
                <nz-form-control [nzValidateStatus]="getValidatorStatus('uf')" nzErrorTip="UF é obrigatória.">
                  <nz-select fullWidth nzShowSearch formControlName="uf" nzPlaceHolder="UF">
                    <nz-option *ngFor="let uf of ufs" [nzValue]="uf.sigla"
                      [nzLabel]="uf?.sigla +' - '+ uf?.nomUnidadeFederativa">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldInstituicoesSiconfisSelected"
                              (ngModelChange)="getFilterInstituicaoSiconfiOptionsSelect(fieldInstituicoesSiconfisSelected)">
                              <nz-option *ngFor="let interval of arrColumnsInstituicoesSiconfisSelect"
                                [nzValue]="interval" [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>

                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group
                              *ngIf="fieldInstituicoesSiconfisSelected && fieldInstituicoesSiconfisSelected.type === 'input'">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>

                          <div nz-col *ngIf="fieldInstituicoesSiconfisSelected" nzSpan="2" nzXl="2" nzXs="12"
                            class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div nz-row class="m-t-10">
                        <div *ngIf="showComboTagsInstituicoesSiconfis" class="p-0" nz-col nzSpan="12" nzXl="12"
                          nzLg="12" nzXs="24">
                          <nz-input-group [nzPrefix]="' '">
                            <div class="align-self-center">
                              <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroInstituicaoSiconfiPorField(fieldsValuesTag)
                            " class="filters"
                                *ngFor="let fieldsValuesTag of fieldsInstituicoesSiconfisSelected; let i = index ">
                                {{ fieldsValuesTag.text }}
                              </nz-tag>
                            </div>
                          </nz-input-group>
                        </div>
                        <div nz-col [class]="'m-t-10 p-l-20'"
                          [nzOffset]="fieldsInstituicoesSiconfisSelected.length !=0?openModal?7:8:openModal?19:20"
                          nzSpan="3" nzXl="3" nzLg="3" nzXs="24">

                          <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                            (click)="filterInstituicaoSiconfi()">
                            <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #ExampleListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableInstituicaoSiconfiColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="ExampleListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="doubleClick(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableInstituicaoSiconfiColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>