import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ColDef, GridOptions } from 'ag-grid-community';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-simple-form',
  templateUrl: './ps-simple-form.component.html',
  styleUrls: ['./ps-simple-form.component.scss'],
})
export class PsSimpleFormComponent implements OnInit {
  @Input() title: string;
  @Input() tabIndex: number;
  @Input() actions = {};

  @Input() form: FormGroup;
  @Input() fields: FormlyFieldConfig[];
  @Input() model;

  @Input() filterFields: FormlyFieldConfig[];
  @Input() filterFieldsAvancado: FormlyFieldConfig[];

  @Input() gridOptions: GridOptions;
  @Input() rowData: any;
  @Input() defaultColDefs: ColDef;
  @Input() columnDefs: ColDef[];

  @Output() changeTabIndex = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  changeModel = (value) => this.changeTabIndex.emit(value);
}
