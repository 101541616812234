import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import { Optional } from 'ag-grid-community';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { InstituicaoSiconfiService } from '../shared/services/instituicao-siconfi.service';
import { AuthService } from './../shared/services/auth.service';

@Component({
  selector: 'ps-painel-administrativo-app-instituicao-siconfi',
  templateUrl: './instituicao-siconfi.component.html',
  styleUrls: ['./instituicao-siconfi.component.scss'],
})
export class InstituicaoSiconfiComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData = [];
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    nome: true,
    uf: true,
  };
  pageTotal: number;
  openModal: boolean;
  ufs: Array<any> = [];

  //InstituicaoSiconfi, trocar para o nome da entidade arrColumnsInstituicoesSiconfisSelect
  tableInstituicaoSiconfiColumns = [];
  titulo: string = 'Instituição Siconfi';
  entidade: string = 'instituicao-siconfi';
  fieldInstituicoesSiconfisSelected;
  arrColumnsInstituicoesSiconfisSelect: any[];
  showComboTagsExemplos: any[];
  selectedInstituicoesSiconfisValues;
  showComboTagsInstituicoesSiconfis;
  searchInput: any;
  fieldsInstituicoesSiconfisSelected = [];
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};

  constructor(
    @Optional()
    private modalRef: NzModalRef<InstituicaoSiconfiComponent>,
    private formBuilder: FormBuilder,
    private service: InstituicaoSiconfiService,
    private activateRouter: ActivatedRoute,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigInstituicaoSiconfiTable();
      await this.setConfigInstituicaoSiconfiFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigInstituicaoSiconfiFilter() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableInstituicaoSiconfiColumns
      );
    });
    this.loadingPage = false;
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidatorStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  async setConfigInstituicaoSiconfiTable() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      const tableColumnsInstituicaoSiconfi = result.tableColumnsProps.map(
        (column) => {
          return {
            ...column,
            compare:
              typeof column.value === 'string'
                ? (a, b) => a[column.value].localeCompare(b[column.value])
                : (a, b) => a[column.value] - b[column.value],

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        }
      );
      this.tableInstituicaoSiconfiColumns = tableColumnsInstituicaoSiconfi;

      this.arrColumnsInstituicoesSiconfisSelect = result.searchColumns;
    });
    this.loadingPage = false;
  }

  resetForm() {
    this.form = this.formBuilder.group({
      idPrivado: [null],
      idPublico: [null, Validators.required],
      nome: [null, Validators.required],
      uf: [null, Validators.required],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
    });
    this.setValidateStatus(['idPublico', 'nome', 'uf']);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 0 || this.tabIndex === 1,
        onClick: this.novoCadastro,
      },

      {
        icon: 'save',
        tooltip: this.form.value.id ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 && (!this.form.value.id || this.form.value.id),
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterInstituicaoSiconfiOptionsSelect(item) {}

  selecionar = () => {
    this.selecionarItem(this.getRegistrySelected());
  };

  doubleClick = (item) => {
    this.openModal ? this.selecionarItem(item) : this.prepareToUpdateForm(item);
  };

  selecionarItem(item) {
    this.modalRef.destroy(item);
  }

  novoCadastro = () => {
    this.resetForm();
  };

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    // this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loadingPage = true;
    return await this.execute(this.form.value).then((result: any) => {
      result.data ? this.reset() : null, (this.loadingPage = false);
    });
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        await this.service.insertOrUpdate(registro, idMp, entidade);

        return this.reset();
      },
    });
  }

  reset() {
    this.resetForm();
  }

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  editarInstituicaoSiconfi = (item) => {
    this.prepareToUpdateForm(item);
  };

  prepareToUpdateForm(instituicao) {
    if (instituicao) {
      this.form.patchValue({ ...instituicao });
      this.changeTabIndex(0);
    }
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  removerRegistro(registro, entidade = this.entidade) {
    if (registro) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja realmente remover o registro?</i>`,
        nzOnOk: async () => {
          this.loadingPage = true;
          await this.service.delete(registro, entidade).then(() => {
            this.removerRegistroGrid(registro);
          });

          this.loadingPage = false;
        },
      });
    }
  }

  removerRegistroGrid(item: any): void {
    this.displayData = this.displayData.filter(
      (data) => data.idPrivado !== item.idPrivado
    );
  }

  atualizar = () => {
    this.filterInstituicaoSiconfi();
  };

  ngOnInit(): void {
    this.setUfs();
    if (this.openModal) {
      this.resetFabButtons();
      this.atualizar();
    }
  }

  async setUfs() {
    this.loadingPage = true;
    this.ufs = await this.service.getUfs();
    this.loadingPage = false;
  }

  getWidthContent() {
    return window.innerWidth;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterInstituicaoSiconfi() {
    return this.getInstituicaoSiconfiPerPeriod(
      this.fieldsInstituicoesSiconfisSelected
    );
  }
  async pageChange(valor) {
    await this.getInstituicaoSiconfiPerPeriod(
      this.fieldsInstituicoesSiconfisSelected,
      Number(valor),
      10
    );
  }

  async getInstituicaoSiconfiPerPeriod(
    fieldsInstituicoesSiconfisSelected,
    page?: number,
    size?: number
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsInstituicoesSiconfisSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (instituicaoSiconfi: any) => {
        // if (instituicaoSiconfi.data.resultado.length === 0) {
        //   this.service.notification.warning(
        //     this.titulo,
        //     'Nenhum registro correspondente aos filtros.',
        //     { nzDuration: 7000 }
        //   );
        // }
        this.displayData = instituicaoSiconfi.data.resultado;
        this.pageTotal = instituicaoSiconfi.data.paramsPaginator[0];
        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldInstituicoesSiconfisSelected && this.searchInput) {
      if (this.fieldInstituicoesSiconfisSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldInstituicoesSiconfisSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsInstituicoesSiconfisSelected.length !== 0) {
        this.removeFiltroInstituicaoSiconfiPorField(
          this.fieldsInstituicoesSiconfisSelected[0]
        );
      }
      this.fieldsInstituicoesSiconfisSelected.push({
        props: this.fieldInstituicoesSiconfisSelected.value,
        field: this.fieldInstituicoesSiconfisSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldInstituicoesSiconfisSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsInstituicoesSiconfisSelect.findIndex(
        (a) => a.value === this.fieldInstituicoesSiconfisSelected.value
      );

      this.arrColumnsInstituicoesSiconfisSelect.splice(index, 1);

      this.showComboTagsInstituicoesSiconfis = true;
      this.fieldInstituicoesSiconfisSelected = '';
      this.searchInput = '';
      this.selectedInstituicoesSiconfisValues = [];
    }
  }

  removeFiltroInstituicaoSiconfiPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableInstituicaoSiconfiColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsInstituicoesSiconfisSelect.splice(
      this.tableInstituicaoSiconfiColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsInstituicoesSiconfisSelected.splice(
      this.fieldsInstituicoesSiconfisSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsInstituicoesSiconfisSelected.length === 0) {
      this.showComboTagsInstituicoesSiconfis = false;
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
