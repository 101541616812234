import { Component, OnInit } from '@angular/core';
import { Module } from '@ps-erp-angular/shared';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'ps-siafic-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public modulo = 'Digitalização';
  public icone!: string;

  constructor(private _authService: AuthService) {
    const moduloInfo: Partial<Module> = this._authService.getmoduloNomeIcone();
    this.modulo = moduloInfo.nomModulo;
    this.icone = moduloInfo.icone;
  }

  ngOnInit(): void {}
}
