import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { Helper } from '../shared/Helper';
import { PessoaJuridicaService } from '../shared/services/pessoa-juridica.service';
import { PessoaFisicaComponent } from './../pessoa-fisica/pessoa-fisica.component';
import { AuthService } from './../shared/services/auth.service';
import { GrupoNaturezaJuridicaService } from './../shared/services/grupo-natureza-juridica.service';
import { PessoaService } from './../shared/services/pessoa.service';

@Component({
  selector: 'ps-painel-administrativo-app-pessoa-juridica',
  templateUrl: './pessoa-juridica.component.html',
  styleUrls: ['./pessoa-juridica.component.scss'],
})
export class PessoaJuridicaComponent implements OnInit {
  @Input() pessoaJuridica: any;
  @Input() modalAtivo: boolean;
  intervaloDigitando;
  nzFilterOption = (): boolean => true;
  selectednatJuridica;
  public showForm: boolean;
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  cnaeIndex: number;
  public form: FormGroup;
  cadastroBasico: boolean = false;
  fabButtons: IMenuButton[];
  displayData = [];
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    razaoSocial: true,
    cnpj: true,
  };
  selectedValueCnae = {};
  naturezaJuridicaSelected;
  arrNaturezaJuridica = [];
  optionsCodCnae = [];
  pessoaFisica;
  windowWidth: string;
  windowHeight: string;
  pageTotal: number;
  public gruposNaturezasJuridica: Array<any>;
  codCnaeNaoEncontrado;
  enderecoNaoEncontrado;
  cepNaoEncontrado;
  messageEnderecoNaoEncontrado;
  optionsCnae = [];
  messageCodCnaeNaoEncontrado;
  selectedValue;
  submitted;
  openModal: boolean = false;

  //pessoajuridica, trocar para o nome da entidade arrColumnsPessoasJuridicasSelect
  tablePessoaJuridicaColumns = [];
  titulo: string = 'Pessoa jurídica';
  entidade: string = 'pessoa-juridica';
  fieldPessoasJuridicasSelected;
  arrColumnsPessoasJuridicasSelect: any[];
  showComboTagsPessoasJuridicas: any[];
  selectedPessoasJuridicasValues;
  showComboTagsPessoaJuridica;
  searchInput: any;
  fieldsPessoasJuridicasSelected = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<PessoaJuridicaComponent>,
    private service: PessoaJuridicaService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public pessoaService: PessoaService,
    private modalService: NzModalService,
    private grupoNaturezaJuridicaService: GrupoNaturezaJuridicaService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      // this.loadingPage = true;
      await this.setConfigPessoaJuridicaTable();

      await this.setConfigPessoaJuridicaFilter();
      // this.loadingPage = false;
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigPessoaJuridicaFilter() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tablePessoaJuridicaColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigPessoaJuridicaTable() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      const tableColumnsPessoasJuridicas = result.tableColumnsProps.map(
        (column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        }
      );
      this.tablePessoaJuridicaColumns = tableColumnsPessoasJuridicas;

      this.arrColumnsPessoasJuridicasSelect = result.searchColumns;
    });
    this.loadingPage = false;
  }

  async consultaCep(cep, index) {
    this.enderecoNaoEncontrado = false;
    this.cepNaoEncontrado = false;
    this.pessoaService
      .consultaEndereco(cep, this.form, index)
      .then((result: any) => {
        (this.form.get('enderecos') as any).controls[index].patchValue({
          ...result?.data[0],
          id: (this.form.get('enderecos') as any).controls[index].value.id,
          nomCidade: result?.data[0].localidade,
          nomBairro: result?.data[0].bairro,
          nomLogradouro: result?.data[0].logradouro,
          nomUf: result?.data[0].uf,
        });
      })
      .catch((err) => {
        this.enderecoNaoEncontrado = true;
        this.cepNaoEncontrado = true;
        // this.messageEnderecoNaoEncontrado = err.error.message;
        (this.form.get('enderecos') as any).controls[index].patchValue({
          nomCidade: null,
          nomBairro: null,
          nomLogradouro: null,
          nomUf: null,
        });
      });
  }

  changeTipoCadastro(valor: boolean) {
    this.cadastroBasico = valor;
  }

  removeEndereco(index: number) {
    if (this.form.get(`enderecos.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover endereço?</i>`,
        nzOnOk: () => {
          this.loadingPage = true;
          this.service
            .delete(this.form.value.enderecos[index], 'pessoa-endereco')
            .then(() => {
              this.loadingPage = false;
              const form: FormArray = this.form.get('enderecos') as FormArray;
              form.removeAt(index);
            });
          // this.resetForm();
        },
      });
    }

    const form: FormArray = this.form.get('enderecos') as FormArray;
    form.removeAt(index);
  }

  showModalPessoaFisica(index) {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: 'Selecione uma Pessoa física',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisica) => {
      this.pessoaFisica = pessoaFisica;
      (this.form.get('pessoaResponsaveis') as any).controls[index].patchValue({
        idPessoaFisica: pessoaFisica.id,
        cpf: pessoaFisica.cpf,
        nome: pessoaFisica.pessoa.nomPessoa,
        email: pessoaFisica.pessoa.emailPrincipal,
      });
    });
  }

  limpaCpf(index) {
    (this.form.get('pessoaResponsaveis') as any).controls[index].patchValue({
      cpf: null,
      idPessoaFisica: null,
      nome: null,
      email: null,
    });
    this.pessoaFisica = null;
  }

  async preencherCampoNaturezaJuridica(naturezaJuridicaSelected) {
    if (typeof naturezaJuridicaSelected === 'string') {
      naturezaJuridicaSelected = this.arrNaturezaJuridica.find(
        (naturezaJuridica) => naturezaJuridica.nome === naturezaJuridicaSelected
      );
      this.form
        .get('natJuridica')
        .get('codigo')
        .setValue(naturezaJuridicaSelected.codigo);
    }
  }

  async consultaNaturezaJuridica() {
    this.loadingPage = true;
    this.arrNaturezaJuridica = await this.grupoNaturezaJuridicaService.getAllForGrouppingAutocomplete();
    this.arrNaturezaJuridica = this.arrNaturezaJuridica.map((data) => {
      return {
        ...data,
        text: `${data.codigo}  -  ${data.nome}`,
        value: data.codigo,
      };
    });
    this.loadingPage = false;
  }

  preencherCampoCnae(codCnae, index) {
    codCnae = codCnae[this.cnaeIndex];
    if (this.optionsCnae.length > 0) {
      const cnae = this.optionsCnae.find((opt) => opt.codigo === codCnae);

      (this.form.get('pessoaJuridicaCnae') as any).controls[
        this.cnaeIndex
      ].patchValue({
        nome: cnae.nome,
        // codigo: cnae.codigo,
        idCnaeSubclasse: cnae.idCnaeSubclasse,
      });
    }
  }

  setCnaeIndex(i) {
    this.cnaeIndex = i;
  }

  async consultaCnae() {
    clearTimeout(this.intervaloDigitando);
    this.codCnaeNaoEncontrado = false;
    this.intervaloDigitando = await setTimeout(async () => {
      this.service
        .consultaCnae()
        .then((result: { data: any }) => {
          this.optionsCnae = [];

          result?.data.map((cnae) => {
            this.optionsCnae.push({
              texto: cnae.codigo,
              codigo: cnae.codigo,
              nome: cnae.nomeSubclasse,
              idCnaeSubclasse: cnae.id,
            });
          });
        })
        .catch((err) => {
          this.codCnaeNaoEncontrado = true;
          this.messageCodCnaeNaoEncontrado = err.message;
          this.optionsCnae = err.data;
        });
    }, 500);
  }

  resetForm() {
    this.form = this.formBuilder.group({
      id: [null],
      cnpj: [null, Validators.required],
      dtAbertura: [null, Validators.required],
      nomFantasia: [null],
      razaoSocial: [null, Validators.required],
      inscricaoEstadual: [null],
      inscricaoMunicipal: [null],
      site: [null],
      tipo: [null, Validators.required],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      natJuridica: this.formBuilder.group({
        codigo: [null],
        descricao: [null],
      }),
      pessoa: this.formBuilder.group({
        id: [null],
        tipoPessoa: [1],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      }),
      contatos: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          email: [null],
          telefone: [null],
          whatsapp: [null],
          telegram: [null],
          principal: [true],
          maskTelefone: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
        }),
      ]),
      enderecos: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          principal: [true],
          cep: [null, Validators.required],
          numero: [null],
          complemento: [null],
          nomCidade: [{ value: null, disabled: true }],
          codigoIbgeCidade: [null],
          nomBairro: [{ value: null, disabled: true }],
          nomLogradouro: [{ value: null, disabled: true }],
          nomUf: [{ value: null, disabled: true }],
          error: [false],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
        }),
      ]),
      pessoaResponsaveis: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          cpf: [null, Validators.required],
          idPessoaFisica: [null],
          nome: [{ value: null, disabled: true }],
          email: [{ value: null, disabled: true }],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
        }),
      ]),
      pessoaJuridicaCnae: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          idCnaeSubclasse: [null],
          principal: [false],
          codigo: [null],
          nome: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
          versao: ['2.3'],
        }),
      ]),
    });
    this.loadWithModal();
    this.loadDescriptionNaturezaJuridica();
  }

  verificaCnaePrincipal(principal) {
    return (
      this.form.get('pessoaJuridicaCnae').value.filter((c) => c.principal)
        .length === 0 || principal
    );
  }

  removeCnae(index: number) {
    //to-do:verificar a entidade dos remove
    if (this.form.get(`pessoaJuridicaCnae.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover o CNAE?</i>`,
        nzOnOk: () => {
          this.loadingPage = true;
          this.service
            .delete(this.form.value.contatos[index], 'pessoa-juridica-cnae')
            .then((response) => {
              this.service
                .delete(
                  this.form.value.pessoaJuridicaCnae[index],
                  'pessoa-juridica-cnae'
                )
                .then(() => {
                  const responsavelForm: FormArray = this.form.get(
                    'pessoaJuridicaCnae'
                  ) as FormArray;
                  responsavelForm.removeAt(index);
                  this.loadingPage = false;
                })
                .catch(() => {
                  this.loadingPage = false;
                });
              this.loadingPage = false;
            })
            .catch((error) => {
              this.loadingPage = false;
            });

          // this.resetForm();
        },
      });
    }
    delete this.selectedValueCnae[index];

    const formb: FormArray = this.form.get('pessoaJuridicaCnae') as FormArray;
    formb.removeAt(index);
  }

  removeResponsavel(index: number) {
    if (this.form.get(`pessoaResponsaveis.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover contato?</i>`,
        nzOnOk: () => {
          this.loadingPage = true;
          this.service
            .delete(this.form.value.contatos[index], 'pessoa-responsaveis')
            .then((response) => {
              const responsavelForm: FormArray = this.form.get(
                'pessoaResponsaveis'
              ) as FormArray;
              responsavelForm.removeAt(index);
              this.loadingPage = false;
            });

          // this.resetForm();
        },
      });
    }
    const responsavelFormb: FormArray = this.form.get(
      'pessoaResponsaveis'
    ) as FormArray;
    responsavelFormb.removeAt(index);
  }

  verificaContatoPrincipal(principal) {
    return (
      this.form.get('contatos').value.filter((c) => c.principal).length === 0 ||
      principal
    );
  }

  removeContato(index: number) {
    if (this.form.get(`contatos.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover contato?</i>`,
        nzOnOk: () => {
          this.loadingPage = true;
          this.service
            .delete(this.form.value.contatos[index], 'pessoa-contato')
            .then(() => {
              this.loadingPage = false;
              const contatoForm: FormArray = this.form.get(
                'contatos'
              ) as FormArray;
              contatoForm.removeAt(index);
            });

          // this.resetForm();
        },
      });
    }
    const contatoForm: FormArray = this.form.get('contatos') as FormArray;
    contatoForm.removeAt(index);
  }

  verificaEnderecoPrincipal(principal) {
    return (
      this.form.get('enderecos').value.filter((c) => c.principal).length ===
        0 || principal
    );
  }

  loadDescriptionNaturezaJuridica(): Subscription {
    return this.form
      .get('natJuridica')
      .get('codigo')
      .valueChanges.subscribe((result) => {
        this.gruposNaturezasJuridica.map((grupo) => {
          grupo.naturezas_juridica.map((naturezaJuridica) => {
            if (naturezaJuridica.codigo === result) {
              this.form
                .get('natJuridica')
                .get('descricao')
                .setValue(naturezaJuridica.name);
            }
          });
        });
      });
  }

  async loadWithModal() {
    if (this.modalAtivo) {
      this.windowWidth = `${(window.innerWidth * 82) / 100}px`;
      this.windowHeight = `${(window.innerHeight * 70) / 100}px`;
      if (this.pessoaJuridica) {
        this.showForm = true;
        await this.prepareToUpdateForm(this.pessoaJuridica);
      }
    }
  }

  async prepareToUpdateForm(pessoaJuridica) {
    this.loadingPage = true;
    this.form.get('id').setValue(pessoaJuridica.id);
    this.form.get('cnpj').setValue(pessoaJuridica.cnpj);
    this.form.get('dtAbertura').setValue(pessoaJuridica.dtAbertura);
    this.form.get('nomFantasia').setValue(pessoaJuridica.nomFantasia);
    this.form.get('razaoSocial').setValue(pessoaJuridica.razaoSocial);
    this.form
      .get('inscricaoEstadual')
      .setValue(pessoaJuridica.inscricaoEstadual);
    this.form
      .get('inscricaoMunicipal')
      .setValue(pessoaJuridica.inscricaoMunicipal);
    this.form.get('site').setValue(pessoaJuridica.site);
    this.form.get('tipo').setValue(pessoaJuridica.tipo);
    this.form.get('usuarioCriacao').setValue(pessoaJuridica.usuarioCriacao);
    this.form.get('aplicacaoOrigem').setValue(pessoaJuridica.aplicacaoOrigem);
    this.form.get('dtCriacao').setValue(pessoaJuridica.dtCriacao);
    if (pessoaJuridica.natJuridica) {
      this.form
        .get('natJuridica.codigo')
        .setValue(pessoaJuridica.natJuridica.codigo);
      this.form
        .get('natJuridica.descricao')
        .setValue(pessoaJuridica.natJuridica.descricao);
    }
    if (pessoaJuridica.pessoa) {
      this.form.get('pessoa.id').setValue(pessoaJuridica.pessoa.id);
      this.form
        .get('pessoa.tipoPessoa')
        .setValue(pessoaJuridica.pessoa.tipoPessoa);
      this.form
        .get('pessoa.usuarioCriacao')
        .setValue(pessoaJuridica.pessoa.usuarioCriacao);
      this.form
        .get('pessoa.aplicacaoOrigem')
        .setValue(pessoaJuridica.pessoa.aplicacaoOrigem);
    }

    for (const index in pessoaJuridica.contatos) {
      if (pessoaJuridica.contatos[index]) {
        if (!this.form.get(`contatos.${index}`)) {
          this.addContato();
        }
        this.form.get(`contatos.${index}`).patchValue({
          ...pessoaJuridica.contatos[index],
          whatsapp: pessoaJuridica.contatos[index].whatsapp === 1,
          telegram: pessoaJuridica.contatos[index].telegram === 1,
          principal: pessoaJuridica.contatos[index].principal === 1,
        });
      }
    }

    for (const index in pessoaJuridica.pessoaResponsaveis) {
      if (pessoaJuridica.pessoaResponsaveis[index]) {
        let object;
        if (!this.form.get(`pessoaResponsaveis.${index}`)) {
          await this.addResponsavel();
        }
        await this.service
          .getPessoaFisicaById(
            pessoaJuridica.pessoaResponsaveis[index].idPessoaFisica
          )
          .then((resp: any) => {
            object = Object.assign({}, pessoaJuridica, resp.data);
          });
        this.form.get(`pessoaResponsaveis.${index}`).patchValue({
          id: object?.pessoaResponsaveis[index].id,
          cpf: object?.cpf,
          nome: object?.pessoa?.nomPessoa,
          email: object?.pessoa?.emailPrincipal,
          idPessoaFisica: object?.pessoaResponsaveis[index].id_pessoa_fisica,
          aplicacaoOrigem: 1,
          usuarioCriacao: this.authService.getIdUser(),
        });
      }
    }
    for (const index in pessoaJuridica.pessoaJuridicaCnae) {
      if (pessoaJuridica.pessoaJuridicaCnae[index].idCnaeSubclasse) {
        if (!this.form.get(`pessoaJuridicaCnae.${index}`)) {
          await this.addCnae();
        }
        await this.service
          .consultaCnaePorId(
            pessoaJuridica.pessoaJuridicaCnae[index].idCnaeSubclasse
          )
          .then((result) => {
            const pJuridicaCnae = result.data;
            (this.form.get(`pessoaJuridicaCnae`) as any).controls[
              index
            ].patchValue({
              id: pessoaJuridica.pessoaJuridicaCnae[index].id,
              idPessoaJuridica: pessoaJuridica.id,
              idCnaeSubclasse: pJuridicaCnae.id_cnae_classe,
              principal:
                pessoaJuridica.pessoaJuridicaCnae[index].principal ?? 0,
              codigo: pJuridicaCnae.codigo,
              nome: pJuridicaCnae.nomeSubclasse,
              usuarioCriacao: this.authService.getIdUser(),
              aplicacaoOrigem:
                pessoaJuridica.pessoaJuridicaCnae[index].aplicacaoOrigem,
            });
          });
      }
    }

    for (const index in pessoaJuridica.enderecos) {
      if (pessoaJuridica.enderecos[index]) {
        if (!this.form.get(`enderecos.${index}`)) {
          this.addEndereco();
        }
        this.form.get(`enderecos.${index}`).patchValue({
          ...pessoaJuridica.enderecos[index],
          principal: pessoaJuridica.enderecos[index].principal === 1,
        });
      }
    }
    this.cadastroBasico = !pessoaJuridica.dtAbertura;
    this.changeTabIndex(0);
    this.loadingPage = false;
  }

  addEndereco() {
    const enderecoForm: FormArray = this.form.get('enderecos') as FormArray;
    enderecoForm.push(
      this.formBuilder.group({
        id: [null],
        principal: [enderecoForm.length === 0 ? true : null],
        cep: [null],
        numero: [null],
        complemento: [null],
        nomCidade: [{ value: null, disabled: true }],
        codigoIbgeCidade: [null],
        nomBairro: [{ value: null, disabled: true }],
        nomLogradouro: [{ value: null, disabled: true }],
        nomUf: [{ value: null, disabled: true }],
        error: [false],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  addResponsavel() {
    const responsavelForm: FormArray = this.form.get(
      'pessoaResponsaveis'
    ) as FormArray;

    responsavelForm.push(
      this.formBuilder.group({
        id: [null],
        cpf: [null, [Validators.required, Validators.required]],
        nome: [{ value: null, disabled: true }],
        email: [{ value: null, disabled: true }],
        idPessoaFisica: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  addContato() {
    const contatoForm: FormArray = this.form.get('contatos') as FormArray;
    contatoForm.push(
      this.formBuilder.group({
        id: [null],
        principal: [contatoForm.length === 0 ? true : null],
        email: [null, [Validators.required, Validators.email]],
        telefone: [null],
        whatsapp: [null],
        telegram: [null],
        maskTelefone: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  addCnae() {
    const cnaeForm: FormArray = this.form.get(
      'pessoaJuridicaCnae'
    ) as FormArray;

    cnaeForm.push(
      this.formBuilder.group({
        id: [null],
        idCnaeSubclasse: [null],
        principal: [false],
        codigo: [null],
        nome: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
        versao: ['2.3'],
      })
    );
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'save',
        tooltip: this.form.value.id ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 && (!this.form.value.id || this.form.value.id),
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterPessoaJuridicaOptionsSelect(item) {}

  novoCadastro = () => {
    this.changeTabIndex(0);
    return this.resetForms();
  };

  salvar = () => {
    this.onSubmit();
    // this.atualizar()
  };

  editar = () => {
    const pessoasJuridicas = this.displayData.filter(
      (pessoas) => pessoas.checked
    );
    if (pessoasJuridicas.length !== 1) {
      return this.service.notification.warning(
        'Pessoa física',
        'Muitos registro selecionados ou nenhum.'
      );
    }
    const pessoaJuridica = pessoasJuridicas[0];
    this.prepareToUpdateForm(pessoaJuridica);
  };

  editarPessoaJuridica = (item) => {
    this.prepareToUpdateForm(item);
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  selecionar = () => {
    this.selecionarItem(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterPessoaJuridica();
  };

  doubleClick = (item) => {
    this.openModal
      ? this.selecionarItem(item)
      : this.editarPessoaJuridica(item);
  };

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  selecionarItem(item) {
    this.modalRef.destroy(item);
  }

  ngOnInit(): void {
    this.consultaNaturezaJuridica();
    this.consultaCnae();
    if (this.openModal) {
      this.resetFabButtons();
      this.atualizar();
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterPessoaJuridica() {
    return this.getPessoaJuridicaPerPeriod(this.fieldsPessoasJuridicasSelected);
  }

  async getPessoaJuridicaPerPeriod(
    fieldsPessoasJuridicasSelected,
    page?: number,
    size?: number
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsPessoasJuridicasSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (pessoajuridica: any) => {
        if (pessoajuridica.data.length === 0) {
          this.service.notification.warning(
            this.entidade,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = pessoajuridica.data.paramsPaginator[0];
        this.displayData = pessoajuridica.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error('Pessoa jurídica', err);
        this.loadingPage = false;
      });
  }

  async pageChange(valor) {
    await this.getPessoaJuridicaPerPeriod(
      this.fieldsPessoasJuridicasSelected,
      Number(valor),
      10
    );
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }
  resetContato() {
    const contatoForm: FormArray = this.form.get('contatos') as FormArray;
    contatoForm.controls.splice(0, contatoForm.controls.length);
    this.addContato();
  }

  resetResponsavel() {
    const responsavelForm: FormArray = this.form.get(
      'pessoaResponsaveis'
    ) as FormArray;
    responsavelForm.controls.splice(0, responsavelForm.controls.length);
    this.addResponsavel();
  }

  resetEndereco() {
    const enderecoForm: FormArray = this.form.get('enderecos') as FormArray;
    enderecoForm.controls.splice(0, enderecoForm.controls.length);
    this.addEndereco();
  }

  resetCnae() {
    const cnaeForm: FormArray = this.form.get(
      'pessoaJuridicaCnae'
    ) as FormArray;

    cnaeForm.controls.splice(0, cnaeForm.controls.length);
    this.addCnae();
  }

  resetForms() {
    this.resetForm();
    this.submitted = false;
    this.showForm = !this.showForm;
    this.submitted = false;
    this.resetContato();
    this.resetEndereco();
    this.resetCnae();
    this.selectedValueCnae = {};
  }

  verifyBasic(): boolean {
    if (
      this.form.get('cnpj')?.errors?.required ||
      this.form.get('razaoSocial')?.errors?.required ||
      this.cepNaoEncontrado ||
      this.enderecoNaoEncontrado
    ) {
      return false;
    }
    return true;
  }

  async onSubmit() {
    this.submitted = true;
    this.loadingPage = true;

    if (this.cadastroBasico) {
      if (!this.verifyBasic()) {
        this.loadingPage = false;

        return;
      }
    } else if (!this.form.valid) {
      this.loadingPage = false;
      return;
    }

    const pessoaJuridica = this.prepareToRequest(
      this.form,
      this.cadastroBasico
    );
    await this.execute(pessoaJuridica, null, 'pessoa-juridica');
    this.loadingPage = false;
    return;
  }

  removerRegistro(pessoaJuridica, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(pessoaJuridica, entidade);
        this.loadingPage = false;
      },
    });
  }

  verificaContatoVazio(contatos: any): boolean {
    return (
      contatos.filter((contato) => contato.email && contato.email !== '')
        .length > 0
    );
  }

  async execute(registro, idMp = null, entidade = null) {
    const usCriacao = this.authService.getIdUser();
    registro = {
      ...registro,
      contatos: registro.contatos.map((contato) => {
        return { ...contato, usuarioCriacao: usCriacao };
      }),
      enderecos: registro.enderecos.map((enderecos) => {
        return { ...enderecos, usuarioCriacao: usCriacao };
      }),
      pessoaJuridicaCnae: registro.pessoaJuridicaCnae.map((cnae) => {
        return { ...cnae, codigo: cnae.codigo };
      }),
    };

    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        await this.service.insertOrUpdate(registro, idMp, entidade);
        return this.resetForms();
      },
    });
  }

  pessoaFormToPessoaJuridica(form) {
    form.get('enderecos').controls.map((endereco) => {
      endereco.get('nomLogradouro').enable();
      endereco.get('nomCidade').enable();
      endereco.get('nomBairro').enable();
      endereco.get('nomUf').enable();
    });
    form.value.enderecos = form.value.enderecos.filter(
      (endereco) => endereco.cep
    );
    return form.value;
  }

  verificaCheckBox(pessoaJuridica) {
    pessoaJuridica.contatos.map((contato) => {
      contato.principal = contato.principal ? 1 : 0;
      contato.whatsapp = contato.whatsapp ? 1 : 0;
      contato.telegram = contato.telegram ? 1 : 0;
    });
    pessoaJuridica.enderecos.map((endereco) => {
      endereco.principal = endereco.principal ? 1 : 0;
    });
    pessoaJuridica.pessoaJuridicaCnae.map((cnae) => {
      cnae.principal = cnae.principal ? 1 : 0;
    });
  }

  prepareToRequest(pessoaJuridica, cadastroBasico = false) {
    pessoaJuridica = this.pessoaFormToPessoaJuridica(pessoaJuridica);
    this.verificaCheckBox(pessoaJuridica);
    if (!pessoaJuridica.pessoa.id) {
      delete pessoaJuridica.pessoa.id;
    }
    pessoaJuridica.contatos.map((contato) =>
      !contato.id ? delete contato.id : null
    );
    pessoaJuridica.enderecos.map((endereco) =>
      !endereco.id ? delete endereco.id : null
    );
    pessoaJuridica.pessoaJuridicaCnae.map((cnae) =>
      !cnae.id ? delete cnae.id : null
    );

    pessoaJuridica.pessoaResponsaveis.map((responsavel) =>
      !responsavel.id ? delete responsavel.id : null
    );

    // if (cadastroBasico) {
    //   delete pessoaJuridica.contatos;
    // }

    return pessoaJuridica;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldPessoasJuridicasSelected && this.searchInput) {
      if (this.fieldPessoasJuridicasSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldPessoasJuridicasSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsPessoasJuridicasSelected.length !== 0) {
        this.removeFiltroPessoaJuridicaPorField(
          this.fieldsPessoasJuridicasSelected[0]
        );
      }
      this.fieldsPessoasJuridicasSelected.push({
        props: this.fieldPessoasJuridicasSelected.value,
        field: this.fieldPessoasJuridicasSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldPessoasJuridicasSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsPessoasJuridicasSelect.findIndex(
        (a) => a.value === this.fieldPessoasJuridicasSelected.value
      );

      this.arrColumnsPessoasJuridicasSelect.splice(index, 1);

      this.showComboTagsPessoaJuridica = true;
      this.fieldPessoasJuridicasSelected = '';
      this.searchInput = '';
      this.selectedPessoasJuridicasValues = [];
    }
  }
  removeFiltroPessoaJuridicaPorField(fieldsValuesTag) {
    const tableExportColumn = this.tablePessoaJuridicaColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsPessoasJuridicasSelect.splice(
      this.tablePessoaJuridicaColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsPessoasJuridicasSelected.splice(
      this.fieldsPessoasJuridicasSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsPessoasJuridicasSelected.length === 0) {
      this.showComboTagsPessoaJuridica = false;
    }
  }

  verificaCnaeVazio(pessoaJuridicaCnae): boolean {
    return (
      pessoaJuridicaCnae.filter((cnae) => cnae.codigo && cnae.codigo !== '')
        .length > 0
    );
  }

  setMaskFone(index, exit = null) {
    this.form
      ?.get(`contatos.${index}.maskTelefone`)
      .setValue(
        this.form?.get(`contatos.${index}.telefone`).value &&
          this.form?.get(`contatos.${index}.telefone`).value[10]
          ? '(00) 00000-0000'
          : exit
          ? '(00) 0000-0000'
          : '(00) 0000-00000'
      );
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }
}
