import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notification: NzNotificationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else if (error.error instanceof ArrayBuffer) {
          const utf8Decoder = new TextDecoder();
          const obj = JSON.parse(utf8Decoder.decode(new Uint8Array(error.error)));
          errorMessage = obj.message;
        } else {
          errorMessage = `${error.error.message} (${error.status})`;
        }

        return throwError(errorMessage);
      })
    );
  }
}
