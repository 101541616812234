import { NzModalRef } from 'ng-zorro-antd/modal';

interface IModal {
  destroyModal(result: any): void;
}

export abstract class Modal implements IModal {
  constructor(protected modal: NzModalRef) {}
  destroyModal(result: any) {
    this.modal.destroy(result);
  }
}
