import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-form-tabs',
  template: `
    <nz-tabset>
      <nz-tab
        *ngFor="let tab of field.fieldGroup; let i = index; let last = last"
        [nzTitle]="tab.templateOptions.label"
      >
        <formly-field [field]="tab"></formly-field>
      </nz-tab>
    </nz-tabset>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PsFormTabsComponent extends FieldType {
  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every((f) => this.isValid(f));
  }
}
