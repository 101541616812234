<nz-card
  style="width: 100%"
  [nzTitle]="title"
  [nzExtra]="extra"
>
  <ng-content></ng-content>
</nz-card>

<ng-template #extra>
  <ps-fab [actions]="actions"></ps-fab>
</ng-template>