import { Component, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PessoaService } from '../shared/services/pessoa.service';
import { AuthService } from './../shared/services/auth.service';
import { PessoaFisicaService } from './../shared/services/pessoa-fisica.service';

@Component({
  selector: 'ps-painel-administrativo-app-pessoa-fisica',
  templateUrl: './pessoa-fisica.component.html',
  styleUrls: ['./pessoa-fisica.component.scss'],
})
export class PessoaFisicaComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  formB: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData: any[] = [];
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    nomPessoa: true,
    cpf: true,
  };
  enderecoNaoEncontrado;
  pageTotal: number;
  naturalidadeValue: string;
  cadastroBasico = false;
  submitted = false;
  public messageEnderecoNaoEncontrado: string;
  public cidadeNaoEncontrada = false;
  public messageCidadeNaoEncontrada: string;
  intervaloDigitando: any;
  optionsCidade: any[];
  selectedValue;
  nzFilterOption = (): boolean => true;
  openModal: boolean = false;

  //example, trocar para o nome da entidade arrColumnsPessoasFisicasSelect
  tablePessoaFisicaColumns = [];
  titulo: string = 'Pessoa Física';
  entidade: string = 'pessoa-fisica';
  fieldPessoasFisicasSelected;
  arrColumnsPessoasFisicasSelect: any[];
  showComboTagsExemplos: any[];
  selectedPessoasFisicasValues;
  showComboTagsPessoasFisicas;
  searchInput: any;
  fieldsPessoasFisicasSelected = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<PessoaFisicaComponent>,
    public service: PessoaFisicaService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService,
    public pessoaService: PessoaService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      await this.setConfigPessoaFisicaTable();
      await this.setConfigPessoaFisicaFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  verificaContatoVazio(contatos: any): boolean {
    return (
      contatos.filter((contato) => contato.email && contato.email !== '')
        .length > 0
    );
  }
  verificaEnderecoPrincipal(principal) {
    return (
      this.formB.get('enderecos').value.filter((c) => c.principal).length ===
        0 || principal
    );
  }

  changeTipoCadastro(valor: boolean) {
    this.cadastroBasico = valor;
  }

  addEndereco() {
    const enderecoForm: FormArray = this.formB.get('enderecos') as FormArray;
    enderecoForm.push(
      this.formBuilder.group({
        id: [null],
        principal: [enderecoForm.length === 0 ? true : null],
        cep: [null],
        numero: [null],
        complemento: [null],
        codigoIbgeCidade: [null],
        nomCidade: [{ value: null, disabled: true }],
        nomBairro: [{ value: null, disabled: true }],
        nomLogradouro: [{ value: null, disabled: true }],
        nomUf: [{ value: null, disabled: true }],
        error: [false],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  addContato() {
    const contatoForm: FormArray = this.formB.get('contatos') as FormArray;
    contatoForm.push(
      this.formBuilder.group({
        id: [null],
        principal: [contatoForm.length === 0 ? true : null],
        email: [null, [Validators.required, Validators.email]],
        telefone: [null],
        whatsapp: [false],
        telegram: [null],
        maskTelefone: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  async setConfigPessoaFisicaFilter() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      this.loadingPage = false;
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tablePessoaFisicaColumns
      );
    });
  }

  async setConfigPessoaFisicaTable() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      this.loadingPage = false;
      const tableColumnsPessoasFisicas = result.tableColumnsProps.map(
        (column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        }
      );
      this.tablePessoaFisicaColumns = tableColumnsPessoasFisicas;

      this.arrColumnsPessoasFisicasSelect = result.searchColumns;
    });
  }

  setMaskFone(index, exit = null) {
    this.formB
      ?.get(`contatos.${index}.maskTelefone`)
      .setValue(
        this.formB?.get(`contatos.${index}.telefone`).value &&
          this.formB?.get(`contatos.${index}.telefone`).value[10]
          ? '(00) 00000-0000'
          : exit
          ? '(00) 0000-0000'
          : '(00) 0000-00000'
      );
  }

  removeContato(index: number) {
    if (this.formB.get(`contatos.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover contato?</i>`,
        nzOnOk: () => {
          this.loadingManutencao = true;
          this.service
            .delete(this.formB.value.contatos[index], 'pessoa-contato')
            .then(() => {
              this.loadingManutencao = false;
              const contatoForm: FormArray = this.formB.get(
                'contatos'
              ) as FormArray;
              contatoForm.removeAt(index);
            });

          // this.resetForm();
        },
      });
    }
    const contatoFormb: FormArray = this.formB.get('contatos') as FormArray;
    contatoFormb.removeAt(index);
  }

  removeEndereco(index: number) {
    if (this.formB.get(`enderecos.${index}.id`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover endereço?</i>`,
        nzOnOk: () => {
          this.loadingManutencao = true;
          this.service
            .delete(this.formB.value.enderecos[index], 'pessoa-endereco')
            .then(() => {
              this.loadingManutencao = false;
              const form: FormArray = this.formB.get('enderecos') as FormArray;
              form.removeAt(index);
            });
          // this.resetForm();
        },
      });
    }

    const formb: FormArray = this.formB.get('enderecos') as FormArray;
    formb.removeAt(index);
  }

  verificaContatoPrincipal(principal) {
    return (
      this.formB.get('contatos').value.filter((c) => c.principal).length ===
        0 || principal
    );
  }

  async consultaCep(cep, index) {
    this.enderecoNaoEncontrado = false;
    this.pessoaService
      .consultaEndereco(cep, this.formB, index)
      .then((result: any) => {
        (this.formB.get('enderecos') as any).controls[index].patchValue({
          ...result?.data[0],
          id: this.formB.get(`enderecos.${index}.id`).value,
          nomCidade: result?.data[0].localidade,
          nomBairro: result?.data[0].bairro,
          nomLogradouro: result?.data[0].logradouro,
          nomUf: result?.data[0].uf,
        });
      })
      .catch((err) => {
        this.enderecoNaoEncontrado = true;
        this.messageEnderecoNaoEncontrado = err.error.message;
        (this.formB.get('enderecos') as any).controls[index].patchValue({
          nomCidade: null,
          nomBairro: null,
          nomLogradouro: null,
          nomUf: null,
        });
      });
  }

  resetForm() {
    this.formB = this.formBuilder.group({
      id: [null], //
      dtNasc: [null], //
      nomPai: [null, Validators.required], //
      nomMae: [null, Validators.required], //
      nacionalidade: [null, Validators.required], //
      estadoCivil: [null], //
      cpf: [null, Validators.required], //
      aplicacaoOrigem: [1], //
      usuarioCriacao: [this.authService.getIdUser()], //
      dtCriacao: [new Date()], //
      naturalidade: [null], //
      rg: this.formBuilder.group({
        numero: [null], //
        dataEmissao: [null], //
        orgaoEmissor: [null], //
        via: [null], //
      }),
      passaporte: this.formBuilder.group({
        numero: [null], //
        validade: [null], //
        nomPais: [null], //
        paisOrigem: [null], //
      }),
      pessoa: this.formBuilder.group({
        id: [null], //
        nomPessoa: [null, Validators.required], //
        tipoPessoa: [0], //
        aplicacaoOrigem: [1], //
        usuarioCriacao: [this.authService.getIdUser()], //
      }),
      contatos: this.formBuilder.array([
        this.formBuilder.group({
          id: [null], //
          principal: [true], //*
          email: [null, [Validators.required, Validators.email]],
          telefone: [null], //*
          whatsapp: [false], //*
          telegram: [null], //*
          maskTelefone: [null], //*
          aplicacaoOrigem: [1], //
          usuarioCriacao: [this.authService.getIdUser()], //
        }),
      ]),
      enderecos: this.formBuilder.array([
        this.formBuilder.group({
          id: [null], //
          principal: [true],
          cep: [null], //
          numero: [null], //
          complemento: [null], //
          codigoIbgeCidade: [null], //
          nomCidade: [{ value: null, disabled: true }], //
          nomBairro: [{ value: null, disabled: true }], //
          nomLogradouro: [{ value: null, disabled: true }], //
          nomUf: [{ value: null, disabled: true }], //
          error: [false], //
          aplicacaoOrigem: [1], //
          usuarioCriacao: [this.authService.getIdUser()], //
        }),
      ]),
    });
  }

  toggleView() {
    this.reset();
  }
  resetContato() {
    const contatoForm: FormArray = this.formB.get('contatos') as FormArray;
    contatoForm.controls.splice(0, contatoForm.controls.length);
    this.addContato();
  }
  resetEndereco() {
    const enderecoForm: FormArray = this.formB.get('enderecos') as FormArray;
    enderecoForm.controls.splice(0, enderecoForm.controls.length);
    this.addEndereco();
  }

  reset() {
    this.resetForm();
    this.submitted = false;
    // this.showForm = !this.showForm;
    this.resetContato();
    this.resetEndereco();

    this.cidadeNaoEncontrada = false;
    this.messageCidadeNaoEncontrada = '';
  }

  async onSubmit() {
    this.submitted = true;

    if (!this.formB.valid && !this.cadastroBasico) {
      return;
    }

    if (
      !this.cadastroBasico &&
      (this.pessoaService.enderecoRepitido(this.formB) ||
        this.pessoaService.verificaPassaporte(this.formB))
    ) {
      return;
    }
    const pessoaFisica = this.prepareToRequest(this.formB);
    return await this.execute(pessoaFisica);
  }

  async execute(registro, idMp = null, entidade = null) {
    const usCriacao = this.authService.getIdUser();
    registro = {
      ...registro,
      contatos: registro.contatos.map((contato) => {
        return { ...contato, usuarioCriacao: usCriacao };
      }),
      enderecos: registro.enderecos.map((enderecos) => {
        return { ...enderecos, usuarioCriacao: usCriacao };
      }),
    };
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        await this.service.insertOrUpdate(registro, idMp, 'pessoa-fisica');
        return this.reset();
      },
    });
  }

  prepareToRequest(pessoaFisica) {
    pessoaFisica = this.pessoaFormToPessoaFisica(pessoaFisica);
    this.verificaCheckBox(pessoaFisica);
    if (!pessoaFisica.pessoa.id) {
      delete pessoaFisica.pessoa.id;
    }
    pessoaFisica.contatos.map((contato) =>
      !contato.id ? delete contato.id : null
    );
    pessoaFisica.enderecos.map((endereco) =>
      !endereco.id ? delete endereco.id : null
    );
    pessoaFisica.dtNasc = pessoaFisica.dtNasc ? pessoaFisica.dtNasc : null;
    pessoaFisica.rg.dataEmissao = pessoaFisica.rg.dataEmissao
      ? pessoaFisica.rg.dataEmissao
      : null;
    return pessoaFisica;
  }

  pessoaFormToPessoaFisica(pessoaFisica) {
    pessoaFisica.get('enderecos').controls.map((endereco) => {
      endereco.get('nomLogradouro').enable();
      endereco.get('nomCidade').enable();
      endereco.get('nomBairro').enable();
      endereco.get('nomUf').enable();
    });
    pessoaFisica.value.enderecos = pessoaFisica.value.enderecos.filter(
      (endereco) => endereco.cep
    );
    return pessoaFisica.value;
  }

  verificaCheckBox(pessoaFisica) {
    pessoaFisica.contatos.map((contato) => {
      contato.principal = contato.principal ? 1 : 0;
      contato.whatsapp = contato.whatsapp ? 1 : 0;
      contato.telegram = contato.telegram ? 1 : 0;
    });
    pessoaFisica.enderecos.map((endereco) => {
      endereco.cep ? (endereco.principal = endereco.principal ? 1 : 0) : null;
    });
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'save',
        tooltip: this.formB.value.id ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 && (!this.formB.value.id || this.formB.value.id),
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      ,
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterPessoaFisicaOptionsSelect(item) {}

  novoCadastro = () => {
    this.reset();

    return this.changeTabIndex(0);
  };

  selecionar = () => {
    this.modalRef.destroy(this.getRegistrySelected());
  };

  salvar = () => {
    this.loadingManutencao = true;
    this.onSubmit();
    this.loadingManutencao = false;
  };

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  editarPessoaFisica = (pessoaFisica) => {
    this.prepareToUpdateForm(pessoaFisica);
  };

  doubleClick(item) {
    this.openModal ? this.selectItem(item) : this.editarPessoaFisica(item);
  }

  deletar = () => {
    return this.modalService.confirm({
      nzTitle: `<i>Deletar Pessoa física?</i>`,
      nzOnOk: async () => {
        this.loadingTable = true;
        await this.service.delete(this.getRegistrySelected(), 'pessoa-fisica');
        this.loadingTable = false;
        return this.reset();
      },
    });
  };
  consultar = () => {
    this.changeTabIndex(1);
  };

  atualizar = () => {
    this.changeTabIndex(1);
    this.filterPessoaFisica();
  };

  prepareToUpdateForm(pessoaFisica) {
    this.formB.get('id').setValue(pessoaFisica.id);
    this.formB.get('dtNasc').setValue(pessoaFisica.dtNasc);
    this.formB.get('nomPai').setValue(pessoaFisica.nomPai);
    this.formB.get('nomMae').setValue(pessoaFisica.nomMae);
    this.formB.get('nacionalidade').setValue(pessoaFisica.nacionalidade);
    this.formB.get('estadoCivil').setValue(pessoaFisica.estadoCivil);
    this.formB.get('cpf').setValue(pessoaFisica.cpf);
    pessoaFisica.naturalidade.texto = `${pessoaFisica.naturalidade.nomCidade} - ${pessoaFisica.naturalidade.estado}`;

    this.naturalidadeValue = pessoaFisica.naturalidade;
    this.formB.get('naturalidade').setValue(pessoaFisica.naturalidade);
    this.formB.get('usuarioCriacao').setValue(pessoaFisica.usuarioCriacao);
    this.formB.get('aplicacaoOrigem').setValue(1);
    this.formB.get('dtCriacao').setValue(pessoaFisica.dtCriacao);

    if (pessoaFisica.rg) {
      this.formB.get('rg.numero').setValue(pessoaFisica.rg.numero);
      this.formB.get('rg.dataEmissao').setValue(pessoaFisica.rg.dataEmissao);
      this.formB.get('rg.orgaoEmissor').setValue(pessoaFisica.rg.orgaoEmissor);
      this.formB.get('rg.via').setValue(pessoaFisica.rg.via);
    }

    if (pessoaFisica.passaporte) {
      this.formB
        .get('passaporte.numero')
        .setValue(pessoaFisica.passaporte.numero);
      this.formB
        .get('passaporte.validade')
        .setValue(pessoaFisica.passaporte.validade);
      this.formB
        .get('passaporte.nomPais')
        .setValue(pessoaFisica.passaporte.nomPais);
      this.formB
        .get('passaporte.paisOrigem')
        .setValue(pessoaFisica.passaporte.paisOrigem);
    }

    if (pessoaFisica.pessoa) {
      this.formB.get('pessoa.id').setValue(pessoaFisica.pessoa.id);
      this.formB
        .get('pessoa.nomPessoa')
        .setValue(pessoaFisica.pessoa.nomPessoa);
      this.formB
        .get('pessoa.tipoPessoa')
        .setValue(pessoaFisica.pessoa.tipoPessoa);
      this.formB
        .get('pessoa.usuarioCriacao')
        .setValue(pessoaFisica.pessoa.usuarioCriacao);
      this.formB.get('pessoa.aplicacaoOrigem').setValue(1);
    }

    for (const index in pessoaFisica.contatos) {
      if (pessoaFisica.contatos[index]) {
        if (!this.formB.get(`contatos.${index}`)) {
          this.addContato();
        }
        this.formB.get(`contatos.${index}`).patchValue({
          ...pessoaFisica.contatos[index],
          aplicacaoOrigem: pessoaFisica.contatos[index].aplicacaoOrigem = 1,
          whatsapp: pessoaFisica.contatos[index].whatsapp === 1,
          telegram: pessoaFisica.contatos[index].telegram === 1,
          principal: pessoaFisica.contatos[index].principal === 1,
        });
      }
    }

    for (const index in pessoaFisica.enderecos) {
      if (pessoaFisica.enderecos[index]) {
        if (!this.formB.get(`enderecos.${index}`)) {
          this.addEndereco();
        }
        this.formB.get(`enderecos.${index}`).patchValue({
          ...pessoaFisica.enderecos[index],
          principal: pessoaFisica.enderecos[index].principal === 1,
        });
      }
    }
    this.cadastroBasico = !pessoaFisica.nomMae;
    // this.showForm = true;
    this.changeTabIndex(0);
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.filterPessoaFisica();
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  check(item?) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  async consultaCidade(evento) {
    clearTimeout(this.intervaloDigitando);
    this.cidadeNaoEncontrada = false;
    this.intervaloDigitando = await setTimeout(async () => {
      this.service
        .consultaCidade(evento, this.formB)
        .then((result) => {
          this.optionsCidade = [];
          result?.data.resultado.map((cidade) => {
            this.optionsCidade.push({
              texto: `${cidade.nomCidade} - ${cidade.idUf.sigla}`,
              nomCidade: cidade.nomCidade,
              estado: cidade.idUf.sigla,
            });
          });
        })
        .catch((err) => {
          this.cidadeNaoEncontrada = true;
          this.messageCidadeNaoEncontrada = err.message;
          this.optionsCidade = err.data;
        });
    }, 500);
  }

  filterPessoaFisica() {
    this.getPessoasFisicasPerPeriod(this.fieldsPessoasFisicasSelected);

    return;
  }

  async pageChange(valor) {
    await this.getPessoasFisicasPerPeriod(
      this.fieldsPessoasFisicasSelected,
      Number(valor),
      10
    );
  }

  async getPessoasFisicasPerPeriod(
    fieldsPessoasFisicasSelected,
    page?: number,
    size?: number
  ) {
    this.loadingTable = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsPessoasFisicasSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (result: any) => {
        this.loadingTable = false;
        if (result.data.length === 0) {
          this.service.notification.warning(
            this.entidade,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = result.data.paramsPaginator[0];

        this.displayData = result.data.resultado.map((pessoaFisica) => {
          return {
            ...pessoaFisica,
            nomPessoa: pessoaFisica.pessoa.nome,
          };
        });

        this.loadingPage = false;
      })
      .catch((err) => {
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldPessoasFisicasSelected && this.searchInput) {
      if (this.fieldPessoasFisicasSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldPessoasFisicasSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsPessoasFisicasSelected.length !== 0) {
        this.removeFiltroPessoaFisicaPorField(
          this.fieldsPessoasFisicasSelected[0]
        );
      }

      // this.fieldsPessoasFisicasSelected = [];
      this.fieldsPessoasFisicasSelected.push({
        props: this.fieldPessoasFisicasSelected.value,
        field: this.fieldPessoasFisicasSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldPessoasFisicasSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsPessoasFisicasSelect.findIndex(
        (a) => a.value === this.fieldPessoasFisicasSelected.value
      );

      this.arrColumnsPessoasFisicasSelect.splice(index, 1);

      this.showComboTagsPessoasFisicas = true;
      this.fieldPessoasFisicasSelected = '';
      this.searchInput = '';
      this.selectedPessoasFisicasValues = [];
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  removeFiltroPessoaFisicaPorField(fieldsValuesTag) {
    const tableExportColumn = this.tablePessoaFisicaColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsPessoasFisicasSelect.splice(
      this.tablePessoaFisicaColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsPessoasFisicasSelected.splice(
      this.fieldsPessoasFisicasSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsPessoasFisicasSelected.length === 0) {
      this.showComboTagsPessoasFisicas = false;
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(pessoaFisica) {
    // o que isso faz: novo recurso do javascript usado para remover uma key de um objeto
    this.modalRef.destroy(pessoaFisica);
  }
}
