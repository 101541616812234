import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Helper } from '../shared/Helper';
import { AuthService } from '../shared/services/auth.service';
import { ServidorCloudService } from '../shared/services/servidor-cloud.service';

@Component({
  selector: 'ps-servidor-cloud',
  templateUrl: './servidor-cloud.component.html',
  styleUrls: ['./servidor-cloud.component.scss'],
})
export class ServidorCloudComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;

  openModal: boolean;

  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    nomeServidor: true,
    IPDNS: true,
    portaServidor: true,
    ativo: true,
    dtCriacao: true,
    dtAlteracao: true,
  };
  validateStatus;
  public servidorCloudAtivo: boolean;

  public pageIndex: number;
  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableServidorCloudColumns = [];
  titulo: string = 'Servidores Cloud';
  entidade: string = 'servidor-cloud';
  fieldServidorCloudsSelected;
  arrColumnsServidorCloudsSelect: any[];
  showComboTagsServidorClouds: any[];
  selectedServidorCloudsValues;
  showComboTagsServidorCloud;
  searchInput: any;
  fieldsServidoresCloudsSelected = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<any>,
    private service: ServidorCloudService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigExampleTable();
      await this.setConfigExampleFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigExampleFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableServidorCloudColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigExampleTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsExamples = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'idPublico' || column.value === 'portaServidor'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            width:
              column.value === 'idPublico'
                ? '5rem'
                : column.value === 'nomeServidor'
                ? '15rem'
                : column.value === 'dtCriacao'
                ? '10rem'
                : column.value === 'portaServidor'
                ? '6rem'
                : column.value === 'ativo'
                ? '5rem'
                : column.value === 'IPDNS'
                ? '10rem'
                : column.value === 'dtAlteracao'
                ? '10rem'
                : null,
          };
        });
        this.tableServidorCloudColumns = tableColumnsExamples;

        this.arrColumnsServidorCloudsSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      idPrivado: [null],
      idPublico: [{ value: null, disabled: true }],
      nomeServidor: [null, Validators.required],
      IPDNS: [null, Validators.required],
      portaServidor: [null, Validators.required],
      ativo: [this.servidorCloudAtivo],
      usCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
    });
    // this.setValidateStatus([
    //   'idPublico',
    //   'nomeServidor',
    //   'IPDNS',
    //   'portaServidor',
    // ]);
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }
  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterServidorCloudOptionsSelect(item) {}

  selecionar = () => {
    this.selectItem(this.getRegistrySelected());
  };

  novoCadastro = () => {
    this.resetForm();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    if (!this.form.valid) {
      this.service.notification.warning(
        this.titulo,
        'Preencha todos os campos!'
      );

      return;
    }

    const ServidorCloud = this.servidorCloudFormToCadastro(this.form);
    return await this.execute(ServidorCloud);
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service
          .insertOrUpdate(registro, idMp, entidade)
          .then(() => this.atualizar());
        this.loadingPage = false;
        return this.reset();
      },
    });
  }
  reset() {
    this.resetForm();
  }

  servidorCloudFormToCadastro(form) {
    const servidorCloud = form.value;
    return {
      ...servidorCloud,
      ativo: servidorCloud.ativo ? 'S' : 'N',
      idPublico: form.controls.idPublico.value,
      id: servidorCloud.idPrivado,
    };
  }
  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  async prepareToUpdateForm(servidorCloud) {
    const servidorCloudAtivo = servidorCloud.ativo === 'S';
    this.form.patchValue({
      ...servidorCloud,
      ativo: servidorCloudAtivo,
    });
    this.servidorCloudAtivo = servidorCloudAtivo;
    // this.radioValue = servidorCloud.instrumentoCriacao;

    this.changeTabIndex(0);
  }

  dbClickServidorCloud = (item) => {
    this.openModal ? this.selectItem(item) : this.prepareToUpdateForm(item);
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterServidorCloud();
  };

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterServidorCloud() {
    return this.getServidorCloudPerPeriod(this.fieldsServidoresCloudsSelected);
  }

  async pageChange(valor) {
    this.pageIndex = Number(valor);
    await this.getServidorCloudPerPeriod(
      this.fieldsServidoresCloudsSelected,
      Number(valor),
      10
    );
  }

  removeFiltroExamplePorField(fieldsValuesTag) {
    const tableExportColumn = this.tableServidorCloudColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsServidorCloudsSelect.splice(
      this.tableServidorCloudColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsServidoresCloudsSelected.splice(
      this.fieldsServidoresCloudsSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsServidoresCloudsSelected.length === 0) {
      this.showComboTagsServidorCloud = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask && value) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getServidorCloudPerPeriod(
    fieldsServidoresCloudsSelected = [],
    page: number = this.pageIndex,
    size: number = 10
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsServidoresCloudsSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (example: any) => {
        if (example.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = example.data.paramsPaginator[0];
        this.displayData = example.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldServidorCloudsSelected && this.searchInput) {
      if (this.fieldServidorCloudsSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldServidorCloudsSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsServidoresCloudsSelected.length !== 0) {
        this.removeFiltroExamplePorField(
          this.fieldsServidoresCloudsSelected[0]
        );
      }
      this.fieldsServidoresCloudsSelected.push({
        props: this.fieldServidorCloudsSelected.value,
        field: this.fieldServidorCloudsSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldServidorCloudsSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsServidorCloudsSelect.findIndex(
        (a) => a.value === this.fieldServidorCloudsSelected.value
      );

      this.arrColumnsServidorCloudsSelect.splice(index, 1);

      this.showComboTagsServidorCloud = true;
      this.fieldServidorCloudsSelected = '';
      this.searchInput = '';
      this.selectedServidorCloudsValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {
    this.modalRef.destroy(item);
  }

  removeFiltroServidorCloudPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableServidorCloudColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsServidorCloudsSelect.splice(
      this.tableServidorCloudColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsServidoresCloudsSelected.splice(
      this.fieldsServidoresCloudsSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsServidoresCloudsSelected.length === 0) {
      this.showComboTagsServidorCloud = false;
    }
  }
}
