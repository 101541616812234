<div
  class="common-layout {{ 'is-' + selectedHeaderColor }}"
  [ngClass]="{
    'is-folded': isFolded,
    'is-side-nav-dark': isSideNavDark,
    'is-expand': isExpand,
    'is-scrolled': isScrolled
  }"
>
  <ps-header
    [urlEventSource]="urlEventSource"
    [icone]="icone"
    [modulo]="modulo"
    [ugExternal]="ugExternal"
    [brasao]="brasao"
  ></ps-header>

  <ps-sidebar
    [menuItems]="menuItems"
    [menuVersion]="menuVersion"
    [version]="appVersion"
    (toggleFolder)="onToggleFolder()"
  ></ps-sidebar>

  <div class="page-container">
    <!-- <perfect-scrollbar> -->
    <div class="main-content">
      <ps-tab-session></ps-tab-session>
    </div>
    <ps-footer [config]="footerConfig"></ps-footer>
    <!-- </perfect-scrollbar> -->
  </div>
</div>

<ng-template #searchView> </ng-template>
<ng-template #quickView> </ng-template>
<ng-template #sidebarMobile> </ng-template>
