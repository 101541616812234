import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Helper } from '@ps-erp-angular/shared';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UnidadeGestoraService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('unidade-gestora', http, notification, authService);
  }

  async consultaPessoaFisica(cpf) {
    if (!Helper.validaCPF(cpf)) {
      return;
    }
    return cpf
      ? this.consultarPorParametro(
        { field: 'cpf', value: cpf },
        'pessoa-fisica',
      )
      : null;
  }

  async consultaPessoaJuridica(cnpj) {
    return cnpj
      ? this.consultarPorParametro(
        { field: 'cnpj', value: cnpj },
        'pessoa-juridica',
      )
      : null;
  }

}
