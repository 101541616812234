import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TIPOS_MODALIDADE_LICITACO } from '../consts/tipos-licitacao.enum';
import { StatusDocumento } from '../enum/const.enum';
import { Helper } from '../helper';
import { StatusDocLabel } from './../consts/document';
import { UrlUploadApiDownload } from './../consts/urls-consts';
import {
  IAssinante,
  ICertByDocumentForSign,
  IDocumento,
  IDocumentoAnexo,
  IEventTableDetailDocument,
  IInfoUpload,
  IResponseCountOnlyDocsPerPeriod,
  IUnidadeGestora,
  IUnidadeOrcamentaria,
} from './../interfaces/document-interfaces';
import { IPessoaFisica } from './../interfaces/pessoa-fisica';
import { PeriodVisualizeDoc } from './../types/document.types';
import { TypeActionsDoc } from './../types/events.types';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';
import { ModifyDocumentService } from './modify-document.service';
import { PsTabSessionService } from './ps-tab-session.service';
import { TableService } from './table.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends AbstractService {
  @Output() aClickedEvent = new EventEmitter<string>();
  // @Output() updateDsp = new EventEmitter<string>();
  // @Output() updateDspAvulso = new EventEmitter<string>();

  themeColors = this.colorConfig.get().colors;

  resultDashBoardDocuments: any[];

  statusDocLabel = StatusDocLabel;

  constructor(
    private tableSvc: TableService,
    private colorConfig: ThemeService,
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public tabSessionService: PsTabSessionService,
    private modifyDocumentService: ModifyDocumentService
  ) {
    super(
      'finishedDocument',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }
  async getDocumentByDocOriginal(docOriginal: {
    hashDocOriginal: string;
    numeroDocOriginal: string;
  }): Promise<IDocumento> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/getby/numero-doc?numeroDocOriginal=${
            docOriginal.numeroDocOriginal
              ? docOriginal['numeroDocOriginal']
              : encodeURI(docOriginal['hashDocOriginal'])
          }`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: IDocumento }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getDocumentsByIds(ids: Array<number>) {
    return this.http
      .post(
        `${this.getServer('document')}/findManyById`,
        { ids },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result) => {
        return (result as { message: string; data: any }).data;
      })
      .catch((err) => this.notification.error('Exportacao de arquivos', err));
  }

  search(documentList, inputValue): void[] {
    const data = documentList;
    return this.tableSvc.search(inputValue, data);
  }

  visualizeDocDescription(item: IDocumento) {
    return item.historicoDoc;
  }

  visualizeProcess(doc: IDocumento) {
    // Função a ser implementada com dados do processo do documento
    // const numProcesso = doc.numProcesso;
    // window.open(
    //   `https://dev.septemcompliance.com/workflow/wfrel_tracker_detail.aspx?inpCodFlowExecute=${numProcesso}`,
    // );
  }

  clickedEvent(msg: any) {
    this.aClickedEvent.emit(msg);
  }

  // async atualizarEmFila(body: any) {
  //   return await this.http
  //     .post(
  //       this.generateUrlByEnvironment(
  //         '3000',
  //         `document/update-queue`,
  //         'digitalizacao',
  //       ),
  //       { body },
  //       this.headerAuthenticateOptions(),
  //     )
  //     .toPromise()
  //     .then((result: any) => {
  //       // this.notification.success(
  //       //   'Assinatura',
  //       //   `${result.message} - Lote ${sum.reduce(
  //       //     (a, b) => a + b,
  //       //     0,
  //       //   )}/${docsLength}`,
  //       //   { nzDuration: 0 },
  //       // );
  //       return result.message;
  //     });
  // }
  // updateDisplay(msg: string) {
  //   this.updateDsp.emit(msg);
  // }

  // updateDisplayAvulso(msg: string) {
  //   this.updateDspAvulso.emit(msg);
  // }

  async assinar(
    certByDocumentForSign: ICertByDocumentForSign[],
    dateUser,
    assinarAnx,
    paramsWhere,
    allChecked = false,
    qtdDocsSelecionados = 0,
    ratificarTodos = false,
    assinarAnxDoc: boolean,
    ratificarAnexo: boolean,
    signDoc: boolean
  ): Promise<any> {
    // Assina 1 ou mais documentos
    let sum = [];
    let size = 3;

    for (let i = 0; i <= certByDocumentForSign.length; i += size) {
      const arr = certByDocumentForSign.slice(i, i + size);
      sum.push(arr.length);

      if (i === 0) {
        await this.assinarEmLote(
          arr,
          dateUser,
          assinarAnx,
          qtdDocsSelecionados
            ? qtdDocsSelecionados
            : certByDocumentForSign.length,
          sum,
          paramsWhere,
          allChecked,
          ratificarTodos,
          assinarAnxDoc,
          ratificarAnexo,
          signDoc
        );
      }

      if (arr.length > 0 && i >= 1) {
        await this.assinarEmLote(
          arr,
          dateUser,
          assinarAnx,
          qtdDocsSelecionados
            ? qtdDocsSelecionados
            : certByDocumentForSign.length,
          sum,
          paramsWhere,
          allChecked,
          ratificarTodos,
          assinarAnxDoc,
          ratificarAnexo,
          signDoc
        );
      }
    }
    return;
  }

  async assinarEmLote(
    loteArr: ICertByDocumentForSign[],
    dateUser,
    assinarAnexo,
    docsLength: number,
    sum: number[],
    paramsWhere,
    allChecked = false,
    ratificarTodos = false,
    assinarAnxDoc: boolean,
    ratificarAnx: boolean,
    signDoc: boolean
  ) {
    const checkParams = assinarAnxDoc === true ? paramsWhere : null;
    return await this.http
      .post(
        this.generateUrlByEnvironment('5017', `jobs/sign-doc`, 'digitalizacao'),
        {
          certByDocumentForSign: loteArr.map((key) => ({
            ...key,
            ratificarAnexo: ratificarAnx,
          })),
          dateUser,
          checkParams,
          assinarAnexo,
          ratificarTodos,
          signDoc,
        },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success(
          'Assinatura',
          `${result.message} - Lote ${sum.reduce(
            (a, b) => a + b,
            0
          )}/${docsLength}`,
          { nzDuration: 0 }
        );
        // return result.message;
      });
  }

  assinarAnexo(
    certByDocumentForSign: ICertByDocumentForSign[],
    dateUser
  ): Promise<any> {
    // Assina 1 ou mais documentos
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/sign-anexo`,
          'digitalizacao'
        ),
        { certByDocumentForSign, dateUser },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Assinar Anexo', result.message)
      );
  }

  findDocument(docNumber, idTipoDocumento): Promise<any> {
    const ug = JSON.parse(localStorage.getItem('unidadeGestoraSelected'));

    const encoded = encodeURIComponent(docNumber);
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '80',
          `document/number/${ug.id}/${encoded}/${idTipoDocumento}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => result.data);
  }

  // Assina 1 ou mais documentos
  discordar(
    idsParaAssinar: number[],
    motivo: string,
    discordarAnx
  ): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/disagree?discordarAnexos=${discordarAnx}`,
          'digitalizacao'
        ),
        { idsParaAssinar, motivo },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Discordar', result.message)
      );
  }

  discordarAnexo(idsParaAssinar: number[], motivo: string): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/discordar-anexo`,
          'digitalizacao'
        ),
        { idsParaAssinar, motivo },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Discordar Anexo', result.message)
      );
  }

  formatDate23hrs(date: string) {
    const systemDate = new Date(date);
    return new Date(
      systemDate.getFullYear(),
      systemDate.getMonth(),
      systemDate.getDate(),
      23,
      59,
      0
    ).toISOString();
  }

  reativarDocs(idsParaAssinar: number[], dataExp: string): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/reativar-docs`,
          'digitalizacao'
        ),
        { idsParaAssinar, dataExp },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Reativar Documentos', result.message)
      );
  }

  desfazer(
    idsParaAssinar: number[],
    motivo: string,
    desfazerAnexos
  ): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/desfazer?desfazerAnexos=${desfazerAnexos}`,
          'digitalizacao'
        ),
        { idsParaAssinar, motivo },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Desfazer desacordo', result.message)
      );
  }

  desfazerAnexo(idsParaAssinar: number[], motivo: string): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/desfazer-anexo`,
          'digitalizacao'
        ),
        { idsParaAssinar, motivo },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Desfazer desacordo', result.message)
      );
  }

  // Cancela 1 ou mais documentos
  cancel(idDoc: number, motivo: string, usuarioCriador): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/any/cancel/${idDoc}`,
          'digitalizacao'
        ),
        { motivo, usuarioCriador },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Cancelamento', result.message)
      );
  }

  cancelarAnexo(idDoc: number, motivo: string, usuarioCriador): Promise<any> {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/cancelar-anexos/${idDoc}`,
          'digitalizacao'
        ),
        { motivo, usuarioCriador },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Cancelamento', result.message)
      );
  }

  // Notifica um assinante que ele possui documentos para assinar
  async notifySubscriber(
    pessoaFisica,
    idsParaAssinar: number[]
  ): Promise<void> {
    await this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/notify/${pessoaFisica.cpf}`,
          'digitalizacao'
        ),
        { idsParaAssinar },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Notificação', result.message)
      )
      .catch((err) => {
        this.notification.error('Notificação', err);
      });
  }

  async notifySubscriberDocUpload(
    cpf,
    idsParaAssinar: string[]
  ): Promise<void> {
    await this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `document/any/notify/${cpf}`,
          'digitalizacao'
        ),
        { idsParaAssinar },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) =>
        this.notification.success('Notificação', result.message)
      );
  }

  // Consulta quantidade de docummentos organizados por periodo
  getCountDocumentsPerPeriod(
    interval?: number,
    typePeriod?: PeriodVisualizeDoc,
    customInterval?: string[],
    avulso?: boolean,
    assinantesSelected = [],
    chart = false,
    countAnexos = false
  ): Promise<IResponseCountOnlyDocsPerPeriod> {
    const paramInterval = interval ? `&interval=${interval}` : '';

    let paramAssinantes;
    let paramUsuario;
    let params2 = new HttpParams();
    let params3 = new HttpParams();
    if (assinantesSelected.length > 0 && assinantesSelected.length <= 1) {
      paramAssinantes = `&assinante=${
        assinantesSelected[0].idPessoaFisica ||
        assinantesSelected[0].idResponsavel
      }`;
      paramUsuario = `&usuario=${assinantesSelected[0].idUsuario}`;
    }
    if (assinantesSelected.length > 1) {
      const newArr = assinantesSelected.map((ass) =>
        Number(ass.idPessoaFisica)
      );
      const newUsersArr = assinantesSelected.map((ass) =>
        Number(ass.idUsuario)
      );
      params2 = params2.set(
        'signers',
        (paramAssinantes = `&assinante=${newArr}`)
      );
      params3 = params3.set(
        'users',
        (paramUsuario = `&usuario=${newUsersArr}`)
      );
    }

    const paramCustomInterval = customInterval
      ? `&initialDate=${customInterval[0]}&finalDate=${customInterval[1]}`
      : ``;
    const type = typePeriod ? `type=${typePeriod}` : '';
    const typeDocument = avulso ? `&avulso=${avulso}` : '';
    const signers = paramAssinantes ? paramAssinantes : '';
    const users = paramUsuario ? paramUsuario : '';
    const charts = chart ? `&chart=${chart}` : '';
    const params = `${type}${paramInterval}${paramCustomInterval}${typeDocument}${signers}${users}${charts}`;
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `${countAnexos ? `documento-anexo` : `document`}/countOnly?${params}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: IResponseCountOnlyDocsPerPeriod) => result);
  }

  getAllDocumentsInInterval(
    typePeriod?: PeriodVisualizeDoc,
    assinantesSelected = [],
    interval?: number,
    avulsos?: boolean,
    customInterval?: string[],
    statusFiltros?: number[],
    fieldsSelected = [],
    checkAll = false,
    skip = 1,
    take = 10,
    searchAnexos = false,
    modulo?: number
  ): Promise<any> {
    let paramAssinantes;
    let paramUsuario;
    let paramStatus;
    if (assinantesSelected.length > 0 && assinantesSelected.length <= 1) {
      paramAssinantes =
        assinantesSelected[0].idPessoaFisica ||
        assinantesSelected[0].getIdResponsavel;

      paramUsuario = assinantesSelected[0].idUsuario;
    }

    if (assinantesSelected.length > 1) {
      const newArr = assinantesSelected.map((ass) =>
        Number(ass.idPessoaFisica)
      );
      const newUsersArr = assinantesSelected.map((ass) =>
        Number(ass.idUsuario)
      );
      (paramAssinantes = newArr), (paramUsuario = newUsersArr);
    }

    // if(fieldsSelected.length > 0)  {
    //   const newArr = assinantesSelected.map((ass) =>
    //   Number(ass.idPessoaFisica),
    // );
    // const newUsersArr = assinantesSelected.map((ass) =>
    //   Number(ass.idUsuario),
    // );
    // params2 = params2.set(
    //   'signers',
    //   (paramAssinantes = `&assinante=${newArr}`),
    // );
    // params3 = params3.set(
    //   'users',
    //   (paramUsuario = `&usuario=${newUsersArr}`),
    // );
    // }

    if (statusFiltros?.length > 0 && statusFiltros?.length <= 1) {
      paramStatus = statusFiltros[0];
    }

    if (statusFiltros?.length > 1) {
      paramStatus = statusFiltros;
    }

    const newObject = {
      paramInterval: interval,
      initialDate: customInterval ? customInterval[0] : null,
      finalDate: customInterval ? customInterval[1] : null,
      type: typePeriod,
      avulso: avulsos,
      assinante: paramAssinantes ? paramAssinantes : null,
      usuario: paramUsuario ? paramUsuario : null,
      status: paramStatus,
      filtrosCombinados: fieldsSelected.filter(
        (column) => !['assinante'].includes(column.props)
      ),
      selectAnexos: searchAnexos ? true : false,
      idModulo: modulo,
      todos: checkAll,
    };

    const paramsPaginator = skip ? `skip=${skip}&take=${take}` : '';
    // const params = `${type}${paramInterval}${paramCustomInterval}${signers}${users}${avulso}${status}${paramsPaginator}`;
    // const params = `${type}${paramInterval}${paramCustomInterval}${signers}${users}${avulso}${status}`;

    return this.http
      .post(
        this.generateUrlByEnvironment(
          '5017',
          `${
            searchAnexos ? `documento-anexo` : `document`
          }/params?${paramsPaginator}`,
          'digitalizacao'
        ),
        { newObject },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return {
          data: result.data,
          where: newObject,
        };
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  getDocumentById(idDocument: number): Promise<IDocumento> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/${idDocument}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: IDocumento }) => result.data);
  }

  getDocumentByPsHash(psHash: string): Promise<IDocumento> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `conteudo/${psHash}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: IDocumento }) => result.data);
  }

  getAllAnexosByDocument(idDocument: number): Promise<IDocumentoAnexo[]> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `documento-anexo/anexos/${idDocument}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(
        (result: { message: string; data: IDocumentoAnexo[] }) => result.data
      );
  }

  getEventsByDocument(
    idDocument: number
  ): Promise<IEventTableDetailDocument[]> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `eventos/${idDocument}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(
        (result: { message: string; data: IEventTableDetailDocument[] }) =>
          result.data
      );
  }

  getUnidadeGestoraById(idUg: number): Promise<IUnidadeGestora> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/unidadeGestora/${idUg}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(
        (result: { message: string; data: IUnidadeGestora }) => result.data
      );
  }

  getSignatarioByIdDocumento(idDocumento: number): Promise<IAssinante[]> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `signatario/${idDocumento}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: IAssinante[] }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getSignatarioByIdDocumentoAnexo(
    idDocumentoAnexo: number
  ): Promise<IAssinante[]> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `anexo-signatario/${idDocumentoAnexo}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: IAssinante[] }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getAllSignatarios(avulso): Promise<IAssinante[]> {
    const typeDocument = avulso ? `?avulso=${avulso}` : '';

    const params = `${typeDocument}`;
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `signatario${params}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: IAssinante[] }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getUrlDocToUploadApi(uuid: string, type: string): Promise<any> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/url/${uuid}/${type}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => result.data)
      .catch((err) =>
        this.notification.error(
          'Visualizar Documento',
          `Não foi possível encontrar o documento ${err}`
        )
      );
  }

  async getUrlDocAnexo(uuid: string, type: string): Promise<string> {
    return await this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `documento-anexo/url/${uuid}/${type}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: string }) => result.data);
  }

  getUuidDocAnexo(uuid: string, idDoc: number): Promise<any> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `documento-anexo/uuid/${uuid}/${idDoc}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: string }) => result.data);
  }

  getPublicUrlDocToUploadApi(uuid: string, type: string): Promise<string> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/url-nouser/${uuid}/${type}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: string }) => result.data);
  }

  getB64DocToUploadApi(uuid: string): Promise<any> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/url/${uuid}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: string }) => result.data)
      .catch((err) =>
        this.notification.error('Visualizar Documento', err.message)
      );
  }

  async downloadDocWithUrlToUploadApi(uuid, typeAction, typeDoc, numberDoc) {
    const actualDate = String(await new Date().toLocaleString());
    // const type: TypeActionsDoc = 'Download';
    let events;
    return await this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/url/${uuid}/${typeAction}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: any) => {
        await FileSaver.saveAs(
          `${UrlUploadApiDownload}/${uuid}`,
          `${typeDoc}-${numberDoc}-${actualDate}`
        );
        events = result.data.events;
        return events;
      })
      .catch((err) => {
        this.notification.error('Download', err);
      });
  }

  async getInfoUploadFiles(idDocumento: number): Promise<IInfoUpload> {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/infoUpload/${idDocumento}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: IInfoUpload }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getURLAnexo(
    anexo: IDocumentoAnexo,
    download
    // document: IDocumento,
  ) {
    let typeAction = 'VisualizarAnexo';
    // const anexo = anexos.find((key) => key.idPrivado === idPrivado);
    let uuidIdentificador = anexo.uuid;
    let events;
    if (download === true) {
      typeAction = 'DownloadAnexo';
    }

    if (anexo.status === StatusDocumento.Confirmado) {
      uuidIdentificador = anexo.uuidManifestoAnexo;
      typeAction = 'VisualizarAnexoAssinado';
    }

    if (anexo.status === StatusDocumento.Desacordo) {
      uuidIdentificador = anexo.uuidManifestoAnexo;
      typeAction = 'VisualizarAnexoDescordado';
    }

    if (anexo.status === StatusDocumento.Cancelado) {
      uuidIdentificador = anexo.uuidManifestoAnexo;
      typeAction = 'VisualizarAnexoCancelado';
    }

    if (
      anexo.status === StatusDocumento.Confirmado &&
      anexo.ratificado === true
    ) {
      uuidIdentificador = anexo.uuidManifestoRatificado;
      typeAction = 'VisualizarAnexoRatificado';
    }

    await this.getUrlDocToUploadApi(uuidIdentificador, typeAction).then(
      async (result) => {
        events = result.events;
        if (download === true) {
          await FileSaver.saveAs(
            `${UrlUploadApiDownload}/${
              anexo.status === StatusDocumento.Confirmado
                ? anexo.uuidManifestoAnexo
                : anexo.uuid
            }`,
            `${anexo.nomeOriginal}`
          );
        }
        download === true ? null : window.open(result.pdfURL, '_blank');
      }
    );
    return events;
  }

  async getUuidFile(action, doc: IDocumento | Partial<IDocumento>) {
    if (!doc.infoUpload) {
      return;
    }
    if (
      action === 'Visualizar' ||
      action === 'view' ||
      action === 'DownloadOriginal' ||
      action === 'VisualizarDocPai'
    ) {
      return doc.infoUpload.uuidArquivoOriginal;
    }
    if (action === 'VisualizarAssinado' || action === 'DownloadAssinado') {
      return doc.infoUpload.uuidArquivoConfirmado;
    }
    if (action === 'VisualizarDesacordado' || action === 'DownloadDesacordo') {
      return doc.infoUpload.uuidArquivoDesacordo;
    }
    if (action === 'VisualizarCancelado' || action === 'DownloadCancelado') {
      return doc.infoUpload.uuidArquivoCancelado;
    }
    if (action === 'VisualizarRatificado') {
      return doc.infoUpload.uuidManifestoRatificado;
    }
  }

  async getUuidFileAnexo(action, anexo: IDocumentoAnexo) {
    if (
      action === 'VisualizarAnexoCancelado' ||
      action === 'VisualizarAnexoDescordado' ||
      action === 'VisualizarAnexoAssinado'
    ) {
      return anexo.uuidManifestoAnexo;
    }
    if (action === 'VisualizarAnexoPendente') {
      return anexo.uuid;
    }
  }

  async getUuidFileAnexado(action, doc: IDocumento | Partial<IDocumento>) {
    const infoUpload = await this.getInfoUploadFiles(doc.idPrivado);
    if (!infoUpload) {
      return;
    }
    if (action === 'Visualizar' || action === 'view') {
      return infoUpload.uuidArquivoOriginal;
    }
  }

  async visualizeAttachments(anexo: IDocumentoAnexo, type: TypeActionsDoc) {
    return this.getUrlDocAnexo(anexo.uuid, type);
  }

  async getAllAssinantes() {
    return await this.http
      .get(
        `${this.getServer('assinante')}/ug`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => result.data)
      .catch((err) => []);
  }

  async getSubscriberLogged() {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `assinante/logged/${this.authService.getUserInfo().idUsuario}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => result.data[0])
      .catch((err: any) => null);
  }

  // getDocumentBySubscriber(
  //   cpfs: string[],
  //   documents: IDocumento[],
  // ): IDocumento[] {
  //   return documents.filter((doc) => {
  //     doc.signatarios.some((ass) =>
  //       cpfs.some((cpf) => cpf === ass.dadosPessoaFisica.cpf),
  //     );
  //   });
  // }

  async findAllSubscribers(allAssinantes) {
    allAssinantes = await this.getAllAssinantes();
  }

  addSubscriberToFilter(
    assinanteSelected,
    assinantesSelected,
    allAssinantes
  ): { assinantesSelected: []; allAssinantes: []; listSignatarios: any } {
    assinantesSelected.push(assinanteSelected);
    allAssinantes = allAssinantes.filter(
      (assinante) => assinante.cpf !== assinanteSelected.cpf
    );

    return {
      assinantesSelected,
      listSignatarios: allAssinantes,
      allAssinantes,
    };
  }

  removeSubscriberToFilter(assinantesSelected, allAssinantes, cpf) {
    const indexAssinante = assinantesSelected.findIndex(
      (assinante) => assinante.cpf === cpf
    );
    allAssinantes.push(assinantesSelected[indexAssinante]);
    assinantesSelected.splice(indexAssinante, 1);
  }

  updateFilterType(filterType, type) {
    filterType.includes(type)
      ? filterType.splice(filterType.indexOf(type), 1)
      : filterType.push(type);
    return filterType;
  }

  async getPessoasById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err: any) => null);
  }

  getPessoasByIdNoAsync(id: number) {
    return this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .subscribe(
        async (result: { message: string; data: any }) => {
          return result.data;
        },
        (err) => {
          return null;
        }
      );
  }

  async getPessoaJuridicaById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaJuridicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err: any) => null);
  }

  async getPessoasFisicasById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: IPessoaFisica[] }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getPessoaFisicasById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getUnidadeOrc(value) {
    return await this.http
      .get(
        `${this.getServer('unidadeOrc')}${value}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: IUnidadeOrcamentaria }) => {
        if (!result.data) {
          throw new Error('Não foi possível encontrar a Unidade Orçamentária!');
        }
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  docIsExpirado(dataLimiteAssinatura) {
    if (!dataLimiteAssinatura) {
      return false;
    }
    return new Date() > new Date(dataLimiteAssinatura);
  }

  isExpiredDoc(doc: IDocumento) {
    return (
      new Date(
        doc.dataLimiteAssinatura?.slice(
          0,
          doc.dataLimiteAssinatura.lastIndexOf('.')
        )
      ) < new Date()
    );
  }

  getNamesFilters(filterType) {
    return filterType.map((filter) => StatusDocLabel[filter]);
  }

  async formatColumnsDocForDysplay(
    doc: IDocumento,
    signatarios,
    checked = false
  ) {
    doc.checked = checked;
    doc.expand = false;
    doc[`numTotalAss`] = signatarios?.length;
    doc[`assEfetivadas`] = signatarios?.filter(
      (ass) => ass.status === StatusDocumento.Assinado
    ).length;
  }

  async setDocumentsData(
    docs: any,
    filterType,
    documentList = [],
    displayData = [],
    signatariosSis,
    assinanteLogado?,
    identifyer = false
  ) {
    const newAssinanteLogado = await this.getSubscriberLogged();
    docs = await this.filterDocsPerType(
      docs,
      filterType,
      signatariosSis,
      identifyer
    );
    // const uniqueDocs = docs
    //   .map((e) => e['idPrivado'])
    //   .map((e, i, final) => final.indexOf(e) === i && i)
    //   .filter((e) => docs[e])
    //   .map((e) => docs[e]);

    documentList = docs ? docs : [];
    displayData = docs ? docs : [];
    return { documentList, displayData, assinanteLogado: newAssinanteLogado };
  }

  async filterDocsPerType(
    docs: IDocumento[],
    filterType: any[],
    signatarios,
    identifyer = false
  ) {
    if (filterType.length === 0) {
      return [];
    }

    const listDocs: IDocumento[] = [];
    for (const doc of docs) {
      const isAssinante = await doc.signatarios?.filter(
        async (assinante) => assinante.cpf === this.authService.getCpfUser()
      ).length;

      if (isAssinante || (await this.isAdmUser())) {
        if (identifyer) {
          listDocs.push(doc);
        } else {
          if (filterType.includes(this.getStatusDocumento(doc, signatarios))) {
            listDocs.push(doc);
          }
        }
      }
    }
    return listDocs;
  }

  getStatusDocumento(doc: IDocumento, signatarios?) {
    const docAssinadoPorUser = doc.signatarios?.filter(
      (assinante) => assinante?.status === StatusDocumento.Assinado
    ).length;

    const qtAssinaturas = doc.signatarios?.filter(
      (assinante) => assinante?.status === StatusDocumento.Assinado
    );

    const todosAssinantes = qtAssinaturas?.length === doc.signatarios?.length;
    if (
      [
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
        StatusDocumento.Cancelado,
        StatusDocumento.Confirmado,
      ].includes(Number(doc.statusDocumento))
    ) {
      return Number(doc.statusDocumento);
    }
    if (doc.statusDocumento === StatusDocumento.Pendente) {
      // if (todosAssinantes) {
      //   return StatusDocumento.Confirmado;
      // }
      if (
        doc.statusDocumento === StatusDocumento.Pendente &&
        !this.docIsExpirado(doc.dataLimiteAssinatura)
      ) {
        return StatusDocumento.Pendente;
      }

      if (docAssinadoPorUser && !this.docIsExpirado(doc.dataLimiteAssinatura)) {
        return StatusDocumento.Assinado;
      }

      if (
        doc.statusDocumento === StatusDocumento.Pendente &&
        this.docIsExpirado(doc.dataLimiteAssinatura)
      ) {
        return StatusDocumento.Expirado;
      }
    }
  }

  setCompareToTableColumns(displayData, tableColumns) {
    if (displayData.length === 0) {
      return;
    }

    tableColumns.map((column) => {
      column[`compare`] =
        typeof displayData[0][column.value] === 'string'
          ? (a: IDocumento, b: IDocumento) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IDocumento, b: IDocumento) => a[column.value] - b[column.value];
    });
  }

  setCompareToTableColumnsExp(displayData, tableColumns) {
    if (displayData.length === 0) {
      return;
    }
    tableColumns.map((column) => {
      column[`compare`] =
        typeof displayData[0][column.value] === 'string'
          ? (a, b) => a[column.value].localeCompare(b[column.value])
          : (a, b) => a[column.value] - b[column.value];
    });
  }

  getColumnsRatificado(tableColumns) {
    return tableColumns.find((column) => column?.value === 'ratificado');
  }

  getColumnsStatus(tableColumns) {
    return tableColumns.find((column) => column?.value === 'statusDocumento');
  }

  getColumnsAnexoStatus(tableColumns) {
    return tableColumns.find((column) => column?.value === 'status');
  }

  getColumnsRatificadoAnexo(tableColumns) {
    return tableColumns.find((column) => column?.value === 'ratificado');
  }

  getColumnsChancela(tableColumns) {
    return tableColumns.find((column) => column?.value === 'chancela');
  }

  getTableColumns(tableColumns): any[] {
    return tableColumns.filter(
      (column) =>
        !['statusDocumento'].includes(column.value) &&
        !['ratificado'].includes(column.value) &&
        !['chancela'].includes(column.value)
    );
  }

  async getAnexosPDFURL(uuids = []): Promise<string[]> {
    const pdfURLs = [];
    for (const uuid of uuids) {
      await this.http
        .get(
          this.generateUrlByEnvironment(
            '5017',
            `document/url/${uuid}/VisualizarAnexo`,
            'digitalizacao'
          ),
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((data: any) => {
          pdfURLs.push(data.data.pdfURL);
        })
        .catch((err) =>
          this.notification.error(
            'Erro ao imprimir',
            `AnxUUID:${uuid}.
             ${err}`
          )
        );
    }
    return pdfURLs;
  }

  async getDocumentsPDFURL(uuids = []): Promise<string[]> {
    const pdfURLs = [];
    for (const uuid of uuids) {
      await this.http
        .get(
          `${this.getServer('document')}/url/${uuid}/Visualizar`,
          this.headerAuthenticateOptions()
        )
        .toPromise()
        .then((data: any) => {
          pdfURLs.push(data.data.pdfURL);
        })
        .catch((err) =>
          this.notification.error(
            'Erro ao imprimir',
            `docUUID:${uuid}.
            ${err}`
          )
        );
    }
    return pdfURLs;
  }

  getColumnsSelect(tableColumns): any[] {
    return tableColumns.filter(
      (column) =>
        !['statusDocumento'].includes(column.value) &&
        !['chancela'].includes(column.value) &&
        !['usuarioCriacao'].includes(column.value) &&
        !['aplicacaoOrigem'].includes(column.value)
    );
  }

  getTableColumnsAnexos(tableColumns) {
    return tableColumns.filter(
      (column) =>
        !['status'].includes(column.value) &&
        !['ratificado'].includes(column.value)
    );
  }

  getTableColumnsExportacao(tableColumns) {
    return tableColumns.filter((column) => !['todos'].includes(column.value));
  }

  prepareColumnValue(item, column) {
    item = {
      ...item,
      modalidadeLicitacao:
        TIPOS_MODALIDADE_LICITACO[Number(item.modalidadeLicitacao)],
    };
    const value = column.objectChildren
      ? item[column.value]
        ? item[column.value][column.objectChildren] ?? null
        : null
      : item[column.value] ?? null;
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  getResult(displayData, documentList, searchInput) {
    displayData = this.search(documentList, searchInput);
    return { displayData };
  }

  async updateAllChecked(
    documentList: IDocumento[],
    checkedAll,
    checkAllIndeterminate,
    signatarios
  ) {
    documentList.map((registro) => {
      registro.checked = !checkedAll;
    });
    checkAllIndeterminate = checkedAll;
    return { checkAllIndeterminate };
  }

  async updateAllAnexosChecked(
    documentList = [],
    checkedAll,
    checkAllAnexosIndeterminate,
    anexoList = []
  ) {
    documentList.map((registro) => {
      registro.checked = !checkedAll;
    });
    anexoList.map((registro) => {
      registro.checked = !checkedAll;
    });
    checkAllAnexosIndeterminate = checkedAll;
    return { checkAllAnexosIndeterminate };
  }

  getValorDocumentoFormatado(valor): string {
    return Number.isNaN(valor)
      ? (valor = 'R$ 0,00')
      : valor?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
  }

  getValorTotal(documentList): string {
    return this.getValorDocumentoFormatado(
      documentList?.reduce((a, b) => {
        return a + Number(b.valorDoc ?? 0);
      }, 0)
    );
  }

  getValorTotalNaoAssinado(documentList): string {
    const docsToSign = this.getDocsParaAssinar(documentList);
    return this.getValorDocumentoFormatado(
      docsToSign?.reduce((a, b) => {
        return a + Number(b.valorDoc ?? 0);
      }, 0)
    );
  }

  getValorTotalAssinado(documentList): string {
    const docsAssinados = this.getDocsAssinados(documentList);
    return this.getValorDocumentoFormatado(
      docsAssinados?.reduce((a, b) => {
        return a + Number(b?.valorDoc ?? 0);
      }, 0)
    );
  }

  getDocsAssinados(documentList: IDocumento[]): IDocumento[] {
    return documentList?.filter(
      (doc) => doc.statusDocumento === StatusDocumento.Confirmado
    );
  }

  getDocsParaAssinar(documentList): IDocumento[] {
    return documentList?.filter(
      (doc) =>
        doc.statusDocumento === StatusDocumento.Pendente ||
        doc.statusDocumento === StatusDocumento.Desacordo
    );
  }

  getAnexosParaAssinar(anexosList): IDocumentoAnexo[] {
    return anexosList?.filter(
      (anx) =>
        anx.status === StatusDocumento.Pendente ||
        anx.status === StatusDocumento.Desacordo
    );
  }

  getDocsSelecionadosParaAssinar(documentList): IDocumento[] {
    return this.getDocsParaAssinar(documentList).filter((a) => a.checked);
  }

  getAnexosSelecionadosParaAssinar(anexosList): IDocumentoAnexo[] {
    return this.getAnexosParaAssinar(anexosList).filter((a) => a.checked);
  }

  getValorSelecionado(documentList): string {
    return this.getValorDocumentoFormatado(
      documentList?.reduce(
        (a, b) => a + (b.checked ? Number(b.valorDoc) : 0),
        0
      )
    );
  }

  getDocsSelecionados(documentList): IDocumento[] {
    return documentList?.filter((doc) => doc.checked);
  }

  getQtdSelecionados(documentList): number {
    return this.getDocsSelecionados(documentList)?.length;
  }

  getAnexosSelecionados(anexosList): IDocumentoAnexo[] {
    return anexosList?.filter((doc) => doc.checked);
  }

  getQtdAnexosSelecionados(anexosList): number {
    return this.getAnexosSelecionados(anexosList)?.length;
  }

  formatProgress = () => ``;

  getWidthContent(): number {
    return window.innerWidth;
  }

  progressBar(doc: IDocumento) {
    const progressSigned = doc.signatarios.filter(
      (key) => key.status === StatusDocumento.Assinado
    );
    const percentage = Math.round(
      (100 * progressSigned.length) / doc.totalSignatarios
    );
    return percentage;
  }

  getStatusCheckDocument(item: IDocumento): boolean {
    if (this.modifyDocumentService.isNotDocActive(item)) {
      return true;
    }

    return this.modifyDocumentService.docFullSigned(
      item,
      this.authService.getCpfUser()
    );
  }

  getDateFormatted(date) {
    if (date) {
      return moment(date).utcOffset(0, true).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  getColorByDataLimiteAssinatura(value) {
    if (!value) {
      return;
    }

    const dataLimiteAssinatura = value.slice(0, value.lastIndexOf('.'));
    const diffByDays = Math.ceil(
      (new Date(dataLimiteAssinatura).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24)
    );

    let color = '#ffe319';
    if (diffByDays < 0) {
      color = '#000000';
    }

    if (diffByDays > 1 && diffByDays < 6) {
      color = '#ff9900';
    }

    if (diffByDays === 1) {
      color = '#f00';
    }

    return `color: ${color}`;
  }

  isUserCreateDoc(doc: IDocumento) {
    return doc.usCriacao === this.authService.getIdUser();
  }

  // canEditDoc(doc: IDocumento) {
  //   const signatarios = this.getSignatarioByIdDocumento(doc.idPrivado);
  //   return (
  //     doc.avulso &&
  //     this.isUserCreateDoc(doc) &&
  //     doc.statusDocumento === StatusDocumento.Pendente &&
  //     signatarios.every((assi) => assi.status === StatusDocumento.Pendente)
  //   );
  // }
}
