import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-manutencao',
  template: `
    <form
      nz-form
      accessible-form
      [form]="form"
      [nzLayout]="'vertical'"
      autocomplete="off"
    >
      <ps-form [form]="form" [fields]="fields" [model]="model"></ps-form>
    </form>
  `,
})
export class PsManutencaoComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() fields: FormlyFieldConfig[];
  @Input() model;

  constructor() {}

  ngOnInit(): void {}
}
