import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Route,
  Router,
} from '@angular/router';
import { PsTabSessionService, TabType } from '@ps-erp-angular/shared';
import { Subscription } from 'rxjs';
import { PsOnRouterChange } from '../../utils/util';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-tab-session',
  templateUrl: './ps-tab-session.component.html',
})
export class PsTabSessionComponent implements OnInit, OnDestroy {
  tabActive = '';
  tabs: TabType[] = [];
  subscriptions: Subscription = new Subscription();
  @Output() tabAdded: EventEmitter<any> = new EventEmitter();
  external = false;
  constructor(
    private router: Router,
    private tabSessionService: PsTabSessionService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      this.external = params['external'];
    });

    this.tabs = tabSessionService.tabs;
    const routerSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.lastIndexOf('?')
          ? event.url.split('?')[0]
          : event.url;
        this.tabActive = url;
      }
    });
    this.subscriptions.add(routerSubscription);
  }

  ngOnInit(): void {
    this.setDefaultTabActive();
  }

  //http://localhost:4200/session/solicitacao-cancelamento-nfse?ug=Monteiro&uf=PB&cnpjInst=09073628000191&cookie=95c16499-a06b-45d1-b694-4854c50f0666&external=true
  setDefaultTabActive(): void {
    if (this.external) {
      // ROTA EXTERNA
      this.tabSessionService.addTab({
        name: 'Solicitação de Cancelamento NFSE',
        url: '/session/solicitacao-cancelamento-nfse',
      });
    }
    if (this.tabs.length) {
      this.router.navigate([this.tabs[0].url]);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get tabSelectedIndex(): number {
    return this.tabs.findIndex((tab: TabType) => tab.url === this.tabActive);
  }

  async closeTab(tab: TabType) {
    const index = this.tabs.findIndex(
      (tabItem: TabType) => tab.url === tabItem.url
    );

    // Navega recursivamente pelas rotas cadastradas procurando pela tab ativa e atualizando para possibilitar destruir o componente.
    const markDetatchRouterRecursive = (tabType: TabType, routers: Route[]) => {
      routers.map((route: Route) => {
        if (route.children) {
          markDetatchRouterRecursive(tabType, route.children);
        }
        if (route.path === tabType.url.replace('/session/', '')) {
          route.data = { ...route.data, detatchRoute: false };
        }
      });
    };
    markDetatchRouterRecursive(
      tab,
      this.router.config.filter((route: Route) => route.path === 'session')
    );
    await this.router.navigate([tab.url]);

    this.tabs.splice(index, 1);
    if (!this.tabs.length) {
      return this.router.navigate(['/']);
    }
    if (index - 1 <= 0) {
      return this.router.navigate([this.tabs[0].url]);
    }
    return this.router.navigate([tab.url]);
  }

  newTab(tab): void {
    this.tabSessionService.addTab({
      name: `Tab ${this.tabs.length + 1}`,
      url: '',
    });
    this.tabAdded.emit(tab);
  }

  handlerTabClick(tab: TabType) {
    PsOnRouterChange.emitRouterChange = {
      name: tab.name,
      path: tab.url,
      queryParams: tab.queryParams,
    };
    return this.router.navigate([tab.url], { queryParams: tab.queryParams });
  }
}
