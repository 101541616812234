<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <input type="hidden" formControlName="id">

        <h4 class="m-t-15 m-l-20">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row class="p-l-20 p-r-20 d-flex x-end y-center row">
          <nz-checkbox nz-checkbox status="primary" [ngModelOptions]="{standalone: true}" [(ngModel)]="cadastroBasico"
            (ngModelChange)="changeTipoCadastro($event)">Cadastro
            básico</nz-checkbox>
        </div>
        <form nz-form [formGroup]="formB" nzLayout="vertical">

          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome</nz-form-label>
                <nz-form-control formGroupName="pessoa">
                  <input nz-input formControlName="nomPessoa" placeholder="Nome">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div *ngIf="!cadastroBasico" nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>
                  Data do nascimento
                </nz-form-label>
                <nz-form-control>
                  <nz-date-picker formControlName="dtNasc" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div *ngIf="!cadastroBasico" nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-label>
                Estado civil
              </nz-form-label>
              <nz-form-control>
                <nz-select fullWidth nzPlaceHolder="Estado civil" formControlName="estadoCivil">
                  <nz-option nzLabel="Solteiro(a)" [nzValue]=0></nz-option>
                  <nz-option nzLabel="Casado(a)" [nzValue]=1></nz-option>
                  <nz-option nzLabel="Separado(a)" [nzValue]=2></nz-option>
                  <nz-option nzLabel="Divorciado(a)" [nzValue]=3></nz-option>
                  <nz-option nzLabel="Viúvo(a)" [nzValue]=4></nz-option>
                  <nz-option nzLabel="Outros" [nzValue]=5></nz-option>
                </nz-select>
              </nz-form-control>
            </div>

          </div>
          <div *ngIf="!cadastroBasico" nz-row [nzGutter]="18" class="p-l-20 p-r-20">

            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome do pai</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomPai" placeholder="Nome do pai">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome da mãe</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomMae" placeholder="Nome da mãe">
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
          <div *ngIf="!cadastroBasico" nz-row [nzGutter]="18" class="p-l-20 p-r-20">

            <div nz-col nzSpan="9" nzSm="9" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nacionalidade</nz-form-label>
                <nz-form-control>
                  <nz-select fullWidth nzPlaceHolder="Selecione o campo" formControlName="nacionalidade">
                    <nz-option nzLabel="Brasileiro(a)" [nzValue]=0></nz-option>
                    <nz-option nzLabel="Estrangeiro(a)" [nzValue]=1></nz-option>
                    <nz-option nzLabel="Naturalizado(a)" [nzValue]=2></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="15" nzSm="15" nzXs="24">
              <nz-form-item>
                <nz-form-label>Naturalidade</nz-form-label>
                <nz-form-control>
                  <nz-select nzShowSearch nzServerSearch formControlName="naturalidade"
                    [nzPlaceHolder]="selectedValue?selectedValue.texto:'Naturalidade'" [nzShowArrow]="false"
                    [nzFilterOption]="nzFilterOption" class="naturalidade" [(ngModel)]="selectedValue"
                    (nzOnSearch)="consultaCidade($event)">
                    <nz-option *ngFor="let cidade of optionsCidade" [nzLabel]="cidade.texto" [nzValue]="cidade">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>

          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
            <div nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Número do CPF</nz-form-label>
                <nz-form-control>
                  <input [mask]="'000.000.000-00'" nz-input formControlName="cpf" placeholder="Número do CPF">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div *ngIf="!cadastroBasico" nz-row [nzGutter]="18" class="p-l-20 p-r-20">

            <div nz-col nzSpan="10" nzSm="10" nzXs="24" formGroupName="rg">
              <nz-form-item>
                <nz-form-label>Número do RG</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="numero" placeholder="Número do RG">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" nzSm="8" nzXs="24" formGroupName="rg">
              <nz-form-item>
                <nz-form-label>Órgão emissor</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="orgaoEmissor" placeholder="Órgão emissor">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24" formGroupName="rg">
              <nz-form-item>
                <nz-form-label>Data da emissão</nz-form-label>
                <nz-form-control>
                  <nz-date-picker fullWidth formControlName="dataEmissao" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>


          </div>
          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20"
            *ngIf="formB.value.nacionalidade && formB.value.nacionalidade !== 0 && !cadastroBasico">

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Número do passaporte</nz-form-label>
                <nz-form-control formGroupName="passaporte">
                  <input nz-input formControlName="numero" placeholder="Número do passaporte">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>
                  Validade do passaporte
                </nz-form-label>
                <nz-form-control formGroupName="passaporte">
                  <nz-date-picker formControlName="validade" (ngModelChange)="checkDataExpiracao($event)"
                    nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>País Emisso</nz-form-label>
                <nz-form-control formGroupName="passaporte">
                  <input nz-input formControlName="paisOrigem" placeholder="País Emissão">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>Nome do País</nz-form-label>
                <nz-form-control formGroupName="passaporte">
                  <input nz-input formControlName="nomPais" placeholder="Nome do País">
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="m-b-0 p-l-20">Contatos</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <button nz-button [disabled]="!pessoaService.verificaContatoVazio(formB.value.contatos)"
                (click)="addContato()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div *ngIf="formB.get('contatos').value.length > 0" formArrayName="contatos">
            <div nz-row *ngFor="let contato of formB.get('contatos')['controls']; index as i" [formGroupName]="i">
              <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                <div>
                  <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                    <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>E-mail</nz-form-label>
                        <nz-form-control>
                          <input nz-input [type]="'email'" formControlName="email" placeholder="E-mail">

                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div *ngIf="!cadastroBasico" nz-col nzSpan="6" nzSm="6" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>telefone</nz-form-label>
                        <nz-form-control>
                          <input nz-input formControlName="telefone" (keyup)="setMaskFone(i)"
                            (blur)="setMaskFone(i, true)" placeholder="Telefone" [mask]="contato.value.maskTelefone">
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div *ngIf="!cadastroBasico" nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="whatsapp">Whatsapp</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div *ngIf="contato.value.telefone?.length > 10" nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="telegram">Telegram</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item *ngIf="verificaContatoPrincipal(contato.value.principal)">
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="principal">Principal</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>


                  </div>
                </div>
              </fieldset>
              <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                <button [disabled]="!pessoaService.verificaEnderecoVazio(formB.value.enderecos)"
                  *ngIf="formB.value.contatos.length > 1" class="m-t-20" nz-button nzDanger [disabled]="false"
                  nzType="primary" (click)="removeContato(i)">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="!cadastroBasico">
            <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
            <div nz-row>
              <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
                <h4 class="m-b-0 p-l-20">Endereços</h4>
              </div>
              <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
                <button nz-button [disabled]="!pessoaService.verificaEnderecoVazio(formB.value.enderecos)"
                  nzType="primary" (click)="addEndereco()">
                  <i nz-icon nzType="plus"></i>
                </button>
              </div>
            </div>
            <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

            <div *ngIf="!cadastroBasico" formArrayName="enderecos">
              <div nz-row *ngFor="let endereco of formB.get('enderecos')['controls']; index as i" [formGroupName]="i">
                <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                  <div>
                    <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
                      <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>CEP</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="cep" placeholder="CEP" [mask]="'00000-000'"
                              (blur)="consultaCep($event.target.value, i)"
                              [status]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="4" nzSm="4" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Número</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="numero" placeholder="Número" maxlength="10"
                              [nzStatus]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="12" nzSm="12" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Complemento</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="complemento" placeholder="Complemento" maxlength="200"
                              [nzStatus]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                    <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                      <div nz-col nzSpan="7" nzSm="7" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Logradouro</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomLogradouro" placeholder="Logradouro">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Bairro</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomBairro" placeholder="Bairro">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Cidade</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomCidade" placeholder="Cidade">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="2" nzSm="2" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>UF</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomUf" placeholder="UF">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                        <nz-form-item *ngIf="verificaEnderecoPrincipal(endereco.value.principal)">
                          <nz-form-label>&nbsp;</nz-form-label>
                          <nz-form-control>
                            <label nz-checkbox formControlName="principal">Principal</label>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                  </div>
                </fieldset>
                <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                  <button *ngIf="formB.value.enderecos.length > 1" class="m-t-20" nz-button nzDanger [disabled]="false"
                    nzType="primary" (click)="removeEndereco(i)">
                    <i nz-icon nzType="delete"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldPessoasFisicasSelected"
                              (ngModelChange)="getFilterPessoaFisicaOptionsSelect(fieldPessoasFisicasSelected)">
                              <nz-option *ngFor="let interval of arrColumnsPessoasFisicasSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>

                          </div>
                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group
                              *ngIf="fieldPessoasFisicasSelected && fieldPessoasFisicasSelected.type === 'input'"
                              [nzPrefix]="''">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>

                          <!-- <div
                            *ngIf="fieldPessoasFisicasSelected && tabIndex == 1 && fieldPessoasFisicasSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div> -->
                          <div nz-col *ngIf="fieldPessoasFisicasSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsPessoasFisicas" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                                removeFiltroPessoaFisicaPorField(fieldsValuesTag)
                            " class="filters"
                                  *ngFor="let fieldsValuesTag of fieldsPessoasFisicasSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="fieldsPessoasFisicasSelected.length !=0?openModal?7:8:openModal?19:20"
                            nzSpan="3" nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                              (click)="filterPessoaFisica()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #PessoaFisicaListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tablePessoaFisicaColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="PessoaFisicaListTable.data" let-index="index">
            <tr (dblclick)="doubleClick(item)" style="cursor: pointer" nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tablePessoaFisicaColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>

      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>