import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PessoaJuridicaService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('pessoa-juridica', http, notification, authService);
  }

  async delete(body: number, entidade: string) {
    return await this.excluir(body, entidade);
  }

  async consultaCnaePorId(id) {
    return await this.consultarPorParametro(
      { field: 'id', value: id },
      'cnae-subclasse',
    );
  }

  async getPessoaFisicaById(id) {
    return await this.consultarPorParametro(
      { field: 'id', value: id },
      'pessoa-fisica',
    );
  }

  // async update(pessoaJuridica, cadastroBasico) {
  //   pessoaJuridica = this.prepareToRequest(pessoaJuridica, cadastroBasico);
  //   return await this.execute(pessoaJuridica);
  // }

  prepareToRequest(pessoaJuridica, cadastroBasico = false) {
    pessoaJuridica = this.pessoaFormToPessoaJuridica(pessoaJuridica);
    this.verificaCheckBox(pessoaJuridica);
    if (!pessoaJuridica.pessoa.id) {
      delete pessoaJuridica.pessoa.id;
    }
    pessoaJuridica.contatos.map((contato) =>
      !contato.id ? delete contato.id : null,
    );
    pessoaJuridica.enderecos.map((endereco) =>
      !endereco.id ? delete endereco.id : null,
    );
    pessoaJuridica.pessoaJuridicaCnae.map((cnae) =>
      !cnae.id ? delete cnae.id : null,
    );

    pessoaJuridica.pessoaResponsaveis.map((responsavel) =>
      !responsavel.id ? delete responsavel.id : null,
    );

    if (cadastroBasico) {
      delete pessoaJuridica.contatos;
    }

    return pessoaJuridica;
  }

  verificaCheckBox(pessoaJuridica) {
    pessoaJuridica.contatos.map((contato) => {
      contato.principal = contato.principal ? 1 : 0;
      contato.whatsapp = contato.whatsapp ? 1 : 0;
      contato.telegram = contato.telegram ? 1 : 0;
    });
    pessoaJuridica.enderecos.map((endereco) => {
      endereco.principal = endereco.principal ? 1 : 0;
    });
    pessoaJuridica.pessoaJuridicaCnae.map((cnae) => {
      cnae.principal = cnae.principal ? 1 : 0;
    });
  }

  pessoaFormToPessoaJuridica(form) {
    form.get('enderecos').controls.map((endereco) => {
      endereco.get('nomLogradouro').enable();
      endereco.get('nomCidade').enable();
      endereco.get('nomBairro').enable();
      endereco.get('nomUf').enable();
    });
    form.value.enderecos = form.value.enderecos.filter(
      (endereco) => endereco.cep,
    );
    return form.value;
  }

  async getCidadesTableProps(): Promise<any> {
    return { tableColumnsExample: [] }
  }

  async getExamplesTableProps(): Promise<any> {
    return { tableColumnsExample: [] }
  }



  getAllExampleInInterval(fieldsExamplesSelected, page, size): Promise<any> {
    return
  }

  async consultaCnae(): Promise<any> {
    // if (
    //   evento === '' ||
    //   (form.get('pessoaJuridicaCnae').hasOwnProperty('codigo') &&
    //     !isUndefined(form.get('pessoaJuridicaCnae').value.codigo))
    // ) {
    //   return { error: false, pessoaJuridicaCnae: [] };
    // }
    return this.pesquisarTodos(
      'cnae-subclasse',
    ).then((resultado) => { return resultado }).catch((err) => {
      this.notification.error("Pessoa jurídica", err.message)
    })
  }


  async getAllPessoaJuridicaInInterval(
    fieldsPessoasJuridicasSelected,
    page = 1,
    size = 10,
    all = null
  ): Promise<any> {
    const paramsPaginator = `page=${page}&size=${size}${all ? `&all=${all}` : ''
      }`;
    const filter =
      fieldsPessoasJuridicasSelected.length !== 0
        ? `${fieldsPessoasJuridicasSelected[0].props}=${fieldsPessoasJuridicasSelected[0].value}`
        : '';
    return this.http
      .get(
        `${this.getServer(
          'pessoa-juridica'
        )}/pesquisar-avancado?${paramsPaginator}&${filter}`,

        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { data: any; message: string }) => {
        this.notification.success('Pessoa jurídica', result.message);
        return result;
      })
      .catch((err: any) => {
        return this.notification.error('Pessoa jurídica', err.message);
      });
  }
}
