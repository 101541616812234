import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CidadeService } from './../shared/services/cidade.service';

import { NzUploadFile } from 'ng-zorro-antd/upload';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'ps-painel-administrativo-app-cidade',
  templateUrl: './cidade.component.html',
  styleUrls: ['./cidade.component.scss'],
})
export class CidadeComponent implements OnInit {
  ////
  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;

  ////
  tabIndex: 0 | 1 = 1;
  fabButtons: IMenuButton[];
  inputValue?: string;
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  manutencaoLoading: boolean = false;
  entidade: string = 'cidade';
  titulo: string = 'Cidade';

  tableCidadeColumns = [];
  displayData: any[] = [];
  checkedAll = false;

  arrColumnsCidadesSelect = [];
  tableMensagemColumns;
  fieldCidadesSelected;

  selectedCidadesValues;
  fieldsCidadesSelected = [];
  showComboTagsCidades;
  searchInput: any;

  checkList: { [typekey: string]: boolean } = {
    id: true,
    versao: false,
    nomCidade: true,
    codigoIbge: true,
    identificador: true,
    id_uf: true,
    dtCriacao: false,
    aplicaoOrigina: false,
  };

  countCheked;

  checkAllIndeterminate = true;

  formB: FormGroup = new FormGroup({});
  constructor(
    private activateRouter: ActivatedRoute,
    private service: CidadeService,
    private formBuilder: FormBuilder,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigCidadeTable();
      await this.setConfigCidadeFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  //
  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj!);
    }
    this.previewImage = file.url || file.preview;
    this.previewVisible = true;
  };
  //

  async setConfigCidadeFilter() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableCidadeColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigCidadeTable() {
    this.loadingPage = true;

    await this.service.getTableProps(this.entidade).then((result) => {
      const tableColumnsCidades = result.tableColumnsProps.map((column) => {
        return {
          ...column,
          compare:
            column.value === 'id' || column.value === 'id_uf'
              ? (a, b) => a[column.value] - b[column.value]
              : (a, b) => a[column.value].localeCompare(b[column.value]),

          // width:
          //   column.value === 'idPublico'
          //     ? '8rem'
          //     : column.value === 'titulo'
          //     ? '15rem'
          //     : column.value === 'dtCriacao'
          //     ? '20rem'
          //     : null,
        };
      });
      this.tableCidadeColumns = tableColumnsCidades;

      this.arrColumnsCidadesSelect = result.searchColumns;
    });
    this.loadingPage = false;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  async getFilterCidadeOptionsSelect(item: any) {}

  resetForm() {
    this.formB = this.formBuilder.group({
      id: [null],
      codigoIbge: [{ value: null, disabled: true }],
      nomCidade: [{ value: null, disabled: true }],
      brasao: [null, Validators.required],
    });
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 0,
        onClick: this.reset,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }
  reset = () => {
    this.resetForm();
  };

  salvar = () => {
    const dataCidade = {
      id: this.formB.controls['id'].value,
      brasao: this.formB.controls['brasao'].value,
      nomCidade: this.formB.controls['nomCidade'].value,
    };
    // for (const key in this.formB.controls) {
    //   dataMessage[key] = this.formB.controls[key].value;
    // }
    return this.modalService.confirm({
      nzTitle: `<i>Deseja alterar o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.update(dataCidade);
        this.resetForm();
        this.loadingPage = false;
      },
    });
  };

  atualizar = async () => {
    this.changeTabIndex(1);
    this.filterCidades();
  };

  editar = async () => {
    this.formB.patchValue(this.getRegistrySelected());
    this.changeTabIndex(0);
  };

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  editarCidade(cidade) {
    this.formB.patchValue(cidade);
    this.changeTabIndex(0);
  }

  filterCidades() {
    return this.getCidadesPerPeriod(this.fieldsCidadesSelected);
  }

  uploadAvatarCity(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      this.formB.controls.brasao.setValue(e.target.result);
    };
  }

  desmarcarTodasMensagens() {
    this.displayData = this.displayData.map((cidade) => {
      return { ...cidade, checked: false };
    });
  }

  async getCidadesPerPeriod(fieldsCidadeSelected) {
    this.loadingPage = true;
    await this.service
      .getAllCidadesInInterval(fieldsCidadeSelected)
      .then(async (cidades: any) => {
        // if (cidades.data.length === 0) {
        //   this.service.notification.warning(
        //     'Cidade',
        //     'Nenhum registro correspondente aos filtros.',
        //     { nzDuration: 7000 }
        //   );
        // }
        this.displayData = cidades.data.map((cidade) => {
          return {
            ...cidade,
            nomCidade: cidade.nom_cidade,
            codigoIbge: cidade.codigo_ibge,
            checked: false,
          };
        });

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error('Cidade', err);
        this.loadingPage = false;
      });
  }

  getWidthContent() {
    return window.innerWidth;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldCidadesSelected && this.searchInput) {
      if (this.fieldCidadesSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldCidadesSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      this.fieldsCidadesSelected.push({
        props: this.fieldCidadesSelected.value,
        field: this.fieldCidadesSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldCidadesSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsCidadesSelect.findIndex(
        (a) => a.value === this.fieldCidadesSelected.value
      );

      this.arrColumnsCidadesSelect.splice(index, 1);

      this.showComboTagsCidades = true;
      this.fieldCidadesSelected = '';
      this.searchInput = '';
      this.selectedCidadesValues = [];
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    return value;
  }
  removeFiltroCidadePorField(fieldsValuesTag) {
    const tableExportColumn = this.tableCidadeColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsCidadesSelect.splice(
      this.tableCidadeColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsCidadesSelected.splice(
      this.fieldsCidadesSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsCidadesSelected.length === 0) {
      this.showComboTagsCidades = false;
    }
  }

  ngOnInit(): void {}
  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }
  async selectItem(item) {}
}
