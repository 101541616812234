<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <form nz-form [formGroup]="form" nzLayout="vertical">
          <h4 class="m-t-15 m-l-20">Informações básicas</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row nzGutter="16">
            <div nz-col nzSpan="4" nzSm="4" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Código</nz-form-label>
                <nz-form-control>
                  <input nz-input type="number" formControlName="codigo" (focusout)="verificarCodigoInformado()"
                    placeholder="Digite um código que ainda não foi usado">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Nome do Módulo</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="nomModulo" type="text" maxlength="100" placeholder="Nome do Módulo">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="2" nzSm="2" nzXs="24">
              <nz-form-item>


                <nz-form-label>Ícone</nz-form-label>
                <nz-form-control>
                  <input style="display: none" accept="image/png, image/jpeg" type="file"
                    (change)="uploadAvatarModuloIcone($event)" #hiddenfileinput nz-tooltip
                    nzTooltipTitle="Apenas arquivos de extensão .png ou .jpg são permitidos."
                    nzTooltipPlacement="topRight">
                  <!-- <nb-user [name]="formB.value.nomCidade" [picture]="formB.value.brasao || null"> -->
                  <nz-avatar (click)="hiddenfileinput.click(); $event.preventDefault()" [nzShape]="'square'"
                    [nzSize]="'large'" [nzIcon]="'picture'" [nzSrc]="form.value.icone || null" nz-tooltip
                    nzTooltipTitle="Apenas arquivos de extensão .png ou .jpg são permitidos."
                    nzTooltipPlacement="topRight">
                  </nz-avatar>
                </nz-form-control>
              </nz-form-item>




              <!-- <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal> -->
            </div>
            <div nz-col nzSpan="2" nzSm="2" nzXs="24">
              <nz-form-item *ngIf="sistemaLegado">


                <nz-form-label>Splash</nz-form-label>
                <nz-form-control>
                  <input style="display: none" accept="image/png, image/jpeg" type="file"
                    (change)="uploadAvatarModuloSplash($event)" #hiddenfileinputSplash nz-tooltip
                    nzTooltipTitle="Apenas arquivos de extensão .png ou .jpg são permitidos."
                    nzTooltipPlacement="topRight">
                  <!-- <nb-user [name]="formB.value.nomCidade" [picture]="formB.value.brasao || null"> -->
                  <nz-avatar [style.background-color]="form.value.bgColor"
                    (click)="hiddenfileinputSplash.click(); $event.preventDefault()" [nzShape]="'square'"
                    [nzSize]="'large'" [nzIcon]="'picture'" [nzSrc]="form.value.splash || null" nz-tooltip
                    nzTooltipTitle="Apenas arquivos de extensão .png ou .jpg são permitidos."
                    nzTooltipPlacement="topRight">
                  </nz-avatar>
                </nz-form-control>
              </nz-form-item>




              <!-- <nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
                (nzOnCancel)="previewVisible = false">
                <ng-template #modalContent>
                  <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
                </ng-template>
              </nz-modal> -->
            </div>

            <div nz-col nzSpan="3" nzSm="3" nzXs="12">
              <nz-form-item *ngIf="sistemaLegado">
                <nz-form-label>Background Color</nz-form-label>
                <nz-form-control style="margin: 0 auto;">
                  <input type="color" #bgColor value="#ff0000" (change)="onColorChange($event)"
                    formControlName="bgColor" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="5" nzSm="5" nzXs="12">
              <nz-form-item>
                <nz-form-label>&nbsp;</nz-form-label>
                <nz-form-control>
                  <label nz-checkbox (ngModelChange)="addSubmoduloPadrao($event)" [(ngModel)]="sistemaLegado"
                    formControlName="sistemaLegado">Sistema Legado</label>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label>App Servidor Windows</nz-form-label>
                <nz-form-control>
                  <input nz-input maxlength="255" formControlName="nomeAplicacaoServidorWindows"
                    placeholder="Nome da aplicação do servidor Windows">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Link</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="link" type="text" maxlength="200" placeholder="link">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="sistemaLegado" nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Servidor Cloud</nz-form-label>
                <nz-form-control>
                  <nz-select nz-input formControlName="idServidorCloud" style="width: 100%"
                    nzPlaceHolder="Selecione o Servidor-Cloud" [(ngModel)]="idServidorCloudSelectValue">
                    <nz-option *ngFor="let servidorCloud of servidoresCloudArr" [nzValue]="servidorCloud.idPrivado"
                      [nzLabel]="servidorCloud.nomeServidor">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5 m-b-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="p-b-10 p-l-20">Submódulos</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <!-- <button [disabled]="verificaEntidadeVazia(form.value.entidades)" type="button" nbButton outline
                status="primary" (click)="addEntidade()"><nb-icon icon="plus"></nb-icon></button> -->
              <button nz-button [disabled]="verificaSubmoduloVazio(form.value.moduloSubmodulos)"
                (click)="addSubmodulo()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div formArrayName="moduloSubmodulos">
            <div nz-row nzGutter="12"
              *ngFor="let moduloSubmodulos of form.get('moduloSubmodulos')['controls']; index as i" [formGroupName]="i">
              <div nz-col [nzSpan]="form.value.moduloSubmodulos.length > 1?23:24"
                [nzSm]="form.value.moduloSubmodulos.length > 1?23:24" nzXs="20">
                <nz-form-item>
                  <nz-form-label nzRequired>Nome</nz-form-label>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input formControlName="desSubmodulo" maxlength="255" (keydown)="showModalSubmodulo(i)" nz-input
                      placeholder="Nome do Submodulo" (click)="showModalSubmodulo(i)" />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button *ngIf="subModuloEntity[i]" (click)="removeSubmodulo(i)" nz-button type="button"
                      nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>
                    <button nz-button nzType="primary" (click)="showModalSubmodulo(i)" nzSearch><span nz-icon
                        nzType="search"></span></button>
                  </ng-template>
                </nz-form-item>


              </div>

              <div nz-row [nzSpan]="1" [nzSm]="1" nzXs="4" *ngIf="form.value.moduloSubmodulos.length > 1">
                <nz-form-item>
                  <nz-form-label>&nbsp;</nz-form-label>
                  <nz-form-control>
                    <button nzDanger nzType="primary" *ngIf="form.value.moduloSubmodulos.length > 1" nz-button outline
                      (click)="removeSubmodulo(i)"> <i nz-icon nzType="delete"></i></button>
                  </nz-form-control></nz-form-item>
              </div>
            </div>


          </div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldModulosSelected"
                              (ngModelChange)="getFilterModuloOptionsSelect(fieldModulosSelected)">
                              <nz-option *ngFor="let interval of arrColumnsModulosSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="fieldModulosSelected && fieldModulosSelected.type === 'input'" nz-col nzSpan="8"
                            [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group>
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>

                          <div *ngIf="fieldModulosSelected && fieldModulosSelected.type === 'boolean'" nz-col nzSpan="4"
                            class="p-t-10" [nzOffset]="this.openModal?2:0" nzXl="4" nzLg="6" nzXs="12">


                            <div nz-col nzSpan="24">
                              <nz-radio-group class="style-radio" [(ngModel)]="searchInput" nzName="radiogroup">
                                <label nz-radio [nzValue]="'S'">Sim</label>
                                <label nz-radio [nzValue]="'N'">Não</label>
                              </nz-radio-group>
                            </div>

                          </div>
                          <div
                            *ngIf="fieldModulosSelected && tabIndex == 1 && fieldModulosSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldModulosSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="
                                !searchInput
                              " (click)="addFiltroValuesPorCampo()" nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>

                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsModulo" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                            removeFiltroModuloPorField(fieldsValuesTag)
                            " class="filters" *ngFor="let fieldsValuesTag of fieldsModulosSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="fieldsModulosSelected.length !=0?openModal?7:8:openModal?19:20" nzSpan="3"
                            nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterModulo()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #ModuloListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableModuloColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="ModuloListTable.data" let-index="index">
            <tr (click)="selectItem(item)" (dblclick)="dblclick(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tableModuloColumns">
                <td *ngIf="checkList[column.value] && !(column.value === 'icone'||column.value === 'splash')">
                  {{ prepareColumnValue(item, column) }}
                </td>
                <td *ngIf="checkList[column.value] && (column.value === 'icone'||column.value === 'splash')">
                  <div><nz-avatar nzIcon="picture" [nzShape]="'square'" *ngIf="column.value === 'icone'"
                      [nzSrc]="item[column.value]"></nz-avatar>
                  </div>
                  <div><nz-avatar nzIcon="picture" [nzShape]="'square'" *ngIf="column.value === 'splash'"
                      [style.background-color]="item['bgColor']" [nzSrc]="item[column.value]"></nz-avatar>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>