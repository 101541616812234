import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OrgaoComponent } from '../orgao/orgao.component';
import { PessoaFisicaComponent } from '../pessoa-fisica/pessoa-fisica.component';
import { UnidadeOrcamentariaService } from '../shared/services/unidade-orcamentaria.service';
import { AuthService } from './../shared/services/auth.service';
import { UnidadeGestoraComponent } from './../unidade-gestora/unidade-gestora.component';

@Component({
  selector: 'ps-painel-administrativo-app-unidade-orcamentaria',
  templateUrl: './unidade-orcamentaria.component.html',
  styleUrls: ['./unidade-orcamentaria.component.scss'],
})
export class UnidadeOrcamentariaComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    nomeUnidade: true,
  };
  pessoaFisica;
  orgao;
  intervaloDigitando;

  unidadeGestora;
  radioValue;
  openModal: boolean = false;
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};

  //UnidadeOrcamentaria, trocar para o nome da entidade arrColumnsUnidadesOrcamentariasSelect
  tableUnidadeOrcamentariaColumns = [];
  titulo: string = 'Unidade Orçamentária';
  entidade: string = 'unidade-orcamentaria';
  fieldUnidadesOrcamentariasSelected;
  arrColumnsUnidadesOrcamentariasSelect: any[];
  showComboTagsExemplos: any[];
  selectedUnidadesOrcamentariasValues;
  showComboTagsUnidadesOrcamentarias;
  searchInput: any;
  fieldsUnidadesOrcamentariasSelected = [];

  constructor(
    private service: UnidadeOrcamentariaService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigUnidadeOrcamentariaTable();
      await this.setConfigUnidadeOrcamentariaFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigUnidadeOrcamentariaFilter() {
    this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableUnidadeOrcamentariaColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigUnidadeOrcamentariaTable() {
    this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsUnidadesOrcamentarias = result.tableColumnsProps.map(
          (column) => {
            return {
              ...column,
              compare:
                column.value === 'id' || column.value === 'id_uf'
                  ? (a, b) => a[column.value] - b[column.value]
                  : (a, b) => a[column.value].localeCompare(b[column.value]),

              // width:
              //   column.value === 'idPublico'
              //     ? '8rem'
              //     : column.value === 'titulo'
              //     ? '15rem'
              //     : column.value === 'dtCriacao'
              //     ? '20rem'
              //     : null,
            };
          }
        );
        this.tableUnidadeOrcamentariaColumns = tableColumnsUnidadesOrcamentarias;

        this.arrColumnsUnidadesOrcamentariasSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      id: [null],
      codigoOrgao: [{ value: null, disabled: true }],
      idPublico: [null, Validators.required],
      nomeUnidade: [null, Validators.required],
      siglaUnidade: [null, Validators.required],
      normaCriacao: [null],
      dataNormaCriacao: [null],
      finalidadeOrgao: [null],
      instrumentoCriacao: [null],
      idOrgao: [null],
      idPublicoOrgao: [null, Validators.required],
      nomeOrgao: [{ value: null, disabled: true }],
      idUnidadeGestora: [null, Validators.required],
      nomUnidadeGestora: [{ value: null, disabled: true }],
      idPublicoUnidadeGestora: [null],
      usuarioCriacao: [this.authService.getIdUser()],
      aplicacaoOrigem: [1],
      dtCricao: [new Date()],
      ordenadoresDespesa: this.formBuilder.array([]),
    });
    this.addOrdenador();
    this.setValidateStatus([
      'idPublico',
      'idPublicoUnidadeGestora',
      'nomeUnidade',
      'idPublicoOrgao',
      'nomeOrgao',
      'idUnidadeGestora',
      'siglaUnidade',
    ]);
  }

  addOrdenador() {
    const ordenadorForm: FormArray = this.form.get(
      'ordenadoresDespesa'
    ) as FormArray;
    ordenadorForm.push(
      this.formBuilder.group({
        idPrivado: [null],
        idPublico: [null],
        pessoaFisica: [null],
        pFNaoEncontrada: [null],
        messagePFNaoEncontrada: [null],
        intervaloDigitando: [null],
        cpf: [null],
        nome: [{ value: null, disabled: true }],
        cargo: [null],
        dataNomeacao: [null],
        situacao: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      // {
      //   icon: 'select',
      //   tooltip: 'Selecionar',
      //   condition:
      //     this.openModal && this.tabIndex === 1,
      //   onClick: this.selecionar,
      // },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: this.form.value.id ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 && (!this.form.value.id || this.form.value.id),
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterUnidadeOrcamentariaOptionsSelect(item) {}

  novoCadastro = () => {
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  editarUnidadeOrcamentaria = (item) => {
    this.prepareToUpdateForm(item);
  };

  doubleClick(item) {
    this.openModal ? null : this.editarUnidadeOrcamentaria(item);
  }

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {};

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  async prepareToUpdateForm(uniadadeOrcamentaria) {
    const unidadeOrcamentaria = [uniadadeOrcamentaria];

    if (unidadeOrcamentaria.length > 0) {
      this.clearAllFormArray('ordenadoresDespesa');
      for (let i = 0; i < unidadeOrcamentaria.length; i++) {
        this.addOrdenador();
      }
    }

    this.form.patchValue({ ...uniadadeOrcamentaria });
    this.consultaUg(uniadadeOrcamentaria.idUnidadeGestora, true);
    this.consultaOrgao(uniadadeOrcamentaria.orgao.idPublico);

    this.form
      .get('ordenadoresDespesa')
      ['controls'].map((ordenador, index) =>
        this.consultaPessoaFisica(ordenador.get('cpf').value, index, true)
      );

    this.changeTabIndex(0);
  }

  async consultaPessoaFisica(cpf, index, refresh = false) {
    if (!cpf || cpf?.length < 11) {
      return;
    }
    if (
      this.form.get(`ordenadoresDespesa.${index}.pessoaFisica`).value?.cpf ===
        cpf &&
      !refresh
    ) {
      return;
    }

    clearTimeout(
      this.form.get(`ordenadoresDespesa.${index}.intervaloDigitando`).value
    );
    this.form
      .get(`ordenadoresDespesa.${index}.pFNaoEncontrada`)
      .setValue(false);
    this.form.get(`ordenadoresDespesa.${index}.intervaloDigitando`).setValue(
      await setTimeout(async () => {
        await this.service
          .consultaPessoaFisica(cpf)
          .then((result) => {
            this.form.get(`ordenadoresDespesa.${index}`).patchValue({
              pessoaFisica: result.data,
              cpf: result.data.cpf,
              nome: result.data.pessoa.nomPessoa,
            });
          })
          .catch((err) => {
            this.form.get(`ordenadoresDespesa.${index}`).patchValue({
              pessoaFisica: err.data,
              pFNaoEncontrada: true,
              messagePFNaoEncontrada: err.message,
              idPessoaFisica: null,
              nomePessoaFisica: null,
              emailPessoaFisica: null,
            });
          });
      }, 500)
    );
  }

  async consultaOrgao(codigo) {
    if (
      codigo &&
      (codigo !== this.form.get('codigoOrgao').value || !this.orgao)
    ) {
      clearTimeout(this.intervaloDigitando);
      this.intervaloDigitando = await setTimeout(async () => {
        this.service
          .consultaOrgao(codigo)
          .then(async (result) => {
            this.orgao = result.data;
            this.form.patchValue({
              idOrgao: this.orgao.id,
              nomeOrgao: this.orgao.nomeOrgao,
              codigoOrgao: this.orgao.idPublico,
              idPublicoOrgao: this.orgao.idPublico,
            });
          })
          .catch((err) => {
            this.orgao = null;

            this.form.patchValue({
              idOrgao: null,
              nomeOrgao: null,
              codigoOrgao: null,
              idPublicoOrgao: null,
            });
          });
      }, 500);
    }
  }

  async consultaUg(codigo, isIdPrivado = false) {
    if (
      codigo &&
      (codigo !== this.unidadeGestora?.idPublico || !this.unidadeGestora)
    ) {
      clearTimeout(this.intervaloDigitando);

      this.intervaloDigitando = await setTimeout(async () => {
        this.service
          .consultaUnidadeGestora(codigo, isIdPrivado)
          .then(async (result) => {
            this.unidadeGestora = result.data;
            this.form.patchValue({
              idUnidadeGestora: this.unidadeGestora.id,
              nomUnidadeGestora: this.unidadeGestora.razaoSocialPessoaJuridica,
              idPublicoUnidadeGestora: this.unidadeGestora.idPublico,
            });
          })
          .catch((err) => {
            this.unidadeGestora = null;

            this.form.patchValue({
              idUnidadeGestora: null,
              nomUnidadeGestora: null,
              idPublicoUnidadeGestora: null,
            });
          });
      }, 500);
    }
  }

  clearAllFormArray(formArray) {
    const form: FormArray = this.form.get(formArray) as FormArray;
    form.controls.splice(0, form.controls.length);
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  async pageChange(valor) {
    await this.getUnidadeOrcamentariaPerPeriod(
      this.fieldsUnidadesOrcamentariasSelected,
      Number(valor),
      10
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  limparOrgao() {
    this.form.patchValue({
      idOrgao: null,
      nomeOrgao: null,
      codigoOrgao: null,
      idPublicoOrgao: null,
    });
    this.orgao = null;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterUnidadeOrcamentaria() {
    return this.getUnidadeOrcamentariaPerPeriod(
      this.fieldsUnidadesOrcamentariasSelected
    );
  }

  showModalOrgao() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Orgão`,
      nzContent: OrgaoComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((orgao) => {
      this.orgao = orgao;
      this.form.patchValue({
        idOrgao: orgao.id,
        nomeOrgao: orgao.nomeOrgao,
        codigoOrgao: orgao.idPublico,
        idPublicoOrgao: orgao.idPublico,
      });
    });
  }

  showModalUg() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Orgão`,
      nzContent: UnidadeGestoraComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((unidadeGestora) => {
      this.unidadeGestora = unidadeGestora;
      this.form.patchValue({
        idUnidadeGestora: this.unidadeGestora.id,
        nomUnidadeGestora: this.unidadeGestora.razaoSocialPessoaJuridica,
        idPublicoUnidadeGestora: this.unidadeGestora.idPublico,
      });
    });
  }

  limparUg() {
    this.form.patchValue({
      idUnidadeGestora: null,
      nomUnidadeGestora: null,
      idPublicoUnidadeGestora: null,
    });
    this.unidadeGestora = null;
  }

  showModalCpfOrdenador(index) {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Ordenador`,
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisica) => {
      this.form.get(`ordenadoresDespesa.${index}`).patchValue({
        pessoaFisica: pessoaFisica,
        cpf: pessoaFisica.cpf,
        nome: pessoaFisica.pessoa.nomPessoa,
      });
    });
  }

  limparCpfOrdenador(index) {
    this.form.get(`ordenadoresDespesa.${index}`).patchValue({
      pessoaFisica: null,
      cpf: null,
      nome: null,
    });
  }

  verificaOrdenadorVazio(ordenadores): boolean {
    return ordenadores.some((ordenador) => ordenador.pessoaFisica);
  }

  removeOrdenador(i) {
    if (this.form.get(`ordenadoresDespesa.${i}.idPrivado`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover Ordenador?</i>`,
        nzOnOk: () => {
          this.loadingManutencao = true;
          this.service
            .excluirFilho(
              this.form.value.id,
              this.form.get(`ordenadoresDespesa.${i}.idPrivado`).value,
              'ordenador-despesa',
              this.entidade,
              this.titulo
            )
            .then(() => {
              this.loadingManutencao = false;
            });
          const form: FormArray = this.form.get(
            'ordenadoresDespesa'
          ) as FormArray;
          form.removeAt(i);
        },
      });
    }

    const form: FormArray = this.form.get('ordenadoresDespesa') as FormArray;
    form.removeAt(i);
  }

  showModalPessoaFisica() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: 'Selecione um tipo de documento',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisica) => {
      // this.pessoaFisica = pessoaFisica;
      // (this.form.get('pessoaResponsaveis') as any).controls[index].patchValue({
      //   idPessoaFisica: pessoaFisica.id,
      //   cpf: pessoaFisica.cpf,
      //   nome: pessoaFisica.pessoa.nomPessoa,
      //   email: pessoaFisica.pessoa.emailPrincipal,
      // });
    });
  }

  removeFiltroUnidadeOrcamentariaPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableUnidadeOrcamentariaColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsUnidadesOrcamentariasSelect.splice(
      this.tableUnidadeOrcamentariaColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsUnidadesOrcamentariasSelected.splice(
      this.fieldsUnidadesOrcamentariasSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsUnidadesOrcamentariasSelected.length === 0) {
      this.showComboTagsUnidadesOrcamentarias = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getUnidadeOrcamentariaPerPeriod(
    fieldsUnidadesOrcamentariasSelected,
    page?: number,
    size?: number
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsUnidadesOrcamentariasSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (UnidadeOrcamentaria: any) => {
        if (UnidadeOrcamentaria.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = UnidadeOrcamentaria.data.paramsPaginator[0];
        this.displayData = UnidadeOrcamentaria.data.resultado.map((result) => {
          return {
            ...result,
            instrumentoCriacao: Number(result.instrumentoCriacao),
          };
        });

        this.loadingPage = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    if (this.form.value.id && this.form.value.id > 0) {
      this.update(this.form).then((result: any) =>
        result.data ? this.reset() : null
      );
      return;
    }

    this.inserirUnidade(this.form).then((result: any) =>
      result.data ? this.reset() : null
    );
  }
  async update(unidadeOrcamentaria) {
    unidadeOrcamentaria = this.unidadeFormToCadastroUnidade(
      unidadeOrcamentaria
    );
    return await this.execute(unidadeOrcamentaria);
  }

  async inserirUnidade(unidadeOrcamentaria) {
    unidadeOrcamentaria = this.unidadeFormToCadastroUnidade(
      unidadeOrcamentaria
    );
    return await this.execute(unidadeOrcamentaria);
  }

  unidadeFormToCadastroUnidade(form) {
    form.get('nomeOrgao').enable();
    const unidade = form.value;
    unidade.ordenadoresDespesa.map((ordenador) =>
      !ordenador.idPrivado ? delete ordenador.idPrivado : null
    );
    return unidade;
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;

        await this.service.insertOrUpdate(registro, idMp, entidade);
        this.loadingPage = false;

        return this.reset();
      },
    });
  }

  reset() {
    this.limparOrgao();
    this.resetForm();
    this.limparUg();
    this.resetForm();
  }

  addFiltroValuesPorCampo() {
    if (this.fieldUnidadesOrcamentariasSelected && this.searchInput) {
      if (this.fieldUnidadesOrcamentariasSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldUnidadesOrcamentariasSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsUnidadesOrcamentariasSelected.length !== 0) {
        this.removeFiltroUnidadeOrcamentariaPorField(
          this.fieldsUnidadesOrcamentariasSelected[0]
        );
      }
      this.fieldsUnidadesOrcamentariasSelected.push({
        props: this.fieldUnidadesOrcamentariasSelected.value,
        field: this.fieldUnidadesOrcamentariasSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldUnidadesOrcamentariasSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsUnidadesOrcamentariasSelect.findIndex(
        (a) => a.value === this.fieldUnidadesOrcamentariasSelected.value
      );

      this.arrColumnsUnidadesOrcamentariasSelect.splice(index, 1);

      this.showComboTagsUnidadesOrcamentarias = true;
      this.fieldUnidadesOrcamentariasSelected = '';
      this.searchInput = '';
      this.selectedUnidadesOrcamentariasValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
