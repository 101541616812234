import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    template: `
        <nz-range-picker *ngIf="type === 'date_range'" [nzMode]="mode" [formControl]="formControl" [formlyAttributes]="field"></nz-range-picker>
        <nz-date-picker *ngIf="type === 'date'" [nzMode]="mode" [formControl]="formControl" [formlyAttributes]="field" [nzFormat]="format"></nz-date-picker>
    `,
    styles: [
        'nz-date-picker { width: 100% }'
    ]
})
export class PsFormInputDateComponent extends FieldType {
    get type() {
        return this.to.type || 'date';
    }

    get mode() {
        return this.to.mode || 'date';
    }

    get format() {
        return this.to.format || 'dd/MM/yyyy HH:mm:ss';
    }
}
