import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-custom-loading',
  template: `
    <nz-spin nzTip="Carregando...">
      <!-- <nz-alert nzType="info" nzMessage="Alert message title" nzDescription="Further details about the context of this alert."> </nz-alert> -->
    </nz-spin>
    <!-- <ng-template #text></ng-template> -->
    <!-- <nz-spin [nzIndicator]="indicatorTemplate"></nz-spin> -->
    <ng-template #indicatorTemplate
      ><i nz-icon nzType="loading" nzTheme="outline"></i
    ></ng-template>
  `,
})
// tslint:disable-next-line: component-class-suffix
export class CustomLoadingOverlay implements ILoadingOverlayAngularComp {
  private params: any;

  agInit(params): void {
    this.params = params;
  }
}
