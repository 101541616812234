import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ISubmodulo } from './../interfaces/document-interfaces';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SubmoduloService extends AbstractService {
  private title: string = 'Submodulo';
  constructor(
    public http: HttpClient,
    public authService: AuthService,
    public notification: NzNotificationService
  ) {
    super('submodulo', http, authService, notification);
  }

  async createSubmodulo(submodulo) {
    return await this.postCreateSubmodule(submodulo)
      .then((resp: { msg: string; data: ISubmodulo[] }) => {
        // this.getManySubmoduloNav();
        this.notification.success(this.title, resp.msg);
        return resp;
      })
      .catch((err) => {
        this.notification.error(this.title, err.msg);
      });
  }

  async postCreateSubmodule(body): Promise<{ msg: string; data: unknown[] }> {
    return new Promise((resolve, reject) => {
      const postRequest: Subscription = this.http
        .post(
          `${this.getServer('submodulo')}/create`,
          body,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            postRequest.unsubscribe();
          },
        });
    });
  }

  // async listarSubmoduleNav(submodulo?: {
  //   descricao: string;
  // }): Promise<unknown> {
  //   return await this.getManySubmoduloNav(submodulo)
  //     .then((resp: { msg: string; data: ISubmodulo[] }) => resp)
  //     .catch((err) => {
  //       this.notification.error(this.title, err.msg);
  //     });
  // }

  // async getManySubmoduloNav(params?: {
  //   descricao: string;
  // }): Promise<{ msg: string; data: unknown[] }> {
  //   return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
  //     const getRequest: Subscription = this.http
  //       .get(
  //         `${this.getServer('submodulo')}/listar?${this.buildUrlQuery(
  //           params as {
  //             [key: string]: string | number | boolean;
  //           }
  //         )}`,
  //         this.headerAuthenticateOptions()
  //       )
  //       .pipe(
  //         tap((response: { msg: string; data: ISubmodulo[] }) => {
  //           ModuloState.modulos = response.data;
  //         })
  //       )
  //       .subscribe({
  //         next: (response: { msg: string; data: ISubmodulo[] }) => {
  //           resolve(response);
  //         },
  //         error: (error) => {
  //           reject(error);
  //         },
  //         complete() {
  //           getRequest.unsubscribe();
  //         },
  //       });
  //   });
  // }

  private buildUrlQuery(params: {
    [key: string]: string | number | boolean;
  }): string {
    const arrQuery: string[] = new Array();
    for (const key in params) {
      if (params[key]) {
        arrQuery.push(`${key}=${params[key]}`);
      }
    }

    return arrQuery.join('&');
  }

  public async deleteSubmodulo(idPrivado: number) {
    return await this.deleteSoftDeleteSetor(idPrivado)
      .then((resp: { msg: string; data: any[] }) => {
        this.notification.success(this.title, resp.msg);
        return resp.data as ISubmodulo[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.msg}. err: ${err}`);
      });
  }

  private deleteSoftDeleteSetor(idPrivado: number) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const putRequest: Subscription = this.http
        .delete(
          `${this.getServer('submodulo')}/delete/${idPrivado}`,
          this.headerAuthenticateOptions()
        )
        .pipe(
          tap(() => {
            // this.listarSubmoduleNav();
          })
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            putRequest.unsubscribe();
          },
        });
    });
  }

  async updateModulo(submodulo) {
    return await this.putUpdateSubmodulo(submodulo)
      .then((resp: { msg: string; data: any[] }) => {
        // this.listarSubmoduleNav();
        this.notification.success(this.title, resp.msg);
        return resp.data as ISubmodulo[];
      })
      .catch((err) => {
        this.notification.error(this.title, `${err.msg}. err: ${err}`);
      });
  }

  private putUpdateSubmodulo(body) {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const putRequest: Subscription = this.http
        .put(
          `${this.getServer('submodulo')}/update`,
          body,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: unknown[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            putRequest.unsubscribe();
          },
        });
    });
  }

  async getOne(
    params?: Partial<ISubmodulo>
  ): Promise<{ msg: string; data: ISubmodulo } | void> {
    return await this.getOneSubmodulo(params).catch((err) => {
      this.notification.error(this.title, err.message);
    });
  }

  async getOneSubmodulo(
    modulo: Partial<ISubmodulo>
  ): Promise<{ msg: string; data: ISubmodulo }> {
    return new Promise<{ msg: string; data: ISubmodulo }>((resolve, reject) => {
      const subscription: Subscription = this.http
        .get(
          `${this.getServer('submodulo')}/get-one?${this.buildUrlQuery(
            modulo as { [key: string]: string | number }
          )}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: ISubmodulo }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            subscription.unsubscribe();
          },
        });
    });
  }

  async listarSubmodule(submodulo?: { descricao: string }): Promise<unknown> {
    return await this.getManySubmodulo(submodulo)
      .then((resp: { msg: string; data: ISubmodulo[] }) => {
        // this.listarSubmoduleNav();
        return resp;
      })
      .catch((err) => {
        this.notification.error(this.title, err.msg);
      });
  }

  async getManySubmodulo(params?: {
    descricao: string;
  }): Promise<{ msg: string; data: unknown[] }> {
    return new Promise<{ msg: string; data: unknown[] }>((resolve, reject) => {
      const getRequest: Subscription = this.http
        .get(
          `${this.getServer('submodulo')}/get-many?${this.buildUrlQuery(
            params as {
              [key: string]: string | number | boolean;
            }
          )}`,
          this.headerAuthenticateOptions()
        )
        .subscribe({
          next: (response: { msg: string; data: ISubmodulo[] }) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
          complete() {
            getRequest.unsubscribe();
          },
        });
    });
  }
}
