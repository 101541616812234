<footer class="footer">
  <hr>
  <div class="footer-content justify-content-between m-r-0">
    <div class="d-flex align-self-center">
      <p class="mb-0">
        <!-- <span
          class="cursor-pointer mr-2 text-gray"
          nz-tooltip
          [nzTooltipTitle]="'Versão atual'"
          >v{{ version }}</span
        > -->
        
        Copyright © <a [href]="config.company.url">{{ config.company.name }}</a>
        {{ date | date: "yyyy" }}
      </p>
    </div>

    <span class="social-icons">
      <a
        [href]="'https://www.instagram.com/publicsoft/?hl=pt-br'"
        target="_blank"
        class="text-gray m-r-20"
      >
        <i nz-icon [nzType]="'instagram'" nzTheme="outline"></i>
      </a>
      <a
        [href]="'https://www.facebook.com/PublicSoftOficial/'"
        target="_blank"
        class="text-gray m-r-20"
      >
        <i nz-icon [nzType]="'facebook'" nzTheme="outline"></i>
      </a>
      <a
        [href]="'https://twitter.com/publicsoft'"
        target="_blank"
        class="text-gray"
      >
        <i nz-icon [nzType]="'twitter'" nzTheme="outline"></i>
      </a>
    </span>

    <!-- <span *ngIf="config.hasIcons">
            <a *ngFor="let icon of config.icons" [href]="icon.url" target="_blank" class="text-gray m-r-15">
                <i nz-icon [nzType]="icon.type" nzTheme="outline"></i>
            </a>
        </span> -->
  </div>
</footer>
