<!-- <nz-card [nzTitle]="'Filtros'" *ngIf="settings.showFilters">
  <form nz-form accessible-form [form]="form" [nzLayout]="'vertical'">
    <ps-form [fields]="fields"></ps-form>
    <button nz-button>Filtrar <i nz-icon nzType="filter"></i></button>
  </form>
</nz-card> -->
<!-- <ps-filter></ps-filter> -->

<!-- {{ locale?.rowGroupColumnsEmptyMessage | json }}
{{ gridActivated }} -->
<!-- {{ gridOptions| json }} -->
<!-- <button (click)="gridActivated = !gridActivated">Destruir</button> -->
<!-- <nz-spin nzSimple [nzIndicator]="indicatorTemplate"> Loading</nz-spin> -->
<!-- <nz-spin [nzSpinning]="!gridActivated" [nzIndicator]="indicatorTemplate"> -->
  <!-- Loading: <i nz-icon nzType="loading"></i> -->
<ag-grid-angular
  *ngIf="isAgGrid && gridActivated"
  class="ag-grid-table ag-theme-material"
  [gridOptions]="gridOptions"
  [columnDefs]="columnDefs"
  [rowGroupPanelShow]="'always'"
  [rowData]="rowData"
  [localeText]="locale"
  (gridReady)="onGridReady($event)"
  [loadingOverlayComponent]="loadingOverlayComponent"
  [frameworkComponents]="frameworksComponent"
  [defaultColDef]="defaultColDef"
></ag-grid-angular>

<!-- </nz-spin> -->
<ng-template #indicatorTemplate>
  <span class="ag-overlay-loading-center">Please wait while your rows are loading</span>
</ng-template>
<!-- <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template> -->
