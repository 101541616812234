<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="titulo" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
    <div body>
      <nz-spin [nzSpinning]="loadingManutencao">
        <h4 class="m-t-15 m-l-20">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div class="p-l-20 p-r-20 d-flex x-end y-center row">
          <nz-checkbox nz-checkbox status="primary" [ngModelOptions]="{standalone: true}" [(ngModel)]="cadastroBasico"
            (ngModelChange)="changeTipoCadastro($event)">Cadastro
            básico</nz-checkbox>
        </div>
        <form nz-form [formGroup]="form" nzLayout="vertical">

          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
            <input type="hidden" formControlName="id">

            <div nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>CNPJ</nz-form-label>
                <nz-form-control>
                  <input nz-input fullWidth formControlName="cnpj" [mask]="'00.000.000/0000-00'" placeholder="CNPJ">

                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="!cadastroBasico" nz-col nzSpan="16" nzSm="16" nzXs="24">
              <nz-form-item>
                <nz-form-label>Nome fantasia</nz-form-label>
                <nz-form-control>
                  <input nz-input fullWidth formControlName="nomFantasia" placeholder="Nome fantasia" maxlength="120">

                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="cadastroBasico" nz-col [nzSpan]="cadastroBasico?'16':'12'" [nzSm]="cadastroBasico?'16':'12'"
              nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Razão social</nz-form-label>
                <nz-form-control>
                  <input nz-input fullWidth formControlName="razaoSocial" placeholder="Razão social">
                </nz-form-control>
              </nz-form-item>
            </div>


          </div>
          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
            <div *ngIf="!cadastroBasico" nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Razão social</nz-form-label>
                <nz-form-control>
                  <input nz-input fullWidth formControlName="razaoSocial" placeholder="Razão social">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div *ngIf="!cadastroBasico" nz-col nzSpan="12" nzSm="12" nzXs="24">
              <nz-form-item>
                <nz-form-label>Site</nz-form-label>
                <nz-form-control>
                  <input nz-input fullWidth formControlName="site" placeholder="Site">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row *ngIf="!cadastroBasico" [nzGutter]="18" class="p-l-20 p-r-20">
            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>
                  Data de abertura
                </nz-form-label>
                <nz-date-picker fullWidth formControlName="dtAbertura" nzFormat="dd/MM/yyyy"></nz-date-picker>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>Inscrição estadual</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="inscricaoEstadual" placeholder="Inscrição estadual" maxlength="20">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>Inscrição municipal</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="inscricaoMunicipal" placeholder="Inscrição municipal" maxlength="20">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="6" nzSm="6" nzXs="24">
              <nz-form-label>
                Tipo
              </nz-form-label>
              <nz-form-control>
                <nz-select fullWidth nzPlaceHolder="Tipo" formControlName="tipo">
                  <nz-option nzLabel="Matriz" [nzValue]=0></nz-option>
                  <nz-option nzLabel="Filial" [nzValue]=1></nz-option>
                </nz-select>
              </nz-form-control>
            </div>
          </div>

          <div nz-row *ngIf="!cadastroBasico" [nzGutter]="18" class="p-l-20 p-r-20">
            <div nz-col nzSpan="15" nzSm="15" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Natureza jurídica</nz-form-label>
                <nz-form-control formGroupName="natJuridica">
                  <nz-select nzShowSearch nzAllowClear formControlName="descricao" nzPlaceHolder="Selecione o campo"
                    [(ngModel)]="naturezaJuridicaSelected"
                    (ngModelChange)="preencherCampoNaturezaJuridica(naturezaJuridicaSelected)">
                    <nz-option *ngFor="let interval of arrNaturezaJuridica" [nzValue]="interval.nome"
                      [nzLabel]="interval?.text">
                    </nz-option>
                  </nz-select>

                  <input *ngIf="false" nz-input formControlName="codigo" placeholder="codigo" maxlength="20">
                  <!--<nz-select nzShowSearch nzServerSearch formControlName="descricao"
                    [nzPlaceHolder]="'Cód. Cnae Subclasse'" [nzShowArrow]="false" [nzFilterOption]="nzFilterOption"
                    class="naturalidade" [(ngModel)]="selectednatJuridica"
                    (ngModelChange)="preencherCampoNaturezaJuridica(selectednatJuridica)"
                    (nzOnSearch)="consultaNaturezaJuridica($event)">
                    <nz-option (click)="preencherCampoNaturezaJuridica(selectednatJuridica)"
                      *ngFor="let option of gruposNaturezasJuridica" [nzLabel]="option.descricao" [nzValue]="option">
                    </nz-option> 
                  </nz-select>-->
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <!----->
          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="m-b-0 p-l-20">Contatos</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <button nz-button [disabled]="!verificaContatoVazio(form.value.contatos)" (click)="addContato()"
                nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div *ngIf="form.get('contatos').value.length > 0" formArrayName="contatos">
            <div nz-row *ngFor="let contato of form.get('contatos')['controls']; index as i" [formGroupName]="i">
              <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                <div>
                  <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                    <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>E-mail</nz-form-label>
                        <nz-form-control>
                          <input nz-input [type]="'email'" formControlName="email" placeholder="E-mail">

                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>Telefone</nz-form-label>
                        <nz-form-control>
                          <input nz-input formControlName="telefone" (keyup)="setMaskFone(i)"
                            (blur)="setMaskFone(i, true)" placeholder="Telefone"
                            [mask]="contato.value.telefone?.length === 10 ? '(00) 0000-0000' : '(00) 00000-0000'">

                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="whatsapp">Whatsapp</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div *ngIf="contato.value.telefone?.length > 10" nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item>
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="telegram">Telegram</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                      <nz-form-item *ngIf="verificaContatoPrincipal(contato.value.principal)">
                        <nz-form-label>&nbsp;</nz-form-label>
                        <nz-form-control>
                          <label nz-checkbox formControlName="principal">Principal</label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>


                  </div>
                </div>
              </fieldset>
              <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                <button [disabled]="!verificaContatoVazio(form.value.contatos)" *ngIf="form.value.contatos.length > 1"
                  class="m-t-20" nz-button nzDanger [disabled]="false" nzType="primary" (click)="removeContato(i)">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>
            </div>
          </div>
          <!----->

          <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div nz-row>
            <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="m-b-0 p-l-20">Responsável(s) legal(s)</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <button nz-button [disabled]="!pessoaService.verificaPessoaRespVazio(form.value.pessoaResponsaveis)"
                (click)="addResponsavel()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

          <div formArrayName="pessoaResponsaveis">
            <div nz-row *ngFor="let responsavel of form.get('pessoaResponsaveis')['controls']; index as i"
              [formGroupName]="i">
              <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                <div>
                  <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                    <div nz-col nzSpan="7" nzSm="7" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>CPF</nz-form-label>
                        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                          <input [mask]="'000.000.000-00'" formControlName="cpf" (keydown)="showModalPessoaFisica(i)"
                            nz-input placeholder=" CPF" (click)="showModalPessoaFisica(i)" />
                        </nz-input-group>
                        <ng-template #suffixIconButton>
                          <button *ngIf="pessoaFisica" (click)="limpaCpf(i)" nz-button type="button" nzType="text"
                            nzSearch>
                            <i nz-icon nzType="close-circle"></i>
                          </button>
                          <button nz-button nzType="primary" (click)="showModalPessoaFisica(i)" nzSearch><span nz-icon
                              nzType="search"></span></button>
                        </ng-template>
                      </nz-form-item>
                    </div>

                    <div nz-col *ngIf="false" nzSpan="5" nzSm="5" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>id</nz-form-label>
                        <nz-form-control>
                          <input nz-input (click)="showModalPessoaFisica(i)" [mask]="'000.000.000-00'"
                            formControlName="id" placeholder="id">

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col *ngIf="false" nzSpan="5" nzSm="5" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>idPessoaFisica</nz-form-label>
                        <nz-form-control>
                          <input nz-input (click)="showModalPessoaFisica(i)" [mask]="'000.000.000-00'"
                            formControlName="idPessoaFisica" placeholder="idPessoaFisica">

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>Nome</nz-form-label>
                        <nz-form-control>
                          <input nz-input formControlName="nome" placeholder="Nome">

                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                      <nz-form-item>
                        <nz-form-label nzRequired>E-mail</nz-form-label>
                        <nz-form-control>
                          <input nz-input [type]="'email'" formControlName="email" placeholder="E-mail">

                        </nz-form-control>
                      </nz-form-item>
                    </div>

                  </div>
                </div>
              </fieldset>

              <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                <button *ngIf="form.value.pessoaResponsaveis.length > 1" class="m-t-20" nz-button nzDanger
                  [disabled]="false" nzType="primary" (click)="removeResponsavel(i)">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>

            </div>
          </div>

          <nz-divider *ngIf="!cadastroBasico" class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
          <div *ngIf="!cadastroBasico" nz-row>
            <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
              <h4 class="m-b-0 p-l-20">CNAE</h4>
            </div>
            <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
              <!--https://angular.io/errors/NG0100-->
              <button nz-button [disabled]="!verificaCnaeVazio(form.value.pessoaJuridicaCnae)" (click)="addCnae()"
                nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>
          <nz-divider *ngIf="!cadastroBasico" class="m-t-0" nzOrientation="left"></nz-divider>
          <div *ngIf="!cadastroBasico" formArrayName="pessoaJuridicaCnae">
            <div nz-row *ngFor="let cnae of form.get('pessoaJuridicaCnae')['controls']; index as i" [formGroupName]="i">
              <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                  <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                    <nz-form-item>
                      <nz-form-label nzRequired>Cód. Cnae subclasse</nz-form-label>
                      <nz-form-control>

                        <nz-select nzShowSearch nzPlaceHolder="Cód. Cnae subclasse" [(ngModel)]="selectedValueCnae[i]"
                          (click)="setCnaeIndex(i)" (ngModelChange)="preencherCampoCnae(selectedValueCnae,i)"
                          formControlName="codigo">
                          <nz-option *ngFor="let option of optionsCnae" [nzValue]="option.codigo"
                            [nzLabel]="option?.texto">
                          </nz-option>
                        </nz-select>
                        <!-- <nz-select nzShowSearch nzServerSearch formControlName="codigo"
                          [nzPlaceHolder]="selectedValueCnae[i]?selectedValueCnae[i].texto:'Cód. Cnae Subclasse'"
                          [nzShowArrow]="false" [nzFilterOption]="nzFilterOption" class="naturalidade"
                          [(ngModel)]="selectedValue" (ngModelChange)="preencherCampoCnae(selectedValue,i)"
                          (nzOnSearch)="consultaCnae($event)">
                          <nz-option (click)="preencherCampoCnae(cnae, i)" *ngFor="let cnae of optionsCnae"
                            [nzLabel]="cnae.texto" [nzValue]="cnae">
                          </nz-option>
                        </nz-select> -->
                        <!-- <input nz-input formControlName="codigo" placeholder="Código"> -->

                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="9" nzSm="9" nzXs="24">
                    <nz-form-item>
                      <nz-form-label nzRequired>Nome</nz-form-label>
                      <nz-form-control>
                        <input nz-input [type]="'text'" formControlName="nome" placeholder="Nome Cnae Subclass">

                      </nz-form-control>
                    </nz-form-item>
                  </div>

                  <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                    <nz-form-item *ngIf="verificaCnaePrincipal(cnae.value.principal)">
                      <nz-form-label>&nbsp;</nz-form-label>
                      <nz-form-control>
                        <label nz-checkbox formControlName="principal">Principal</label>
                      </nz-form-control>
                    </nz-form-item>
                  </div>
                </div>

              </fieldset>

              <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                <button *ngIf="form.value.pessoaJuridicaCnae.length > 1" class="m-t-20" nz-button nzDanger
                  [disabled]="false" nzType="primary" (click)="removeCnae(i)">
                  <i nz-icon nzType="delete"></i>
                </button>
              </div>
            </div>
          </div>


          <div>
            <nz-divider class="m-t-0 m-b-15" nzOrientation="left"></nz-divider>
            <div nz-row>
              <div nz-col class="m-t-5" nzSpan="5" nzSm="5" nzXs="24">
                <h4 class="m-b-0 p-l-20">Endereços</h4>
              </div>
              <div class="m-b-15" nz-col nzOffset="18" nzSpan="1" nzSm="1" nzXs="24">
                <button nz-button [disabled]="!pessoaService.verificaEnderecoVazio(form.value.enderecos)"
                  nzType="primary" (click)="addEndereco()">
                  <i nz-icon nzType="plus"></i>
                </button>
              </div>
            </div>
            <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

            <div formArrayName="enderecos">
              <div nz-row *ngFor="let endereco of form.get('enderecos')['controls']; index as i" [formGroupName]="i">
                <fieldset nz-col nzSpan="23" nzSm="23" nzXs="24">
                  <div>
                    <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">
                      <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>CEP</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="cep" placeholder="CEP" [mask]="'00000-000'"
                              (blur)="consultaCep($event.target.value, i)"
                              [status]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="4" nzSm="4" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Número</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="numero" placeholder="Número" maxlength="10"
                              [nzStatus]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="12" nzSm="12" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Complemento</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="complemento" placeholder="Complemento" maxlength="200"
                              [nzStatus]="endereco.value.error ? 'danger' : ''">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                    <div nz-row [nzGutter]="18" class="p-l-20 p-r-20">

                      <div nz-col nzSpan="7" nzSm="7" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Logradouro</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomLogradouro" placeholder="Logradouro">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Bairro</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomBairro" placeholder="Bairro">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="6" nzSm="6" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>Cidade</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomCidade" placeholder="Cidade">
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col nzSpan="2" nzSm="2" nzXs="24">
                        <nz-form-item>
                          <nz-form-label>UF</nz-form-label>
                          <nz-form-control>
                            <input nz-input formControlName="nomUf" placeholder="UF">
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                      <div nz-col nzSpan="3" nzSm="3" nzXs="24">
                        <nz-form-item *ngIf="verificaEnderecoPrincipal(endereco.value.principal)">
                          <nz-form-label>&nbsp;</nz-form-label>
                          <nz-form-control>
                            <label nz-checkbox formControlName="principal">Principal</label>
                          </nz-form-control>
                        </nz-form-item>
                      </div>
                    </div>

                  </div>
                </fieldset>
                <div nz-col class="p-t-10" nzSpan="1" nzSm="1" nzXs="24">
                  <button *ngIf="form.value.enderecos.length > 1" class="m-t-20" nz-button nzDanger [disabled]="false"
                    nzType="primary" (click)="removeEndereco(i)">
                    <i nz-icon nzType="delete"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div nz-row [nzGutter]="18" class="p-l-20 p-r-20"></div>
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row nzGutter="16">
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldPessoasJuridicasSelected"
                              (ngModelChange)="getFilterPessoaJuridicaOptionsSelect(fieldPessoasJuridicasSelected)">
                              <nz-option *ngFor="let interval of arrColumnsPessoasJuridicasSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>

                          </div>
                          <div nz-col nzSpan="8" [nzOffset]="this.openModal?2:0" nzXl="8" nzLg="12" nzXs="24">
                            <nz-input-group
                              *ngIf="fieldPessoasJuridicasSelected && fieldPessoasJuridicasSelected.type === 'input'"
                              class="m-l-10 ">
                              <input type="text" nz-input class="text-disabled" placeholder="Informe um valor"
                                [(ngModel)]="searchInput" />
                            </nz-input-group>
                          </div>
                          <div
                            *ngIf="fieldPessoasJuridicasSelected && tabIndex == 1 && fieldPessoasJuridicasSelected.type === 'range-picker'"
                            nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>
                          <div nz-col *ngIf="fieldPessoasJuridicasSelected" nzSpan="2" nzXl="2" nzXs="12"
                            class="p-l-10">
                            <button nz-button [disabled]=" !searchInput " (click)=" addFiltroValuesPorCampo()"
                              nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                        <div nz-row class="m-t-10">
                          <div *ngIf="showComboTagsPessoaJuridica" class="p-0" nz-col nzSpan="12" nzXl="12" nzLg="12"
                            nzXs="24">
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                                removeFiltroPessoaJuridicaPorField(fieldsValuesTag)
                            " class="filters"
                                  *ngFor="let fieldsValuesTag of fieldsPessoasJuridicasSelected; let i = index ">
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div nz-col [class]="'m-t-10 p-l-20'"
                            [nzOffset]="fieldsPessoasJuridicasSelected.length !=0?openModal?7:8:openModal?19:20"
                            nzSpan="3" nzXl="3" nzLg="3" nzXs="24">

                            <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                              (click)="filterPessoaJuridica()">
                              <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loadingTable" [nzShowPagination]="false" #PessoaJuridicaListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="true" nzWidth="15px"></th>
            <th class="p-t-15 p-b-15" style="background: #edf1fd " nzWidth="60px" [nzLeft]="true"><label nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tablePessoaJuridicaColumns">
              <th class="p-t-15 p-b-15" nzWidth="150px" *ngIf="checkList[column.value]" nzWidth="{{column.width}}"
                [nzSortFn]="column.compare">
                {{ column.text }} </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="PessoaJuridicaListTable.data" let-index="index">
            <tr (dblclick)="doubleClick(item)" style="cursor: pointer" nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item)"></label>
              </td>
              <ng-container *ngFor="let column of tablePessoaJuridicaColumns">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination style="text-align: end" class="m-t-20" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"></nz-pagination>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>