import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

declare var $: any;

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[accessible-form]' })
// tslint:disable-next-line: directive-class-suffix
export class AccessibleForm {

    // tslint:disable-next-line: no-input-rename
    @Input('form') form: NgForm;

    constructor(private el: ElementRef) {

    }

    @HostListener('submit', ['$event'])
    onSubmit(event) {
        event.preventDefault();

        if (!this.form.valid) {
            let target: HTMLElement;

            target = this.el.nativeElement.querySelector('.ng-invalid');

            if (target) {
                $('html,body').animate({ scrollTop: $(target).offset().top - 300}, 'slow');
                target.focus();
            }
        }
    }
}
