import { EventEmitter } from '@angular/core';
import { TabType } from '../components';

export const USER_KEY_STORAGE = 'app.userInfo';

export function isTypeof(object: any, type: any) {
  return typeof object === type;
}

export function evalStringExpression(expression: string, argNames: string[]) {
  try {
    return Function(...argNames, `return ${expression};`) as any;
  } catch (error) {
    console.error(error);
  }
}

export class PsOnRouterChange {
  private static _change: EventEmitter<TabType> = new EventEmitter<TabType>();
  public static set emitRouterChange(event) {
    this._change.emit(event);
  }

  public static get routerChanges$(): EventEmitter<TabType> {
    return this._change;
  }
}
