import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ITreeview } from './../interfaces/exportacao-interface';
import {
  AbstractError,
  IColumnsFieldsProps,
  IResponseFieldsAnexoProps,
  ISearchFieldsProps,
} from './../interfaces/shared.interface';
import { PeriodVisualizeDoc } from './../types/document.types';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ExportacaoArquivosService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super(
      'exportacaoArquivos',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  async isZipFileAvaliable(uuid: string) {
    return this.http
      .get(
        this.generateUrlByEnvironment(
          '3030',
          `exporter/file-avaliable/${uuid}`,
          'exportador-digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return result as { message: string; data: any };
      })
      .catch((err) => {
        return err as { message: string; data: any };
      });
  }

  async getAllUsCreations(): Promise<Array<unknown>> {
    let usuarioCriacaoArr: Array<unknown> = new Array<unknown>();
    await this.http
      .get(
        this.getServer('exportacao') + '/usuario-criacao/get-all',
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: Array<unknown> }) => {
        usuarioCriacaoArr = result.data;
      })
      .catch((err) => {
        this.notification.warning(
          'Erro ao carregar filtro de usuário criação',
          err
        );
        return err as { message: string; data: any };
      });
    return usuarioCriacaoArr;
  }

  async downloadFileAvaliable(uuid: string) {
    window.open(
      this.generateUrlByEnvironment(
        '3030',
        `exporter/download/${uuid}`,
        'exportador-digitalizacao'
      ),
      '_blank'
    );
  }

  async startDownloadZipFiles(files: any) {
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '3030',
          `exporter/`,
          'exportador-digitalizacao'
        ),
        files,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        // this.notification.success(
        //   'Exportação de arquivos',
        //   'Seu arquivo está sendo preparado para download',
        // );
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; data: any };
      });
  }

  async deleteArquivosExportados(filesExporteds: ITreeview) {
    return await this.http
      .delete(
        this.generateUrlByEnvironment(
          '3334',
          `exportacao/delete/${filesExporteds.idPrivado}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success(
          'Exportação de arquivos',
          'Arquivos excluídos com sucesso.'
        );
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; data: any };
      });
  }

  getDateFormattedUtcOffset(date) {
    if (date) {
      return moment(date).utcOffset(0, true).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  getDateFormatted(date) {
    if (date) {
      return moment(date).format('DD/MM/yyyy HH:mm:ss');
    }
  }

  getDateFormatted3h(date) {
    if (date) {
      return moment(date).utcOffset(-360).format('DD/MM/yyyy HH:mm:ss');
    }
  }
  async getAllFilesExporteds() {
    return await this.http
      .get(
        this.generateUrlByEnvironment(
          '3334',
          'exportacao/exportar/get-all',
          'digitalizacao'
        )
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success(
          'Exportação de arquivos',
          'arquivos exportados com sucesso'
        );
        return result as { message: string; data: any };
      })
      .catch((err) => {
        this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; data: any };
      });
  }

  async getAllFilesExportedsInInterval(
    fieldsFilesSelected = []
    // checkeAll = false,
    // // todos = false,
    // skip = 1,
    // take = 10,
  ): Promise<any> {
    const filtrosCombinados = [];

    for (const field of fieldsFilesSelected) {
      filtrosCombinados.push(field);
    }
    const newObject = {
      filtrosCombinados:
        fieldsFilesSelected.length > 0 ? filtrosCombinados : null,
    };

    // const paramsPaginator = skip ? `skip=${skip}&take=${take}` : '';
    return this.http
      .post(
        this.generateUrlByEnvironment(
          '3334',
          `exportacao/params?`,
          'digitalizacao'
        ),
        { newObject },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return {
          data: result.data,
          where: newObject,
        };
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  async getFileExportedsTableProps(
    entidade: string = null
  ): Promise<IResponseFieldsAnexoProps> {
    const camposPesquisa:
      | ISearchFieldsProps[]
      | Array<{ text: string; value: any; type: string }> = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    return await this.http
      .get(
        `${this.getServer(entidade)}/any/props`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((response: { message: string; data: any }) => {
        // camposPesquisa.push({
        //   text: 'Todos',
        //   value: 'todos',
        // });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
              type: response.data[p].type,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
              type: response.data[p].type,
            });
          }
        }
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return {
          searchColumns: camposPesquisa,
          tableColumnsAnexo: colunasTabela,
        };
      });
  }

  getAllDocumentsInInterval(
    typePeriod?: PeriodVisualizeDoc,
    assinantesSelected = [],
    interval?: number,
    avulsos?: boolean,
    customInterval?: string[],
    statusFiltros?: number[],
    fieldsSelected = [],
    checkAll = false,
    skip = 1,
    take = 10,
    searchAnexos = false
  ): Promise<any> {
    let paramAssinantes;
    let paramUsuario;
    let paramStatus;
    if (assinantesSelected.length > 0 && assinantesSelected.length <= 1) {
      paramAssinantes =
        assinantesSelected[0].idPessoaFisica ||
        assinantesSelected[0].getIdResponsavel;

      paramUsuario = assinantesSelected[0].idUsuario;
    }

    if (assinantesSelected.length > 1) {
      const newArr = assinantesSelected.map((ass) =>
        Number(ass.idPessoaFisica)
      );
      const newUsersArr = assinantesSelected.map((ass) =>
        Number(ass.idUsuario)
      );
      (paramAssinantes = newArr), (paramUsuario = newUsersArr);
    }

    // if(fieldsSelected.length > 0)  {
    //   const newArr = assinantesSelected.map((ass) =>
    //   Number(ass.idPessoaFisica),
    // );
    // const newUsersArr = assinantesSelected.map((ass) =>
    //   Number(ass.idUsuario),
    // );
    // params2 = params2.set(
    //   'signers',
    //   (paramAssinantes = `&assinante=${newArr}`),
    // );
    // params3 = params3.set(
    //   'users',
    //   (paramUsuario = `&usuario=${newUsersArr}`),
    // );
    // }

    if (statusFiltros?.length > 0 && statusFiltros?.length <= 1) {
      paramStatus = statusFiltros[0];
    }

    if (statusFiltros?.length > 1) {
      paramStatus = statusFiltros;
    }

    const newObject = {
      paramInterval: interval,
      initialDate: customInterval ? customInterval[0] : null,
      finalDate: customInterval ? customInterval[1] : null,
      type: typePeriod,
      avulso: avulsos,
      assinante: paramAssinantes ? paramAssinantes : null,
      usuario: paramUsuario ? paramUsuario : null,
      status: paramStatus,
      filtrosCombinados:
        fieldsSelected.length > 0 && !typePeriod
          ? fieldsSelected.filter(
              (column) => !['assinante'].includes(column.props)
            )
          : null,
      selectAnexos: searchAnexos ? true : false,
      todos: checkAll,
    };

    const paramsPaginator = skip ? `skip=${skip}&take=${take}` : '';
    // const params = `${type}${paramInterval}${paramCustomInterval}${signers}${users}${avulso}${status}${paramsPaginator}`;
    // const params = `${type}${paramInterval}${paramCustomInterval}${signers}${users}${avulso}${status}`;

    return this.http
      .post(
        this.generateUrlByEnvironment(
          '3334',
          `${
            searchAnexos ? `documento-anexo` : `document`
          }/minimal?${paramsPaginator}`,
          'digitalizacao'
        ),
        { newObject },
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return {
          data: result.data,
          where: newObject,
        };
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  async getFileExportByUuidUpload(uuidUpload, relations: boolean = false) {
    return await this.http
      .get(
        `${this.generateUrlByEnvironment(
          '3334',
          `exportacao/${uuidUpload}`,
          'digitalizacao'
        )}?relations=${relations}`
      )
      .toPromise()
      .then((result: any) => {
        // this.notification.success(
        //   'Exportação de arquivos',
        //   'arquivos exportados com sucesso',
        // );
        return result as { message: string; data: any };
      })
      .catch((err) => {
        // this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; data: any };
      });
  }

  async putArquivosExportados(editFileExported: ITreeview) {
    return await this.http
      .put(
        this.generateUrlByEnvironment(
          '3334',
          `exportacao/file-export/${editFileExported.hash}`,
          'digitalizacao'
        ),
        editFileExported,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success('Exportação de arquivos', result.message);
        return result as { message: string; result: any };
      })
      .catch((err) => {
        this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; result: any };
      });
  }

  async getArquivosExportadosByHash(psHash: ITreeview) {
    return await this.http
      .get(
        this.generateUrlByEnvironment(
          '3334',
          `exportacao/treeview/${psHash}`,
          'digitalizacao'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        // this.notification.success('Exportação de arquivos', result.message);
        return result as { message: string; data: any };
      })
      .catch((err) => {
        return err as { message: string; data: any };

        // this.notification.warning('Exportação de arquivos', err);
        // return err as { message: string; result: any };
      });
  }

  async postArquivosExportados(arquivos: ITreeview) {
    return await this.http
      .post(
        this.generateUrlByEnvironment(
          '3334',
          'exportacao/exportar',
          'digitalizacao'
        ),
        arquivos,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        this.notification.success(
          'Exportação de arquivos',
          'Arquivos salvos com sucesso.'
        );
        return result as { message: string; result: any };
      })
      .catch((err) => {
        this.notification.warning('Exportação de arquivos', err);
        return err as { message: string; result: any };
      });
  }
}
