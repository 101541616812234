import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { IPerfil } from '../shared/interfaces/perfil.interface';
import { AuthService } from '../shared/services/auth.service';
import { PerfilService } from '../shared/services/perfil.service';
import { UnidadeGestoraComponent } from './../unidade-gestora/unidade-gestora.component';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
  checkedAll?: boolean;
}

interface FSEntry {
  id: any;
  idChildren: any;
  name: string;
  checked: boolean;
  type: string;
}

@Component({
  selector: 'ps-painel-administrativo-app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  teste: boolean = false;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    desPerfil: true,
    nomPerfil: true,
  };
  checkboxAdm: boolean;
  checkboxPerfilAtivo: boolean;
  unidadeGestora;
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};

  //perfil, trocar para o nome da entidade arrColumnsPerfisSelect
  tablePerfilColumns = [];
  titulo: string = 'Perfil';
  openModal: boolean = false;
  entidade: string = 'perfil';
  fieldPerfisSelected;
  arrColumnsPerfisSelect: any[];
  showComboTagsExemplos: any[];
  selectedPerfisValues;
  showComboTagsPerfis;
  searchInput: any;
  fieldsPerfisSelected = [];
  perfis: TreeNode<FSEntry>[] = [];
  errorTreeGrid: string;

  constructor(
    private service: PerfilService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigPerfilTable();
      await this.setConfigPerfilFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  showModalUg() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Orgão`,
      nzContent: UnidadeGestoraComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((unidadeGestora) => {
      this.unidadeGestora = unidadeGestora;
      this.form.patchValue({
        idUnidadeGestora: unidadeGestora.id,
        razaoSocialPessoaJuridica: this.unidadeGestora
          .razaoSocialPessoaJuridica,
      });
      if (!this.form.value.id) {
        this.montarTreeGrid();
      }
    });
  }

  limparUg() {
    this.form.patchValue({
      id: null,
      razaoSocialPessoaJuridica: null,
    });
    this.unidadeGestora = null;
    !this.form.value.id ? this.montarTreeGrid() : null;
  }

  async montarTreeGrid(permissoes = null) {
    this.perfis = [];
    if (!this.unidadeGestora) {
      return;
    }
    this.loadingPage = true;
    const result = (await this.service.consultaPermissaoUg(this.unidadeGestora))
      .permissoes;

    if (permissoes && permissoes.length > 0) {
      result.map((moduloPermissao) => {
        permissoes.map((moduloPerfil) => {
          if (moduloPermissao.id === moduloPerfil.id) {
            moduloPermissao.checked = true;

            moduloPermissao.submodulos.map((submoduloPermissao) => {
              moduloPerfil.submodulos.map((submoduloPerfil) => {
                if (submoduloPermissao.id === submoduloPerfil.id) {
                  submoduloPermissao.checked = true;
                  submoduloPermissao.formularios.map((formularioPermissao) => {
                    submoduloPerfil.formularios.map((formularioPerfil) => {
                      if (formularioPermissao.id === formularioPerfil.id) {
                        formularioPermissao.checked = true;
                        formularioPermissao.cadastrar =
                          formularioPerfil.cadastrar;
                        formularioPermissao.alterar = formularioPerfil.alterar;
                        formularioPermissao.excluir = formularioPerfil.excluir;
                        formularioPermissao.visualizar =
                          formularioPerfil.visualizar;
                        formularioPermissao.executar =
                          formularioPerfil.executar;
                      }
                    });
                  });
                }
              });
            });
          }
        });
      });
    }
    result.map((modulo) => {
      const submodulos = [];
      modulo.submodulos.map((submodulo) => {
        const formularios = [];
        submodulo.formularios.map((formulario) => {
          formularios.push({
            data: {
              id: formulario.id,
              idChildren: submodulo.id,
              name: formulario.nomFormulario,
              type: 'formulario',
              checked: formulario.checked,
            },
            checkedAll: submodulo.formularios.every(
              (form) => form.checked === true
            ),
            children: [
              {
                data: {
                  id: null,
                  idChildren: formulario.id,
                  name: 'Cadastrar',
                  checked:
                    formulario.cadastrar && formulario.cadastrar === 1
                      ? formulario.cadastrar
                      : false,
                  type: 'permissao',
                },
              },
              {
                data: {
                  id: null,
                  idChildren: formulario.id,
                  name: 'Alterar',
                  checked:
                    formulario.alterar && formulario.alterar === 1
                      ? formulario.alterar
                      : false,
                  type: 'permissao',
                },
              },
              {
                data: {
                  id: null,
                  idChildren: formulario.id,
                  name: 'Excluir',
                  checked:
                    formulario.excluir && formulario.excluir === 1
                      ? formulario.excluir
                      : false,
                  type: 'permissao',
                },
              },
              {
                data: {
                  id: null,
                  idChildren: formulario.id,
                  name: 'Visualizar',
                  checked:
                    formulario.visualizar && formulario.visualizar === 1
                      ? formulario.visualizar
                      : false,
                  type: 'permissao',
                },
              },
              {
                data: {
                  id: null,
                  idChildren: formulario.id,
                  name: 'Executar',
                  checked:
                    formulario.executar && formulario.executar === 1
                      ? formulario.executar
                      : false,
                  type: 'permissao',
                },
              },
            ],
          });
        });
        submodulos.push({
          data: {
            id: submodulo.id,
            idChildren: modulo.id,
            name: submodulo.nomSubmodulo,
            checked: formularios.some((formulario) => formulario.data.checked),
            type: 'submodulo',
          },
          checkedAll: modulo.submodulos.every((mod) => mod.checked === true),
          children: formularios,
        });
      });
      this.perfis.some((perfil) => perfil.data.id === modulo.id)
        ? ''
        : this.perfis.push({
            data: {
              id: modulo.id,
              idChildren: null,
              name: modulo.nomModulo,
              checked: submodulos.some((formulario) => formulario.data.checked),
              type: 'modulo',
            },
            // checkedAll: submodulos.every(() => modulo.data.checked === true),
            children: submodulos,
          });
    });

    // this.perfis = this.perfis.filter((perfil, index, perfis) => {
    //   return this.perfis.find((fPerfil) => fPerfil.data.id === perfil.data.id);
    // });

    this.loadingPage = false;
    this.errorTreeGrid =
      result.length === 0 ? 'Nenhuma permissão encontrada' : '';
  }

  async setConfigPerfilFilter() {
    // this.loadingPage = true;

    await this.service.getTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tablePerfilColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigPerfilTable() {
    // this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsPerfis = result.tableColumnsProps.map((column) => {
          return {
            ...column,
            compare:
              column.value === 'id' || column.value === 'id_uf'
                ? (a, b) => a[column.value] - b[column.value]
                : (a, b) => a[column.value].localeCompare(b[column.value]),

            // width:
            //   column.value === 'idPublico'
            //     ? '8rem'
            //     : column.value === 'titulo'
            //     ? '15rem'
            //     : column.value === 'dtCriacao'
            //     ? '20rem'
            //     : null,
          };
        });
        this.tablePerfilColumns = tableColumnsPerfis;

        this.arrColumnsPerfisSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  checkAllPerfil(permissao: any) {
    permissao.map((child) => (child.data.checked = !child.data.checked));
  }

  checkPerfil(permissao: TreeNode<FSEntry>): void {
    // permissao.data.checked
    //   ? permissao.children.forEach((child) => (child.data.checked = false))
    //   : null;
    this.checkFalseRecursive(permissao);
  }

  checkFalseRecursive(permissao: TreeNode<FSEntry>): void {
    permissao.data.checked = false;
    if (permissao.children) {
      permissao.children.forEach((child) => this.checkFalseRecursive(child));
    }
    // if (permissao.children.length === 0) {
    //   return;
    // }
    // for (const child of permissao.children) {
    //   child.data.checked = false;

    //   if (child.children) {
    //     if (permissao.children.length !== 0) {f
    //       for (const kChild of child.children) {
    //         this.checkFalseRecursive(kChild);
    //       }
    //     }
    //   }
    // }
  }

  resetForm() {
    this.form = this.formBuilder.group({
      id: [null],
      nomPerfil: [null, Validators.required],
      desPerfil: [null, Validators.required],
      perfilAtivo: [true],
      adm: [null],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      razaoSocialPessoaJuridica: [null, Validators.required],
      idUnidadeGestora: [null, Validators.required],
      perMod: this.formBuilder.array([
        this.formBuilder.group({
          idPerfil: [null],
          idModulo: [null],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
          perSub: this.formBuilder.array([
            this.formBuilder.group({
              idPerfilModulo: [null],
              idSubmodulo: [null],
              aplicacaoOrigem: [1],
              usuarioCriacao: [this.authService.getIdUser()],
              perFor: this.formBuilder.array([
                this.formBuilder.group({
                  id: [null],
                  aplicacaoOrigem: [1],
                  usuarioCriacao: [this.authService.getIdUser()],
                  cadastrar: [false],
                  alterar: [false],
                  excluir: [false],
                  visualizar: [false],
                  executar: [false],
                }),
              ]),
            }),
          ]),
        }),
      ]),
    });

    this.setValidateStatus([
      'nomPerfil',
      'desPerfil',
      'idUnidadeGestora',
      'razaoSocialPessoaJuridica',
    ]);
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }
  buscaEmProfundidade(node: TreeNode<FSEntry>, depth?: number): boolean {
    if (depth >= 2) {
      return node.data.checked;
    }
    for (const child of node.children) {
      const childChecked = this.buscaEmProfundidade(child, depth + 1);
      if (childChecked) {
        return true;
      }
    }
    return false;
  }

  checkForm(modulo): boolean {
    const search = this.buscaEmProfundidade(modulo, 0);
    return search;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterPerfilOptionsSelect(item) {}

  novoCadastro = () => {
    this.reset();
    this.changeTabIndex(0);
  };

  salvar = () => {
    this.onSubmit();
  };

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  doubleClick = (registro) => {
    this.openModal ? this.selectPerfil(registro) : this.editarPerfil(registro);
  };
  selectPerfil = (registro) => {};

  editarPerfil = (registro) => {
    this.prepareToUpdateForm(registro);
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterPerfil();
  };

  ngOnInit(): void {}

  getWidthContent() {
    return window.innerWidth;
  }

  async prepareToUpdateForm(perfil) {
    this.form.get('id').setValue(perfil.id);
    this.form.get('nomPerfil').setValue(perfil.nomPerfil);
    this.form.get('desPerfil').setValue(perfil.desPerfil);
    this.form.get('perfilAtivo').setValue(perfil.perfilAtivo);
    this.form.get('adm').setValue(perfil.adm);
    this.form.get('usuarioCriacao').setValue(perfil.usuarioCriacao);
    this.form.get('aplicacaoOrigem').setValue(perfil.aplicacaoOrigem);
    this.form.get('dtCriacao').setValue(perfil.dtCriacao);
    this.loadingPage = true;
    await this.service
      .consultaUgPorId(perfil.idUnidadeGestora)
      .then((result: any) => {
        this.unidadeGestora = result.data;
        this.form.get('idUnidadeGestora').setValue(result.data.id);
        this.form
          .get('razaoSocialPessoaJuridica')
          .setValue(result.data.razaoSocialPessoaJuridica);
      });

    const permissoes = [];
    for (const periflModulo of perfil.perMod) {
      await this.service
        .consultarPorParametro(
          { field: 'id', value: periflModulo.idModulo },
          'modulo'
        )
        .then(async (modulo: any) => {
          if (modulo.data) {
            const submodulos = [];
            for (const perfilSubmodulo of periflModulo.perSub) {
              await this.service
                .consultarPorParametro(
                  { field: 'id', value: perfilSubmodulo.idSubmodulo },
                  'submodulo-sistema'
                )
                .then((submodulo: any) => {
                  this.loadingPage = false;
                  if (submodulo.data) {
                    const formularios = [];
                    perfilSubmodulo.perFor.map((perfilFormulario) => {
                      submodulo.data.formularios.map((formulario) => {
                        if (perfilFormulario.idFormulario === formulario.id) {
                          formularios.push({
                            id: perfilFormulario.idFormulario,
                            usuarioCriacao: perfilFormulario.usuarioCriacao,
                            aplicacaoOrigem: perfilFormulario.aplicacaoOrigem,
                            nomFormulario: formulario.nomFormulario,
                            cadastrar: perfilFormulario.cadastrar,
                            alterar: perfilFormulario.alterar,
                            excluir: perfilFormulario.excluir,
                            visualizar: perfilFormulario.visualizar,
                            executar: perfilFormulario.executar,
                          });
                        }
                      });
                    });
                    submodulos.push({
                      id: submodulo.data.id,
                      nomSubmodulo: submodulo.data.nomSubmodulo,
                      usuarioCriacao: submodulo.data.usuarioCriacao,
                      aplicacaoOrigem: submodulo.data.aplicacaoOrigem,
                      formularios: formularios,
                    });
                  }
                });
            }
            permissoes.push({
              id: modulo.data.id,
              nomModulo: modulo.data.nomModulo,
              usuarioCriacao: modulo.data.usuarioCriacao,
              aplicacaoOrigem: modulo.data.aplicacaoOrigem,
              submodulos: submodulos,
            });
          }
        });
    }
    this.montarTreeGrid(permissoes);
    this.changeTabIndex(0);
  }

  reset() {
    this.resetForm();
    this.limparUg();
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    await this.inserirOuAtualizar(this.form, this.perfis).then((result: any) =>
      result.data ? this.reset() : null
    );
  }

  async inserirOuAtualizar(perfil, permissoes) {
    // perfil = this.prepareToRequest(perfil, permissoes);
    permissoes = this.editarPermicoesCheckedToDataRecursive(permissoes);
    permissoes = this.editarPermicoesCheckedToDataRecursive(permissoes);
    permissoes = this.editarPermicoesCheckedToDataRecursive(permissoes);

    return await this.execute(perfil, permissoes);
  }

  editarPermicoesCheckedToDataRecursive(
    permissoes: Array<TreeNode<FSEntry>>
  ): Array<TreeNode<FSEntry>> {
    for (const permissao of permissoes) {
      if (permissao['children'] && permissao['children'].length !== 0) {
        permissao.data = {
          ...permissao.data,
          checked: permissao.children.some((child) => child.data.checked),
        };
      }
      for (const key in permissao) {
        if (key === 'children' && permissao.children.length !== 0) {
          this.editarPermicoesCheckedToDataRecursive(permissao.children);
        }
      }
    }

    return permissoes as Array<TreeNode<FSEntry>>;
  }

  async execute(registro, permissoes, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        registro = this.prepareToRequest(registro, permissoes);

        await this.service.insertOrUpdate(registro, idMp, entidade);
        this.loadingPage = false;
        return this.reset();
      },
    });
  }

  prepareToRequest(perfil, permissoes) {
    perfil = this.perfilForm(perfil, permissoes);
    return perfil;
  }

  perfilForm(form, permissoes): IPerfil | any {
    permissoes = permissoes.filter((modulo) => modulo.data.checked);
    permissoes.map(
      (modulo) =>
        (modulo.children = modulo.children.filter(
          (submodulo) => submodulo.data.checked
        ))
    );
    permissoes.map((modulo) =>
      modulo.children.map(
        (submodulo) =>
          (submodulo.children = submodulo.children.filter(
            (formulario) => formulario.data.checked
          ))
      )
    );
    return {
      id: form.get('id').value,
      nomPerfil: form.get('nomPerfil').value,
      desPerfil: form.get('desPerfil').value,
      perfilAtivo: form.get('perfilAtivo').value ? 1 : 0,
      adm: form.get('adm').value ? 1 : 0,
      aplicacaoOrigem: form.get('aplicacaoOrigem').value,
      usuarioCriacao: form.get('usuarioCriacao').value,
      idUnidadeGestora: form.get('idUnidadeGestora').value,
      perMod: permissoes.map((perfilModulo) => {
        return {
          idModulo: perfilModulo.data.id,
          usuarioCriacao: form.get('usuarioCriacao').value,
          aplicacaoOrigem: form.get('aplicacaoOrigem').value,
          perSub: perfilModulo.children.map((perfilSubmodulo) => {
            return {
              idSubmodulo: perfilSubmodulo.data.id,
              usuarioCriacao: form.get('usuarioCriacao').value,
              aplicacaoOrigem: form.get('aplicacaoOrigem').value,
              perFor: perfilSubmodulo.children.map((perfilFormulario) => {
                return {
                  idFormulario: perfilFormulario.data.id,
                  usuarioCriacao: form.get('usuarioCriacao').value,
                  aplicacaoOrigem: form.get('aplicacaoOrigem').value,
                  cadastrar: perfilFormulario.children[0].data.checked ? 1 : 0,
                  alterar: perfilFormulario.children[1].data.checked ? 1 : 0,
                  excluir: perfilFormulario.children[2].data.checked ? 1 : 0,
                  visualizar: perfilFormulario.children[3].data.checked ? 1 : 0,
                  executar: perfilFormulario.children[4].data.checked ? 1 : 0,
                };
              }),
            };
          }),
        };
      }),
    };
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade).then(() => {
          this.loadingPage = false;
          this.removerRegistroGrid(registro);
        });
      },
      nzOnCancel: async () => {
        this.loadingPage = false;
      },
    });
  }

  removerRegistroGrid(item: any): void {
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterPerfil() {
    return this.getPerfilPerPeriod(this.fieldsPerfisSelected);
  }

  removeFiltroPerfilPorField(fieldsValuesTag) {
    const tableExportColumn = this.tablePerfilColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsPerfisSelect.splice(
      this.tablePerfilColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsPerfisSelected.splice(
      this.fieldsPerfisSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsPerfisSelected.length === 0) {
      this.showComboTagsPerfis = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async pageChange(valor) {
    await this.getPerfilPerPeriod(this.fieldsPerfisSelected, Number(valor), 10);
  }
  hasSubmoduleChecked(modulo): boolean {
    let submodulos = modulo.children;
    return !!submodulos.some((submodulo) => !!submodulo.data.checked);
    //usando o if porque se retirar o for assim que ver o return encerra suas interações
  }

  async getPerfilPerPeriod(fieldsPerfisSelected, page?: number, size?: number) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsPerfisSelected,
        entidade: this.entidade,
        page,
        size,
        titulo: this.titulo,
      })
      .then(async (perfil: any) => {
        if (perfil.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = perfil.data.paramsPaginator[0];
        this.displayData = perfil.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        // this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldPerfisSelected && this.searchInput) {
      if (this.fieldPerfisSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldPerfisSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsPerfisSelected.length !== 0) {
        this.removeFiltroPerfilPorField(this.fieldsPerfisSelected[0]);
      }
      this.fieldsPerfisSelected.push({
        props: this.fieldPerfisSelected.value,
        field: this.fieldPerfisSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldPerfisSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsPerfisSelect.findIndex(
        (a) => a.value === this.fieldPerfisSelected.value
      );

      this.arrColumnsPerfisSelect.splice(index, 1);

      this.showComboTagsPerfis = true;
      this.fieldPerfisSelected = '';
      this.searchInput = '';
      this.selectedPerfisValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  selectItem(item) {}
}
