import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PsDirectiveModule } from '../directives';
import { SearchRecursivePipe } from '../pipes';
import { PsActionsComponent } from './ps-actions';
import { PsComplexFormComponent } from './ps-complex-form/ps-complex-form.component';
import { PsConsultaComponent } from './ps-consulta/ps-consulta.component';
import { PsContentComponent } from './ps-content/ps-content.component';
import { PsDividerComponent } from './ps-divider/ps-divider.component';
import { PsDynamicFormModule } from './ps-dynamic-form';
import { PsFilterModule } from './ps-filter/ps-filter.module';
import { PsFooterComponent } from './ps-footer/ps-footer.component';
import { PsGridModule } from './ps-grid/ps-grid.module';
import { PsHeaderComponent } from './ps-header/ps-header.component';
import { PsManutencaoComponent } from './ps-manutencao/ps-manutencao.component';
import { PsModalLupaComponent } from './ps-modal';
import { PsProcessoComponent } from './ps-processo/ps-processo.component';
import { PsRecursiveSidebarComponent } from './ps-recursive-sidebar/ps-recursive-sidebar.component';
import { PsSidebarComponent } from './ps-sidebar/ps-sidebar.component';
import { PsSimpleFormComponent } from './ps-simple-form/ps-simple-form.component';
import { PsTabSessionService } from './ps-tab-session';
import { PsTabSessionComponent } from './ps-tab-session/ps-tab-session.component';
import { PsTableComponent } from './ps-table/ps-table.component';
import { PsUniqueFormComponent } from './ps-unique-form/ps-unique-form.component';
import { SpeedDialFabComponent } from './speed-dial-fab';

@NgModule({
  declarations: [
    PsHeaderComponent,
    PsSidebarComponent,
    PsRecursiveSidebarComponent,
    PsFooterComponent,
    PsContentComponent,
    SearchRecursivePipe,
    PsDividerComponent,
    PsTabSessionComponent,
    PsActionsComponent,
    SpeedDialFabComponent,
    PsModalLupaComponent,
    PsConsultaComponent,
    PsManutencaoComponent,
    PsSimpleFormComponent,
    PsComplexFormComponent,
    PsUniqueFormComponent,
    PsTableComponent,
    PsProcessoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PsDynamicFormModule,
    PerfectScrollbarModule,
    PsDirectiveModule,
    PsGridModule.forRoot({
      gridType: 'antd',
    }),
    NgxMaskModule.forRoot(),
    NzAvatarModule,
    NzBadgeModule,
    NzEmptyModule,
    NzDatePickerModule,
    NzDrawerModule,
    NzSelectModule,
    NzDropDownModule,
    NzLayoutModule,
    NzIconModule,
    NzOutletModule,
    NzProgressModule,
    NzStatisticModule,
    NzToolTipModule,
    NzFormModule,
    NzTagModule,
    NzCardModule,
    NzTabsModule,
    NzSpinModule,
    NzGridModule,
    NzButtonModule,
    NzDividerModule,
    NzInputModule,
    NzMenuModule,
    NzSwitchModule,
    NzRadioModule,
    NzCalendarModule,
    NzTableModule,
    NzListModule,
    NzModalModule,
    NzCollapseModule,
    NzUploadModule,
    PsFilterModule,
  ],
  exports: [
    PsHeaderComponent,
    PsSidebarComponent,
    PsRecursiveSidebarComponent,
    PsFooterComponent,
    PsContentComponent,
    PsDividerComponent,
    PsTabSessionComponent,
    PsActionsComponent,
    SpeedDialFabComponent,
    PsModalLupaComponent,
    PsFilterModule,
    PsConsultaComponent,
    PsManutencaoComponent,
    PsSimpleFormComponent,
    PsComplexFormComponent,
    PsUniqueFormComponent,
    PsTableComponent,
    PerfectScrollbarModule,
    PsProcessoComponent,
  ],
  providers: [PsTabSessionService],
})
export class PsComponentsModule {}
