import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AbstractService } from './abstract.service';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root',
})
export class InstituicaoSiconfiService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('intituicao-siconfi', http, notification, authService);
  }



  getUfs(): Promise<any> {
    return this.http
      .get(
        `${this.getServer(
          'unidade-federativa'
        )}/pesquisar-avancado?page=1&size=1000`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        return result.data.resultado;
      });
  }


}
