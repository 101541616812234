import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzCardModule } from 'ng-zorro-antd/card';

import { AuthService, SistemaService } from '@ps-erp-angular/shared';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PsDynamicFormModule } from '../components';
import { PsDirectiveModule } from '../directives';
import { Page404Component } from './page-feedbacks';

@NgModule({
  declarations: [Page404Component],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    PsDirectiveModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    PsDynamicFormModule,
  ],
  exports: [],
  providers: [AuthService, SistemaService],
})
export class PsPagesModule {}
