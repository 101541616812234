
<div style="text-align: end">
  <p style="text-align: end">Atualizado em: {{ getDateNow }}</p>
</div>
<div class="row is-not-mobile">
  <div class="col-md-24 col-xl-12 p-b-15">
    <nz-collapse nzGhost>
      <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
        <div nz-row class="d-flex">
          <div nz-col nzSpan="24" nzXs="24">
            <div class="p-l-10 p-b-15" nz-col nzSpan="24">
              <div class="p-r-10">

                <div class="p-l-10" nz-col nzSpan="24">

                  <nz-select style="width: 350px" nzShowSearch nzAllowClear nzPlaceHolder="Busque pelo ID">
                  </nz-select>

                  <nz-avatar *ngIf="true" (click)="setFilterType(2)" class="m-l-10" [nzSize]="40" nzIcon="send"
                            nz-tooltip></nz-avatar>
                  
                          <button class="m-l-10" nzSize="small" nz-button nzType="primary"
                          (click)="getProcessos()">
                          <i nz-icon nzType="filter" nzTheme="outline"></i>Buscar
                        </button>
                        
                        <div class="m-t-10">
                          <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                            [(ngModel)]="fieldMensagensEnvioSelected"
                            (ngModelChange)="getFilterMensagemEnvioOptionsSelect(fieldMensagensEnvioSelected)">
                            <nz-option *ngFor="let interval of arrColumnsMensagensEnvioSelect" [nzValue]="interval"
                              [nzLabel]="interval?.text">
                            </nz-option>
                          </nz-select>
                        </div>
                  
                </div>

              </div>
            </div>
          </div>
        </div>

      </nz-collapse-panel>
    </nz-collapse>
  </div>
</div>

<div>
  <nz-table #basicTable [nzData]="listProcessos" class="m-b-20" nzSize="small" [nzPageSize]="10" [nzShowPagination]="true"
  [nzScroll]="{ y: '420px' }" style="overflow-y: overlay">
    <thead>
      <tr>
        <th style="background: #edf1fd" nzWidth="70px"></th>
        <th style="background: #edf1fd" [nzLeft]="getWindow() > 1024" nzWidth="70px">
            <label nz-checkbox ></label>
        </th>

        <ng-container>
         <th nzWidth="150px">ID</th>
        </ng-container>

        <ng-container>
          <th nzWidth="150px">Uusario</th>
        </ng-container>

        <ng-container>
          <th nzWidth="150px">Unidade Gestora</th>
        </ng-container>

        <ng-container>
          <th nzWidth="150px">Criado</th>
        </ng-container>

        <ng-container>
          <th nzWidth="150px">Atualizado</th>
        </ng-container>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let processo of basicTable.data">
        <td></td>
        <td></td>
        <td>
          {{ processo.id }}
        </td>
        <td>
          {{ processo.usuario.nome }}
        </td>
        <td>
          {{ processo.cnpjUnidadeGestora }}
        </td>
        <td>
          {{ processo.createdAt | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ processo.updateAt | date: 'dd/MM/yyyy' }}
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

