import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { PsComponentsModule } from '../components';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';

@NgModule({
  declarations: [FullLayoutComponent, CommonLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    PsComponentsModule,
    NzBreadCrumbModule,
    NzLayoutModule,
    NzIconModule,
  ],
  exports: [FullLayoutComponent, CommonLayoutComponent],
})
export class PsLayoutModule {}
