import {
  AbstractError,
  IColumnsFieldsProps,
  IResponseFieldsProps,
  ISearchFieldsProps,
} from './../interfaces/shared.interface';
import { DocumentService } from './document.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Helper } from '../helper';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PessoaJuridicaService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService,
    public documentService: DocumentService
  ) {
    super(
      'pessoaJuridica',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  async getDocumentTableProps(
    entidade: string = null
  ): Promise<IResponseFieldsProps> {
    const camposPesquisa: ISearchFieldsProps[] = [];
    const colunasTabela: IColumnsFieldsProps[] = [];
    // return await this.http.get(`${this.getServer(entidade)}/props`, this.headerAuthenticateOptions()).toPromise()
    return new Promise((resolve) =>
      resolve({
        message: 'sucesso',
        data: {
          cnpj: {
            isSearch: true,
            label: 'CNPJ',
            columnTable: true,
            mask: 'cnpj',
          },
          nomPessoa: {
            isSearch: true,
            label: 'Razão social',
            columnTable: true,
            objectChildren: ['pessoa'],
          },
        },
      })
    )
      .then((response: { message: string; data: any }) => {
        camposPesquisa.push({
          text: 'Todos',
          value: 'todos',
        });

        for (const p in response.data) {
          if (response.data[p].isSearch) {
            camposPesquisa.push({
              text: response.data[p].label,
              value: p,
            });
          }
        }
        for (const p in response.data) {
          if (response.data[p].columnTable) {
            colunasTabela.push({
              text: response.data[p].label,
              value: p,
              mask: response.data[p].mask ? response.data[p].mask : null,
              objectChildren: response.data[p].hasOwnProperty('objectChildren')
                ? response.data[p].objectChildren[0]
                : null,
            });
          }
        }
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      })
      .catch((err: AbstractError) => {
        this.notification.error('Propriedades', err.error.message);
        return { searchColumns: camposPesquisa, tableColumns: colunasTabela };
      });
  }

  async getPessoasJuridicas(parametrosPesquisa): Promise<any> {
    return await this.pesquisar(
      parametrosPesquisa,
      'pessoaJuridicaGlobal'
    ).then((result) => {
      return {
        pessoa: result.data.resultado,
        params: result.data.paramsPaginator,
      };
    });
  }

  async getPessoasFisicaById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaFisicaById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getPessoasResponsavelById(id: number) {
    return await this.http
      .get(
        `${this.getServer('pessoaResponsavelById')}${id}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async deletePessoasResponsavelById(id: number) {
    return await this.http
      .put(
        `${this.getServer('pessoaResponsavelDelete')}/${id}`,
        {},
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then(async (result: { message: string; data: any }) => {
        return result;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getPessoasJuridicasByCnpj(cnpj: string): Promise<any> {
    if (!Helper.validaCNPJ(cnpj)) {
      this.notification.warning('Pesquisa', 'CNPJ inválido');
      return;
    }

    return await this.http
      .get(
        `${this.getServer(
          'pessoaJuridica'
        )}/consultar-por-parametro?field=cnpj&value=${cnpj}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { message: string; data: any }) => result.data);
  }

  async inserir(form: any) {
    if (form.value.cnpj && !Helper.validaCNPJ(form.value.cnpj)) {
      return this.notification.warning('Pesquisa', 'CNPJ inválido');
    }
    return this.http
      .post(
        `${this.getServer('pessoaJuridica')}`,
        await this.formatToRequest(form),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((data) => {
        this.notification.success('Pessoa jurídica', 'Cadastrado com sucesso!');
        return data as { message: string; result: any };
      })
      .catch((err) => {
        this.notification.warning('Pessoa jurídica', err);
        return err as { message: string; result: any };
      });
  }

  async alterar(form: any) {
    if (form.value.cnpj && !Helper.validaCNPJ(form.value.cnpj)) {
      return this.notification.warning('Pesquisa', 'CNPJ inválido');
    }

    return this.http
      .put(
        `${this.getServer('pessoaJuridica')}`,
        await this.formatToRequest(form),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((data: any) => {
        this.notification.success('Pessoa jurídica', `${data.message}`);
      })
      .catch((err) => {
        this.notification.warning('Pessoa jurídica', err);
      });
  }

  async formatToRequest(form): Promise<any> {
    const searchPerson = form.value.pessoaResponsaveis.map((resp) => {
      return {
        id: resp.id,
        idPessoaFisica: resp.idPessoaFisica,
        usuarioCriacao: form.value.usuarioCriacao,
        aplicacaoOrigem: form.value.aplicacaoOrigem,
      };
    });

    form.controls.nomCidade.enable();
    form.controls.nomBairro.enable();
    form.controls.nomUf.enable();

    const formToInsert = {
      pessoa: {
        id: form.value.idPessoa,
        tipoPessoa: 1,
        usuarioCriacao: form.value.usuarioCriacao,
        aplicacaoOrigem: form.value.aplicacaoOrigem,
      },
      enderecos: [
        {
          id: form.value.idEndereco,
          cep: form.value.cep,
          nomBairro: form.value.nomBairro,
          nomCidade: form.value.nomCidade,
          nomLogradouro: form.value.nomLogradouro,
          complemento: form.value.complemento,
          numero: form.value.numero,
          codigoIbgeCidade: form.value.codigoIbgeCidade,
          nomUf: form.value.nomUf,
          principal: form.value.principal,
          usuarioCriacao: form.value.usuarioCriacao,
          aplicacaoOrigem: form.value.aplicacaoOrigem,
        },
      ],
      pessoaResponsaveis: searchPerson,
      usuarioCriacao: form.value.usuarioCriacao,
      aplicacaoOrigem: form.value.aplicacaoOrigem,
      razaoSocial: form.value.razaoSocial,
      cnpj: form.value.cnpj,
      id: form.value.id,
    };
    form.controls.nomCidade.disable();
    form.controls.nomBairro.disable();
    form.controls.nomUf.disable();
    return formToInsert;
  }

  async consultaEndereco(cep) {
    const infoToken = this.helper.decodeToken(this.authService.getToken());

    if (cep === '' || cep.length < 8) {
      return null;
    }
    return this.abstractHttp
      .get(
        `${this.getServer('logradouro')}/${
          infoToken.userInfo.idUg
        }/listar/${cep}`,
        this.headerAuthenticateOptions()
      )
      .toPromise();
  }
}
