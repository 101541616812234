import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Subscription } from "rxjs";
import { AbstractService } from "./abstract.service";
import { AuthService } from "./api/auth.service";

@Injectable()
export class SolicitacaoAlvaraService extends AbstractService {

  private titulo: string = 'Solicitação de Alvará'
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService 
  ) {
    super(
      'solicitacao-Alvara',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    )
  }

  public async getContribuinte(formParams?) {
    return await this.getGetContribuinte(formParams)
      .then((response: any) => {
        if(response.data === null) {
          throw new Error('Contribuinte não encontrado!')
        }
        return response.data
      })
    
  }

  private getGetContribuinte(formParams){
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(formParams).toString()}`;
      const localInfoString = localStorage.getItem('local-info');
      const localInfo = JSON.parse(localInfoString);

      const url = `${this.getServer('pessoa')}/juridica${formParams ? params : ''}`
      const subscription: Subscription = this.http.get(url, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`
        }),
      })
      .subscribe({
        next: (response) => {
          resolve(response)
        },
        error: (err) => {
          reject(err)
        },
        complete: () => {
          subscription.unsubscribe();
        }
      }) 
    })
  }

  public async postSolicitacaoAlvara(formParams, formData) {
    return await this.postPostSolicitacaoAlvara(formParams, formData)
      .then((resp: any) => {
        return resp.data;
      })
  }

  private postPostSolicitacaoAlvara(formParams, formData) {
    return new Promise((resolve, reject) => {
      const params = `?${new URLSearchParams(formParams).toString()}`
      const localInfoString = localStorage.getItem('local-info')
      const localInfo = JSON.parse(localInfoString);
      const url = `${this.getServer('alvara')}/solicitar-alvara`
      const subscription: Subscription = this.http
        .post(url, formData,{
          headers: new HttpHeaders({
            //'x-authorization': `${localInfo.cnpjInst}`,
            'x-authorization': `09073628000191`
          })
        })
        .subscribe({
          next: (resp) => {
            resolve(resp)
          },
          error: (err) => {
            reject(err);
          },
          complete: () => {
            subscription.unsubscribe();
          },
        })

    })
  }
}