import { Component, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'ps-painel-administrativo-app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss'],
})
export class ApiComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;

  tabIndex: 0 | 1 = 1;
  openModal: boolean;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData;
  pageIndex;
  pageTotal;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    nome: true,
    port: true,
  };

  //example, trocar para o nome da entidade arrColumnsExamplesSelect
  tableApiColumns = [];
  titulo: string = 'Api';
  entidade: string = 'api';
  fieldApisSelected;
  arrColumnsApisSelect: any[];
  showComboTagsApis: any[];
  selectedApisValues;
  showComboTagsApi;
  searchInput: any;
  fieldsApisSelected = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<ApiComponent>,
    private service: ApiService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigExampleTable();
      await this.setConfigExampleFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigExampleFilter() {
    // this.loadingPage = true;

    await this.service.getCidadesTableProps().then((result) => {
      Object.assign(this, result);

      this.service.setCompareToTableColumns(
        this.displayData,
        this.tableApiColumns
      );
    });
    this.loadingPage = false;
  }

  async setConfigExampleTable() {
    this.loadingPage = true;

    await this.service.getExamplesTableProps().then((result) => {
      const tableColumnsCidades = result.tableColumnsExample.map((column) => {
        return {
          ...column,
          compare:
            column.value === 'id' ||
            column.value === 'id_uf' ||
            column.value === 'port'
              ? (a, b) => a[column.value] - b[column.value]
              : (a, b) => a[column.value].localeCompare(b[column.value]),

          // width:
          //   column.value === 'idPublico'
          //     ? '8rem'
          //     : column.value === 'titulo'
          //     ? '15rem'
          //     : column.value === 'dtCriacao'
          //     ? '20rem'
          //     : null,
        };
      });
      this.tableApiColumns = tableColumnsCidades;

      this.arrColumnsApisSelect = result.searchColumns;
    });
    this.loadingPage = false;
  }

  resetForm() {
    //registrar os campos do formulário
    this.form = this.formBuilder.group({
      id: [null],
      codigo: [null, Validators.required],
      server: [null],
      nome: [null, Validators.required],
      port: [null, Validators.required],
      usuarioCriacao: [this.authService.getIdUser()],
      aplicacaoOrigem: [1],
      dtCriacao: [new Date()],
      entidades: this.formBuilder.array([
        this.formBuilder.group({
          id: [null],
          nome: [null, Validators.required],
          aplicacaoOrigem: [1],
          usuarioCriacao: [this.authService.getIdUser()],
          dtCriacao: [new Date()],
        }),
      ]),
    });
  }

  addEntidade() {
    const form: FormArray = this.form.get('entidades') as FormArray;
    form.push(
      this.formBuilder.group({
        id: [null],
        nome: [null, Validators.required],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
        dtCriacao: [new Date()],
      })
    );
  }

  prepareToUpdateForm(api) {
    this.form.get('id').setValue(api.id);
    this.form.get('nome').setValue(api.nome);
    this.form.get('codigo').setValue(api.codigo);
    this.form.get('port').setValue(api.port);
    this.form.get('server').setValue(api.server);
    this.form.get('usuarioCriacao').setValue(api.usuarioCriacao);
    this.form.get('aplicacaoOrigem').setValue(api.aplicacaoOrigem);
    this.form.get('dtCriacao').setValue(api.dtCriacao);

    for (const index in api.entidades) {
      if (api.entidades[index]) {
        if (!this.form.get(`entidades.${index}`)) {
          this.addEntidade();
        }
        this.form
          .get(`entidades.${index}.id`)
          .setValue(api.entidades[index].id);
        this.form
          .get(`entidades.${index}.nome`)
          .setValue(api.entidades[index].nome);
        this.form
          .get(`entidades.${index}.usuarioCriacao`)
          .setValue(api.entidades[index].usuarioCriacao);
        this.form
          .get(`entidades.${index}.aplicacaoOrigem`)
          .setValue(api.entidades[index].aplicacaoOrigem);
        this.form
          .get(`entidades.${index}.dtCriacao`)
          .setValue(api.entidades[index].dtCriacao);
      }
    }
    // this.showForm = true;
  }

  removeEntidade(index: number) {
    if (this.form.get(`entidades.${index}.id`).value) {
      this.modalService.confirm({
        nzTitle:
          '<i nz-icon nzType="warning" nzTheme="outline">Deseja realmente remover o registro?</i>',
        // nzContent: '<b>Some descriptions</b>',
        nzOnOk: async () => {
          this.service
            .excluir(this.form.get(`entidades.${index}`).value, 'entidade')
            .then((_) => {
              const form: FormArray = this.form.get('entidades') as FormArray;
              form.removeAt(index);
            });
        },
      });
      // ).onClose.subscribe(response => {
      //   if (response) {
      //     this.apiService.excluir(this.form.get(`entidades.${index}`).value, 'entidade')
      //       .then(_ => {
      //         const form: FormArray = this.form.get('entidades') as FormArray;
      //         form.removeAt(index);
      //       });
      //   }
      // });
      return;
    }
    const formb: FormArray = this.form.get('entidades') as FormArray;
    formb.removeAt(index);
  }

  resetEntidade() {
    const form: FormArray = this.form.get('entidades') as FormArray;
    form.controls.splice(0, form.controls.length);
    this.addEntidade();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },

      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        color: 'red',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },

      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getFilterApiOptionsSelect(item) {}

  novoCadastro = () => {
    this.changeTabIndex(0);
    this.resetForm();
  };

  salvar = () => {
    this.onSubmit();
  };
  async onSubmit() {
    if (this.form.invalid) {
      this.service.notification.warning(
        this.titulo,
        'Preencha todos os campos!'
      );

      return;
    }
    const api = await this.prepareToResquest(this.form);
    return await this.execute(api);
  }
  prepareToResquest(form) {
    const api = form.value;
    api.entidades.map((entidade) => (!entidade.id ? delete entidade.id : null));
    return api;
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service
          .insertOrUpdate(registro, idMp, entidade)
          .then(() => this.atualizar());
        this.loadingPage = false;
        return this.reset();
      },
    });
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  reset() {
    this.resetForm();

    this.resetEntidade();
  }

  editar = () => {
    this.editarApi(this.getRegistrySelected());
  };

  editarApi(item) {
    this.prepareToUpdateForm(item);
    this.changeTabIndex(0);
  }
  dblclickTable(item) {
    this.openModal ? this.selectItem(item) : this.editarApi(item);
  }
  selectItem(item) {
    this.modalRef.destroy(item);
  }

  deletar = () => {
    this.delete(this.getRegistrySelected());
  };

  delete(item) {
    this.loadingTable = true;
    return this.modalService.confirm({
      nzTitle: `<i>Deseja excluir esse registro?</i>`,
      nzOnOk: async () => {
        this.service
          .delete(item, this.entidade)
          .then(() => {
            this.atualizar();
          })
          .catch(() => (this.loadingTable = false));
      },
    });
  }

  atualizar = () => {
    this.getApiPerPeriod();
  };

  ngOnInit(): void {
    if (this.openModal) this.getApiPerPeriod();
  }

  getWidthContent() {
    return window.innerWidth;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterApi() {
    return this.getApiPerPeriod(this.fieldsApisSelected);
  }

  async pageChange(valor) {
    this.pageIndex = Number(valor);
    await this.getApiPerPeriod(this.fieldsApisSelected, Number(valor), 10);
  }

  async getApiPerPeriod(
    fieldsApiSelected = [],
    page: number = this.pageIndex,
    size: number = 10
  ) {
    this.loadingTable = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsApiSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (resp: any) => {
        if (resp?.data?.length === 0) {
          this.service.notification.warning(
            this.entidade,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
          this.loadingTable = true;
          return;
        }
        this.pageTotal = resp.data.paramsPaginator[0];
        this.displayData = resp.data.resultado;
        this.loadingPage = false;
        this.loadingTable = false;
      })
      .catch((err) => {
        this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
        this.loadingTable = false;
      });
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldApisSelected && this.searchInput) {
      if (this.fieldApisSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldApisSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsApisSelected.length !== 0) {
        this.removeFiltroApiPorField(this.fieldsApisSelected[0]);
      }
      this.fieldsApisSelected.push({
        props: this.fieldApisSelected.value,
        field: this.fieldApisSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldApisSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsApisSelect.findIndex(
        (a) => a.value === this.fieldApisSelected.value
      );

      this.arrColumnsApisSelect.splice(index, 1);

      this.showComboTagsApi = true;
      this.fieldApisSelected = '';
      this.searchInput = '';
      this.selectedApisValues = [];
    }
  }

  removeFiltroApiPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableApiColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsApisSelect.splice(
      this.tableApiColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsApisSelected.splice(
      this.fieldsApisSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsApisSelected.length === 0) {
      this.showComboTagsApi = false;
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  verificaEntidadeVazia(entidades): boolean {
    return (
      entidades.filter((entidade) => !entidade.nome || entidade.nome === '')
        .length > 0
    );
  }
}
