<!-- visão para laptops e monitores (768px < x) -->
<div class="header is-not-mobile">
  <nz-progress *ngIf="authorization" [nzPercent]='percentDone' [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }"
    nzStatus="active">
  </nz-progress>
  <div class="header-child">
    <div class="nav-wrap d-flex flex-sm-row flex-column">
      <div class="nav-left">
        <!-- <nz-avatar [nzSize]="32" [nzSrc]="publicsoftLogo"></nz-avatar> -->
        <a style="color: rgb(55, 92, 130)" (click)="newWindow()"><img
            src="./../global-assets/images/logo_ps_18_anos2.png" class="logo-header-lib" alt="logo publicsoft"></a>
        <img src="./../global-assets/images/cabecalho_elemento1.png" class="header-element-1" alt="">
        <img src="./../global-assets/images/cabecalho_elemento2.png" class="header-element-2" alt="">
        <!-- <h4 class="m-l-9 mt-2 m-r-15">
          <span>
            <b style="padding: 7px">
              <a style="color: rgb(55, 92, 130)" (click)="newWindow()">PublicSoft
              
            </b>
          </span>
        </h4> -->
      </div>
      <div class="nav-right">
        <span class="datetext">{{ ugSelected?.nomCidade }} - {{ ugSelected?.estadoCidade }}</span>
        <button nz-button nzType="text" nz-dropdown nzTrigger="click" [nzDropdownMenu]="date"
          [nzPlacement]="'bottomRight'" [(nzVisible)]="dateVisible">
          <i nz-icon nzType="calendar"></i>
        </button>
        <nz-dropdown-menu #date="nzDropdownMenu">
          <div [ngStyle]="{ width: '300px', border: '1px solid #d9d9d9', backgroundColor: 'white' }">
            <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onValueChange($event)"
              (nzPanelChange)="onPanelChange($event)"></nz-calendar>
          </div>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
  <div nz-row class="header-child nav-big">
    <div nz-col class="modulo" nz-tooltip [nzTooltipTitle]="'Você está no modulo: ' + modulo">
      <!-- Aqui é Icone -->
      <nz-avatar [nzSize]="42" [nzSrc]="icone"></nz-avatar>
      <h5 *ngIf='!isFolded' class="m-l-15 mt-2 m-r-15"> {{modulo}} </h5>
    </div>

    <span class="config-cel-pc">
      <nz-avatar [nzSize]="42" [nzSrc]="UG?.brasao ? UG.brasao : UG?.brasaoUg ? UG?.brasaoUg : brasao"></nz-avatar>
      <h5 class="text-truncate m-l-15 mt-2 m-r-10">{{ugSelected?.nome ? ugSelected.nome : ugExternal }}
      </h5>
    </span>

    <div nz-col style="margin-left: auto;" class="align-self-center menu">
      <div class="nav-wrap">
        <ul class="nav-right">
          <li class="mobile-toggle">
            <a class="button-size" (click)="toggleExpand()">
              <i class="icon-size" nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
            </a>
          </li>
          <li>
            <a *ngIf="urlEventSource" class="button-size" nz-dropdown [nzDropdownMenu]="menuNotifications"
              nzTrigger="click" nzPlacement="bottomRight" nz-tooltip [nzTooltipTitle]="'Notificações do sistema'">
              <nz-badge [nzCount]="arrayOfExceptions.length"><i nz-icon nzType="notification"></i></nz-badge>
            </a>
            <nz-dropdown-menu #menuNotifications="nzDropdownMenu">
              <div nz-menu class="wd-xl animated jello"
                [class]="arrayOfExceptions.length !== 0?'scrollable-container':null ">
                <ul nz-list nzBordered nzSize="large" *ngIf="arrayOfExceptions.length != 0">
                  <li nz-list-item *ngFor="let item of arrayOfExceptions" nzNoFlex>

                    <ul nz-list-item-actions>
                      <nz-list-item-action style="padding: 0 0px;">
                        <p style="font-size: 11px; margin-bottom: 1px;">{{item.data}}</p>

                      </nz-list-item-action>
                      <nz-list-item-action>
                        <a (click)="deleteMsg(item)" nz-tooltip [nzTooltipTitle]="'Marcar como lida'"><i nz-icon
                            nzType="eye" nzTheme="outline"></i></a>
                      </nz-list-item-action>
                    </ul>
                    {{ item.message }}

                    <br>

                  </li>
                </ul>

                <nz-empty nzNotFoundImage="simple" *ngIf="arrayOfExceptions.length ===0"></nz-empty>
              </div>
              <div nz-menu class="wd-xl p-b-0 p-t-0 animated jello" *ngIf="arrayOfExceptions.length >= 2 ">
                <ul nz-list nzBordered nzSize="large">
                  <li nz-list-item nzNoFlex>
                    <ul nz-list-item-actions>
                      <nz-list-item-action>
                        <span (click)="clearAllNotifications()" class="cursor-pointer text-primary p-r-10">Marcar
                          todas
                          como
                          lidas</span>
                        <a (click)="clearAllNotifications()" nz-tooltip [nzTooltipTitle]="'Marcar todas como lida'"><i
                            nz-icon nzType="eye" nzTheme="outline"></i></a>
                      </nz-list-item-action>
                    </ul>

                    <br>
                  </li>

                </ul>

              </div>
              <!-- <button nz-button nzType="primary" *ngIf="arrayOfExceptions.length >= 2 "
                (click)="clearAllNotifications()" nzBlock>Marcar todas como
                lida</button> -->
            </nz-dropdown-menu>
          </li>
          <li>
            <a nz-tooltip [nzTooltipTitle]="'Clique para voltar ao módulo de acesso'" nzTooltipPlacement="left"
              class="button-size" [href]="selectModURL"><i class="icon-size" nz-icon nzType="home"></i></a>
          </li>
          <li>
            <a class="button-size" nz-dropdown [nzDropdownMenu]="iconMenu" nzTrigger="click" nzPlacement="bottomRight"
              nz-tooltip [nzTooltipTitle]="'Clique para acessar o menu de módulos'">
              <i class="icon-size" nz-icon nzType="appstore"></i>
            </a>
            <nz-dropdown-menu #iconMenu="nzDropdownMenu">
              <div nz-menu class="wd-xl animated jello">
                <nz-spin [nzSpinning]="false" [nzTip]="'Carregando...'">
                  <div nz-row [nzJustify]="'center'" [nzAlign]="'middle'" class="app-icons">
                    <div nz-col [nzSpan]="10" *ngFor="let modulo of getUgSelectedModulos()">
                      <a (click)="modalChangeModulo(modulo)">
                        <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                          [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'" [nzSrc]="getModuloIcon(modulo)">
                        </nz-avatar>
                        <small [class]="getUgSelectedModulos().length > 1 ? 'text-modulos text-truncate' : ''">{{
                          modulo.nome }}</small>
                      </a>
                    </div>
                  </div>
                </nz-spin>
              </div>
            </nz-dropdown-menu>
          </li>
          <!-- <li>
            <a class="button-size" (click)="quickViewToggle()">
              <i class="icon-size" nz-icon nzType="setting" theme="outline"></i>
            </a>
            <nz-drawer [nzWidth]="280" nzTitle="Quick View" [nzClosable]="false" [nzVisible]="quickViewVisible"
              nzPlacement="right" (nzOnClose)="quickViewToggle()">
              <ng-container *ngIf="quickView">
                <ng-template *ngTemplateOutlet="quickView"></ng-template>
              </ng-container>
            </nz-drawer>
          </li> -->
          <li>
            <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
              [nzPlacement]="'bottomRight'" nz-tooltip [nzTooltipTitle]="'Meu perfil'" nzTooltipPlacement="left">
              <nz-avatar [nzIcon]="'user'" nzSrc="{{authService.getAvatar()}}"></nz-avatar>
            </span>
            <nz-dropdown-menu #profile="nzDropdownMenu">
              <ul nz-menu class="p-b-15 p-t-20">
                <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                  <div class="d-flex m-r-50">
                    <nz-avatar [nzIcon]="'user'" nzSize="large" nzSrc="{{authService.getAvatar()}}"></nz-avatar>
                    <div class="m-l-10">
                      <p class="m-b-0 text-dark font-weight-semibold">{{authService.getNomUser()}}</p>
                      <div class="row m-l-0">
                        <p class="m-b-0 opacity-07" *ngIf="cpfVisible">
                          {{getCpf()}}</p>
                        <p class="m-b-0 opacity-07" *ngIf="!cpfVisible">
                          {{getCpfMasked()}}</p>
                        <i style="margin: 5px;" nz-icon [nzType]="!cpfVisible ? 'eye-invisible' : 'eye'"
                          (click)="cpfVisible = !cpfVisible"></i>
                      </div>
                      <p *ngIf='!certificadoInvalido' class="m-b-0 text-green"><i class="text-green mr-1" nz-icon
                          nzType="safety-certificate" nzTheme="outline"></i>{{certificadoLabel}}</p>
                      <p *ngIf='certificadoInvalido' class="m-b-0 text-red"><i class="text-red mr-1" nz-icon
                          nzType="safety-certificate" nzTheme="outline"></i>{{certificadoLabel}}</p>
                    </div>
                  </div>
                </li>
                <li nz-menu-item>
                  <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="toggleEditar($event)">
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                      <span class="m-l-10">Minha Conta</span>
                    </div>
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon [nzType]="editarPerfil ? 'up': 'down'"
                        theme="outline"></i>
                    </div>
                  </a>
                  <ul nz-menu class="menu-conta" [ngClass]="editarPerfil ? 'menu-open': 'menu-close'">
                    <li nz-menu-item>
                      <a class="p-v-5 d-flex align-items-center justify-content-between"
                        (click)="addTab({title: 'Perfil', path: '/session/editar-perfil'})">
                        <div>
                          <i class="opacity-04 font-size-16" nz-icon nzType="edit" theme="outline"></i>
                          <span class="m-l-10">Editar Perfil</span>
                        </div>
                      </a>
                    </li>
                    <!-- REMOVIDO *ngIf="!assNotification" -->
                    <li nz-menu-item>
                      <a class="p-v-5 d-flex align-items-center justify-content-between"
                        (click)="addTab({title: 'Meus Certificados', path: '/session/meus-certificados'})">
                        <div>
                          <i class="opacity-04 font-size-16" nz-icon nzType="safety-certificate" theme="outline"></i>
                          <span class="m-l-10">Meus Certificados</span>
                        </div>
                      </a>
                    </li>
                    <li nz-menu-item>
                      <a class="p-v-5 d-flex align-items-center justify-content-between">
                        <div>
                          <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                          <span class="m-l-10">Configurações da Conta</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li nz-menu-item [routerLink]="'/login'">
                  <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                      <span class="m-l-10">Sair</span>
                    </div>
                  </a>
                </li>
              </ul>
            </nz-dropdown-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- visão até tablet (x <= 768px) -->
<div class="header is-mobile">
  <div class="header-child">
    <div class="nav-wrap">
      <ul class="nav-left ml-0">
        <li>
          <a class="button-size" (click)="toggleFold()">
            <i class="icon-size" nz-icon nzType="menu" theme="outline"></i>
          </a>
        </li>
        <li class="d-flex align-self-center">
          <nz-avatar class="mt-1" [nzSize]="32" [nzSrc]="publicsoftLogo"></nz-avatar>
          <h5 class="m-l-15 mt-2 m-r-15 text-truncate"><i style="margin-right: 15px" nz-icon nzType="double-right"
              nzTheme="outline"></i>{{modulo}}</h5>
        </li>
      </ul>
      <ul class="nav-right mr-0">
        <li>
          <a class="pt-0 pb-0 pl-0 pr-2" (click)="toggleDrawer()">
            <i class="icon-size" nz-icon nzType="more" theme="outline"></i>
          </a>
        </li>
        <nz-drawer [nzClosable]="true" [nzVisible]="drawer" nzPlacement="right" [nzTitle]="titleDrawer"
          (nzOnClose)="toggleDrawer()">
          <ng-template #titleDrawer>
            <span class="mobile-city">{{ ugSelected?.nomCidade }} - {{ ugSelected?.estadoCidade }}</span>
            <div class="is-table">
              <span class="mobile-date">
                <!-- {{ authService.getDateSelected() | date: 'dd/MM/yyyy' }} -->
                <a class="ml-1" nz-dropdown nzTrigger="click" [nzDropdownMenu]="date2" [nzPlacement]="'bottomCenter'"
                  [(nzVisible)]="dateMobile">
                  <i class="calendar font-size-16" nz-icon nzType="calendar" theme="outline"></i>
                </a>
              </span>
              <nz-dropdown-menu #date2="nzDropdownMenu">
                <div [ngStyle]="{ width: '300px', border: '1px solid #d9d9d9', backgroundColor: 'white' }">
                  <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onValueChange($event)"
                    (nzPanelChange)="onPanelChange($event)"></nz-calendar>
                </div>
              </nz-dropdown-menu>
            </div>

            <div class="is-mobile-l">
              <span class="mobile-date">
                <!-- {{ authService.getDateSelected() | date: 'dd/MM/yyyy' }} -->
                <a class="ml-1" (click)="toggleDate()">
                  <i class="calendar font-size-16" nz-icon nzType="calendar" theme="outline"></i>
                </a>
                <nz-modal [(nzVisible)]="dateModal" nzTitle="Selecionar Data" [nzContent]="dateContent"
                  [nzFooter]="null" (nzOnCancel)="toggleDate()">
                  <ng-template #dateContent>
                    <div>
                      <nz-calendar [nzFullscreen]="false" (nzSelectChange)="onValueChange($event)"
                        (nzPanelChange)="onPanelChange($event)"></nz-calendar>
                    </div>
                  </ng-template>
                </nz-modal>
              </span>
            </div>
            <p *ngIf='!certificadoInvalido' class="m-b-0 text-green"><i class="text-green mr-1" nz-icon
                nzType="safety-certificate" nzTheme="outline"></i>{{certificadoLabel}}</p>
            <p *ngIf='certificadoInvalido' class="m-b-0 text-red"><i class="text-red mr-1" nz-icon
                nzType="safety-certificate" nzTheme="outline"></i>{{certificadoLabel}}</p>

          </ng-template>
          <ng-container *nzDrawerContent>
            <ul nz-menu>
              <!-- <li nz-menu-item class="border-top"> -->
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between" [href]="selectModURL">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="home" theme="outline"></i>
                    <span class="m-l-10">Acesso</span>
                  </div>
                </a>
              </li>
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="toggleModulos()">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="appstore" theme="outline"></i>
                    <span class="m-l-10">Módulos</span>
                  </div>
                </a>
                <nz-modal [(nzVisible)]="modulosDrawer" nzTitle="Módulos" [nzContent]="modulosContent" [nzFooter]="null"
                  (nzOnCancel)="toggleModulos()">
                  <ng-template #modulosContent>
                    <div nz-menu class="wd-xl animated jello">
                      <nz-spin [nzSpinning]="false" [nzTip]="'Carregando...'">
                        <div nz-row [nzJustify]="'center'" [nzAlign]="'middle'" class="app-icons">
                          <div nz-col [nzSpan]="8" *ngFor="let modulo of getUgSelectedModulos()">
                            <a [href]="modulo.url">
                              <i class="icon-size" nz-icon [nzType]="'area-chart'" class="bg-geekblue text-white"></i>
                              <small class="text-truncate" style="max-width: 400px;">{{ modulo.nome }}</small>
                            </a>
                          </div>
                        </div>
                      </nz-spin>
                    </div>
                  </ng-template>
                </nz-modal>
              </li>
              <li nz-menu-item class="border-bottom">
                <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="toggleSystem()">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="setting" theme="outline"></i>
                    <span class="m-l-10">Configurações do Sistema</span>
                  </div>
                </a>
                <nz-modal [(nzVisible)]="systemDrawer" nzTitle="Configurações do sistema" [nzContent]="systemContent"
                  [nzFooter]="null" (nzOnCancel)="toggleSystem()">
                  <ng-template #systemContent>
                    <div class="pb-3 pt-3 pl-3 pr-3">
                      Configurações do sistema
                    </div>
                  </ng-template>
                </nz-modal>
              </li>
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between"
                  (click)="addTab({title: 'Perfil', path: '/session/perfil'}); toggleDrawer()">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                    <span class="m-l-10">Editar Perfil</span>
                  </div>
                </a>
              </li>
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between"
                  (click)="addTab({title: 'Meus Certificados', path: '/session/meus-certificados'}); toggleDrawer();">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="security-scan" theme="outline"></i>
                    <span class="m-l-10">Meus Certificados</span>
                  </div>
                </a>
              </li>
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="toggleConfig()">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                    <span class="m-l-10">Configurações da Conta</span>
                  </div>
                </a>
                <nz-modal [(nzVisible)]="configDrawer" nzTitle="Configurações da conta" [nzContent]="configContent"
                  [nzFooter]="null" (nzOnCancel)="toggleConfig()">
                  <ng-template #configContent>
                    <div class="pb-3 pt-3 pl-3 pr-3">
                      Configurações da conta
                    </div>
                  </ng-template>
                </nz-modal>
              </li>
              <li nz-menu-item [routerLink]="'/login'">
                <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                    <span class="m-l-10">Sair</span>
                  </div>
                </a>
              </li>
            </ul>
          </ng-container>
        </nz-drawer>
      </ul>
    </div>
  </div>
  <div class="header-child d-flex justify-content-center">
    <nz-avatar style="margin-top: 8px" [nzSize]="32" [nzText]="moduloAbbreviation"></nz-avatar>
    <h5 class="m-l-15 mt-2 m-r-15 text-truncate">{{ugSelected?.nome}}</h5>

    <!-- <button nz-button nzType="text" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [nzPlacement]="'bottomRight'">
      <i nz-icon nzType="down-circle"></i>
    </button> -->
    <!-- <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item *ngFor="let ug of unidadesGestoras" (click)="modalChangeUg(ug)">{{ug.nome}}</li>
      </ul>
    </nz-dropdown-menu> -->
  </div>
</div>