import {
  IDocumentsChartData,
  IDocumentsChartColors,
  IDocumentChartProps,
  IDocumentResumeChartColors,
  IDocumentResumeChartOptions,
  IDocumentResumeChartProps,
} from './../interfaces/dashboard';

export class DashboardMocks {
  static getDocumentsChartProps(): IDocumentChartProps {
    return {
      optionsData: this.getDocumentsChartData(),
      labels: this.getDocumentsChartLabels(),
      optionsBuild: this.getDocumentsChartOptions(),
      optionsColors: this.getDocumentsChartColors(),
      typeChart: this.getDocumentsChartType(),
    };
  }

  static getDocumentsChartData(): IDocumentsChartData[] {
    return [
      {
        data: [],
        label: 'Desacordos',
        categoryPercentage: 0.5,
      },
      {
        data: [],
        label: 'Pendentes',
        categoryPercentage: 0.5,
      },
      {
        data: [],
        label: 'Concluídos',
        categoryPercentage: 0.5,
      },
    ];
  }

  static getDocumentsChartLabels(): string[] {
    return [];
  }

  static getDocumentsChartOptions() {
    return {
      scaleShowVerticalLines: false,
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxes: [
          {
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Month',
            },
            gridLines: false,
            ticks: {
              display: true,
              beginAtZero: true,
              fontSize: 13,
              padding: 10,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: false,
              labelString: 'Value',
            },
            gridLines: {
              drawBorder: false,
              offsetGridLines: false,
              drawTicks: false,
              borderDash: [3, 4],
              zeroLineWidth: 1,
              zeroLineBorderDash: [3, 4],
            },
            ticks: {
              max: 100,
              stepSize: 10,
              display: true,
              beginAtZero: true,
              fontSize: 13,
              padding: 10,
            },
          },
        ],
      },
    };
  }

  static getDocumentsChartColors(): IDocumentsChartColors[] {
    return [
      {
        backgroundColor: 'red',
        borderWidth: 0,
      },
      {
        backgroundColor: 'blue',
        borderWidth: 0,
      },
      {
        backgroundColor: 'green',
        borderWidth: 0,
      },
    ];
  }

  static getDocumentsChartType(): string {
    return 'bar';
  }

  static getDocumentsResumeChartData(): number[] {
    return [0, 0, 0, 0, 0, 0];
  }

  static getDocumentsResumeChartLabels(): string[] {
    return [
      'Pendentes',
      'Assinados',
      'Concluidos',
      'Em Desacordo',
      'Expirados',
      'Cancelados',
    ];
  }

  static getDocumentsResumeChartColors(): IDocumentResumeChartColors[] {
    return [
      {
        backgroundColor: [],
        pointBackgroundColor: [],
        backgroundColorName: ['gold', 'blue', 'green', 'red', 'orange', 'dark'],
        icons: [
          'field-time',
          'form',
          'check-circle',
          'close-circle',
          'history',
          'stop',
        ],
      },
    ];
  }

  static getDocumentsResumeChartOptions(): IDocumentResumeChartOptions {
    return {
      cutoutPercentage: 75,
      maintainAspectRatio: false,
    };
  }

  static getDocumentsResumeChartType(): string {
    return 'doughnut';
  }

  static getOrderTypeDocuments(): number[] {
    return [2, 1, 4, 3, 2, 6];
  }

  static getPeriodChartDocumentsOrder(): number[] {
    return [3, 2, 4, 1, 5, 6];
  }

  static getDocumentsResumeChartProps(): IDocumentResumeChartProps {
    return {
      optionsData: this.getDocumentsResumeChartData(),
      labels: this.getDocumentsResumeChartLabels(),
      optionsColors: this.getDocumentsResumeChartColors(),
      optionsBuild: this.getDocumentsResumeChartOptions(),
      typeChart: this.getDocumentsResumeChartType(),
      orderType: this.getOrderTypeDocuments(),
    };
  }
}
