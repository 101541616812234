import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-group',
  template: `
    <div nz-row [nzGutter]="16">
      <formly-field
        nz-col
        *ngFor="let f of field.fieldGroup"
        [nzSpan]="f.templateOptions?.attributes?.span"
        [nzXs]="f.templateOptions?.attributes?.xs"
        [nzMd]="f.templateOptions?.attributes?.md"
        [field]="f"
      >
      </formly-field>
      <ng-content></ng-content>
    </div>
  `,
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[class]': 'field.fieldGroupClassName || ""',
  },
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsFormGroupComponent extends FieldType {}
