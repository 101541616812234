import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from './../../shared/services/auth.service';
import { AbstractService } from './abstract.service';

@Injectable()
export class GrupoNaturezaJuridicaService extends AbstractService {
  constructor(public http: HttpClient, public authService: AuthService, public notification: NzNotificationService,) {
    super("natureza-juridica", http, notification, authService,);
  }



  getAllForGrouppingAutocomplete(options?: any): Promise<any> {
    return this.pesquisarTodos('natureza-juridica').then((result) => {
      return result.data
    }).catch(err => {
      return this.notification.error('Natureza Juridica', err);
    })
  }
}
