import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-full-layout',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent {
  constructor() {}
}
