import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper } from '@ps-erp-angular/shared';
import { Optional } from 'ag-grid-community';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../shared/services/auth.service';
import { InstituicaoSiconfiComponent } from './../instituicao-siconfi/instituicao-siconfi.component';
import { PessoaFisicaComponent } from './../pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './../pessoa-juridica/pessoa-juridica.component';
import { UnidadeGestoraService } from './../shared/services/unidade-gestora.service';

@Component({
  selector: 'ps-painel-administrativo-app-unidade-gestora',
  templateUrl: './unidade-gestora.component.html',
  styleUrls: ['./unidade-gestora.component.scss'],
})
export class UnidadeGestoraComponent implements OnInit {
  loadingPage: boolean = false;
  loadingTable: boolean = false;
  loadingManutencao: boolean = false;
  loadingConsulta: boolean = false;
  tabIndex: 0 | 1 = 1;
  form: FormGroup = new FormGroup({});
  fabButtons: IMenuButton[];
  displayData = [];
  pageTotal: number;
  checkAllIndeterminate: boolean = true;
  checkedAll = false;
  checkList: { [typekey: string]: boolean } = {
    razaoSocialPessoaJuridica: true,
    sigla: true,
  };
  radioValue;
  intervaloDigitando;
  // checkedExpiracao: boolean
  openModal: boolean;
  validateStatus: { [typekey: string]: () => 'sucess' | 'error' } = {};
  instituicaoSiconfi;
  pessoaJuridica;
  pessoaFisicaResponsavel;

  //example, trocar para o nome da entidade arrColumnsUnidadesGestorasSelect
  tableUnidadeGestoraColumns = [];
  titulo: string = 'Unidade Gestora';
  entidade: string = 'unidade-gestora';
  fieldUnidadesGestorasSelected;
  arrColumnsUnidadesGestorasSelect: any[];
  selectedUnidadesGestorasValues;
  showComboTagsUnidadesGestoras;
  searchInput: any;
  fieldsUnidadesGestorasSelected: any[] = [];

  constructor(
    @Optional()
    private modalRef: NzModalRef<UnidadeGestoraComponent>,
    private service: UnidadeGestoraService,
    private activateRouter: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService
  ) {
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigUnidadeGestoraTable();
      await this.setConfigUnidadeGestoraFilter();
    });
    this.resetForm();
    this.resetFabButtons();
  }

  async setConfigUnidadeGestoraFilter() {
    this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        Object.assign(this, result);

        this.service.setCompareToTableColumns(
          this.displayData,
          this.tableUnidadeGestoraColumns
        );
      });
    this.loadingPage = false;
  }

  async setConfigUnidadeGestoraTable() {
    this.loadingPage = true;

    await this.service
      .getTableProps(this.entidade, this.titulo)
      .then((result) => {
        const tableColumnsUnidadesGestoras = result.tableColumnsProps.map(
          (column) => {
            return {
              ...column,
              compare:
                column.value === 'id' || column.value === 'id_uf'
                  ? (a, b) => a[column.value] - b[column.value]
                  : (a, b) => a[column.value].localeCompare(b[column.value]),

              // width:
              //   column.value === 'idPublico'
              //     ? '8rem'
              //     : column.value === 'titulo'
              //     ? '15rem'
              //     : column.value === 'dtCriacao'
              //     ? '20rem'
              //     : null,
            };
          }
        );
        this.tableUnidadeGestoraColumns = tableColumnsUnidadesGestoras;

        this.arrColumnsUnidadesGestorasSelect = result.searchColumns;
      });
    this.loadingPage = false;
  }

  setValidateStatus(formControlNameArr: Array<string>) {
    for (const formControlName of formControlNameArr) {
      this.validateStatus[formControlName] = (): 'sucess' | 'error' =>
        this.form.get(formControlName).errors &&
        (this.form.get(formControlName).touched ||
          this.form.get(formControlName).dirty)
          ? 'error'
          : 'sucess';
    }
  }

  getValidateStatus(validator: string): 'sucess' | 'error' {
    return this.validateStatus[validator]();
  }

  removeOrdenador(i) {
    if (this.form.get(`ordenadoresDespesa.${i}.idPrivado`).value) {
      return this.modalService.confirm({
        nzTitle: `<i>Deseja remover Ordenador?</i>`,
        nzOnOk: () => {
          this.loadingManutencao = true;
          this.service
            .excluirFilho(
              this.form.value.id,
              this.form.get(`ordenadoresDespesa.${i}.idPrivado`).value,
              'ordenador-despesa',
              this.entidade,
              this.titulo
            )
            .then(() => {
              this.loadingManutencao = false;
            });
          const form: FormArray = this.form.get(
            'ordenadoresDespesa'
          ) as FormArray;
          form.removeAt(i);
        },
      });
    }

    const form: FormArray = this.form.get('ordenadoresDespesa') as FormArray;
    form.removeAt(i);
  }

  showModalPessoaFisicaResponsavel() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um responsável `,
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisicaResponsavel) => {
      this.pessoaFisicaResponsavel = pessoaFisicaResponsavel;
      const contatoPrincipal = pessoaFisicaResponsavel.contatos.find(
        (endereco) => endereco.principal === 1
      );
      this.form.patchValue({
        idPessoaFisica: pessoaFisicaResponsavel.id,
        cpfPessoaFisica: pessoaFisicaResponsavel.cpf,
        nomePessoaFisica: pessoaFisicaResponsavel.nomPessoa,
        emailPessoaFisica: contatoPrincipal.email,
      });
    });
  }
  limparPessoaFisicaResponsavel() {
    this.form.patchValue({
      cpfPessoaFisica: null,
      idPessoaFisica: null,
      nomePessoaFisica: null,
      emailPessoaFisica: null,
    });
    this.pessoaFisicaResponsavel = null;
  }

  showModalPessoaJuridica() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Pessoa jurídica`,
      nzContent: PessoaJuridicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    tipoDocumentoModal.afterClose.subscribe((pessoaJuridica) => {
      for (const contato of pessoaJuridica.contatos) {
        this.addContato(contato);
      }
      const enderecoPrincipal = pessoaJuridica.enderecos.find(
        (endereco) => endereco.principal === 1
      );
      this.pessoaJuridica = pessoaJuridica;
      this.form.patchValue({
        idPessoaJuridica: pessoaJuridica.id,
        cnpjPessoaJuridica: pessoaJuridica.cnpj,
        razaoSocialPessoaJuridica: pessoaJuridica.razaoSocial,
        emailPessoaJuridica: pessoaJuridica.pessoa.emailPrincipal,
        nomCidade: enderecoPrincipal?.nomCidade,
        estadoCidade: enderecoPrincipal?.nomUf,
      });
    });
  }

  showModalInstituicaoSiconfi() {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione uma Instituição Siconfi`,
      nzContent: InstituicaoSiconfiComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((InstituicaoSiconfi) => {
      this.instituicaoSiconfi = InstituicaoSiconfi;
      this.form.patchValue({
        idPrivadoInstSiconf: InstituicaoSiconfi.idPrivado,
        nomeSiconfi: InstituicaoSiconfi.nome,
        idPublicoInstSiconf: InstituicaoSiconfi.idPublico,
      });
    });
  }

  limparInstituicaoSiconfi() {
    this.form.patchValue({
      idPublicoInstSiconf: null,
      idPrivadoInstSiconf: null,
      nomeSiconfi: null,
    });
    this.instituicaoSiconfi = null;
  }

  limparPessoaJuridica() {
    this.form.patchValue({
      cnpjPessoaJuridica: null,
      idPessoaJuridica: null,
      razaoSocialPessoaJuridica: null,
      emailPessoaJuridica: null,
      estadoCidade: null,
      nomCidade: null,
    });
    this.pessoaJuridica = null;

    this.clearAllFormArray('contatos');
  }

  clearAllFormArray(formArray) {
    const form: FormArray = this.form.get(formArray) as FormArray;
    form.controls.splice(0, form.controls.length);
  }

  resetForm() {
    this.form = this.formBuilder.group({
      idPublico: [null],
      id: [null],
      cnpjPessoaJuridica: [null, Validators.required],
      idPessoaJuridica: [null],
      expiracao: [null],
      configuracao: [null],
      razaoSocialPessoaJuridica: [{ value: null, disabled: true }],
      emailPessoaJuridica: [{ value: null, disabled: true }],
      idPessoaFisica: [null],
      cpfPessoaFisica: [null],
      nomePessoaFisica: [{ value: null, disabled: true }],
      emailPessoaFisica: [{ value: null, disabled: true }],
      estadoCidade: [null],
      nomCidade: [null],
      sigla: [null, Validators.required],
      codTce: [null, Validators.required],
      idPublicoInstSiconf: [null],
      idPrivadoInstSiconf: [null],
      nomeSiconfi: [{ value: null, disabled: true }],
      normaCriacao: [null],
      dataNormaCriacao: [null],
      instrumentoCriacao: [null],
      finalidadeOrgao: [null],
      brasao: [null],
      aplicacaoOrigem: [1],
      usuarioCriacao: [this.authService.getIdUser()],
      dtCriacao: [new Date()],
      contatos: this.formBuilder.array([]),
      ordenadoresDespesa: this.formBuilder.array([]),
    });
    this.addOrdenador();
    // this.addContato();
    this.setValidateStatus([
      'idPublico',
      'cpfPessoaFisica',
      'idPublico',
      'sigla',
      'codTce',
      'cnpjPessoaJuridica',
      'razaoSocialPessoaJuridica',
    ]);
  }

  showModalCpfOrdenador(index) {
    const tipoDocumentoModal = this.modalService.create({
      nzTitle: `Selecione um Ordenador`,
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    tipoDocumentoModal.afterClose.subscribe((pessoaFisica) => {
      this.form.get(`ordenadoresDespesa.${index}`).patchValue({
        pessoaFisica: pessoaFisica,
        cpf: pessoaFisica.cpf,
        nome: pessoaFisica.pessoa.nomPessoa,
      });
    });
  }

  limparCpfOrdenador(index) {
    this.form.get(`ordenadoresDespesa.${index}`).patchValue({
      pessoaFisica: null,
      cpf: null,
      nome: null,
    });
  }

  verificaOrdenadorVazio(ordenadores): boolean {
    return ordenadores.some((ordenador) => ordenador.pessoaFisica);
  }

  addOrdenador() {
    const ordenadorForm: FormArray = this.form.get(
      'ordenadoresDespesa'
    ) as FormArray;
    ordenadorForm.push(
      this.formBuilder.group({
        idPrivado: [null],
        idPublico: [null],
        pessoaFisica: [null],
        pFNaoEncontrada: [null],
        messagePFNaoEncontrada: [null],
        intervaloDigitando: [null],
        cpf: [null],
        nome: [{ value: null, disabled: true }],
        cargo: [null],
        dataNomeacao: [null],
        situacao: [null],
        aplicacaoOrigem: [1],
        usuarioCriacao: [this.authService.getIdUser()],
      })
    );
  }
  addContato(formContato?) {
    const form: FormArray = this.form.get('contatos') as FormArray;
    form.push(
      this.formBuilder.group({
        email: [{ value: formContato?.email, disabled: true }],
        telefone: [{ value: formContato?.telefone, disabled: true }],
        whatsapp: [{ value: formContato?.whatsapp, disabled: true }],
        telegram: [{ value: formContato?.telegram, disabled: true }],
        principal: [{ value: formContato?.principal, disabled: true }],
        maskTelefone: [{ value: formContato?.maskTelefone, disabled: true }],
        aplicacaoOrigem: [
          { value: formContato?.aplicacaoOrigem, disabled: true },
        ],
        usuarioCriacao: [
          { value: formContato?.usuarioCriacao, disabled: true },
        ],
      })
    );
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'save',
        tooltip: this.form.value.id ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 && (!this.form.value.id || this.form.value.id),
        onClick: this.salvar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        condition: true,
        onClick: this.atualizar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar = () => {
    this.changeTabIndex(1);
  };

  getFilterUnidadeGestoraOptionsSelect(item) {}

  selecionar = () => {
    this.selecionarItem(this.getRegistrySelected());
  };

  doubleClick = (item) => {
    this.openModal ? this.selecionarItem(item) : this.prepareToUpdateForm(item);
  };

  selecionarItem(item) {
    this.modalRef.destroy(item);
  }

  novoCadastro = () => {
    this.changeTabIndex(0);
    this.reset();
    this.resetFabButtons();
  };

  reset() {
    this.limparPessoaJuridica();
    this.limparPessoaFisicaResponsavel();
    this.limparInstituicaoSiconfi();
    this.resetForm();
  }

  salvar = () => {
    this.onSubmit();
  };

  async onSubmit() {
    if (this.form.invalid) {
      return this.service.notification.warning(
        this.titulo,
        'Por favor, preencha todos os campos corretamente'
      );
    }
    await this.inserirOuAtualizarUnidadeGestora(this.form);
  }

  async inserirOuAtualizarUnidadeGestora(unidadeGestora) {
    let idMp = this.authService.getInfoToken().userInfo.idMunicipio;
    return await this.execute(unidadeGestora, idMp);
  }

  async execute(registro, idMp = null, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>${
        registro.id || registro.idPrivado
          ? 'Confirma as alterações a serem enviadas?'
          : 'Confirma os dados a serem cadastrados?'
      }</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        registro = await this.unidadeFormToUnidadeGestora(registro);
        if (registro.brasao)
          registro.brasao = await this.service.getUrlAvatar(
            registro.brasao,
            registro.razaoSocialPessoaJuridica
          );
        await this.service.insertOrUpdate(registro, idMp, entidade);
        this.loadingPage = false;

        return this.reset();
      },
    });
  }

  prepareToUpdateForm(unidadeGestora) {
    if (unidadeGestora.ordenadoresDespesa.length > 0) {
      this.clearAllFormArray('ordenadoresDespesa');
      for (let i = 0; i < unidadeGestora.ordenadoresDespesa.length; i++) {
        this.addOrdenador();
      }
    }
    this.form.patchValue({ ...unidadeGestora });

    this.form
      .get('ordenadoresDespesa')
      ['controls'].map((ordenador, index) =>
        this.consultaCpfOrdenador(ordenador.get('cpf').value, index, true)
      );
    this.consultaCnpj(unidadeGestora.cnpjPessoaJuridica);
    this.consultaCpf(unidadeGestora.cpfPessoaFisica);
    this.service
      .consultarPorParametro(
        { field: 'idPrivado', value: unidadeGestora.idPrivadoInstSiconf },
        'instituicao-siconfi'
      )
      .then((result) => {
        this.instituicaoSiconfi = result.data;

        this.form.patchValue({
          idPrivadoInstSiconf: result.data.idPrivado,
          nomeSiconfi: result.data.nome,
          idPublicoInstSiconf: result.data.idPublico,
        });
      });
    this.changeTabIndex(0);
  }

  async consultaCpfOrdenador(cpf, index, refresh = false) {
    if (!cpf || cpf?.length < 11) {
      return;
    }
    if (
      this.form.get(`ordenadoresDespesa.${index}.pessoaFisica`).value?.cpf ===
        cpf &&
      !refresh
    ) {
      return;
    }

    clearTimeout(
      this.form.get(`ordenadoresDespesa.${index}.intervaloDigitando`).value
    );

    this.form
      .get(`ordenadoresDespesa.${index}.pFNaoEncontrada`)
      .setValue(false);

    this.form.get(`ordenadoresDespesa.${index}.intervaloDigitando`).setValue(
      await setTimeout(async () => {
        this.service
          .consultaPessoaFisica(cpf)
          .then(async (result) => {
            this.form.get(`ordenadoresDespesa.${index}`).patchValue({
              pessoaFisica: result.data,
              cpf: result.data.cpf,
              nome: result.data.pessoa.nomPessoa,
            });
          })
          .catch((err) => {
            this.form
              .get(`ordenadoresDespesa.${index}.pFNaoEncontrada`)
              .setValue(true);
            this.form
              .get(`vinculacoes.${index}.messagePFNaoEncontrada`)
              .setValue(err.message);
            this.form.patchValue({
              pessoaFisica: null,
              cpf: null,
              nome: null,
            });
          });
      }, 500)
    );
  }

  async consultaCpf(cpf) {
    if (cpf?.length < 11 || !cpf) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    // this.cpfNaoEncontrado = false;
    this.intervaloDigitando = await setTimeout(async () => {
      await this.service
        .consultaPessoaFisica(cpf)
        .then((result) => {
          this.pessoaFisicaResponsavel = result.data;
          this.form.patchValue({
            idPessoaFisica: this.pessoaFisicaResponsavel.id,
            nomePessoaFisica: this.pessoaFisicaResponsavel.pessoa.nomPessoa,
            emailPessoaFisica: this.pessoaFisicaResponsavel.pessoa
              .emailPrincipal,
          });
        })
        .catch((err) => {
          this.pessoaFisicaResponsavel = err.data;
          // this.cpfNaoEncontrado = true;
          // this.messageCpfNaoEncontrado = err.message;
          this.form.patchValue({
            idPessoaFisica: null,
            nomePessoaFisica: null,
            emailPessoaFisica: null,
          });
        });
    }, 500);
  }

  async consultaCnpj(cnpj) {
    if (cnpj?.length < 14 || !cnpj) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    // this.cnpjNaoEncontrado = false;
    this.intervaloDigitando = await setTimeout(async () => {
      this.service
        .consultaPessoaJuridica(cnpj)
        .then((result) => {
          this.pessoaJuridica = result.data;

          this.clearAllFormArray('contatos');
          for (const contato of result.data.contatos) {
            this.addContato(contato);
          }

          const enderecoPrincipal = this.pessoaJuridica.enderecos.find(
            (endereco) => endereco.principal === 1
          );

          this.form.patchValue({
            idPessoaJuridica: this.pessoaJuridica.id,
            razaoSocialPessoaJuridica: this.pessoaJuridica.razaoSocial,
            nomCidade: enderecoPrincipal?.nomCidade,
            estadoCidade: enderecoPrincipal?.nomUf,
          });
        })
        .catch((err) => {
          this.pessoaJuridica = err.data;
          // this.cnpjNaoEncontrado = true;
          // this.messageCnpjNaoEncontrado = err.message;
          this.form.patchValue({
            idPessoaJuridica: null,
            razaoSocialPessoaJuridica: null,
            emailPessoaJuridica: null,
            nomCidade: null,
            estadoCidade: null,
          });
        });
    }, 500);
  }

  async unidadeFormToUnidadeGestora(form) {
    this.form.get('razaoSocialPessoaJuridica').enable();
    this.form.get('emailPessoaJuridica').enable();
    this.form.get('nomePessoaFisica').enable();
    this.form.get('emailPessoaFisica').enable();

    const formValue = form.value;
    formValue.ordenadoresDespesa.map((ordenadorDespesa) => {
      if (!ordenadorDespesa.idPrivado) {
        delete ordenadorDespesa.idPrivado;
      }
    });
    return formValue;
  }

  editar = () => {
    this.prepareToUpdateForm(this.getRegistrySelected());
  };

  editarUnidadeGestora = (item) => {
    this.prepareToUpdateForm(item);
  };

  deletar = () => {
    this.removerRegistro(this.getRegistrySelected());
  };

  atualizar = () => {
    this.filterUnidadeGestora(), this.changeTabIndex(1);
  };

  ngOnInit(): void {
    if (this.openModal) {
      this.resetFabButtons();
      this.atualizar();
    }
  }

  getWidthContent() {
    return window.innerWidth;
  }

  removerRegistro(registro, entidade = this.entidade) {
    return this.modalService.confirm({
      nzTitle: `<i>Deseja realmente remover o registro?</i>`,
      nzOnOk: async () => {
        this.loadingPage = true;
        await this.service.delete(registro, entidade, this.titulo).then(() => {
          this.removerRegistroGrid(registro);
        });
        this.loadingPage = false;
      },
    });
  }

  async uploadAvatarUg(event) {
    if (
      event.target.files[0].type !== 'image/png' &&
      event.target.files[0].type !== 'image/jpeg'
    ) {
      this.service.notification.warning(
        'Formato de arquivo inválido',
        'Por favor, selecione um arquivo PNG ou JPG.'
      );
      return false;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      this.form.controls.brasao.setValue(e.target.result);
    };
  }

  removerRegistroGrid(item: any): void {
    // this.displayData.slice(0, this.displayData.indexOf(item))
    this.displayData = this.displayData.filter(
      (data) => data.idPublico !== item.idPublico
    );
  }

  getRegistrySelected() {
    const registrys = this.displayData.filter((registry) => registry.checked);
    if (registrys.length !== 1) {
      this.service.notification.warning(
        this.titulo,
        'Muitos registro selecionados ou nenhum.'
      );
      return;
    }
    const registry = registrys[0];
    return registry;
  }

  check(item) {
    this.checkedAll = this.displayData.every(
      (data: any) => data.checked === true
    );
    this.checkAllIndeterminate = !this.checkedAll;
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  filterUnidadeGestora() {
    return this.getUnidadeGestoraPerPeriod(this.fieldsUnidadesGestorasSelected);
  }

  removeFiltroUnidadeGestoraPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableUnidadeGestoraColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsUnidadesGestorasSelect.splice(
      this.tableUnidadeGestoraColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsUnidadesGestorasSelected.splice(
      this.fieldsUnidadesGestorasSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsUnidadesGestorasSelected.length === 0) {
      this.showComboTagsUnidadesGestoras = false;
    }
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async pageChange(valor) {
    await this.getUnidadeGestoraPerPeriod(
      this.fieldsUnidadesGestorasSelected,
      Number(valor),
      10
    );
  }

  async getUnidadeGestoraPerPeriod(
    fieldsUnidadesGestorasSelected,
    page?: number,
    size?: number
  ) {
    this.loadingPage = true;
    await this.service
      .getAllRegistrosInInterval({
        params: fieldsUnidadesGestorasSelected,
        entidade: this.entidade,
        titulo: this.titulo,
        page,
        size,
      })
      .then(async (example: any) => {
        if (example.data.length === 0) {
          this.service.notification.warning(
            this.titulo,
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.pageTotal = example.data.paramsPaginator[0];
        this.displayData = example.data.resultado;

        this.loadingPage = false;
      })
      .catch((err) => {
        // this.service.notification.error(this.titulo, err);
        this.loadingPage = false;
      });
  }

  addFiltroValuesPorCampo() {
    if (this.fieldUnidadesGestorasSelected && this.searchInput) {
      if (this.fieldUnidadesGestorasSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldUnidadesGestorasSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      if (this.fieldsUnidadesGestorasSelected.length !== 0) {
        this.removeFiltroUnidadeGestoraPorField(
          this.fieldsUnidadesGestorasSelected[0]
        );
      }
      this.fieldsUnidadesGestorasSelected.push({
        props: this.fieldUnidadesGestorasSelected.value,
        field: this.fieldUnidadesGestorasSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldUnidadesGestorasSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsUnidadesGestorasSelect.findIndex(
        (a) => a.value === this.fieldUnidadesGestorasSelected.value
      );

      this.arrColumnsUnidadesGestorasSelect.splice(index, 1);

      this.showComboTagsUnidadesGestoras = true;
      this.fieldUnidadesGestorasSelected = '';
      this.searchInput = '';
      this.selectedUnidadesGestorasValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;

    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }
}
