import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AbstractCustomComponent } from './abstract-custom.component';

@Component({
  template: '<ng-template #templ></ng-template>',
  // tslint:disable-next-line: component-selector
  selector: 'ps-form-template',
})
export class PsFormTemplateComponent
  extends FieldType
  implements AfterViewInit, OnChanges {
  @ViewChild('templ', { read: ViewContainerRef }) target: ViewContainerRef;

  constructor(private resolver: ComponentFactoryResolver) {
    super();
  }
  ngAfterViewInit() {
    const f = this.resolver.resolveComponentFactory(this.to.component);
    const component: ComponentRef<AbstractCustomComponent> = this.target.createComponent<
      any
    >(f);
    component.instance.model = this.model;
    component.instance.field = this.field;
    component.instance.templateOptions = this.to;
    component.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {}
}
