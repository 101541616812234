import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Helper } from '../Helper';
import { AuthService } from './../../shared/services/auth.service';
import { AbstractService } from './abstract.service';
export interface AbstractResponse {
  message?: string;
  type?: string;
  data?: any;
}
@Injectable({
  providedIn: 'root',
})
export class PessoaFisicaService extends AbstractService {
  constructor(
    public http: HttpClient,
    public notification: NzNotificationService,
    public authService: AuthService
  ) {
    super('pessoa-fisica', http, notification, authService);
  }

  async getCidadesTableProps(): Promise<any> {
    return {};
  }

  validarCampo(campo, tipoValidacao) {
    return Helper.validarCampo(campo, tipoValidacao);
  }

  async delete(pessoaFisica: number, entidade) {
    return await this.excluir(pessoaFisica, entidade);
  }

  async consultaCidade(nomCidade, form) {
    //
    //   nomCidade === '' ||
    //     (form.get('naturalidade').value.hasOwnProperty('nomCidade') &&
    //       form.get('naturalidade').value.nomCidade ===
    //         (nomCidade.nomCidade
    //           ? nomCidade.nomCidade
    //           : nomCidade.replace('- [w]+', '')))
    //

    if (nomCidade === '') {
      return null;
    }

    return this.pesquisarAvancado(
      { page: 1, size: 25, field: { valor: 'nomCidade' }, value: nomCidade },
      'cidade'
    );
  }







  async getAllPessoasFisicasInInterval(
    fieldsPessoasFisicasSelected,
    page: number = 1,
    size: number = 10,
    all = null
  ): Promise<any> {
    const paramsPaginator = `page=${page}&size=${size}${all ? `&all=${all}` : ''
      }`;
    const filter =
      fieldsPessoasFisicasSelected.length !== 0
        ? `${fieldsPessoasFisicasSelected[0].props}=${fieldsPessoasFisicasSelected[0].value}`
        : '';
    return this.http
      .get(
        `${this.getServer(
          'pessoa-fisica'
        )}/pesquisar-avancado?${paramsPaginator}&${filter}`,
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: { data: any; message: string }) => {
        this.notification.success('Pessoa física', result.message);
        return result;
      })
      .catch((err: any) => {
        return this.notification.error('Pessoa física', err);
      });
  }



  // async update(pessoaFisica, cadastroBasico?) {
  //   pessoaFisica = this.prepareToRequest(pessoaFisica);
  //   return await this.execute(pessoaFisica);
  // }

  // prepareToRequest(pessoaFisica) {
  //   pessoaFisica = this.pessoaFormToPessoaFisica(pessoaFisica);
  //   this.verificaCheckBox(pessoaFisica);
  //   if (!pessoaFisica.pessoa.id) {
  //     delete pessoaFisica.pessoa.id;
  //   }
  //   pessoaFisica.contatos.map((contato) =>
  //     !contato.id ? delete contato.id : null
  //   );
  //   pessoaFisica.enderecos.map((endereco) =>
  //     !endereco.id ? delete endereco.id : null
  //   );
  //   pessoaFisica.dtNasc = pessoaFisica.dtNasc ? pessoaFisica.dtNasc : null;
  //   pessoaFisica.rg.dataEmissao = pessoaFisica.rg.dataEmissao
  //     ? pessoaFisica.rg.dataEmissao
  //     : null;
  //   return pessoaFisica;
  // }
}
